import axios from 'axios'


const api = {
	init: function(store) {


		// INTERCEPTEUR DE REQUETE (avant l'appel)
		// Ajoute le "token" dès qu'on fait une requête AJAX avec axios
		// passe également la valeur du loading de l'app à true (affiche un loader)
		axios.interceptors.request.use(function (config) {
			if (!config.params) config.params = {}
			if (!config.params['token']) config.params['token'] = store.getters.user.token
			return config;
		}, function (error) {
			return Promise.reject(error);
		});


		// INTERCEPTEUR DE REPONSE (après l'appel)
		// Affiche une notification en haut à droite en cas d'erreur
		// passe également la valeur du loading de l'app à false
		axios.interceptors.response.use(function (response) {
			if (response.data.status == 'error') store.dispatch('add_notification', { status: 'error', message: response.data.message })
			return response;
		}, function (error) {
			store.dispatch('add_notification', { status: 'error', message: "Oups ! Impossible de se connecter au serveur." })
			store.dispatch('set_loading', { loading: false })
			return Promise.reject(error);
		});


	}
}

export default api