<template>
	<transition name="pane-adresse-commerce-alimentation">
		<div class="paneleft pan-adresse-commerce-alimentation">
			<pane-adresse-retour accueil="" retour="commerces" cityscan="true" v-show="false"/>
			<pane-adresse-element
				:propObj="this.app.cityscan.adresse.alimentation"
				:sstyle="'color: rgb(124, 0, 41)'"
				:sclass="'cs-tile-icon cs-icon-thematic icon-1330-02 ng-star-inserted'"
				:titre="$t('alimentation_generale')"
				:detail="'commerce'"
				:retour="'commerces'"
			/>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'
import PaneAdresseElement from './PaneAdresseElement.vue'

export default {
	name: 'PaneAdresseCommerceAlimentation',
	data: function() {
		return {
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
	},

	components: { PaneAdresseRetour, PaneAdresseElement }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-commerce-alimentation-enter
	transform translateX(100%)
.pane-adresse-commerce-alimentation-leave-to
	transform translateX(-100%)

.pan-adresse-commerce-alimentation
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
