<template>
	<div class="stat-box" id='graph_destination'>
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-cyan-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-users text-cyan-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('part_investisseur') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('lissee_sur_les_4_derniers_trimestres') }}</p>
				</div>
				<div class="ml-auto">
					<div>
						<Button  icon="pi pi-image" class="p-button-rounded" @click="$store.dispatch('ExportToPng',{id: 'grde'})" />
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<div class="graph">
				<div>
					<br>
					<Knob id="grde" v-model="this.chartOptions.series[0].data[1].y" valueTemplate="{value}%" readonly :size="200" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Knob from 'primevue/knob';
import Button from 'primevue/button';

export default {
	name: 'GraphDestinationVentes',

	data: function() {
		return {
			items: [
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'grde'});} },
			],
			chartOptions: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie',
					style: {
						fontFamily: 'Nunito',
					}
				},
				tooltip: {
					pointFormat: '<strong>{point.percentage:.0f}%</strong>'
				},
				plotOptions: {
					pie: {
						allowPointSelect: false,
						dataLabels: {
							enabled: false
						},
						showInLegend: true
					}
				},
				series: [{
					name: 'Brands',
					colorByPoint: true,
					data: [
						{
							name: 'Occupant',
							y: 85,
							color: '#ccc',
						}, {
							name: 'Investisseur',
							y: 15,
							color: '#2196F3',
						}
					]
				}]
			}
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		maj: function() {
			let nbocc = 0
			let tout = 0
			let parocc = 0
			let parinv = 0
			for (let j=4; j<8; j++) {
				nbocc += this.app.livedata.volume_investisseur.ventes_occ.tableau[j]
				tout += this.app.livedata.volume_periode_2.vente.tableau[j]
			}
			if (nbocc >= 0) {
				if (nbocc >= tout) {
					parocc = 100
					parinv = 0
				} else {
					parocc = Math.round(nbocc / tout * 100)
					parinv = 100 - parocc
				}
			} else {
				if (tout > 0) {
					parocc = 0
					parinv = 100
				} else {
					parocc = 0
					parinv = 0
				}
			}
			this.chartOptions.series[0].data[0].y= parocc
			this.chartOptions.series[0].data[1].y= parinv
		}
	},
	
	mounted: function() {
		this.maj()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj()
			}
		},
	},

	components: { Knob,Button }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.stat-box
	.stat-box-header
		padding-bottom 0
	.stat-box-content
		padding 0 16px

.graph
	min-height 200px
	text-align center
	line-height @height
	display flex
	justify-content center

</style>
