import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './modules/app'
import map from './modules/map'
import user from './modules/user'
import report from './modules/report'

const state = {
	
}

const store = new Vuex.Store({
	state,
	modules: {
		app,
		map,
		user,
		report
	},
	strict: process.env.NODE_ENV !== 'production'
})

export default store