<template>
	<div class="notification" :class="notification.status" @click="remove_notification()">
		<icon class="icon-error" file="alert-triangle" :size="32" />
		<icon class="icon-success" file="check" :size="32" />
		<icon class="icon-warning" file="alert-triangle" :size="32" />
		<icon class="icon-infowarning" file="info2" :size="32" />
		{{ notification.message }}
	</div>
</template>

<script>

export default {
	name: 'Notification',
	data () {
		return {
			
		}
	},
	props: [ 'notification' ],
	methods: {
		remove_notification: function() {
			this.$store.dispatch('remove_notification', { notification: this.notification })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.notification
	display block
	margin 0 0 8px 0
	background #999
	width 300px
	padding 24px 16px 24px 64px
	border-radius radius
	cursor pointer
	font-weight 500
	line-height 20px
	color #fff
	transition 0.25s easeOutQuart
	z-index 5001
	&.error
		background-color red
		.icon-error
			display block
	&.success
		background-color darken(lime, 12%)
		.icon-success
			display block
	&.warning
		background-color orange
		.icon-warning
			display block
	&.info_warning
		background-color white
		color #6c21f9
		border 1px solid #6c21f9
		.icon-infowarning
			display block
	.icon
		display none
		position absolute
		left 20px
		top calc(50% - 16px)

</style>
