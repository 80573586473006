<template>
	<transition name="pane-adresse-retour">
		<div class="pan-adresse-retour">
			<div class="module_ret">
				<div v-if="app.module_actif" class="btns retour" :title="$t('retour')" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: retour })"><icon file="back" :size="24" /></div>
				<div v-if="app.module_actif" class="btns accueil" :class="{ vis: !homevisible }" title="Revenir au menu principal" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })"><icon file="home-two" :size="24" />&nbsp;</div>
				<pane-filters-promo v-show="neuf" />
				<map-avm v-show="neuf" />
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import MapAvm from '../pane-map/MapAvm.vue'
import PaneFiltersPromo from '../PaneFiltersPromo.vue'

export default {
	name: 'PaneAdresseRetour',
	data: function() {
		return {
			detail: '',
		}
	},

	props: {
		retour: {
			default: ''
		},
		accueil: {
			default: ''
		},
		yanport: {
			default: false
		},
		cityscan: {
			default: false
		},
		neuf: {
			default: false
		},
		aide: {
			default: false
		},
		homevisible: {
			default: true
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
	},

	components: { PaneFiltersPromo, MapAvm }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.vis
	visibility hidden
.pane-adresse-retour-enter
	transform translateX(100%)
.pane-adresse-retour-leave-to
	transform translateX(-100%)
.retour
	width 60px
	position absolute
	left 0px
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		left 0px !important

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.poweredcityscan
	position absolute
	left 185px
	top 18px
	width 180px
	height 60px
	vertical-align middle
	font-size: 18px
	font-style italic
	color primaryA400

.accueil
	width 60px;
	position absolute
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.neuf
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette0
	text-align center
	line-height 60px
	cursor pointer

.pan-adresse-retour
	position absolute
	top 0px
	float left
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%

.btnlayer_fu
	width 180px
	height 32px
	transition 0.5s easeOutQuart
	padding 0px 10px 10px 10px
	text-align center
	background #fafafa
	color #666
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	border solid 1px #333
	vertical-align middle
	line-height 32px
	color #666
	.divmap
		z-index 9999
		position absolute
		right calc(50% - 12px)
		top calc(50% - 12px)
		.icon
			display block

</style>
