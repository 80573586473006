<template>
	<div>
		<component :is="name"></component>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatBoxes from './stat-box/'

export default {
	name: 'StatBox',

	data: function() {
		return {
			
		}
	},

	props: [ 'name' ],
	
	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),
	
	components: StatBoxes
}
</script>

<style lang="stylus">
@import '../../../assets/css/app'


.stat-box
	display block
	width 100%
	margin 0 0 16px 0
	background #fff
	.stat-box-header
		padding 24px
		min-height 24px
		.title
			font-size 16px
			font-weight 600
			line-height 24px
		.tabs
			position absolute
			right 84px
			top 34px
			.tab
				display inline-block
				margin 0 0 0 4px
				padding 0 12px
				border-radius 40px
				line-height 24px
				font-size 12px
				color #333
				cursor pointer
				&:hover
					background-color light
				&.active
					background blue
					color #fff
		.tabs_other
			position absolute
			right 124px
			top 12px
			.tab
				display inline-block
				margin 0 0 4px 4px
				padding 0 12px
				border-radius 40px
				line-height 24px
				font-size 12px
				color #333
				cursor pointer
				font-weight 500
				&:hover
					background-color light
				&.active
					background grey
					color #fff
	.stat-box-content
		display block


.table
	table
		width 100%
		border-collapse collapse
		font-size 13px
		tr
			border-bottom 1px solid light
			&:first-child
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th, td
				padding 8px 
				text-align left
				&:first-child
					padding-left 24px
			th
				font-weight 600

.tabletwotitles
	table
		margin 5px
		width 99%
		border-collapse collapse
		font-size 13px
		tr
			border-bottom 1px solid light
			&:first-child
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:nth-child(2)
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th, td
				padding 8px 
				text-align center
				border-left 1px solid #666
				border-top 1px solid #666
				border-bottom 1px solid #666
				&:last-child
					border-right 1px solid #666
			th
				font-weight 600


.tableGrille
	table
		width 100%
		border-collapse collapse
		font-size 13px
		tr
			border-bottom 1px solid light
			&:first-child
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th
				background light
				color #666

			th, td
				padding 8px 
				text-align center
				border-left 1px solid #666
				border-top 1px solid #666
				border-bottom 1px solid #666
				&:last-child
					border-right 1px solid #666
			th
				font-weight 600


.sanstitre
	table
		margin 0px
		width 99%
		border-collapse collapse
		font-size 13px
		background white
		tr
			border-bottom 1px solid light
			&:first-child
				background white
				color #666
				text-transform none
				&:hover
					background-color light
				th
					padding 0px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th, td
				padding 5px
				text-align center
				border-left 1px solid #666
				border-top 1px solid #666
				border-bottom 1px solid #666
				&:first-child
					padding-left 5px
				&:last-child
					border-right 1px solid #666
			th
				font-weight 600

.info
	display block
	padding 16px 16px 16px 48px
	background lighten(blue, 90%)
	border-radius radius
	color blue
	font-weight 400
	line-height 16px
	.icon
		position absolute
		left 16px
		top calc(50% - 12px)

</style>
