<template>
	<div class="program" :class="{ inactif: program.commercialisation == 'termine', expanded: program == expanded_program, selected: program.selected }">
		<div class="program-main" @click="expand_program()" v-if="!app.loading">
			<div v-if="!program.pc" class="image">				
				<ImagePreview v-if="program.url_photo!='-'" :src="program.url_photo" width="112" height="90" @error="handleImageError"/>
				<ImagePreview v-else src="https://www.myadequation.fr/EFOCUS./no-camera2.png" width="112" height="90"/>
				<div v-if="!program.pc" style="height:100%">
					<div style="display: flex; left:4px;bottom:12px;">
						<div v-if="program.tva_reduite_prix_m2_parking_sans"><div class="tvaR sp">5.5%</div>&nbsp;</div>
						<div v-if="program.psla"><div class="PSLA sp">{{ $t('psla') }}</div>&nbsp;</div>
						<div v-if="program.brs"><div class="BRS sp">{{ $t('brs') }}</div>&nbsp;</div>

						<div style="cursor:pointer" v-if="program.liste_veb && program.liste_veb.length && vebacte()">
							<div><div class="vebacte sp">{{ $t('VEB') }}</div>&nbsp;</div>
						</div>
						<div style="cursor:pointer" v-else-if="program.liste_veb && program.liste_veb.length && vebresa()">
							<div><div class="vebresa sp">{{ $t('VEB') }}</div>&nbsp;</div>
						</div>

						
					</div>
				</div>
			</div>
			<div class="program-content_pc" v-if="program.pc">
				<p class="name" :title="program.nom" :class="program.commercialisation" style="font-weight:700 !important;">{{ program.adresse }} | {{ program.codepostal }} {{ program.ville }}</p>
				<p class="seller sperso" v-if="program.promoteur">{{ program.promoteur }}</p><p class="seller sperso" v-else>{{ $t('n.c.') }}</p>
				<p class="seller sperso">{{ $t('nombre_de_logements') }} : <span v-if="program.nb_log_pc">{{ program.nb_log_pc }}</span><span v-else class="seller sperso">{{ $t('n.c.') }}</span></p>
				<div class="forme-urbaine" v-if="program.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="24" style="color:#cccccc" /></div>
				<div class="forme-urbaine" v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
			</div>
			<div class="program-content" v-else>
				<p class="name" :title="program.nom" :class="program.commercialisation">{{ program.nom }}</p>
				<p class="city">{{ program.codepostal }} {{ program.ville }}</p>
				<p class="seller sperso" v-if="program.promoteur">{{ program.promoteur }}</p><p class="seller sperso" v-else>{{ $t('n.c.') }}</p>
				<div class="forme-urbaine" v-if="program.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="24" style="color:#cccccc" /></div>
				<div class="forme-urbaine" v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
				<div style="width: 100%">
					<div style="display: flex;right:0px;justify-content: flex-end;">
						<div v-if="getAvecStat()" style="width:100%"><div class="sansp">{{ $t('sans_parking') }}</div></div>
						<div class="prix" :title="getTitle()" v-if="!program.avant_premiere">&nbsp;{{ getPrice() }}/m²</div>
						<div class="prix" title="NC" v-else>{{ $t('n.c.') }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="program-main" @click="expand_program()" v-else>
			<div v-if="!program.pc" class="image">
				<Skeleton width="112px" height="90px"></Skeleton>
			</div>
			<div class="program-content_pc" v-if="program.pc">
				<Skeleton class="name"  width="170px" height="24px"></Skeleton>
				<Skeleton class="seller sperso"  width="170px" height="21px"></Skeleton>
				<Skeleton class="seller sperso"  width="170px" height="21px"></Skeleton>
				<Skeleton class="forme-urbaine"  width="24px" height="24px"></Skeleton>
			</div>
			<div class="program-content" v-else>
				<p class="name"><Skeleton width="170px" height="16px"></Skeleton></p>
				<p class="city"><Skeleton width="212px" height="16px"></Skeleton></p>
				<p class="seller"><Skeleton width="170px" height="16px"></Skeleton></p>
				<div class="forme-urbaine"><p style="font-size: 2px;">&nbsp;</p><Skeleton width="24px" height="24px"></Skeleton></div>
				<p style="font-size: 5px;">&nbsp;</p>
				<div style="width: 100%">
					<div style="display: flex;right:0px;justify-content: flex-end;">
						<div class="prix"><Skeleton width="80px" height="20px"></Skeleton></div>
					</div>
				</div>
			</div>
		</div>
		<div class="cz" v-if="!app.loading">
			<label v-if="program.pc||(app.authorized&&!app.nolive&&!program.avant_premiere&&program.stock_initial>5)" class="checkbox" :class="{ checked: false }"  :id="program.id + '-' + program.ville" :data="program.ville">
				<input :data="program.ville" type="checkbox" :checked="program.selected" @click="select_program(program.id + '-' + program.ville, program.pc)"/>
				<span class="chk"><icon file="check" :size="16" /></span>
				<span class="text"></span>
			</label>
		</div>
		<div class="cz" v-else>
			<p class="name"><Skeleton width="22px" height="22px"></Skeleton></p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import ImagePreview from 'primevue/imagepreview';
import Skeleton from 'primevue/skeleton';

export default {
	name: 'Program',

	data: function PaneProgram() {
		return {
			expanded: false,
			filter: 'volume',
			filter2: 'typologie',
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png'
		}
	},

	props: [ 'program' ],

	computed: Object.assign(mapGetters([ 'app', 'expanded_program', 'selected_secteurs' ]), {

	}),

	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},
		vebacte: function() {
			let n = 0
			if (this.program.liste_veb && this.program.liste_veb.length) {
				for (let index = 0; index < this.program.liste_veb.length; index++) {
					if (this.program.liste_veb[index].tra_veb_date_acte) n += this.program.liste_veb[index].tra_stock_initial
				}
			}
			return n
		},

		vebresa: function() {
			let n = 0
			if (this.program.liste_veb && this.program.liste_veb.length) {
				for (let index = 0; index < this.program.liste_veb.length; index++) {
					if (this.program.liste_veb[index].tra_veb_date_reservation && !this.program.liste_veb[index].tra_veb_date_acte) n += this.program.liste_veb[index].tra_stock_initial
				}
			}
			return n
		},
		getPrice: function() {
			let nPrice=0
			let sEtat = 'Prix_Stock_'
			// Gestion préférences
			//if (this.app.preferences.neuf.listing.etat == "vente" ) sEtat = 'Prix_Vente_'; //Mes1 = 'Prix des ventes ';
			//if (this.app.preferences.neuf.listing.etat == "offre" ) sEtat = 'Prix_Offre_'; //Mes1 = 'Prix de l\'offre ';
			
			let sTva = 'TVAP_LIBRE_'
			// Gestion préférences
			//if (this.app.preferences.neuf.listing.tva == "aide" ) sTva = 'TVAP_AIDE_'; //Mes2 = 'en tva pleine aidé '
			//if (this.app.preferences.neuf.listing.tva == "reduite" ) sTva = 'TVAR_'; //Mes2 = 'en tva réduite '

			let sPk = 'APK'
			// Gestion préférences
			//if (this.app.preferences.neuf.listing.parking == "sans" ) sPk = 'SPK'; //Mes3 = 'hors parking'
			nPrice = this.program[sEtat + sTva + sPk]
			
			if (nPrice <= 0)
			{
				if (this.program.tva_reduite_prix_m2_parking_avec > 0) {
					nPrice = this.program.tva_reduite_prix_m2_parking_avec
				} else {
					if (this.program.tva_reduite_prix_m2_parking_sans > 0) {
						nPrice = this.program.tva_reduite_prix_m2_parking_sans
					} else {
						if (this.program.tva_pleine_libre_prix_m2_parking_avec > 0) {
							nPrice = this.program.tva_pleine_libre_prix_m2_parking_avec
						} else {
							if (this.program.tva_pleine_libre_prix_m2_parking_sans > 0) {
								nPrice = this.program.tva_pleine_libre_prix_m2_parking_sans
							} else {
								if (this.program.tva_pleine_aide_prix_m2_parking_avec > 0) {
									nPrice = this.program.tva_pleine_aide_prix_m2_parking_avec
								} else {
									if (this.program.tva_pleine_aide_prix_m2_parking_sans > 0) {
										nPrice = this.program.tva_pleine_aide_prix_m2_parking_sans
									}
								}
							}
						}
					}
				}
			}
			const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(Math.round(nPrice/10)*10)
			}
		},

		getTitle: function() {
			let messageprice = ''
			let nPrice=0
			let sEtat = 'Prix_Stock_'
			let Mes1 = 'Prix de tous les logements '
			// Gestion préférences
			//if (this.app.preferences.neuf.listing.etat == "vente" ) {
			//	sEtat = 'Prix_Vente_';
			//	Mes1 = 'Prix des ventes ';
			//}
			//if (this.app.preferences.neuf.listing.etat == "offre" ) {
			//	sEtat = 'Prix_Offre_';
			//	Mes1 = 'Prix de l\'offre ';
			//}
			
			let sTva = 'TVAP_LIBRE_'
			let Mes2 = 'en tva pleine libre '
			// Gestion préférences
			//if (this.app.preferences.neuf.listing.tva == "aide" ) {
			//	sTva = 'TVAP_AIDE_';
			//	Mes2 = 'en tva pleine aidé ';
			//}
			//if (this.app.preferences.neuf.listing.tva == "reduite" ) {
			//	sTva = 'TVAR_';
			//	Mes2 = 'en tva réduite ';
			//}

			let sPk = 'APK'
			let Mes3 = 'parking inclus'
			// Gestion préférences
			//if (this.app.preferences.neuf.listing.parking == "sans" ) {
			//	sPk = 'SPK';
			//	Mes3 = 'hors parking';
			//}
			
			nPrice = this.program[sEtat + sTva + sPk]
			
			if (nPrice > 0) {
				messageprice = Mes1 + Mes2 + Mes3
			}
			else {
				if (this.program.tva_reduite_prix_m2_parking_avec > 0) {
					messageprice = "Prix de tous les logements en TVA réduite parking inclus"
				} else {
					if (this.program.tva_reduite_prix_m2_parking_sans > 0) {
						messageprice = "Prix de tous les logements en TVA réduite hors parking"
					} else {
						if (this.program.tva_pleine_libre_prix_m2_parking_avec > 0) {
							messageprice = "Prix de tous les logements en TVA pleine libre parking inclus"
						} else {
							if (this.program.tva_pleine_libre_prix_m2_parking_sans > 0) {
								messageprice = "Prix de tous les logements en TVA pleine libre hors parking"
							} else {
								if (this.program.tva_pleine_aide_prix_m2_parking_avec > 0) {
									messageprice = "Prix de tous les logements en TVA pleine aidée parking inclus"
								} else {
									if (this.program.tva_pleine_aide_prix_m2_parking_sans > 0) {
										messageprice = "Prix de tous les logements en TVA pleine aidée hors parking"
									}
								}
							}
						}
					}
				}
			}
            return messageprice
		},

		getAvecStat: function() {
			return (!this.program.tva_reduite_prix_m2_parking_avec && !this.program.tva_pleine_libre_prix_m2_parking_avec && !this.program.tva_pleine_aide_prix_m2_parking_avec)
		},

		DonneTrimestre: function(val) {
			let DateTrim
			let t = String(val).substr(4,2)
			let a = String(val).substr(0,4)
			switch(parseInt(t))
			{
				case 1:
				case 2:
				case 3:
					DateTrim = "1T " + a; 
					break;
				case 4:
				case 5:
				case 6:
					DateTrim = "2T " + a; 
					break;
				case 7:
				case 8:
				case 9:
					DateTrim = "3T " + a; 
					break;
				case 10:
				case 11:
				case 12:
					DateTrim = "4T " + a; 
					break;
			}
			return DateTrim
		},

		DonneValeurPrix: function(val, fin) {
			if (val==0) {
				return "-"
			} else {
				return val + fin
			}
		},

		DonneValeurPrix1000: function(val) {
			if (val==0) {
				return "-"
			} else {
				return Math.round(val/1000)
			}
		},

		expand_program: function() {
			if (this.expanded_program != this.program) {
				this.$store.dispatch('expand_program', { program: this.program })
				this.$router.push({ name: 'program', params: { id: this.program.id }}).catch(()=>{});
			} else {
				this.$store.dispatch('expand_program', { program: false })
				this.$router.push({ name: 'neuf'}).catch(()=>{});
			}
		},

		select_program: function(id, pc) {
			let selected = false
			if (document.getElementById(id).className == 'checkbox checked') {
				document.getElementById(id).className = 'checkbox'
				selected = false
				if (!pc) this.$store.dispatch('dec_nbprogselect', {})
				else  this.$store.dispatch('dec_nbpcselect', {})
			} else {
				document.getElementById(id).className = 'checkbox checked'
				selected = true
				if (!pc) this.$store.dispatch('inc_nbprogselect', {})
				else this.$store.dispatch('inc_nbpcselect', {})
			}
			for (let i in this.app.programs) {
				if ((this.app.programs[i].id + "-" + this.app.programs[i].ville)==id) {
					if (this.app.programs[i].marker) {
						if (selected) {
							this.app.programs[i].marker.classList.add('selected')
							this.app.programs[i].marker.classList.remove('invisible')
						} else {
							this.app.programs[i].marker.classList.remove('selected')
							if (this.app.uniquementselection) this.app.programs[i].marker.classList.add('invisible')
						}
					}
				}
			}
		},

		show_program_on_map: function() {
			this.$store.dispatch('center_map', { coords: { lat: this.program.location.lat, lng: this.program.location.lon }, zoom: 16 })
		},

		print: function() {
			this.$store.dispatch('set_print', { print: 'expanded-program' })
		}
	},
	
	components: { ImagePreview, Skeleton }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.cz
	position absolute
	right 4px !important
	top 6px !important

.program
	border-right: 4px solid white;
	border-top 1px solid #eee
	background #FFF
	&.inactif
		.program-main
			//background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		border-right 4px solid lighten(primaryA100,92%) !important
		&:hover
			.program-main
				background lighten(primaryA100,92%)
		.program-main
			background lighten(primaryA100,92%) !important
			.program-content
				.name, .prix
					color palette2 !important
	&:hover
		border-right 4px solid hsl(153deg 48% 49%)
	//.program-main
		//background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.cz
			display none !important
		.program-main
			background #fff
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller, .prix
					color #fff !important
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 100%
						background #eee
				.forme-urbaine
					display none !important
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		padding-top 8px
		padding-bottom 8px
		padding-right 4px
		padding-left 8px

		overflow hidden
		cursor pointer
		.image
			position absolute
			height 90px
			width 112px
			border-radius 12px
			overflow hidden
			background #eee url(../../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 120px
			.name
				color #333 !important
				font-weight 700
				height 24px
				font-size 0.9rem
				line-height 24px
				white-space nowrap
				width 170px
				text-overflow ellipsis
				overflow hidden
			.city, .address
				font-size 12px
			.seller
				color #333 !important
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				width 170px
				text-overflow ellipsis
				overflow hidden
				white-space nowrap
				line-height 24px
			.forme-urbaine
				position absolute
				top 40px
				right 0px
				color #999
		.program-content_pc
			margin-left 10px
			.name
				color #333 !important
				font-weight 600
				height 24px
				font-size 13px
				line-height 24px
				white-space nowrap
				width 290px
				text-overflow ellipsis
				overflow hidden
			.city, .address
				font-size 12px
			.seller
				color #333 !important
				margin 8px 0 0 0
				font-size 12px
				width 270px
				text-overflow ellipsis
				overflow hidden
				white-space nowrap
				line-height 24px
			.forme-urbaine
				position absolute
				top 40px
				right 0px
				color #999
	
		.price
			position absolute
			left 10px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

	.program-detail
		overflow hidden
		background #fff
		height auto
		&.detail-enter, &.detail-leave-to
			height 430px
			background-color red
		.program-detail-content
			padding 16px
			.resume
				margin 8px 0 0 0
				&:first-child
					margin 0
				&:after
					content ''
					display block
					clear both
				.resume-data
					float left
					width 33.3333%
					margin 0 0 8px 0
					.label
						font-size 10px
						text-transform uppercase
						color #999
					.value
						font-weight 500
			.program-detail-info
				padding 8px 0
				.program-detail-info-filters
					margin 0 0 8px 0
				.program-detail-info-content
					padding 8px 0
					background #f5f5f5
					border-radius radius
					overflow hidden
					table
						width 100%
						border-collapse collapse
						tr
							border-bottom 1px solid #eee
							&:last-child
								border none
							&:hover
								td
									background #eee
							td, th
								padding 4px 8px
							th
								font-size 12px
								font-weight 500
							td
								text-align center
								&:first-child
									text-align left
									font-size 12px
									font-weight 500
									padding-left 16px
			.actions
				margin 8px 0 0 0
				.action
					display inline-block
					margin 0 24px 0 0
					height 32px
					color #666
					font-size 12px
					font-weight 500
					line-height @height
					cursor pointer
					&:hover
						color #333
					.icon
						float left
						margin 4px 8px 0 0


.prix
	text-align right
	font-weight 700
	color #333
	font-size 0.9rem

.sp
	padding 5px 6px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 10px
	line-height 10px
	color: var(--primary-color-text);
	margin-right 2px
	height 20px
	left 0
	bottom 10px
	background #3b82f6
	box-sizing border-box
	border-radius 20px

.vebacte
	background #fd943a

.vebresa
	background #fdc13a
	
.BRS
	width 36px
.VEB
	background #fdc13a
.PSLA
	width 42px
.tvaR
	width 34px
.sansp
	padding 5px 6px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 10px
	line-height 10px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
	margin-right 2px
	height 20px
	left 0
	bottom 0px
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	width 84px
.vide
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	font-size 12px
	line-height 16px
	letter-spacing 0.4px

.sperso {
	margin: 0px !important;
    padding-bottom: 4px !important;
    padding-top: 2px !important;
}

@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
