<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="rplsencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;" id="RplsEx">
				<div class="pan-titre-programme" id="pan-titre-rpls">
					<div class="module_ret">
						<div class="btns fermer" :title="$t('Fermer')"  @click="expand_rpls();$store.dispatch('set_help_rs', {aide:false})"><icon file="fermer" :size="16" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateImage(expanded_rpls.residence);"><icon file="download" :size="24" />&nbsp;</div>
<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div class="indice_confiance" :class="{ pc : true }">
							{{ $t('Fiche résidence sociale') }}
						</div>
					</div>
					<br/>
				</div>
				<div><br><br><br><br></div>
				<div>
					<div class="program" :class="{ inactif: false }">
						<div class="program-main" style="height:285px;">
							<a v-if="expanded_rpls.position && expanded_rpls.position.lat" target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rpls.position.lat + ',' + expanded_rpls.position.lon">
								<div class="image">
									<img @error="handleImageError" :src="expanded_rpls.urlphoto" alt="">
								</div>
							</a>
						</div>

						<div class="program-main" style="padding: 8px !important;">
							<div class="program-content">
								<b><p class="name" v-if="expanded_rpls.COMPLGEO && !isInteger(expanded_rpls.COMPLGEO) && expanded_rpls.COMPLGEO!=expanded_rpls.DEPCOM_LIBELLE" :title="expanded_rpls.COMPLGEO">{{ expanded_rpls.COMPLGEO }}</p><p class="name" v-else>{{ expanded_rpls.NUMVOIE }}&nbsp;{{ expanded_rpls.NOMVOIE }}</p></b>
							</div>
						</div>
					</div>
					<div class="program-detail">
						<div class="program-detail-content" style="padding-top: 8px !important;">
							<div class="program-detail-info">
								<div class="program-maj" v-if="expanded_rpls.date_elk">
									<div v-if="expanded_rpls.date_elk" style="font-size: 14px;" class="recent">{{ $t('Données de l\'année ') }} {{parseInt(expanded_rpls.date_elk.substring(0, 4))-1}}</div>
									<div><br v-if="expanded_rpls.date_elk"><span v-if="!expanded_rpls.date_elk">{{ $t('donnees') }}</span> {{ $t('publiees_le') }} {{expanded_rpls.date_elk.substring(8,10)}}/{{expanded_rpls.date_elk.substring(5,7)}}/{{expanded_rpls.date_elk.substring(0, 4)}}</div>
								</div>
								<br>
								<ul class="list-none p-2 m-0">
									<li>
										<ul class="list-none p-0 m-0 overflow-hidden">
											<li v-if="expanded_rpls.nb_logement">
												<div style="display: flex;" class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-box mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rpls.nb_logement}} {{$t('logement')}}</span><span class="font-medium text-700" v-if="expanded_rpls.nb_logement>1">s</span>
													<div class="pl-5" style="display: flex;">
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbT1!=null && expanded_rpls.nbT1">
															<icon file="2" :size="24" class="mr-2 text-700" v-if="expanded_rpls.nbT1!=null"/>
															<span class="font-medium text-700" v-if="expanded_rpls.nbT1!=null">{{expanded_rpls.nbT1}} {{$t('T1')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbT2!=null && expanded_rpls.nbT2">
															<icon file="2" :size="24" class="mr-2 text-700" v-if="expanded_rpls.nbT2!=null"/>
															<span class="font-medium text-700" v-if="expanded_rpls.nbT2!=null">{{expanded_rpls.nbT2}} {{$t('T2')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbT3!=null && expanded_rpls.nbT3">
															<icon file="3" :size="24" class="mr-2 text-700" v-if="expanded_rpls.nbT3!=null"/>
															<span class="font-medium text-700" v-if="expanded_rpls.nbT3!=null">{{expanded_rpls.nbT3}} {{$t('T3')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbT4!=null && expanded_rpls.nbT4">
															<icon file="4" :size="24" class="mr-2 text-700" v-if="expanded_rpls.nbT4!=null"/>
															<span class="font-medium text-700" v-if="expanded_rpls.nbT4!=null">{{expanded_rpls.nbT4}} {{$t('T4')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbT5!=null && expanded_rpls.nbT5">
															<icon file="5" :size="24" class="mr-2 text-700" v-if="expanded_rpls.nbT5!=null"/>
															<span class="font-medium text-700" v-if="expanded_rpls.nbT5!=null">{{expanded_rpls.nbT5}} {{$t('T5')}}</span>
														</div>
													</div>
												</div>

												<div style="display: flex;" class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-building mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rpls.TYPECONST_LIBELLE}}</span>
												</div>

												<div style="display: flex;" class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-euro mr-2 text-700"></i>
													<span class="font-medium text-700">{{$t('Financement')}}</span>
													<div class="pl-5" style="display: flex;">
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbHBM + expanded_rpls.nbHLMO + expanded_rpls.nbILM + expanded_rpls.nbILN + expanded_rpls.nbPLA + expanded_rpls.nbPLR + expanded_rpls.nbPLUS + expanded_rpls.nbCFF">
															<span class="font-medium text-700">{{expanded_rpls.nbHBM + expanded_rpls.nbHLMO + expanded_rpls.nbILM + expanded_rpls.nbILN + expanded_rpls.nbPLA + expanded_rpls.nbPLR + expanded_rpls.nbPLUS + expanded_rpls.nbCFF}} PLUS</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbPLAI + expanded_rpls.nbPLAIS">
															<span class="font-medium text-700" v-if="expanded_rpls.nbPLS!=null||expanded_rpls.nbPLAIS!=null">{{expanded_rpls.nbPLAI + expanded_rpls.nbPLAIS}} PLAI</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbPLS + expanded_rpls.nbPAPL">
															<span class="font-medium text-700" v-if="expanded_rpls.nbPLS!=null||expanded_rpls.nbPAPL!=null">{{expanded_rpls.nbPLS + expanded_rpls.nbPAPL}} PLS</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbPLI + expanded_rpls.nbPCL">
															<span class="font-medium text-700" v-if="expanded_rpls.nbPLI!=null||expanded_rpls.nbPCL!=null">{{expanded_rpls.nbPLI + expanded_rpls.nbPCL}} PLI</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rpls.nbAUTRE">
															<span class="font-medium text-700" v-if="expanded_rpls.nbAUTRE!=null">{{expanded_rpls.nbAUTRE}} {{$t('Autre')}}</span>
														</div>
													</div>

												</div>
											</li>
											<li v-if="expanded_rpls.LIBSEGPATRIM">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-user mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rpls.LIBSEGPATRIM}}</span>
												</a>
											</li>
											<li>
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-map-marker mr-2 text-700"></i>
													<span class="font-medium text-700">{{ expanded_rpls.NUMVOIE }} {{ expanded_rpls.TYPVOIE }} {{ expanded_rpls.NOMVOIE }}, {{ expanded_rpls.DEPCOM_CODE }} {{ expanded_rpls.DEPCOM_LIBELLE }}</span>
													<span class="inline-flex align-items-center justify-content-center ml-auto bg-blue-100 text-blue-900 border-circle" style="min-width: 2rem; height: 2rem"><a v-if="expanded_rpls.position && expanded_rpls.position.lat" target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rpls.position.lat + ',' + expanded_rpls.position.lon"><img alt="streeview" src="https://www.myadequation.fr/EFOCUS/streetview.png" :title="$t('voir_sur_google_street_view')"/></a></span>
												</a>
											</li>
											<li v-if="expanded_rpls.CONSTRUCT">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-calendar mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rpls.CONSTRUCT}}</span>
												</a>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
import domtoimage from 'dom-to-image'
import Button from 'primevue/button';

export default {
	name: 'PaneRpls',

	data: function() {
		return {
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'base',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5,
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_rpls', 'selected_rpls_secteurs' ]), {
		
	}),

	watch: {
		'app.expanded_rpls': function(newValue) {
			if (newValue) {
				this.filter_svo = "s"
			}
		},
	},
	
	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},

		isInteger: function(str) {
			const num = Number(str);
			return Number.isInteger(num);
		},

		generateImage(id) {
			const elementToExport = document.getElementById('RplsEx');
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
			};

			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'RPLS_' + id + '.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});

		},

		getTxtTaux: function(value) {
			switch (value) {
				case 'Faible':
					return '< 70 %'
				case 'Moyen':
					return '70 - 90 %'
				case 'Fort':
					return 'plus de 90 %'
				default:
					return value
			}
		},
		
		getTxtTauxClass: function(value) {
			if (value < 0.7) return 'Faible'
			else {
				if (value < 0.9) return 'Moyen'
				else return 'Fort'
			}
		},

		getTxtClass: function(value) {
			switch (value) {
				case '< 70%':
					return 'Faible'
				case '70 - 90%':
					return 'Moyen'
				case '> 90%':
					return 'Fort'
				default:
					return ''
			}
		},

		expand_rpls: function() {
			this.$router.push({ name: 'rpls'}).catch(()=>{});
			if (this.expanded_rpls != this.rpls) {
				this.$store.dispatch('expand_rpls', { rpls: this.rpls })
			} else {
				this.$store.dispatch('expand_rpls', { rpls: false })
			}
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "rplsencours"})
			this.$store.dispatch('SendEvent', { action: "program_download", opt_label: this.expanded_rpls.COMPLGEO, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-rpls' })
		}
	},

	// eslint-disable-next-line
	components: { domtoimage, Button }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	height calc(100% - 120px)
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 200px
			top 16px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

.program-detail
	overflow hidden
	height auto
	&.detail-enter, &.detail-leave-to
		height 430px
		background-color red
	.program-detail-content
		padding 16px
		.resume
			margin 8px 0 0 0
			&:first-child
				margin 0
			&:after
				content ''
				display block
				clear both
			.resume-data
				float left
				width 33.3333%
				margin 0 0 8px 0
				.label
					font-size 10px
					text-transform uppercase
					color #999
				.value
					font-weight 500
		.actions
			margin 8px 0 0 0
			.action
				display inline-block
				margin 0 24px 0 0
				height 32px
				color #666
				font-size 12px
				font-weight 500
				line-height @height
				cursor pointer
				&:hover
					color #333
				.icon
					float left
					margin 4px 8px 0 0
		.program-detail-info
			padding 8px 0
			.program-detail-info-filters
				text-align center
				margin 0 0 8px 0
			.program-detail-info-content
				padding 8px 0
				background #f5f5f5
				border-radius radius
				overflow hidden
				table
					width 100%
					border-collapse collapse
					tr
						&:last-child
							border none
						&:hover
							td
						td, th
							padding 4px 8px
						th
							font-size 12px
							font-weight 500
						td
							text-align center
							&:first-child
								text-align left
								font-size 12px
								font-weight bold
								padding-left 16px

.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 620px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.cible
	color #000

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.Faible
	color #f2131f
	font-weight 600
.Moyen
	color #f2aa1f
	font-weight 600
.Fort
	color #1eaa1f
	font-weight 600
.recent
	color #1eaa1f
	font-weight 600

.center
	text-align center !important

@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
