<template>
	<transition name="pane-adresse-icones">
		<div class="paneleft pan-adresse-icones">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" v-show="false"/>
			<div class="panecontent wrapper-scroll">
				<ul class="accordion-menu">
					<li v-for="i in app.listeicons" style="height:48px" v-bind:key="i">
						<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic ng-star-inserted" :class="getIcone(i)"></span></div>
						<div class="titre_sommaire"><b>{{i}}</b></div>
					</li>
				</ul>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseIcones',
	data: function() {
		return {
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
		getIcone: function(icone) {
            return 'icon-' + icone
		},
	},
	
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/cityscan.css'

.pane-adresse-icones-enter
	transform translateX(100%)
.pane-adresse-icones-leave-to
	transform translateX(-100%)

.pan-adresse-icones
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.level_one
	background url(../../assets/img/level_one.png) center center no-repeat
.level_two
	background url(../../assets/img/level_two.png) center center no-repeat
.level_three
	background url(../../assets/img/level_three.png) center center no-repeat
.level_four
	background url(../../assets/img/level_four.png) center center no-repeat
.level_five
	background url(../../assets/img/level_five.png) center center no-repeat

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
