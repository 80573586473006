<template>
	<div class="switch-button" :class="divise" :style="{height: size2}">
		<div class="switch-button-content">
			<icon v-if="icon" :file="icon" :size="size" /><p class="name">{{ label }}</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'SwitchButton',
	data: function() {
		return {

		}
	},

	props: {
		icon: { default: '' },
		size: { default: '32' },
		size2: { default: '32px' },
		label: { default: '' },
		divise: { default: 'two' }
	},

	computed: Object.assign(mapGetters([ 'app' ]), {

	}),
	
	methods: {
		
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.switch-button
	float left
	padding 0 0 8px 8px !important
	&:hover
		.switch-button-content
			background darken(light, 5%)
			&.active
				background palette0
			&.inactive
				background #ccc
			
	&.active
		.switch-button-content
			background primaryA400
			color #fff
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
	&.small
		.switch-button-content
			height 48px
			.name
				margin 0
				font-size 14px
	.switch-button-content
		height 80%
		display flex
		flex-direction column
		align-items center
		justify-content center
		cursor pointer
		text-align center
		font-size 12px
		color #666
		border-radius radius
		background none
		.name
			margin 4px 0 0 0
			font-weight 600
			font-family volte, sans-serif
			line-height 14px


.one
	width 100%		
.two
	width 50%
.three
	width 33%
.four
	width 25%
.five
	width 20%
.six
	width 16.6%
.seven
	width 14.25%
.eight
	width 12.5%

</style>
