<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<p class="title">{{$t('Scoring')}} {{ $t('acces_route_rapide') }}</p>
		</div>
		<div class="stat-box-content">
			<div class="graph">
				<vue-apex-charts  :options="chartOptions" :series="series"></vue-apex-charts>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'apexcharts'
export default {
	name: 'GraphDestinationVentes',

	data: function() {
		return {
			chartOptions: {
				chart: {
					height: 350,
					type: 'radar',
				},
				title: {
					text: 'Basic Radar Chart'
				},
				xaxis: {
					categories: ['January', 'February', 'March', 'April', 'May', 'June']
				}
			},
			series: [{
				name: 'Series 1',
				data: [80, 50, 30, 40, 100, 20],
			}]
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		maj: function() {
			let nbocc = 0
			let tout = 0
			let parocc = 0
			let parinv = 0
			for (let j=4; j<8; j++) {
				nbocc += this.app.livedata.volume_investisseur.ventes_occ.tableau[j]
				tout += this.app.livedata.volume_periode_2.vente.tableau[j]
			}
			if (nbocc >= 0) {
				if (nbocc >= tout) {
					parocc = 100
					parinv = 0
				} else {
					parocc = Math.round(nbocc / tout * 100)
					parinv = 100 - parocc
				}
			} else {
				if (tout > 0) {
					parocc = 0
					parinv = 100
				} else {
					parocc = 0
					parinv = 0
				}
			}
			this.chartOptions.series[0].data[0].y= parocc
			this.chartOptions.series[0].data[1].y= parinv
		}
	},
	mounted: function() {

	},

	components: { VueApexCharts }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.stat-box
	.stat-box-header
		padding-bottom 0
	.stat-box-content
		padding 0 16px

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
