<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-orange-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-flag text-orange-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('impact') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('selon_les_dispositifs') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<div class="table">
				<table id='datatable_dispositifs'>
					<tbody>
						<tr>
							<th id="dispositif_11"></th>
							<th id="dispositif_12">{{app.livedata.volume_aide.intitule_colonne[0]}}</th>
							<th id="dispositif_13">{{app.livedata.volume_aide.intitule_colonne[1]}}</th>
							<th id="dispositif_14">{{app.livedata.volume_aide.intitule_colonne[2]}}</th>
							<th id="dispositif_15">{{app.livedata.volume_aide.intitule_colonne[3]}}</th>
							<th id="dispositif_16">{{app.livedata.volume_aide.intitule_colonne[4]}}</th>
							<th id="dispositif_17">{{app.livedata.volume_aide.intitule_colonne[5]}}</th>
							<th id="dispositif_18">{{app.livedata.volume_aide.intitule_colonne[6]}}</th>
							<th id="dispositif_19">{{app.livedata.volume_aide.intitule_colonne[7]}}</th>
						</tr>
						<tr>
							<th id="dispositif_21">{{app.livedata.volume_aide.ventes_aide.libelle}}</th>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[0]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[1]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[2]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[3]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[4]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[5]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[6]}}</td>
							<td>{{app.livedata.volume_aide.ventes_aide.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="dispositif_31">{{app.livedata.volume_aide.pervente_aide.libelle}}</th>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[0] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[1] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[2] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[3] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[4] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[5] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[6] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_aide.tableau[7] + " " + app.livedata.volume_aide.pervente_aide.unite}}</td>
						</tr>
						<tr>
							<th id="dispositif_41">{{app.livedata.volume_aide.ventes_libre.libelle}}</th>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[0]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[1]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[2]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[3]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[4]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[5]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[6]}}</td>
							<td>{{app.livedata.volume_aide.ventes_libre.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="dispositif_51">{{app.livedata.volume_aide.pervente_libre.libelle}}</th>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[0] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[1] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[2] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[3] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[4] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[5] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[6] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
							<td>{{app.livedata.volume_aide.pervente_libre.tableau[7] + " " + app.livedata.volume_aide.pervente_libre.unite}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';

export default {
	name: 'ArrayDispositifQPV',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'datatable_dispositifs', name: 'Impact dispositifs'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'datatable_dispositifs'});} },
			]			
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
		
	}),

	methods: {
		
	},

	components: { SpeedDial }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'




</style>
