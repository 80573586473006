<template>
	<div class="footer">
		<div class="surface-section px-4 md:px-6 lg:px-8">
			<div class="py-2 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
				<div class="py-2 flex flex-column sm:flex-row sm:align-items-center justify-content-between">
					<img alt="adequation" src="../assets/img/logo-green.png" width="100" style="padding-right:10px;padding-top:6px"><div>&nbsp;</div>
					<div class="mt-2 line-height-3">&copy; 2024 Adequation, {{ $t('inc._all_rights_reserved') }}</div>
				</div>
				<div class="mt-3 sm:mt-0">
					<a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700" target="_blank" href="https://www.linkedin.com/company/adequation/">
						<i class="pi pi-linkedin text-xl"></i>
					</a>
					
					<a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700" target="_blank" href="https://www.adequation.fr">
						<i class="pi pi-globe text-xl"></i>
					</a>
					
					<a class="cursor-pointer text-500 ml-3 transition-colors transition-duration-150 hover:text-700" target="_blank" href="https://media.adequation.fr/">
						<i class="pi pi-link text-xl"></i>
					</a>
					
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaneFooter',

	data: function() {
		return {
			
		}
	},
	
	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	})
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.opacity-enter, .opacity-leave-to
	opacity 0

.footer
	z-index 500
	vertical-align middle	
	position absolute
	bottom 0
	height auto
	width 100%
	background-color white
	text-align center
	border-top 1px solid #DCDCDC
	background-color #FAFAFA
	font-size 9pt
	a
		color primaryA400
</style>
