<template>
	<transition name="pane-adresse-transport-borne">
		<div class="paneleft pan-adresse-transport-borne">
			<pane-adresse-retour accueil="" retour="transport" cityscan="true" v-show="false"/>
			<pane-adresse-element 
				:propObj="this.app.cityscan.adresse.borne" 
				:sstyle="'color: rgb(94, 121, 92)'" 
				:sclass="'cs-tile-icon cs-icon-thematic icon-2002-02 ng-star-inserted'" 
				:titre="$t('borne_de_recharge')"
				:detail="''"
				:retour="'transport'"
			/>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'
import PaneAdresseElement from './PaneAdresseElement.vue'

export default {
	name: 'PaneAdresseTransportBorne',
	data: function() {
		return {
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
	},
	
	components: { PaneAdresseRetour, PaneAdresseElement }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-transport-borne-enter
	transform translateX(100%)
.pane-adresse-transport-borne-leave-to
	transform translateX(-100%)

.pan-adresse-transport-borne
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
