<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-purple-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-chart-line text-purple-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('historique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('evolution_des_volumes') }}</p>
				</div>
				<div class="ml-auto">
					<div>
						<Button v-if="app.tab == 'trimestres'" icon="pi pi-image" class="p-button-rounded" @click="$store.dispatch('ExportToPng',{id: 'chartOptions_1'})" />
						<Button v-else icon="pi pi-image" class="p-button-rounded" @click="$store.dispatch('ExportToPng',{id: 'chartOptions_2'})" />
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content" id="chartVolumePromo">
			<div v-if="app.tab == 'trimestres'" class="graph">
				<highcharts :options="chartOptions_trim" id="chartOptions_1"></highcharts>
			</div>
			<div v-if="app.tab == 'mois'" class="graph">
				<highcharts :options="chartOptions_mois" id="chartOptions_2"></highcharts>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'
import Button from 'primevue/button';

export default {
	name: 'GraphEvolutionOffre',

	data: function() {
		return {
			items: [
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'chartOptions_1'});} },
			],
			items2: [
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'chartOptions_2'});} },
			],
			chartOptions_trim: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_mois: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'areaspline',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#f44336',
						name: "Offre commerciale",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(244,67,54,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#8BC34A',
						name: "Ventes",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(139,195,74,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0,0,0,0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			}
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {

		maj_trimestre: function() {
			this.chartOptions_trim.series[0].data = [
				this.app.livedata.volume_periode_1.offre.tableau[0], 
				this.app.livedata.volume_periode_1.offre.tableau[1], 
				this.app.livedata.volume_periode_1.offre.tableau[2]
			]
			this.chartOptions_trim.series[1].data = [this.app.livedata.volume_periode_1.vente.tableau[0], this.app.livedata.volume_periode_1.vente.tableau[1], this.app.livedata.volume_periode_1.vente.tableau[2]]
			this.chartOptions_trim.series[2].data = [this.app.livedata.volume_periode_1.mev.tableau[0], this.app.livedata.volume_periode_1.mev.tableau[1], this.app.livedata.volume_periode_1.mev.tableau[2]]
			this.chartOptions_trim.xAxis.categories= [this.app.livedata.volume_periode_1.intitule_colonne[0], this.app.livedata.volume_periode_1.intitule_colonne[1], this.app.livedata.volume_periode_1.intitule_colonne[2]]
		},

		maj_mois: function() {
			this.chartOptions_mois.series[0].data = [
				this.app.livedata.volume_periode_2.offre.tableau[0], 
				this.app.livedata.volume_periode_2.offre.tableau[1], 
				this.app.livedata.volume_periode_2.offre.tableau[2], 
				this.app.livedata.volume_periode_2.offre.tableau[3], 
				this.app.livedata.volume_periode_2.offre.tableau[4],
				this.app.livedata.volume_periode_2.offre.tableau[5],
				this.app.livedata.volume_periode_2.offre.tableau[6],
				this.app.livedata.volume_periode_2.offre.tableau[7]
			]
			this.chartOptions_mois.series[1].data = [
				this.app.livedata.volume_periode_2.vente.tableau[0], 
				this.app.livedata.volume_periode_2.vente.tableau[1], 
				this.app.livedata.volume_periode_2.vente.tableau[2], 
				this.app.livedata.volume_periode_2.vente.tableau[3], 
				this.app.livedata.volume_periode_2.vente.tableau[4],
				this.app.livedata.volume_periode_2.vente.tableau[5],
				this.app.livedata.volume_periode_2.vente.tableau[6],
				this.app.livedata.volume_periode_2.vente.tableau[7]
			]
			this.chartOptions_mois.series[2].data = [
				this.app.livedata.volume_periode_2.mev.tableau[0], 
				this.app.livedata.volume_periode_2.mev.tableau[1], 
				this.app.livedata.volume_periode_2.mev.tableau[2], 
				this.app.livedata.volume_periode_2.mev.tableau[3], 
				this.app.livedata.volume_periode_2.mev.tableau[4],
				this.app.livedata.volume_periode_2.mev.tableau[5],
				this.app.livedata.volume_periode_2.mev.tableau[6],
				this.app.livedata.volume_periode_2.mev.tableau[7]
			]
			this.chartOptions_mois.xAxis.categories= [
				this.app.livedata.volume_periode_2.intitule_colonne[0], 
				this.app.livedata.volume_periode_2.intitule_colonne[1], 
				this.app.livedata.volume_periode_2.intitule_colonne[2], 
				this.app.livedata.volume_periode_2.intitule_colonne[3], 
				this.app.livedata.volume_periode_2.intitule_colonne[4],
				this.app.livedata.volume_periode_2.intitule_colonne[5],
				this.app.livedata.volume_periode_2.intitule_colonne[6],
				this.app.livedata.volume_periode_2.intitule_colonne[7]
			]
		}
	},

	mounted: function() {
		this.maj_trimestre()
		this.maj_mois()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj_trimestre()
				this.maj_mois()
			}
		},
	},
	
	components: { highcharts: Chart, Button }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.stat-box
	.stat-box-content
		padding 0 16px
		text-align center

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
