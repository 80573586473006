<template>
	<div class="tab-report" id='report-rs'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset :legend="$t('Fiche Territoire')" :toggleable="true" class="surface-ground">

				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="ml-2" style="text-align: left;" v-tooltip.left="'INSEE 2021 & SITADEL 2020-2022'">
							<p class="text-600 mt-0 mb-0">{{ $t('Dynamique territoriale') }}</p>
						</div>
						<br>
						<table style="width:100%">
							<th style="display: none"></th>
							<tr>
								<td>
									<div class="pb-2" style="vertical-align:bottom">
										<div class='kpi_label mb-2'>&nbsp;{{$t('Habitants')}}</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="person" style="color:#666;height:40px;width:40px;"/>
												<div style="margin-top: 0.5rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].population.population)}}</div>
											</div>&nbsp;
											<div style="margin-top: 0.75rem !important;">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="app.insee_kpi[0].data.population.evolution_rate>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="app.insee_kpi[0].data.population.evolution_rate<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.population.evolution_rate>0">+ {{(app.insee_kpi[0].data.population.evolution_rate*100).toFixed(2)}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.population.evolution_rate<0">{{(app.insee_kpi[0].data.population.evolution_rate*100).toFixed(2)}} %</span>
											</div>
										</div>
									</div>
								</td>
								<td>
									<div class="pb-2" style="vertical-align:bottom">
										<div class='kpi_label mb-2'>&nbsp;{{$t('Ménages')}}</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="group" style="color:#666;height:40px;width:40px;"/>
												<div style="margin-top: 0.5rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].household.total_household)}}</div>
											</div>&nbsp;
											<div style="margin-top: 0.75rem !important;">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="app.insee_kpi[0].data.household.evolution_rate>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="app.insee_kpi[0].data.household.evolution_rate<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.household.evolution_rate>0">+ {{(app.insee_kpi[0].data.household.evolution_rate*100).toFixed(2)}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.household.evolution_rate<0">{{(app.insee_kpi[0].data.household.evolution_rate*100).toFixed(2)}} %</span>
											</div>
										</div>
									</div>
								</td>
								<td>
									<div class="pb-2" style="vertical-align:bottom">
										<div class='kpi_label mb-2'>&nbsp;{{$t('Migration résidentielle')}}</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="truck" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb(app.insee_kpi[0].data.household.last_year_rotation.total)}}</div>
												<div class="mt-1 pl-2" style="font-size:8pt;color:#333;margin-top: 0.45rem !important;" v-tooltip="$t('Emménagement par an')">
													{{$t('Emm.')}}<br>{{$t('par an')}}
												</div>
											</div>
										</div>
									</div>
								</td>
								<td>
									<div class="pb-2" style="vertical-align:bottom">
										<div class='kpi_label mb-2'>&nbsp;{{$t('Construction')}}</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="domainadd" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb(app.insee_sitadel[0].data.construction_total)}}</div>
												<div class="mt-1 pl-2" style="font-size:8pt;color:#333;margin-top: 0.45rem !important;" v-tooltip="$t('Logements par an')">
													{{$t('Log.')}}<br>{{$t('par an')}}
												</div>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="4">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="pb-2" style="vertical-align:bottom; width: 100%;max-width: 500px;">
										<div class='kpi_label mb-2'>&nbsp;{{$t('Répartion des ménages')}}</div>
										<ul class="list-none p-0 m-0" id="grp_repart_menage">
											<li class="flex" style="height:42px; align-items: center;justify-content: flex-start;">
												<div class="mt-2" style="width:350px; height: 10px; border-radius: 5px;margin-top: 1.1rem !important;">
													<div style="background-color: #6b23f1; height: 10px; border-radius: 5px" :style="{ width : Math.round(chartDataMen[0].nb / chartDataMen[0].nb * 100) + '%'}"></div>
												</div>
												<div class="pl-2 pt-1" style="white-space: nowrap;"><span style="color: #6b23f1;font-size:2rem">{{chartDataMen[0].nb}} %</span>&nbsp;&nbsp;{{ chartDataMen[0].label }}</div>
											</li>
											<li class="flex" style="height:22px; align-items: center;justify-content: flex-start;">
												<div class="mt-2" style="height: 10px; border-radius: 5px;margin-top: 0.8rem !important;" :style="{ width : Math.round(chartDataMen[1].nb / chartDataMen[0].nb * 350) + 'px'}">
													<div style="background-color: #c2abed; height: 10px; border-radius: 5px"></div>
												</div>
												<div class="pl-2 pt-1" style="white-space: nowrap;"><span style="color: #c2abed;font-size:1.5rem">{{chartDataMen[1].nb}} %</span>&nbsp;&nbsp;{{ chartDataMen[1].label }}</div>
											</li>
											<li class="flex" style="height:42px; align-items: center;justify-content: flex-start;">
												<div class="mt-2" style="height: 10px; border-radius: 5px" :style="{ width : Math.round(chartDataMen[2].nb / chartDataMen[0].nb * 350) + 'px'}">
													<div style="background-color: #d5c5f0; height: 10px; border-radius: 5px"></div>
												</div>
												<div class="pl-2 pt-1" style="white-space: nowrap;"><span style="color: #d5c5f0;font-size:1rem">{{chartDataMen[2].nb}} %</span>&nbsp;&nbsp;{{ chartDataMen[2].label }}</div>
											</li>
										</ul>
									</div>
								</td>
								<td>
									<div class="pb-2" style="vertical-align:bottom">
										<div class='kpi_label mb-2'>&nbsp;{{$t('Emplois')}}</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600 mr-1" style="font-size: 1.5rem;">
												<icon file="business" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].employment.total)}}</div>
											</div>
											<div style="margin-top: 0.75rem !important;">
												<i class="pi pi-arrow-up-right text-green-500 mr-1" style="font-size:8pt" v-if="app.insee_kpi[0].data.employment.evolution_rate>0"></i>
												<i class="pi pi-arrow-down-right text-pink-500 mr-1" v-if="app.insee_kpi[0].data.employment.evolution_rate<0"></i>
												<span class="text-green-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.employment.evolution_rate>0">+ {{(app.insee_kpi[0].data.employment.evolution_rate*100).toFixed(2)}} %</span>
												<span class="text-pink-500 font-medium " style="white-space: nowrap;font-size:8pt" v-if="app.insee_kpi[0].data.employment.evolution_rate<0">{{(app.insee_kpi[0].data.employment.evolution_rate*100).toFixed(2)}} %</span>
											</div>
										</div>
									</div>
								</td>
								<td>
									<div class="pb-2" style="vertical-align:bottom">
										<div class='kpi_label mb-2'>&nbsp;</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="work_history" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.5rem !important;">{{ formatnb2(app.insee_kpi[0].data.employment.indice_concentration)}}</div>
											</div>
											<div class="mt-1 pl-2" style="font-size:8pt;color:#333;margin-top: 0.15rem !important;">
												{{$t('Emploi')}}<span v-if="app.insee_kpi[0].data.employment.indice_concentration>=2">s</span><br>{{$t('pour 1 actif')}}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>


				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="ml-2" style="text-align: left;" v-tooltip.left="'INSEE 2021'">
							<p class="text-600 mt-0 mb-0">{{ $t('Parc de logement') }}</p>
						</div>
						<br>
						<table style="width:100%">
							<th style="display: none"></th>
							<tr style="height:280px">
								<td style="width:33%">
									<div class="pb-2" style="vertical-align:bottom">
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="apartment" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.6rem !important;">{{ formatnb(app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.total_lodgments)}}</div>
											</div>

											<div class="pl-2 pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem">
												{{$t('Logements')}}
											</div>
										</div>
									</div>
								</td>
								<td style="width:33%">
									<div class="pb-2" style="position: absolute;top: 120px;left: 80px;color: #fff;transform: translate(-50%, -50%);padding: 10px;border-radius: 8px;text-align: left;pointer-events: none; ">
										<div style="padding-top:5px; padding-bottom: 10px;text-align: center">
											<div class="flex" style="vertical-align:bottom; justify-content: flex-start;">
												<Knob v-model="chartDataForme[0].nb" :size="140" readonly valueTemplate="{value}%"  id="grp_repart_forme" />
												<div style="line-height:80px; padding-top: 12px;"></div>
											</div>
											<div class="text-purple-600" style="font-size: 1.5rem;">
												{{ chartDataForme[0].label }}
											</div>
										</div>
									</div>
								</td>
								<td style="width:33%">
									<div style="position: absolute;top: 140px;left: 140px;color: #fff;transform: translate(-50%, -50%);padding: 10px;border-radius: 8px;text-align: left;pointer-events: none;">
										<div id="grp_repart_dest">
											<div class="pb-2">
												<div class="flex align-items-center pb-2" style="vertical-align:bottom">
													<div class="text-purple-600" style="font-size: 1.5rem;">
														<div class="flex" style="vertical-align:bottom; justify-content: flex-start;">
															<Chart type="polarArea" :data="chartDataOccupationGraph" :options="polarOption" :width="280" :height="280"/>
														</div>
													</div>
												</div>
											</div>
											<div class="overlay1">
												<span style="font-size:2rem">{{ chartDataOccupation[0].nb }}%</span><br>{{ chartDataOccupation[0].label }}
											</div>
											<div class="overlay2">
												<span style="font-size:1.5rem">{{ chartDataOccupation[1].nb }}%</span><br>{{ chartDataOccupation[1].label }}
											</div>
											<div class="overlay3">
												<span style="font-size:1rem">{{ chartDataOccupation[2].nb }}%</span><br>{{ chartDataOccupation[2].label }}
											</div>
											<div class="overlay4">
												<span style="font-size:0.8rem">{{ chartDataOccupation[3].nb }}%</span><br>{{ chartDataOccupation[3].label }}
											</div>
										</div>
									</div>
								</td>
								<!--
								<td style="width:25%">
									<div class="pb-2" style="vertical-align:bottom">
										<div class="ml-2" style="text-align: left;" v-tooltip.left="'SNE 2022'">
											<p class="text-600 mt-0 mb-0">{{ $t('Pression de la demande en logement social') }}</p>
										</div>
										<div class="flex align-items-center pb-2" style="vertical-align:bottom; justify-content: flex-start;">
											<div class="flex text-purple-600" style="font-size: 1.5rem;">
												<icon file="real_estate_agent" style="color:#666;height:40px;width:40px;"/>
												<div class="pl-2" style="margin-top: 0.6rem !important;">{{formatnb(Math.round(app.insee_sne[0].data.social_demand.global.demand_pressure*10,1)/10)}}</div>
											</div>

											<div class="pl-2 pt-1" style="margin-top: 0.7rem !important;line-height:1.5rem">
												{{$t('demandes pour 1 attribution')}}
											</div>
										</div>
									</div>
								</td>
								-->
							</tr>
						</table>
					</div>
				</div>



				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="ml-2" style="text-align: left;" v-tooltip.left="'FILOSOFI 2021'">
							<p class="text-600 mt-0 mb-0">{{ $t('Revenu médian mensuel des ménages par tranches d\'âges') }}</p>
						</div>
						<br>
						<table style="width:100%">
							<th style="display: none"></th>
							<tr>
								<td>
									<div class="pb-2" style="vertical-align:bottom;width:1470px; height: 348px" id="grp_revenu_menage">
										<ul class="list-none p-0 m-0">
											<li class="mb-3">
												<div class="flex justify-content-between align-items-center">
													<span class="text-900 inline-flex justify-content-between align-items-center">
														<span class="font-medium text-900">- {{ $t('median') }}</span>
													</span>
													<span class="text-purple-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household }} {{$t('€/mois')}}</span>
												</div>
												<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
													<div class="bg-purple-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].lodgment.household.average_salary_household) / max_mediane * 100) + '%'}"></div>
												</div>
											</li>
											<li class="mb-3">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<span class="font-medium text-900">- {{ $t('de_30_ans_et_moins') }}</span>
															</span>
															<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.median_income }} {{$t('€/mois')}}</span>
														</div>
														<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
															<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_0_30.median_income) / max_mediane * 100) + '%'}"></div>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-3">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<span class="font-medium text-900">- {{ $t('de_30_a_39_ans') }}</span>
															</span>
															<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.median_income }} {{$t('€/mois')}}</span>
														</div>
														<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
															<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_30_39.median_income) / max_mediane * 100) + '%'}"></div>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-3">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<span class="font-medium text-900">- {{ $t('de_40_a_49_ans') }}</span>
															</span>
															<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.median_income }} {{$t('€/mois')}}</span>
														</div>
														<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
															<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_40_49.median_income) / max_mediane * 100) + '%'}"></div>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-3">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<span class="font-medium text-900">- {{ $t('de_50_a_59_ans') }}</span>
															</span>
															<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.median_income }} {{$t('€/mois')}}</span>
														</div>
														<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
															<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_50_59.median_income) / max_mediane * 100) + '%'}"></div>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-3">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<span class="font-medium text-900">- {{ $t('de_60_a_74_ans') }}</span>
															</span>
															<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.median_income }} {{$t('€/mois')}}</span>
														</div>
														<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
															<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_60_74.median_income) / max_mediane * 100) + '%'}"></div>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-3">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<span class="font-medium text-900">- {{ $t('de_75_ans_et_plus') }}</span>
															</span>
															<span class="text-teal-600 font-medium">{{ app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.median_income }} {{$t('€/mois')}}</span>
														</div>
														<div class="surface-300 mt-2" style="height: 7px; border-radius: 4px;">
															<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : Math.round((app.insee[0].data[app.insee[0].data.length-1].household.age_division.age_75_more.median_income) / max_mediane * 100) + '%'}"></div>
														</div>
													</div>
												</div>
											</li>
										</ul>										
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="ml-2" style="text-align: left;" v-tooltip.left="'FILOSOFI 2021'">
							<p class="text-600 mt-0 mb-0">{{ $t('Carte de localisation des points d\'intêrets') }}</p>
						</div>
						<br>
						<div style="width: 100%;height: auto;display: flex;justify-content: center;flex-direction: row;flex-wrap: wrap;">
							<div style="margin-right: 20px;display: flex;flex-direction: column;">
								<div class="btncarte" style="margin-bottom:20px;" @click="initcarte()" v-tooltip.left="$t('Centrer sur l’adresse du projet')">
									<icon :size="32" style="color:#fff" file="reset_focus" />
								</div>
								<div class="btncarte" style="margin-bottom:20px;" @click="toggleMapInteraction()" v-tooltip.left="$t('Figer / Libérer la carte')">
									<icon v-if="mapInteractionEnabled" :size="32" file="lock_open_right" />
									<icon v-else :size="32" file="lock_close" />
								</div>
							</div>
							<div class="TailleCarte">
								<div id="map_poi">

								</div>
							</div>
						</div>
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Fieldset from 'primevue/fieldset';
import Knob from 'primevue/knob';
import Chart from 'primevue/chart';
import axios from 'axios'

export default {
	name: 'TabTerritoire',

	data: function() {
		return {
			zac_style : {
				weight: 2, 
				color: "#c65459", 
				fillColor: "#c65459",
				fillOpacity: 0.3
			},
			qpv_style : {
				weight: 2, 
				color: "#ff9900", 
				fillColor: "#ff9900",
				fillOpacity: 0.3
			},
			qpv_style3 : {
				weight: 2, 
				color: "#ff9900", 
				fillColor: "#ff9900",
				fillOpacity: 0.1,
				dashArray : "10, 10"
			},
			mapInteractionEnabled: false,
			LayerMapnik: false,
			LayerImagery: false,
			LayerDark: false,
			map_poi: false,
			poi_layer: false,
			currentPOIs: {},
			group_poi: [],
			options: [
				{
					id: 'Transports',
					label: 'Transports',
					children: [ {
							id: 'Bus',
							label: 'Bus',
						}, {
							id: 'Tramway',
							label: 'Tramway',
						}, {
							id: 'Métro',
							label: 'Métro',
						}, 
						{
							id: 'Gare',
							label: 'Gare',
						}, {
							id: 'Aéroport',
							label: 'Aéroport',
						}
					],
				},
				{
					id: 'Education',
					label: 'Education',
						children: [ {
							id: 'Collége',
							label: 'Collége',
						}, {
							id: 'Ecole élémentaire',
							label: 'Ecole élémentaire',
						}, {
							id: 'Ecole maternelle',
							label: 'Ecole maternelle',
						}, {
							id: 'Lycée',
							label: 'Lycée',
						}, {
							id: 'Section d\'enseignement',
							label: 'Section d\'enseignement',
						}, {
							id: 'Enseignement spécialisé',
							label: 'Enseignement spécialisé',
						}, {
							id: 'Enseignement supérieur',
							label: 'Enseignement supérieur',
						},
					],
				},
				{
					id: 'Alimentaire',
					label: 'Alimentaire',
						children: [ {
							id: 'Boulangerie',
							label: 'Boulangerie',
						}, {
							id: 'Epicerie',
							label: 'Epicerie',
						}, {
							id: 'Supermarché',
							label: 'Supermarché',
						}, {
							id: 'Hypermarché',
							label: 'Hypermarché',
						}, {
							id: 'Boucher',
							label: 'Boucher',
						}, {
							id: 'Primeur',
							label: 'Primeur',
						}
					],
				},
				{
					id: 'Café - Hôtel - Restaurant',
					label: 'Café - Hôtel - Restaurant',
						children: [ {
							id: 'Hôtel',
							label: 'Hôtel',
						}, {
							id: 'Bar',
							label: 'Bar',
						}, {
							id: 'Café',
							label: 'Café',
						}, {
							id: 'Restaurant',
							label: 'Restaurant',
						}, {
							id: 'Club',
							label: 'Club',
						}
					],
				},
				{
					id: 'Achats de proximité',
					label: 'Achats de proximité',
						children: [ {
							id: 'Magasin de jouets',
							label: 'Magasin de jouets',
						}, {
							id: 'Fleuriste',
							label: 'Fleuriste',
						}, {
							id: 'Librairie',
							label: 'Librairie',
						}, {
							id: 'Marchand de journaux',
							label: 'Marchand de journaux',
						}, {
							id: 'Tabac - Presse',
							label: 'Tabac - Presse',
						}
					],
				},
				{
					id: 'Shopping',
					label: 'Shopping',
						children: [{
							id: 'Magasin de bricolage',
							label: 'Magasin de bricolage',
						}, {
							id: 'Jardinerie',
							label: 'Jardinerie',
						}, {
							id: 'Magasin de meubles',
							label: 'Magasin de meubles',
						}, {
							id: 'Magasin de vêtements',
							label: 'Magasin de vêtements',
						}, {
							id: 'Friperie',
							label: 'Friperie',
						}, {
							id: 'Magasin de chaussures',
							label: 'Magasin de chaussures',
						}, {
							id: 'Bijouterie',
							label: 'Bijouterie',
						}, {
							id: 'Vendeur de vélo',
							label: 'Vendeur de vélo',
						}, {
							id: 'Magasin de sport',
							label: 'Magasin de sport',
						}
					],
				},
				{
					id: 'Equipement public',
					label: 'Equipement public',
						children: [ {
							id: 'Police',
							label: 'Police',
						}, {
							id: 'Ambassade',
							label: 'Ambassade',
						}, {
							id: 'Hôtel de ville',
							label: 'Hôtel de ville',
						}, {
							id: 'Tribunal',
							label: 'Tribunal',
						}, {
							id: 'Bureau de poste',
							label: 'Bureau de poste',
						}
					],
				},
				{
					id: 'Santé',
					label: 'Santé',
						children: [ {
							id: 'Hôpital',
							label: 'Hôpital',
						}, {
							id: 'Pharmacie',
							label: 'Pharmacie',
						}, {
							id: 'Opticien',
							label: 'Opticien',
						}, {
							id: 'Dentiste',
							label: 'Dentiste',
						}, {
							id: 'Médecin',
							label: 'Médecin',
						}
					],
				},
				{
					id: 'Beauté',
					label: 'Beauté',
						children: [{
							id: 'Cosmétique',
							label: 'Cosmétique',
						}, {
							id: 'Coiffeur',
							label: 'Coiffeur',
						}, {
							id: 'Salon de beauté',
							label: 'Salon de beauté',
						}
					],
				},
				{
					id: 'Services',
					label: 'Services',
						children: [ {
							id: 'Distributeurs',
							label: 'Distributeurs',
						}, {
							id: 'Location de voiture',
							label: 'Location de voiture',
						}, {
							id: 'Vétérinaire',
							label: 'Vétérinaire',
						}, {
							id: 'Vendeur auto',
							label: 'Vendeur auto',
						}, {
							id: 'Agence de voyage',
							label: 'Agence de voyage',
						}, {
							id: 'Garage',
							label: 'Garage',
						}, {
							id: 'Blanchisserie',
							label: 'Blanchisserie',
						}, {
							id: 'Banque',
							label: 'Banque',
						}
					],
				}
			],
			poiList: [],
			poiEducation: [],
			poiTransport: [],		
			activeSection2 : '',
			polarOption: {
				scales: {
					r: {
						display: false, 
					}
				},
				plugins: {
					datalabels: {
						color: (context) => {
							return context.dataIndex === 0 ? '#FFF' : '#000'; // Couleur du label
						},
						font: (context) => {
							const value = context.dataset.data[context.dataIndex];
							return {
							size: value < 100 ? 14 : 18, // Taille de la police selon la valeur
							weight: 'bold'
							};
						},
						anchor: 'center', // Positionnement des labels
						align: 'center',
						offset: 10, // Décalage pour éviter les chevauchements
						// eslint-disable-next-line
						formatter: (value, context) => {
							// eslint-disable-next-line
							const label = context.chart.data.labels[context.dataIndex];
							return ``;
						},
						clamp: true, // Assure que les labels ne sont pas coupés
						clip: false // Permet aux labels de dépasser les bords de la zone
					},
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false				
					},
				},
				maintainAspectRatio: true,
				rotation: -15 * (Math.PI / 180),
			},			
		}
	},

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		toggleMapInteraction: function() {
			if (this.mapInteractionEnabled) {
				this.map_poi.dragging.disable();
				this.map_poi.touchZoom.disable();
				this.map_poi.doubleClickZoom.disable();
				this.map_poi.scrollWheelZoom.disable();
				this.map_poi.boxZoom.disable();
				this.map_poi.keyboard.disable();
				if (this.map_poi.tap) this.map_poi.tap.disable(); // Désactiver les interactions tactiles si activé
				document.getElementById('map_poi').style.cursor = 'default'; // Changer le curseur pour indiquer que la carte est figée
			} else {
				this.map_poi.dragging.enable();
				this.map_poi.touchZoom.enable();
				this.map_poi.doubleClickZoom.enable();
				this.map_poi.scrollWheelZoom.enable();
				this.map_poi.boxZoom.enable();
				this.map_poi.keyboard.enable();
				if (this.map_poi.tap) this.map_poi.tap.enable(); // Activer les interactions tactiles si activé
				document.getElementById('map_poi').style.cursor = 'grab'; // Changer le curseur pour indiquer que la carte est interactive
			}
			this.mapInteractionEnabled = !this.mapInteractionEnabled;
		},

		formatnb: function(p) {
			let nPrice=p
			
			const euro = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(nPrice)
			}
		},

		formatnb2: function(p) {
			let nPrice=p
			nPrice = Math.round(nPrice*100)/100
			const euro = new Intl.NumberFormat('fr-FR', {
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(nPrice)
			}
		},

		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'tabTerritoire'
			} else {
				if (this.activeSection2 == 'tabTerritoire') {
					this.activeSection2 = ''
				}
			}
		},




		iconClass(fclass) {
			return fclass;
		},

		iconPOI(fclass, b) {

			let s = new Object();
			let bus 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_Bus.svg';
			let metro 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_Metro.svg';
			let train 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_TRAIN.svg';
			let tram 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_Tram.svg';
			let airport 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/airport.svg';

			s['subway_entrance'] = metro;
			s['subway_exit'] = metro;
			s['tram_stop'] = tram;
			s['gare_station'] = train;
			s['bus_stop'] = bus;
			s['bus_station'] = bus;
			s['airport'] = airport;

			let ecole = 'https://www.myadequation.fr/EFOCUS/icones/2.0/ecole.svg';
			let alimentaire 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/alimentaire.svg';
			let cafe = 'https://www.myadequation.fr/EFOCUS/icones/2.0/cafe.svg';
			let club = 'https://www.myadequation.fr/EFOCUS/icones/2.0/club.svg';
			let hotel = 'https://www.myadequation.fr/EFOCUS/icones/2.0/hotel.svg';
			let restaurant = 'https://www.myadequation.fr/EFOCUS/icones/2.0/restaurant.svg';
			let theatre = 'https://www.myadequation.fr/EFOCUS/icones/2.0/theatre.svg';
			let equipement_maison = 'https://www.myadequation.fr/EFOCUS/icones/2.0/utilitaire.svg';
			let equipement_personne = 'https://www.myadequation.fr/EFOCUS/icones/2.0/equ_personne.svg';
			let equipement_public = 'https://www.myadequation.fr/EFOCUS/icones/2.0/publics.svg';
			let hopital = 'https://www.myadequation.fr/EFOCUS/icones/2.0/hopital.svg';
			let pharmacie = 'https://www.myadequation.fr/EFOCUS/icones/2.0/pharmacie.svg';
			let bank = 'https://www.myadequation.fr/EFOCUS/icones/2.0/bank.svg';
			let medecin = 'https://www.myadequation.fr/EFOCUS/icones/2.0/medecin.svg';
			let police = 'https://www.myadequation.fr/EFOCUS/icones/2.0/police.svg';
			let soin = 'https://www.myadequation.fr/EFOCUS/icones/2.0/soin.svg';
			let service = 'https://www.myadequation.fr/EFOCUS/icones/2.0/Services.svg';
			let shopping = 'https://www.myadequation.fr/EFOCUS/icones/2.0/shopping.svg';
			let Poste = 'https://www.myadequation.fr/EFOCUS/icones/2.0/Poste.svg';
			let parc = 'https://www.myadequation.fr/EFOCUS/icones/2.0/parc.svg';
			let musees = 'https://www.myadequation.fr/EFOCUS/icones/2.0/musees.svg';
			let sport = 'https://www.myadequation.fr/EFOCUS/icones/2.0/sport.svg';
			let cinema = 'https://www.myadequation.fr/EFOCUS/icones/2.0/cinema.svg';
			let none = 'https://www.myadequation.fr/EFOCUS/icones/2.0/none.svg';

			s['playground'] = parc;
			s['attraction'] = parc;
			s['bench'] = parc;
			s['camp_site'] = hotel;
			s['caravan_site'] = hotel;
			s['convenience'] = alimentaire;
			s['park'] = parc;
			s['theme_park'] = parc;
			s['picnic_site'] = parc;
			s['gift_shop'] = shopping;
			s['jeweller'] = equipement_personne;
			s['travel_agent'] = service;
			s['food_court'] = cafe ;
			s['embassy'] = equipement_public;
			s['car_sharing'] = service;
			s['bank'] = bank;
			s['bar'] = cafe;
			s['pub'] = cafe;
			s['public_building'] = equipement_public;
			s['town_hall'] = equipement_public;
			s['courthouse'] = equipement_public;
			s['laundry'] = service;
			s['post_box'] = service;
			s['butcher'] = alimentaire;
			s['bakery'] = alimentaire;
			s['post_office'] = Poste;
			s['cafe'] = cafe;
			s['fire_station'] = equipement_public;
			s['mall'] = shopping;
			s['arts_centre'] = musees;
			s['sports_centre'] = sport;
			s['bed_and_breakfast'] = hotel;
			s['guesthouse'] = hotel;
			s['hostel'] = hotel;
			s['hotel'] = hotel;
			s['motel'] = hotel;
			s['castle'] = musees;
			s['cinema'] = cinema;
			s['nightclub'] = club;
			s['hairdresser'] = soin;
			s['chemist'] = soin;
			s['dentist'] = medecin;
			s['education'] = ecole;
			s['florist'] = shopping;
			s['fort'] = musees;
			s['recycling_clothes'] = shopping;
			s['kindergarten'] = ecole;
			s['hospital'] = hopital;
			s['department_store'] = alimentaire;
			s['garden_centre'] = equipement_maison;
			s['car_wash'] = service;
			s['bookshop'] = shopping;
			s['library'] = shopping;
			s['bicycle_rental'] = service;
			s['car_rental'] = service;
			s['doityourself'] = equipement_maison;
			s['shoe_shop'] = equipement_personne;
			s['toy_shop'] = shopping;
			s['furniture_shop'] = equipement_maison;
			s['sports_shop'] = shopping;
			s['mobile_phone_shop'] = shopping;
			s['clothes'] = equipement_personne;
			s['computer_shop'] = shopping;
			s['nursing_home'] = soin;
			s['newsagent'] = shopping;
			s['doctors'] = medecin;
			s['memorial'] = musees;
			s['monument'] = musees;
			s['museum'] = musees;
			s['tourist_info'] = musees;
			s['optician'] = soin;
			s['artwork'] = musees;
			s['stationery'] = shopping;
			s['ice_rink'] = sport;
			s['pharmacy'] = pharmacie;
			s['swimming_pool'] = sport;
			s['track'] = sport;
			s['viewpoint'] = musees;
			s['police'] = police;
			s['greengrocer'] = alimentaire;
			s['recycling'] = equipement_maison;
			s['recycling_glass'] = equipement_maison;
			s['recycling_metal'] = equipement_maison;
			s['recycling_paper'] = equipement_maison;
			s['fast_food'] = restaurant ;
			s['restaurant'] = restaurant ;
			s['ruins'] = musees;
			s['beauty_shop'] = soin;
			s['archaeological'] = musees;
			s['stadium'] = sport;
			s['wastewater_plant'] = equipement_maison;
			s['supermarket'] = alimentaire;
			s['vending_cigarette'] = shopping;
			s['kiosk'] = shopping;
			s['golf_course'] = sport;
			s['pitch'] = sport;
			s['theatre'] = theatre;
			s['observation_tower'] = musees;
			s['college'] = ecole;
			s['university'] = ecole;
			s['car_dealership'] = service;
			s['bicycle_shop'] = shopping;
			s['veterinary'] = service;
			s['zoo'] = musees;
			s['clinic'] = hopital;
			s['market_place'] = shopping;
			s['outdoor_shop'] = shopping;
			s['school'] = ecole;
			s[''] = none;


			// Alimentaire
			s["Boulangerie"] = 'https://cdn.adequation.fr/files/2b0eb16e4a491f84e3aceb2c38924278.svg';
            s["Epicerie"] = 'https://cdn.adequation.fr/files/2c9421fd6066c61b9567883d5cd43815.svg';
            s["Supermarché"] = 'https://cdn.adequation.fr/files/c538e203a751c0af6c2f1b4a376abd67.svg';
            s["Hypermarché"] = 'https://cdn.adequation.fr/files/7e332e8adb35a8d66a58a3bab5d5a07f.svg';
            s["Centre commercial"] = 'https://cdn.adequation.fr/files/79bc1ff3c4fb65f48b004e2c69fc9d59.svg';
            s["Boucher"] = 'https://cdn.adequation.fr/files/48e334f75186e8745a8ba2f7d0697430.svg';
            s["Primeur"] = 'https://cdn.adequation.fr/files/2c853b70624ed2fef300297e801c9590.svg';

			// Café - Hôtel - Restaurant
            s["Hôtel"] = 'https://cdn.adequation.fr/files/7ff5d5d6e703e2ab89776f8517c35235.svg';
            s["Bar"] = 'https://cdn.adequation.fr/files/4aea274a4733aa134e86e23729477a12.svg';
            s["Café"] = 'https://cdn.adequation.fr/files/1eceadbbd7a22398d445ca018d839b55.svg';
            s["Restaurant"] = 'https://cdn.adequation.fr/files/bfe5b2ff644c613211345b248d5ee3c6.svg';
            s["Camping"] = 'https://cdn.adequation.fr/files/2804aeafe94452305f04f819c42efb3c.svg';
            s["Camping caravane"] = 'https://cdn.adequation.fr/files/c6bdb82c4191c05ee388790ea3855c2d.svg';

			// Culture
            s["Librairie"] = 'https://cdn.adequation.fr/files/1c89622adb758e331ae37f93c7cb94d6.svg';
            s["Marchand de journaux"] = 'https://cdn.adequation.fr/files/38dc72ca0d1589ea50233d55869f031e.svg';
            s["Château"] = 'https://cdn.adequation.fr/files/381fd5c9fa5970a699caa9a30f7738a4.svg';
            s["Musée"] = 'https://cdn.adequation.fr/files/828ffe9d3b82b95c639bf8fae294e758.svg';
            s["Parc"] = 'https://cdn.adequation.fr/files/d9f3b13062886e5cebd5eba50d52ca3b.svg';
            s["Théâtre"] = 'https://cdn.adequation.fr/files/6424253e4d45ed26ffc84a96a3712bdb.svg';

			// Loisirs
            s["Magasin de jouets"] = 'https://cdn.adequation.fr/files/c5d519e849c1f1eedd917288bfc26e39.svg';
            s["Fleuriste"] = 'https://cdn.adequation.fr/files/2a70a57a874d4ab5fb6ebfb027be9f65.svg';
            s["Boutique de souvenirs"] = 'https://cdn.adequation.fr/files/bdfec4c916a5e086bfee6a4e84fff77f.svg';
            s["Magasin de sport"] = 'https://cdn.adequation.fr/files/a20ded350c9621cad08801a8b90dfca6.svg';
            s["Papeterie"] = 'https://cdn.adequation.fr/files/0826cc60a43eda5be6a3e7c518eda9a6.svg';
            s["Tabac - Presse"] = 'https://cdn.adequation.fr/files/0a73d1d043b09af1451d8724479f9f8c.svg';
            s["Cinéma"] = 'https://cdn.adequation.fr/files/5f766f4d9bafa3d41e4e1a93f8a2830f.svg';
            s["Terrain de golf"] = 'https://cdn.adequation.fr/files/f875835f46138c92c1060a3105f0816c.svg';
            s["Patinoire"] = 'https://cdn.adequation.fr/files/16c13e671970b88db17ecea84d1a92b6.svg';
            s["Terrain de sport"] = 'https://cdn.adequation.fr/files/c89aead10e0110e170249c203ac66a89.svg';
            s["Aire de jeux"] = 'https://cdn.adequation.fr/files/79de389a4950e90d4b5b86a97480d2e6.svg';
            s["Centre sportif"] = 'https://cdn.adequation.fr/files/219ec286047153c27f523c06f67e3dbb.svg';
            s["Stade"] = 'https://cdn.adequation.fr/files/f8e886413d72bd471eccdd163421a7f7.svg';
            s["Piscine"] = 'https://cdn.adequation.fr/files/4823a554559b82b691283c97882ec273.svg';
            s["Zoo"] = 'https://cdn.adequation.fr/files/2b53f3126aa13e81b1a139b8093ab83b.svg';

			// Equipement de la maison
            s["Magasin de bricolage"] = 'https://cdn.adequation.fr/files/babb08e10e6d0744569e6cbb11e35d04.svg';
            s["Jardinerie"] = 'https://cdn.adequation.fr/files/0af7b0a81c3942c3677ecc0ebb8bb896.svg';
            s["Magasin de meubles"] = 'https://cdn.adequation.fr/files/7931a93383c56f978f321cf71a51effb.svg';

			// Equipement de la personne
            s["Magasin de chaussures"] = 'https://cdn.adequation.fr/files/3819ede246591388897007daa032fb38.svg';
            s["Bijouterie"] = 'https://cdn.adequation.fr/files/2cf88d5286de3c0ca117811dcd6c0c33.svg';
            s["Magasin de vêtements"] = 'https://cdn.adequation.fr/files/2fa953ad3c9f4f4f39443b2c855eed78.svg';

			// Equipement public
            s["Police"] = 'https://cdn.adequation.fr/files/8c5ee0ccd2f44e7e9fd15109d2abf4e6.svg';
            s["Hôtel de ville"] = 'https://cdn.adequation.fr/files/4bc4ef7342c41f790055ce794c2ff312.svg';
            s["Tribunal"] = 'https://cdn.adequation.fr/files/e971d048621e241f5887fc9bb092bacb.svg';
            s["Bureau de poste"] = 'https://cdn.adequation.fr/files/ea3a75d144b3e8af2c4160f72021a28a.svg';
            s["Enseignement"] = 'https://cdn.adequation.fr/files/24e951729c3e1dd52fd7a550d6140cb9.svg';
            s["Université"] = 'https://cdn.adequation.fr/files/c42cb943be5a45443cbdbd4305b9d83c.svg';
            s["Prison"] = 'https://cdn.adequation.fr/files/34513adbe42a6ad46d346d0391b3267e.svg';
            s["Bâtiment public"] = 'https://cdn.adequation.fr/files/e7afcd875302f7746d8c9c0b92eb6c95.svg';

			// Santé - Beauté
            s["Hôpital"] = 'https://cdn.adequation.fr/files/3f7b839d2215e8754fee2521f3b7de13.svg';
            s["Pharmacie"] = 'https://cdn.adequation.fr/files/e4933611a1453e7ef33a61a645a1c706.svg';
            s["Cosmétique"] = 'https://cdn.adequation.fr/files/9a0abd25a9d25c62ed1b3aaa1ac1e855.svg';
            s["Opticien"] = 'https://cdn.adequation.fr/files/0882a75b69e71984c599e83d53cf4573.svg';
            s["Coiffeur"] = 'https://cdn.adequation.fr/files/c52490e0cb16aa56c31b272b6892cbb2.svg';
            s["Dentiste"] = 'https://cdn.adequation.fr/files/20f302cc84659b18ef02eb4fd512414f.svg';
            s["Salon de beauté"] = 'https://cdn.adequation.fr/files/8e92c06daf43fa39a90c8f53c26eecf0.svg';
            s["Médecins"] = 'https://cdn.adequation.fr/files/a7ef633ccd55444b1db625758bbd7d30.svg';
            s["Maison de repos"] = 'https://cdn.adequation.fr/files/bf02bf5998bab6917b1a24bfb79db3cb.svg';

			// Services
            s["Vendeur auto"] = 'https://cdn.adequation.fr/files/d55dd6b92fb57434a56c42cb2be6569f.svg';
            s["Banque"] = 'https://cdn.adequation.fr/files/0dfa6d570dcc2aea5815fae20a0825d4.svg';
            s["Magasin de téléphonie"] = 'https://cdn.adequation.fr/files/2a45786637c623bbb42c613ff9d55476.svg';
            s["Cimetière"] = 'https://www.myadequation.fr/EFOCUS/icones/4.0/cimetiere.svg';
            s["Garderie"] = 'https://cdn.adequation.fr/files/b8b34d9832f8be6295649be985794090.svg';
					
			if (b) {
				try {					
					let v = s[fclass]
					return v
				} catch (error) {
					return false
				}
			}
					
			let Icon
			if (s[fclass]!='') {
				Icon = L.icon({iconUrl: s[fclass], iconSize: [30, 30], className: 'transparent-icon'});
				return Icon;
			}
			return false;
		},

		fetchPOIList_old() {
			let listepoi = "";
			if (this.app.view.includes('adresse') || this.app.view.includes('transport') || this.app.view.includes('education') || 
				this.app.view.includes('commerces') || this.app.view.includes('vie') || this.app.view.includes('loisirs') || 
				this.app.view.includes('numerique') || this.app.view.includes('services')) {
				// Nothing
			} else {
				let cp = this.app.contour_poi;
				for (let index = 0; index < cp.length; index++) {
					const element = cp[index];					
					let bItem = false;

					if (['Education', 'Collége', 'Ecole élémentaire', 'Ecole maternelle', 'Ecole primaire', 'Lycée',
						'Section d\'enseignement', 'Enseignement spécialisé', 'Enseignement supérieur', 'Transports', 'Bus', 'Tramway', 'Métro', 'Gare', 'Aéroport'].indexOf(element)<0) {
						for (let i=0; i < this.options.length; i++) {
							const op = this.options[i];
							if (element == op.label) {
								bItem = true;
								for (let j = 0; j < op.children.length; j++) {
									const souselement = op.children[j];
									if (listepoi!='') listepoi += ","
									listepoi += "'" + souselement.label + "'"
								}
								break;
							}
						}
						if (!bItem) {
							if (listepoi!='') listepoi += ","
							listepoi += "'" + element + "'"
						}
					}
				}
				let UneUrl = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_poi&maxFeatures=1000&cql_filter=BBOX(wkb_geometry," + this.map_poi.getBounds().toBBoxString() + ") AND fclass_fr in (" + listepoi + ")&outputFormat=application/json"
				let UneUrlEducation = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_enseignement&maxFeatures=10000&bbox=" + this.map_poi.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.map_poi.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				//var that = this;
				axios.get(UneUrl)
					.then(response => {
						this.poiList = response.data.features;
						this.poiEducation = [];
						this.poiTransport = [];
						axios.get(UneUrlEducation)
							.then(response => {
								let cp = this.app.contour_poi;
								for (let index = 0; index < cp.length; index++) {
									const element = cp[index];														
									if (['Education', 'Collége', 'Ecole élémentaire', 'Ecole maternelle', 'Ecole primaire', 'Lycée',
										'Section d\'enseignement', 'Enseignement spécialisé', 'Enseignement supérieur'].indexOf(element)>=0) {
										for (let index = 0; index < response.data.features.length; index++) {
											const ecole = response.data.features[index];
											if (element=='Education') this.poiEducation.push(ecole)
											else {

												switch (ecole.properties.nature) {
													case "COLLEGE":
														if (element=='Collége') this.poiEducation.push(ecole)
														break;

													case "ECOLE MATERNELLE":
													case "ECOLE MATERNELLE D APPLICATION":
														if (element=='Ecole maternelle') this.poiEducation.push(ecole)
														break;

													case "ECOLE ELEMENTAIRE D APPLICATION":
													case "ECOLE DE NIVEAU ELEMENTAIRE":
													case "ECOLE DE NIVEAU ELEMENTAIRE SPECIALISEE":
														if (element=='Ecole élémentaire') this.poiEducation.push(ecole)
														break;

													case "LYCEE POLYVALENT":
													case "LYCEE PROFESSIONNEL":
													case "LYCEE D ENSEIGNEMENT TECHNOLOGIQUE":
													case "LYCEE D ENSEIGNEMENT GENERAL":
													case "LYCEE ENSEIGNT GENERAL ET TECHNOLOGIQUE":
														if (element=='Lycée') this.poiEducation.push(ecole)
														break;
													
													case "ETABLISSEMENT REGIONAL D'ENSEIGNT ADAPTE":
													case "SECTION ENSEIGT GENERAL ET TECHNOLOGIQUE":
													case "SECTION ENSEIGNT GEN. ET PROF. ADAPTE":
													case "SECTION D ENSEIGNEMENT PROFESSIONNEL":
														if (element=='Section d\'enseignement') this.poiEducation.push(ecole)
														break;
													
													case "ECOLE PROFESSIONNELLE SPECIALISEE":
													case "COLLEGE SPECIALISE":
														if (element=='Enseignement spécialisé') this.poiEducation.push(ecole)
														break;

													case "UNIVERSITE":
														if (element=='Enseignement supérieur') this.poiEducation.push(ecole)
														break;

													default:
														break;

												}
											}
										}
									}
								}

								axios.get(UneUrlTransport)
									.then(response => {
										let cp = this.app.contour_poi;
										for (let index = 0; index < cp.length; index++) {
											const element = cp[index];												
//											if (['Transports', 'Bus', 'Tramway', 'Métro', 'Gare', 'Aéroport'].indexOf(element)>=0) {
											if (['Transports', 'Bus', 'Tramway', 'Métro', 'Gare', 'Aéroport'].indexOf(element)>=0) {
													for (let index = 0; index < response.data.features.length; index++) {
													const transport = response.data.features[index];
													switch (transport.properties.type) {
														case "bus_station":
														case "bus_stop":
															transport.libelle = 'Bus';
															if (element=='Bus'||element=='Transports') this.poiTransport.push(transport)
															break;
														case "tram_stop":
															transport.libelle = 'Tramway';
															if (element=='Tramway'||element=='Transports') this.poiTransport.push(transport)
															break;
														case "subway_entrance":
															transport.libelle = 'Métro';
															if (element=='Métro'||element=='Transports') this.poiTransport.push(transport)
															break;
														case "gare_station":
															transport.libelle = 'Gare';
															if (element=='Gare'||element=='Transports') this.poiTransport.push(transport)
															break;
														case "airport":
															transport.libelle = 'Aéroport';
																if (element=='Aéroport'||element=='Transports') this.poiTransport.push(transport)
																break;
														default:
															break;
													}
												}
											}
										}
										this.displayPOIOnMap(this.poiList, this.poiEducation, this.poiTransport);
									})
									.catch(error => {
										console.error(error);
									});
							})
							.catch(error => {
								console.error(error);
							});
					})
					.catch(error => {
						console.error(error);
					});
			}
			this.$store.dispatch('set_liste_poi', { listepoi: listepoi })
		},

		// eslint-disable-next-line 
		displayPOIOnMap(filteredPOI, filteredEcole, filteredTransport) {
			// Effacer les anciens marqueurs de POI
			const newPOIs = filteredPOI;
			let newPOIMap = []
			for (const p in newPOIs) {
				newPOIMap.push(newPOIs[p].properties.gid)
			}

			// Remove POIs that are no longer in the bounds
			for (const id in this.currentPOIs) {
				let index = newPOIMap.indexOf(this.currentPOIs[id].properties.gid);
				if (index == -1) {
					if (this.group_poi[this.currentPOIs[id].properties.fclass_fr]) {
						this.group_poi[this.currentPOIs[id].properties.fclass_fr].removeLayer(this.currentPOIs[id].marker);
					}
					delete this.currentPOIs[id];
				}
			}
			if (newPOIs) {
				// Add or update POIs
				newPOIs.forEach(poi => {
					let longitude;
					let latitude;
					try {					
						[longitude, latitude] = poi.geometry.coordinates[0];	
					} catch (error) {
						[longitude, latitude] = [poi.geometry.coordinates[0],poi.geometry.coordinates[1]];
					}
					if (this.currentPOIs[poi.properties.gid]) {
						// Update existing POI if necessary
					} else {
						// Add new POI

						if (this.iconPOI(poi.properties.fclass_fr, true)) {
							const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(poi.properties.fclass_fr)});

							// Ajouter une info-bulle au marqueur
							poiMarker.bindPopup(
								'<div class="cs-itinerary-popin">'+
									'<div class="cs-itinerary-popin-header" style="background-color: ' + 'rgb(255, 73, 24);' + '">' + 													
										'<div class="cs-itinerary-popin-title-wrapper">' +
											'<h4 class="cs-itinerary-popin-title">' + this.iconClass(poi.properties.fclass_fr) + ' </h4>' +
										'</div>' +
										'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
									'</div>' +
									'<div class="cs-itinerary-popin-content">' +
										'<div class="cs-itinerary-popin-main-data">' +
											'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + poi.properties.name + '</span>' +
											'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
												'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
											'</cs-poi-line-numbers>' +
										'</div>' +
									'</div>' +
								'</div>'
							).openPopup();

							if (!this.group_poi[poi.properties.fclass_fr]) {
								this.group_poi[poi.properties.fclass_fr] = new L.layerGroup();
								/*
								this.group_poi[poi.properties.fclass_fr] = new L.markerClusterGroup({
									iconCreateFunction: function(cluster) {
										const childCount = cluster.getChildCount();
										const imgurl = that.iconPOI(poi.properties.fclass_fr, true)
										if (imgurl) {
											return L.divIcon({
												className: 'my-custom-cluster transparent-icon',
												html: `
													<div style="background-image: url('${imgurl}'); width: 30px; height: 30px; background-size: cover; position: relative;">
													<span style="position: absolute; top: -2px; right: -2px; color:white; background-color: #0fa8bd; border-radius: 50%; width: 13px; height: 13px; font-size: 8px; text-align: center; line-height: 13px;">
														${childCount}
													</span>
													</div>
												`,
												iconSize: [30, 30]
											});
										}
									}
								});
								*/
								this.map_poi.addLayer(this.group_poi[poi.properties.fclass_fr])
							}
							poiMarker.addTo(this.group_poi[poi.properties.fclass_fr]);

							this.currentPOIs[poi.properties.gid] = { lat: latitude, lng: longitude, marker: poiMarker, properties: poi.properties };
						}
					}
				});
			}
			

			// Ajouter les marqueurs de POI sur la carte
			if (filteredEcole) {
				if (!this.group_poi['education']) this.group_poi['education'] = L.featureGroup().addTo(this.map_poi)

				filteredEcole.forEach(poi => {
					let longitude;
					let latitude;
					try {					
						[longitude, latitude] = poi.geometry.coordinates[0];	
					} catch (error) {
						[longitude, latitude] = [poi.geometry.coordinates[0],poi.geometry.coordinates[1]];
					}
					
					const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI('education')});

					// Ajouter une info-bulle au marqueur
					poiMarker.bindPopup(
						'<div class="cs-itinerary-popin">'+
							'<div class="cs-itinerary-popin-header" style="background-color: ' + 'blue;' + '">' +
								'<div class="cs-itinerary-popin-title-wrapper">' +
									'<h4 class="cs-itinerary-popin-title">' + poi.properties.nature + '</h4>' +
								'</div>' +
								'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
							'</div>' +
							'<div class="cs-itinerary-popin-content">' +
								'<div class="cs-itinerary-popin-main-data">' +
									'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + poi.properties.nom + '</span>' +
									'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
										'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
									'</cs-poi-line-numbers>' +
								'</div>' +
							'</div>' +
						'</div>'
					).openPopup();
					poiMarker.addTo(this.group_poi['education']);
				});
			}

			// Ajouter les marqueurs de POI sur la carte
			if (filteredTransport && filteredTransport.length > 0) {
				if (!this.group_poi['transport']) this.group_poi['transport'] = L.featureGroup().addTo(this.map_poi)
				filteredTransport.forEach(poi => {
					let longitude;
					let latitude;
					try {					
						[longitude, latitude] = poi.geometry.coordinates[0];	
					} catch (error) {
						[longitude, latitude] = [poi.geometry.coordinates[0],poi.geometry.coordinates[1]];
					}
					const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(poi.properties.type)});

					// Ajouter une info-bulle au marqueur
					poiMarker.bindPopup(
						'<div class="cs-itinerary-popin">'+
							'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
								'<div class="cs-itinerary-popin-title-wrapper">' +
									'<h4 class="cs-itinerary-popin-title">' + poi.libelle + '</h4>' +
								'</div>' +
								'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
							'</div>' +
							'<div class="cs-itinerary-popin-content">' +
								'<div class="cs-itinerary-popin-main-data">' +
									'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + poi.properties.name + '</span>' +
									'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
										'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
									'</cs-poi-line-numbers>' +
								'</div>' +
							'</div>' +
						'</div>'
					).openPopup();
					poiMarker.addTo(this.group_poi['transport']);
				});
			} else {
				try { this.group_poi['transport'].clearLayers() } catch (error)  { /* erreur*/ }
				try { this.map_poi.removeLayer(this.group_poi['transport']) } catch (error)  { /* erreur*/ }
				try { this.group_poi['transport'] = new L.featureGroup(); } catch (error)  { /* erreur*/ }
				
			}
		},

		gere_layer: function() {

			try {
				delete $.ajaxSettings.headers["Authorization"];
			} catch (error) {
				// erreur
			}

			try { this.group_metro.clearLayers() } catch (error)  { /* erreur*/ }
			try { this.map_poi.removeLayer(this.group_metro) } catch (error)  { /* erreur*/ }
			try { this.group_metro = new L.featureGroup(); } catch (error)  { /* erreur*/ }

			try { this.group_tram.clearLayers() } catch (error)  { /* erreur*/ }
			try { this.map_poi.removeLayer(this.group_tram) } catch (error)  { /* erreur*/ }
			try { this.group_tram = new L.featureGroup(); } catch (error)  { /* erreur*/ }

			try { this.group_train.clearLayers() } catch (error)  { /* erreur*/ }
			try { this.map_poi.removeLayer(this.group_train) } catch (error)  { /* erreur*/ }
			try { this.group_train = new L.featureGroup(); } catch (error)  { /* erreur*/ }

			if (this.group_ligne_gpe)
			this.group_ligne_gpe.clearLayers()

			let that = this
			let UneUrl
			if (this.app.contour_ligne_gpe) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web%3Agpe_lignes&maxFeatures=100000&bbox=' + this.map_poi.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style:function(feature){
							let v = {"color":feature.properties.color,"weight":3, "outline": "none"}
							return v;
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Ligne ' + feature.properties.ligne).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.map_poi.addLayer(this.group_train);
			}


			if (this.app.contour_poi && this.app.contour_poi.length > 0 && this.app.viewlayer_poi) {
				this.fetchPOIList_old();
			} else {
				for (let i in this.group_poi) {
					this.group_poi[i].clearLayers()
				}
				this.group_poi = []
			}

			let null_style = {}
			if (this.app.contour_ecole) {
				let Groupe_scolaireI = L.icon({iconUrl: 'https://www.myadequation.fr/EFOCUS/icones/Groupe scolaire.svg', iconSize: [30, 30],});
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:enseignement&maxFeatures=100000&bbox=' + this.map_poi.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						pointToLayer: function (feature, latlng) {
							return L.marker(latlng, {icon: Groupe_scolaireI});
						},
						onEachFeature: function(feature, layer)
						{
							layer.bindPopup(feature.properties.nom);
						}
					}).addTo(that.group_ecole);
				});
				this.map_poi.addLayer(this.group_ecole);
			}

			if (this.app.contour_train) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.map_poi.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='rail';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
								return that.metro_style;
								case 'tram': 
								return that.tram_style;
								case 'rail': 
								return JSON.parse(feature.properties.style);
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Train ' + feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.map_poi.addLayer(this.group_train);
			}

			if (this.app.contour_metro) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.map_poi.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='subway';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
									var v = JSON.parse(feature.properties.style)									
									if (feature.properties.nom == "Métro 1") {
										if (feature.properties.siren == "200054807") v.color = "#fb1d1a"
										else v.color = "#F2C931"
									}
									else if (feature.properties.nom == "Métro 2") {
										if (feature.properties.siren == "200054807") v.color = "#199bf6"
										else v.color = "#216EB4"
									}
									else if (feature.properties.nom == "Métro 3") v.color = "#9A9940"
									else if (feature.properties.nom == "Métro 3bis") v.color = "#9A9940"
									else if (feature.properties.nom == "Métro 4") v.color = "#BB4D98"
									else if (feature.properties.nom == "Métro 5") v.color = "#DE8B53"
									else if (feature.properties.nom == "Métro 6") v.color = "#79BB92"
									else if (feature.properties.nom == "Métro 6 - Viaduc de Passy") v.color = "#79BB92"									
									else if (feature.properties.nom == "Métro 7") v.color = "#DF9AB1"
									else if (feature.properties.nom == "Métro 7bis") v.color = "#DF9AB1"
									else if (feature.properties.nom == "Métro 8") v.color = "#C5A3CA"
									else if (feature.properties.nom == "Métro 9") v.color = "#CDC83F"
									else if (feature.properties.nom == "Métro 10") v.color = "#DFB039"
									else if (feature.properties.nom == "Métro 11") v.color = "#8E6538"
									else if (feature.properties.nom == "Métro 12") v.color = "#328E5B"
									else if (feature.properties.nom == "Métro 13") v.color = "#89C7D6"
									else if (feature.properties.nom == "Métro 14") v.color = "#67328E"
									else if (feature.properties.nom == "Ligne 1") v.color = "#F2C931"
									else if (feature.properties.nom == "Ligne 2") v.color = "#BA2028"
									else if (feature.properties.nom == "Métro Ligne a") v.color = "#fb1d1a"
									else if (feature.properties.nom == "Métro Ligne b") v.color = "#79BB92"
									else if (feature.properties.nom == "Ligne A") {
										if (feature.properties.siren == "243100518") v.color = "#fb1d1a"
										else v.color = "#eb2a2c"
									}
									else if (feature.properties.nom == "Ligne B") {
										if (feature.properties.siren == "243100518") v.color = "#F2C931"
										else v.color = "#1da1e0"
									}
									else if (feature.properties.nom == "Ligne C") {
										if (feature.properties.siren == "243100518") v.color = "#79BB92"
										else v.color = "#f27c3c"
									}
									else if (feature.properties.nom == "Ligne D") {
										if (feature.properties.siren == "243100518") v.color = "#DE8B53"
										else v.color = "#1da459"
									}
									else v.weight = 0;
									return v;
								case 'tram': 
								return that.tram_style;
								case 'rail': 
								return that.train_style;
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_metro);
				});
				this.map_poi.addLayer(this.group_metro);
			}

			if (this.app.contour_tramway) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.map_poi.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='tram';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
								return that.metro_style;
								case 'tram': 
								return JSON.parse(feature.properties.style);
								case 'rail': 
								return that.train_style;
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default';
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = '';
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' });
							});
						}
					}).addTo(that.group_tram);
				});
				this.map_poi.addLayer(this.group_tram);
			}
		},

		initcarte: function() {
			if (this.poi_layer) {
				this.map_poi.removeLayer(this.poi_layer)
				this.poi_layer = L.featureGroup().addTo(this.map_poi)
			} else {
				this.poi_layer = L.featureGroup().addTo(this.map_poi)
			}

			let classname = 'map-icon-prj';
			let decal = [20, 40];
			let iconMrk = L.divIcon({ className: classname, html: '<div class="pin_monprojet"><div class="label"></div></div>', iconAnchor: decal });
			let marker = L.marker([this.app.mrk.lat, this.app.mrk.lng],{ icon: iconMrk, title: 'Mon projet' });
			marker.addTo(this.poi_layer)

			if (this.app.contour_poi && this.app.contour_poi.length > 0) {
				this.gere_layer();
			}

			var that = this;


			if (this.app.contour_qpv_trois) {
				$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:qpv_300&outputFormat=application%2Fjson", function(dataQpv3)
				{
					L.geoJson(dataQpv3,
					{
						style: function(){
							return that.qpv_style3; 
						},
						onEachFeature: function(feature, layer)
						{
							layer.bindPopup(
							'<div class="cs-itinerary-popin">'+
								'<div class="cs-itinerary-popin-header" style="background-color: ' + '#ff9900;' + '">' + 													
									'<div class="cs-itinerary-popin-title-wrapper">' +
										'<h4 class="cs-itinerary-popin-title">QPV </h4>' +
									'</div>' +
									'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
								'</div>' +
								'<div class="cs-itinerary-popin-content">' +
									'<div class="cs-itinerary-popin-main-data">' +
										'<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom_qp + '</b>' + '</p>' +
										'<p class="cs-text-legend">' + 'Commune : ' + feature.properties.commune_qp + '</p>' +
										'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
											'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
										'</cs-poi-line-numbers>' +
									'</div>' +
								'</div>' +
							'</div>'
						).openPopup();
						}
						
					}).addTo(that.poi_layer);
				});
			}

			if (this.app.contour_qpv) {
				$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:qpv&outputFormat=application%2Fjson", function(dataQpv)
				{
					L.geoJson(dataQpv,
					{
						
						style: function(){
							return that.qpv_style; 
						},
						onEachFeature: function(feature, layer)
						{
								layer.bindPopup(
								'<div class="cs-itinerary-popin">'+
									'<div class="cs-itinerary-popin-header" style="background-color: ' + '#ff9900;' + '">' + 													
										'<div class="cs-itinerary-popin-title-wrapper">' +
											'<h4 class="cs-itinerary-popin-title">QPV </h4>' +
										'</div>' +
										'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
									'</div>' +
									'<div class="cs-itinerary-popin-content">' +
										'<div class="cs-itinerary-popin-main-data">' +
											'<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom_qp + '</b>' + '</p>' +
											'<p class="cs-text-legend">' + 'Commune : ' + feature.properties.commune_qp + '</p>' +
											'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
												'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
											'</cs-poi-line-numbers>' +
										'</div>' +
									'</div>' +
								'</div>'
							).openPopup();
						}
					}).addTo(that.poi_layer);
				});			
			}

			if (this.app.contour_zac) {
				$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:zonage_urbain&outputFormat=application%2Fjson", function(dataZac)
				{
					L.geoJson(dataZac,
					{
						filter: function(feature) {
							return feature.properties.outil_op === "ZAC"; // Ne garder que les entités où outil_op est "ZAC"
						},
						style: function(){
							return that.zac_style; 
						},
						onEachFeature: function(feature, layer)
						{
							if (feature.properties.outil_op === "ZAC") {

								let info = '<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom + '</b>' + '</p>'
								if (feature.properties.mo && feature.properties.mo!='-') info = info + '<p class="cs-text-legend">Maitre d’ouvrage : ' + feature.properties.mo + '</p>'
								if (feature.properties.commune1 && feature.properties.commune1!='-') info = info + '<p class="cs-text-legend">Commune : ' + feature.properties.commune1 + '</p>'
								if (feature.properties.sp_tot && feature.properties.sp_tot!='-') info = info + '<p class="cs-text-legend">Surface total : ' + feature.properties.sp_tot + ' m²</p>'
								if (feature.properties.nb_logt && feature.properties.nb_logt!='-') info = info + '<p class="cs-text-legend">Nombre de logements : ' + feature.properties.nb_logt + '</p>'
								if (feature.properties.sp_logt && feature.properties.sp_logt!='-') info = info + '<p class="cs-text-legend">Surface de logements : ' + feature.properties.sp_logt + ' m²</p>'
								if (feature.properties.sp_comm && feature.properties.sp_comm!='-') info = info + '<p class="cs-text-legend">Surface de commerces : ' + feature.properties.sp_comm + ' m²</p>'
								if (feature.properties.sp_bur && feature.properties.sp_bur!='-') info = info + '<p class="cs-text-legend">Surface de bureaux : ' + feature.properties.sp_bur + ' m²</p>'
								if (feature.properties.date1 && feature.properties.date1!='-') info = info + '<p class="cs-text-legend">Année de lancement du projet : ' + feature.properties.date1 + '</p>'
								if (feature.properties.date2 && feature.properties.date2!='-') info = info + '<p class="cs-text-legend">Année de début des travaux : ' + feature.properties.date2 + '</p>'
								if (feature.properties.date3 && feature.properties.date3!='-') info = info + '<p class="cs-text-legend">Année de 	livraison finale : ' + feature.properties.date3 + '</p>'
								if (feature.properties.outil_op && feature.properties.outil_op!='-') info = info + '<p class="cs-text-legend">Type de l’opération : ' + feature.properties.outil_op + '</p>'
								layer.bindPopup(
								'<div class="cs-itinerary-popin">'+
									'<div class="cs-itinerary-popin-header" style="background-color: ' + '#c65459;' + '">' + 													
										'<div class="cs-itinerary-popin-title-wrapper">' +
											'<h4 class="cs-itinerary-popin-title">ZAC </h4>' +
										'</div>' +
										'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
									'</div>' +
									'<div class="cs-itinerary-popin-content">' +
										'<div class="cs-itinerary-popin-main-data">' + info + 
											'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
												'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
											'</cs-poi-line-numbers>' +
										'</div>' +
									'</div>' +
								'</div>'
							).openPopup();
							}
						}
						
					}).addTo(that.poi_layer);
				});
			}


			
			

			
			
















			let point = { lat: this.app.mrk.lat, lng: this.app.mrk.lng }
			let radius = 300
			const zoomLevel = this.map_poi.getBoundsZoom([
				[point.lat - radius / 111320, point.lng - radius / (111320 * Math.cos(point.lat * Math.PI / 180))],
				[point.lat + radius / 111320, point.lng + radius / (111320 * Math.cos(point.lat * Math.PI / 180))]
			]);

			this.map_poi.setView([point.lat, point.lng], zoomLevel);			
		}
	},

	mounted: function() {
		let accessToken = 'pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';
		this.LayerMapnik_A = L.tileLayer('https://api.mapbox.com/styles/v1/sebdupont/cl8x860c1000l15qi6h68eupp/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken, {
			attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
			tileSize: 512,
			zoomOffset: -1
		});

		let la = this.LayerMapnik_A

		this.map_poi = L.map('map_poi', {
			center: [this.app.adr_init.latlng.lat, this.app.adr_init.latlng.lng],
			attribution: '<a target="_blank" href="https://leaflet.js/">Leaflet</a> ©',
			minZoom: 12,
			zoom : this.$store.map_osm.getZoom(),
			zoomControl: true,
			editable: true,
			layers: [ la ]
		})
		L.control.scale().addTo(this.map_poi);

		var that = this		
		this.map_poi.on("moveend", that.gere_layer)
		this.map_poi.on("zoomend", that.gere_layer)
		this.initcarte()

		this.map_poi.dragging.disable();
		this.map_poi.touchZoom.disable();
		this.map_poi.doubleClickZoom.disable();
		this.map_poi.scrollWheelZoom.disable();
		this.map_poi.boxZoom.disable();
		this.map_poi.keyboard.disable();
		if (this.map_poi.tap) this.map_poi.tap.disable(); // Désactiver les interactions tactiles si activé
		document.getElementById('map').style.cursor = 'default'; // Changer le curseur pour indiquer que la carte est figée
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		max_pop:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number
			n = n + this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number
			return n
		},
		
		max_mediane:function() {
			let n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.median_income
			if (this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.median_income > n) n = this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.median_income
			return ((Math.round(n/1000,0)+1) * 1000)

		},

		chartDataMen:function() {
			let t = [
				{label: "29 ans et moins", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_0_30.number/this.max_pop*100)},
				{label: "de 30 à 39 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_30_39.number/this.max_pop*100)},
				{label: "de 40 à 49 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_40_49.number/this.max_pop*100)},
				{label: "de 50 à 59 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_50_59.number/this.max_pop*100)},
				{label: "de 60 à 74 ans", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_60_74.number/this.max_pop*100)},
				{label: "75 ans et plus", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].household.age_division.age_75_more.number/this.max_pop*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});
			return t
		},		

		chartDataForme:function() {
			let t = [
				{label: "Collectif", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.apartment_rate*100)},
				{label: "Individuel", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.house_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});
			return t
		},		

		chartDataFormeGraph:function() {
			
			let t = [
				{label: "Collectif", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.apartment_rate*100)},
				{label: "Individuel", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.house_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});

			let j = {
                labels: [t[0].label,t[1].label],
                datasets: [
                    {
                        data: [t[0].nb, t[1].nb],
                        backgroundColor: [
							"#6b23f1",
                            "transparent",
                        ],
                        hoverBackgroundColor: [
                            "#6b23f1",
                        ]
                    }
                ]
            }

			return j
		},		

		chartDataOccupationGraph:function() {
			
			let t = [
				{label: "Propriétaire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.owner_rate*100)},
				{label: "Locataire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.tenant_rate*100)},
				{label: "Social", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.social_tenant_rate*100)},
				{label: "Gratuit", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.free_tenant_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});

			let j = {
                labels: [t[0].label,t[1].label,t[2].label,t[3].label],
                datasets: [
                    {
                        data: [t[0].nb, t[1].nb, t[2].nb, t[3].nb],
                        backgroundColor: [
						"#6a25e9",
						"#c2abed",
						"#d5c5f0",
						"#e9d3fc",
                        ],
                        hoverBackgroundColor: [
                            "#6b23f1",
                        ]
                    }
                ]
            }

			return j
		},		

		chartDataOccupation:function() {
			let t = [
			{label: "Propriétaire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.owner_rate*100)},
			{label: "Locataire", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.tenant_rate*100)},
			{label: "Social", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.social_tenant_rate*100)},
			{label: "Gratuit", nb: Math.round(this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.free_tenant_rate*100)},
			]
			t.sort(function(a, b) {
				return b.nb - a.nb;
			});
			return t
		},		

		chartDataFU: function() {
			let v = {
				labels: ['Collectif','Individuel'],
				datasets: [
					{
						data: [this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.apartment_rate*100, this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.division.house_rate*100],
						backgroundColor: [
                            "#42A5F5",
                            "#66BB6A"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784"
                        ]
					}
				]
			}
			return v
		},

		chartOptionsFU: function() {
			return {
				legend: {
					display:false
                }
			}
		},
			
		chartDataOcc: function() {
			let v = {
				labels: ['Propriétaire','Locataire libre', 'Locataire social', 'à titre gratuit'],
				datasets: [
					{
						data: [
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.owner_rate*100,
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.tenant_rate*100,
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.social_tenant_rate*100,
							this.app.insee[0].data[this.app.insee[0].data.length-1].lodgment.occupation.free_tenant_rate*100
						],
						backgroundColor: [
                            "#42A5F5",
                            "#66BB6A",
							"#FFA726",
							"#FF6384"
                        ],
                        hoverBackgroundColor: [
                            "#64B5F6",
                            "#81C784",
                            "#FFB74D",
							"#FF6384"
                        ]
					}
				]
			}
			return v
		},

		chartOptionsOcc: function() {
			return {
				legend: {
					display:false
                }
			}
		},
		
	}),

	components: { Fieldset, Knob, Chart }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'


.btncarte
    margin 0
    display inline-flex
    cursor pointer
    -webkit-user-select none
    -moz-user-select none
    user-select none
    align-items center
    vertical-align bottom
    text-align center
    overflow hidden
    position relative
    color #fff
    background palette1
    border 1px solid palette1
    padding .75rem 1.25rem
    font-size 1rem
    transition background-color .2s, color .2s, border-color .2s, box-shadow .2s
    border-radius 6px

.btncarte:hover
	background palette0

#map_poi
	width 100%
	height 100%

.TailleCarte
	width 800px
	height 620px
	margin-bottom 10px

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
.stat-box-header
	padding 24px
	min-height 24px
	.title
		font-size 16px
		font-weight 600
		line-height 24px
	.tabs
		position absolute
		right 24px
		top 24px
		.tab
			display inline-block
			margin 0 0 0 4px
			padding 0 12px
			border-radius 40px
			line-height 24px
			font-size 12px
			color #333
			cursor pointer
			&:hover
				background-color light
			&.active
				background blue
				color #fff
	.tabs_other
		position absolute
		right 24px
		top 12px
		.tab
			display inline-block
			margin 0 0 4px 4px
			padding 0 12px
			border-radius 40px
			line-height 24px
			font-size 12px
			color #333
			cursor pointer
			font-weight 500
			&:hover
				background-color light
			&.active
				background grey
				color #fff
		
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0

.overlay1 {
	position: absolute;
	top: 80px;
	left: 190px;
	color: #fff;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: left;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}

.overlay2 {
	position: absolute;
	top: 170px;
	left: 188px;
	color: #000;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: left;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}

.overlay3 {
	position: absolute;
	top: 168px;
	left: 112px;
	color: #000;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: right;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}

.overlay4 {
	position: absolute;
	top: 110px;
	left: 112px;
	color: #000;
	transform: translate(-50%, -50%);
	padding: 10px;
	border-radius: 8px;
	text-align: right;
	pointer-events: none; /* Pour permettre les interactions avec le graphique en dessous */
}
			
</style>
