<template>
	<div class="tab-report" id='report-rs'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset :legend="$t('donnees_insee')" :toggleable="true" class="surface-ground">
				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="row">
							<div class="col full">
								<div class="stat-box-header">
									<div class="flex align-items-center surface-card">
										<div style="width:48px;height:48px;border-radius:10px" class="bg-indigo-100 inline-flex align-items-center justify-content-center mr-3">
											<i class="pi pi-shield text-indigo-600 text-3xl"></i>
										</div>
										<div>
											<span class="text-900 text-xl font-medium mb-2">{{$t('Insee')}}</span>
											<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('statistiques') }}</p>
										</div>
									</div>
								</div>
								<div class="part-content">
									<div class="row" v-if="app.livedata.insee">
										<div class="col half">
											<stat-box name="graph-insee-emploi" />
										</div>
										<div class="col quarter">
											<stat-box name="graph-insee-csp" />
										</div>
										<div class="col quarter">
											<stat-box name="graph-insee-revenu" />
										</div>
									</div>
									<div class="row" v-if="app.livedata.insee">
										<div class="col half">
											<stat-box name="graph-insee-menages" />
										</div>
										<div class="col half">
											<stat-box name="graph-insee-migration" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="part">
					<div class="part-content">
						<div class="row">
							<div class="col full">
								<div class="program-detail-info">
									<div class="program-detail-info-content">
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import StatBox from './report/StatBox'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Fieldset from 'primevue/fieldset';

export default {
	name: 'TabResidences',

	data: function() {
		return {
			activeSection2 : '',
		}
	},

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'taboptim'
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		
	}),

	components: { StatBox ,Fieldset}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
.stat-box-header
	padding 24px
	min-height 24px
	.title
		font-size 16px
		font-weight 600
		line-height 24px
	.tabs
		position absolute
		right 24px
		top 24px
		.tab
			display inline-block
			margin 0 0 0 4px
			padding 0 12px
			border-radius 40px
			line-height 24px
			font-size 12px
			color #333
			cursor pointer
			&:hover
				background-color light
			&.active
				background blue
				color #fff
	.tabs_other
		position absolute
		right 24px
		top 12px
		.tab
			display inline-block
			margin 0 0 4px 4px
			padding 0 12px
			border-radius 40px
			line-height 24px
			font-size 12px
			color #333
			cursor pointer
			font-weight 500
			&:hover
				background-color light
			&.active
				background grey
				color #fff
		
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0
		
</style>
