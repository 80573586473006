import ArrayHistorique from './array/ArrayHistorique'
import ArrayOffre from './array/ArrayOffre'
import ArrayChiffrescles from './array/ArrayChiffrescles'
import ArrayInseeAge from './array/ArrayInseeAge'
import ArrayInseeTxStructuration from './array/ArrayInseeTxStructuration'
import ArrayInseeTxStructurationEtudiant from './array/ArrayInseeTxStructurationEtudiant'
import ArraySecteurs from './array/ArraySecteurs'
import ArrayPrixParkinginclus from './array/ArrayPrixParkinginclus'
import ArrayPrixParkingsans from './array/ArrayPrixParkingsans'
import ArrayRythme from './array/ArrayRythme'
import DivSynoptique from './array/DivSynoptique'
import ArraySynoptique from './array/ArraySynoptique'
import ArraySynoptiquePc from './array/ArraySynoptiquePc'
import ArraySynoptiqueRs from './array/ArraySynoptiqueRs'
import ArrayProgrammesGranulometrie from './array/ArrayProgrammesGranulometrie'
import ArrayProgrammesTvap  from './array/ArrayProgrammesTvap'
import ArrayProgrammesTvar  from './array/ArrayProgrammesTvar'
import ArrayDestinationVentes from './array/ArrayDestinationVentes'
import ArrayDispositifQpv from './array/ArrayDispositifQpv'
import GraphEvolutionRythme from './graph/GraphEvolutionRythme'
import GraphEvolutionOffre from './graph/GraphEvolutionOffre'
import GraphEvolutionInseeAge from './graph/GraphEvolutionInseeAge'
import GraphOffre from './graph/GraphOffre'
import GraphEvolutionPrixPi from './graph/GraphEvolutionPrixPi'
import GraphEvolutionPrixHp from './graph/GraphEvolutionPrixHp'
import GraphDestinationVentes from './graph/GraphDestinationVentes'
import GraphDispositifQpv from './graph/GraphDispositifQpv'
import GraphCityscanAdresseRouteRapide from './graph/GraphCityscanAdresseRouteRapide'
import GraphInseeCsp from './graph/GraphInseeCsp'
import GraphInseeEmploi from './graph/GraphInseeEmploi'
import GraphInseeRevenu from './graph/GraphInseeRevenu'
import GraphInseeMenages from './graph/GraphInseeMenages'
import GraphInseeMigration from './graph/GraphInseeMigration'

export default {
	ArrayOffre, ArrayChiffrescles, GraphOffre, ArrayHistorique, ArraySecteurs, ArrayDestinationVentes, ArrayDispositifQpv, GraphEvolutionOffre, GraphEvolutionInseeAge, GraphDestinationVentes, GraphDispositifQpv, ArraySynoptique, DivSynoptique, ArraySynoptiquePc, ArraySynoptiqueRs, ArrayProgrammesGranulometrie, ArrayProgrammesTvap, ArrayProgrammesTvar, ArrayRythme, ArrayPrixParkinginclus, ArrayPrixParkingsans, GraphEvolutionRythme, GraphEvolutionPrixPi, GraphEvolutionPrixHp, GraphCityscanAdresseRouteRapide
	, GraphInseeCsp, GraphInseeEmploi, GraphInseeRevenu, GraphInseeMenages, GraphInseeMigration, ArrayInseeAge, ArrayInseeTxStructuration, ArrayInseeTxStructurationEtudiant
}