<template>
	<div class="tabs" style="top: 25px !important">
		<div class="p-selectbutton p-buttonset p-component">
			<div class="p-button p-component pd05" @click="ChangeTab('trimestres')" :class="{ 'p-highlight': app.tab == 'trimestres' }" style="min-width:146px; text-align: center;">
				<span style="width:100%; text-align: center;">3 {{app.livedata.periode1.toUpperCase()}}</span>
				<span class="p-ink"></span>
			</div>
			<div class="p-button p-component pd05" @click="ChangeTab('mois')" :class="{ 'p-highlight': app.tab == 'mois' }" style="min-width:146px; text-align: center;">
				<span style="width:100%; text-align: center;">8 {{app.livedata.periode2.toUpperCase()}}</span>
				<span class="p-ink"></span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'TabPeriode',

	data: function() {
		return {
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {

	}),

	methods: {
		ChangeTab: function(periodicite) {
			this.$store.dispatch('change_periodicite', { periodicite: periodicite })
		},
	},
	
	components: {  }
}
</script>

<style lang="stylus" scoped>
@import '../../../../assets/css/app'

</style>
