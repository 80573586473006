<template>
	<transition name="compte">
		<div class="pane pane-compte">
			<div class="logo" @click="$store.dispatch('set_view', { view: 'home' })"><img src="../assets/img/logo.png" alt="ADEQUATION"></div>
			<user />
			<div class="compte-user-etudes">
				<div class="history">
					<p class="history-label">{{ $t('mes_dernieres_etudes') }}</p>
					<div v-for="(histo, index) in user.historique_efocus" class="history-value" @click="fire_last_search(search)" v-bind:key="index">
						<span><icon file="download" />{{ histo.DATE.slice(0, 10) }} {{$t('à')}} {{ histo.HEURE.slice(0, 5) }} {{histo.REGION.toUpperCase()}} {{histo.REFERENCE_PAIEMENT}}</span>
					</div>
				</div>
			</div>
			<div class="compte-user-history">
				<div class="history">
					<p class="history-label">{{ $t('mes_dernieres_recherches') }}</p>
					<div v-for="(search, index) in searches" class="history-value" @click="fire_last_search(search)" v-bind:key="index">
						<span><icon file="search" />{{ search }}</span>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import User from './global/User'

export default {
	name: 'PanePrograms',
	data: function() {
		return {
			location: "",
			searches: [ 'Lyon', '31 rue Mazenod 69003 Lyon', 'Lyon Part-Dieu' ],
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'programs', 'expanded_program', 'user' ]), {

	}),

	methods: {
		endate: function() {
			this.$store.dispatch('set_view', { view: 'home' })
		},

		enheure: function() {
			this.$store.dispatch('set_view', { view: 'home' })
		},

		set_home: function() {
			this.$store.dispatch('set_view', { view: 'home' })
		},

		fire_last_search: function(search) {
			this.location = search
			this.set_location()
		}
	},
	
	components: { User }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.compte-enter, .compte-leave-to
	transform translateX(-100%)

.pane-compte
	position absolute
	left 0
	top 0
	bottom 0
	height 100%
	width (PaneLeftSize + 300px)
	background-color violet
	transition 0.5s easeOutQuart
	z-index 500
	.logo
		position absolute
		left 16px
		top 16px
		padding 0
		height 40px
		img
			height 100%
	.compte-search
		position absolute
		left 50%
		top calc(50% - 64px)
		transform translate(-50%, -50%)
		color #fff
		.slogan
			margin 0 0 24px 0
			white-space nowrap
			font-family volte, sans-serif
			font-size 20px
			text-align center
		.form
			width 350px
			margin 0 auto
			.input
				background #fff
				border-radius 40px
				input
					display block
					width 100%
					height 48px
					margin 0 4px 0 0
					padding 0 24px
					border none
					background none
					background-size 24px 24px
					outline none
					font inherit
					color #333
					&::-webkit-input-placeholder
						font-weight 400
				button
					position absolute
					right 0
					top 0
					height 48px
					width 48px
					border none
					background none
					border-radius 40px
					outline none
					cursor pointer
					opacity 0.5
					&:hover, &:active
						opacity 0.75
			.hint
				padding 0 0 0 32px
				margin 8px 0 0 0
				opacity 0.35
				font-size 12px
				line-height 16px
				.icon
					position absolute
					left 0
					top 50%
					transform translateY(-50%)
	.compte-user-history
		position absolute
		left 24px
		bottom 24px
		color #fff
		.history-label
			opacity 0.5
			font-family volte, sans-serif
			font-size 14px
			text-transform uppercase
			line-height 24px
		.history-value
			> span
				display inline-block
				padding 4px 16px 4px 12px
				margin 0 0 0 -12px
				cursor pointer
				border-radius 40px
				line-height 24px
				&:hover
					background-color alpha(#fff, 10%)
				.icon
					float left
					margin 0 8px 0 0
	.compte-user-etudes
		position absolute
		left 24px
		top headersize
		color #fff
		.history-label
			opacity 0.5
			font-family volte, sans-serif
			font-size 14px
			text-transform uppercase
			line-height 24px
		.history-value
			> span
				display inline-block
				padding 4px 16px 4px 12px
				margin 0 0 0 -12px
				cursor pointer
				border-radius 40px
				line-height 24px
				&:hover
					background-color alpha(#fff, 10%)
				.icon
					float left
					margin 0 8px 0 0

</style>
