<template>
	<div class="tab-report" id='report-heatmap-prix'>
		<div class="tab-content" v-observe-visibility="onVisibilityChange">
			<Fieldset legend="Analyse par prix" :toggleable="true" class="surface-ground">
				<div class="part">
					<div class="p-3 border-round shadow-2 align-items-center surface-card">
						<div class="row">
							<div class="col full">
								<div class="stat-box-header">
									<div class="flex align-items-center surface-card">
										<div style="width:48px;height:48px;border-radius:10px" class="bg-indigo-100 inline-flex align-items-center justify-content-center mr-3">
											<i class="pi pi-shield text-indigo-600 text-3xl"></i>
										</div>
										<div>
											<span class="text-900 text-xl font-medium mb-2">{{ $t('heatmap') }}</span>
											<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('des_prix_par_typologie') }}</p>
										</div>
									</div>
								</div>
								<div class="stat-box-content">
									<div class="program-detail-info">
										<div class="table">
											<div class="program-detail-info-filters" style="text-align: left;">
												<table>
													<th style="display: none"></th>
													<tr>
														<td style="width: 250px;">
															<span >&nbsp;{{ $t('filtres') }} : </span>
														</td>
														<td >
															<div class="p-selectbutton p-buttonset p-component">
																<div class="p-button p-component pd05 p-highlight" style="min-width: 190px; text-align: center;">
																	<span style="width: 100%; text-align: center;">{{ $t('programmes_en_tva_reduite') }}</span>
																	<span class="p-ink" style="height: 68px; width: 68px; "></span>
																</div>
															</div>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div class="part-content">
									<div class="row">
										<div class="col full">
											<apexchart id="heatmap_p" type="heatmap" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="part">
					<div class="part-content">
						<div class="row">
							<div class="col full">
								<div class="program-detail-info">
									<div class="program-detail-info-content">
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</Fieldset>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ObserveVisibility  } from 'vue-observe-visibility'
import Fieldset from 'primevue/fieldset';

export default {
	name: 'TabHeatmapPrix',

	data: function() {
		return {
			TabP : [],
			TabT1 : [],
			TabT2 : [],
			TabT3 : [],
			TabT4 : [],
			TabT5 : [],
			minPrice: 0,
			maxPrice: 0,
			priceRange: 0,
			numberOfCategories : 6,
			priceCategories : [],
			activeSection2 : '',
			chartOptions: {
				chart: {
					type: 'heatmap',
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#008FFB"],
				xaxis: {
					type: 'category',
					categories: []
				},
			},
			series: [],
		}
	},

	directives: {
		'observe-visibility': ObserveVisibility
	},

	methods: {
		updateSeriesLine: function(dataPrix, dataCategories) {
			this.series = dataPrix
			let chartOptions= {
				chart: {
					type: 'heatmap',
					toolbar: {
						show: false
					}
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#008FFB"],
				xaxis: {
					type: 'category',
					categories: dataCategories
				},
			}
			this.chartOptions = chartOptions
		},

		GetAll() {
			this.series[4] = {name:'T1', data: this.app.livedata.priceCountsT1};
			this.series[3] = {name:'T2', data: this.app.livedata.priceCountsT2};
			this.series[2] = {name:'T3', data: this.app.livedata.priceCountsT3};
			this.series[1] = {name:'T4', data: this.app.livedata.priceCountsT4};
			this.series[0] = {name:'T5', data: this.app.livedata.priceCountsT5};
			let priceCategories = [];
			for (let index = 0; index < this.app.livedata.priceCategories.length; index++) {
				priceCategories.push(this.app.livedata.priceCategories[index].label);					
			}
		
			let chartOptions= {
				chart: {
					type: 'heatmap',
					toolbar: {
						show: false
					}
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#008FFB"],
				xaxis: {
					type: 'category',
					categories: priceCategories
				}
			}
			this.chartOptions = chartOptions
		},
		GetT(typo) {
			// Exemple de liste de prix
			const prices = [100, 110, 120, 80, 120, 130, 200, 150, 120, 80];

			// Trouvez le prix minimum et maximum dans le tableau
			const minPrice = Math.min(...prices);
			const maxPrice = Math.max(...prices);

			// Déterminez le nombre de catégories (ou plages) que vous souhaitez
			const numberOfCategories = 6; // Vous pouvez ajuster ce nombre selon vos besoins

			// Calculez la taille de chaque plage de prix
			const priceRange = (maxPrice - minPrice) / numberOfCategories;

			// Initialisez le tableau de catégories de prix
			const priceCategories = [];

			// Remplissez le tableau de catégories de prix avec les plages
			for (let i = 0; i < numberOfCategories; i++) {
				const categoryMin = minPrice + i * priceRange;
				const categoryMax = minPrice + (i + 1) * priceRange;
				priceCategories.push(
					{
						label: `de ${categoryMin} à ${categoryMax}`,
						min: categoryMin,
						max: categoryMax,
					}
					);
			}
			let t=[]
			if (this.statgeotypotranche[typo]) {
				if (this.statgeotypotranche[typo].points) {
					for (let index = 0; index < this.statgeotypotranche[typo].points.length-1; index++) {
						const element = this.statgeotypotranche[typo].points[index];
						t.push(element.y.count)
					}
				}
			}
			return t
		},

		maj_graph: function() {
			this.GetAll();
		},

		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'tabheatmap'
			} else {
				if (this.activeSection2 == 'tabheatmap') {
					this.activeSection2 = ''
				}
			}
		},
	},

	mounted: function() {
		this.maj_graph()
	},

	computed: Object.assign(mapGetters([ 'app', 'report' ]), {
		
	}),

	components: { Fieldset }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'
@import '../../assets/css/grid'

.tab-content
	padding 24px 32px
	.part
		margin 0 0 40px 0
		.part-title
			margin 0 0 8px 0
			line-height 32px
			font-weight 600
			text-transform uppercase
			font-size 16px
.stat-box-header
	padding 24px
	min-height 24px
	.title
		font-size 16px
		font-weight 600
		line-height 24px
	.tabs
		position absolute
		right 24px
		top 24px
		.tab
			display inline-block
			margin 0 0 0 4px
			padding 0 12px
			border-radius 40px
			line-height 24px
			font-size 12px
			color #333
			cursor pointer
			&:hover
				background-color light
			&.active
				background blue
				color #fff
	.tabs_other
		position absolute
		right 24px
		top 12px
		.tab
			display inline-block
			margin 0 0 4px 4px
			padding 0 12px
			border-radius 40px
			line-height 24px
			font-size 12px
			color #333
			cursor pointer
			font-weight 500
			&:hover
				background-color light
			&.active
				background grey
				color #fff
.tabs2
	position fixed
	top 61px
	left 0px
	left 60px
	color secondary
	background white
	border-bottom 1px solid hsl(0deg 0% 86%)
	border-left 1px solid hsl(0deg 0% 86%)
	&:after
		content ''
		display block
		clear both
	.tab
		float left
		margin 0 0px 0 0
		height 60px
		width 180px
		display flex
		align-items center
		justify-content center
		flex-direction column
		opacity 1
		font-size 12px
		font-weight 400
		border-bottom 4px solid transparent
		text-align center
		text-transform uppercase
		cursor pointer
		color #a1a5b8
		&:hover
			color #666
		&.active
			opacity 2
			border-bottom-color var(--primary)
			color var(--primary)
		.icon
			display block
			margin 0 0 2px 0
.program-detail-info
	padding 8px 0
	.program-detail-info-filters
		text-align center
		margin 0 0 8px 0
				
</style>
