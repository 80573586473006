import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
	path: '/',
	name: 'e-focus',
	meta: { title: 'e-focus' }
  },
  {
	path: '/print',
	name: 'print',
	meta: { title: 'e-focus - Print' }
  },
  {
	path: '/report',
	name: 'report',
	meta: { title: 'e-focus - Rapport' }
  },
  {
	path: '/report_B',
	name: 'report_B',
	meta: { title: 'e-focus - Rapport - B' }
  },
  {
	path: '/optimisation',
	name: 'optimisation',
	meta: { title: 'e-focus - Rapport - Optimisation de projet' }
  },
  {
	path: '/synoptique-programmes',
	name: 'synoptique-programmes',
	meta: { title: 'e-focus - Rapport - Synoptique de programmes' }
  },
  {
	path: '/permis-construire',
	name: 'permis-construire',
	meta: { title: 'e-focus - Rapport - Permis de construire' }
  },
  {
	path: '/statistiques-secteurs',
	name: 'statistiques-secteurs',
	meta: { title: 'e-focus - Rapport - Statistiques des secteurs' }
  },
  {
	path: '/login',
	name: 'login',
	meta: { title: 'e-focus - Login' }
  },
  {
	path: '/modules',
	name: 'modules',
	meta: { title: 'e-focus - Modules disponibles' }
  },
  {
	path: '/home',
	name: 'home',
	meta: { title: 'e-focus - Accueil' }
  },
  {
	path: '/user',
	name: 'user',
	meta: { title: 'e-focus - Mon compte' }
  },
  {
	path: '/ancien',
	name: 'ancien',
	meta: { title: "e-focus - Module Marché de l'ancien" }
  },
  {
	path: '/locatif',
	name: 'locatif',
	meta: { title: "e-focus - Module Marché du locatif" }
  },
  {
	path: '/adresse',
	name: 'adresse',
	meta: { title: "e-focus - Module Qualité de l'adresse" }
  },
  {
	path: '/neuf',
	name: 'neuf',
	meta: { title: 'e-focus - Module du neuf' }
  },
  {
	path: '/program/:id',
	name: 'program',
	meta: { title: 'e-focus - Fiche Programme' }
  },
  {
	path: '/offre/:id',
	name: 'offre',
	meta: { title: 'e-focus - Fiche Ancien' }
  },
  {
	path: '/rss',
	name: 'rss',
	meta: { title: 'e-focus - Résidences services' }
  },
  {
	path: '/rs/:id',
	name: 'rs',
	meta: { title: 'e-focus - Fiche RS' }
  },
  {
	path: '/gisements',
	name: 'gisements',
	meta: { title: 'e-focus - Gisements fonciers' }
  },
  {
	path: '/gisement/:id',
	name: 'gisement',
	meta: { title: 'e-focus - Gisement foncier' }
  },
  {
	path: '/rpls',
	name: 'rpls',
	meta: { title: 'e-focus - Rpls' }
  },
  {
	path: '/rpls/:id',
	name: 'rpls',
	meta: { title: 'e-focus - Fiche RPLS' }
  },
  {
	path: '/annonces',
	name: 'annonces',
	meta: { title: 'e-focus - Annonces Locatives' }
  },
  {
	path: '/annonce/:id',
	name: 'annonce',
	meta: { title: 'e-focus - Fiche Annonce locative' }
  },
  {
	path: '/vie',
	name: 'vie',
	meta: { title: "e-focus - Module Qualité de l'adresse - Qualité de vie" }
  },
  {
	path: '/transport',
	name: 'transport',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transports" }
  },
  {
	path: '/transport-bus',
	name: 'transport-bus',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-bus" }
  },
  {
	path: '/transport-tram',
	name: 'transport-tram',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-tramway" }
  },
  {
	path: '/transport-metro',
	name: 'transport-metro',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-metro" }
  },
  {
	path: '/transport-route-rapide',
	name: 'transport-route-rapide',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-route-rapide" }
  },
  {
	path: '/transport-parking',
	name: 'transport-parking',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-parking" }
  },
  {
	path: '/transport-gare',
	name: 'transport-gare',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-gare" }
  },
  {
	path: '/transport-aeroport',
	name: 'transport-aeroport',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-aéroport" }
  },
  {
	path: '/transport-port',
	name: 'transport-port',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-port" }
  },
  {
	path: '/transport-velo',
	name: 'transport-velo',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-vélo" }
  },
  {
	path: '/transport-auto',
	name: 'transport-auto',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-auto" }
  },
  {
	path: '/transport-borne',
	name: 'transport-borne',
	meta: { title: "e-focus - Module Qualité de l'adresse - Transport-bornes de recharge" }
  },
  {
	path: '/education',
	name: 'education',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education" }
  },
  {
	path: '/education-creche',
	name: 'education-creche',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-crêche" }
  },
  {
	path: '/education-maternelle',
	name: 'education-maternelle',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-maternelle" }
  },
  {
	path: '/education-elementaire',
	name: 'education-elementaire',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-élémentaire" }
  },
  {
	path: '/education-college',
	name: 'education-college',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-collége" }
  },
  {
	path: '/education-lycee',
	name: 'education-lycee',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-lycée" }
  },
  {
	path: '/education-lycee-pro',
	name: 'education-lycee-pro',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-lycee-professionnel" }
  },
  {
	path: '/education-universite',
	name: 'education-universite',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-université" }
  },
  {
	path: '/education-superieur',
	name: 'education-superieur',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-superieur" }
  },
  {
	path: '/education-formation',
	name: 'education-formation',
	meta: { title: "e-focus - Module Qualité de l'adresse - Education-formation-professionnel" }
  },
  {
	path: '/commerces',
	name: 'commerces',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerces" }
  },
  {
	path: '/commerce-alimentation',
	name: 'commerce-alimentation',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-alimentation" }
  },
  {
	path: '/commerce-bouche',
	name: 'commerce-bouche',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-bouche" }
  },
  {
	path: '/commerce-utilitaire',
	name: 'commerce-utilitaire',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-utilitaire" }
  },
  {
	path: '/commerce-shopping',
	name: 'commerce-shopping',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-shopping" }
  },
  {
	path: '/commerce-soin',
	name: 'commerce-soin',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-soin" }
  },
  {
	path: '/commerce-logement',
	name: 'commerce-logement',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-logement" }
  },
  {
	path: '/commerce-vehicule',
	name: 'commerce-vehicule',
	meta: { title: "e-focus - Module Qualité de l'adresse - Commerce-vehicule" }
  },
  {
	path: '/loisirs',
	name: 'loisirs',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisirs" }
  },
  {
	path: '/loisir-bibliotheque',
	name: 'loisir-bibliotheque',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-bibliothéque" }
  },
  {
	path: '/loisir-theatre',
	name: 'loisir-theatre',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-theatre" }
  },
  {
	path: '/loisir-musee',
	name: 'loisir-musee',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-musée" }
  },
  {
	path: '/loisir-cinema',
	name: 'loisir-cinema',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-cinéma" }
  },
  {
	path: '/loisir-bar',
	name: 'loisir-bar',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-bar" }
  },
  {
	path: '/loisir-restaurant',
	name: 'loisir-restaurant',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-restaurant" }
  },
  {
	path: '/loisir-discotheque',
	name: 'loisir-discotheque',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-discothéque" }
  },
  {
	path: '/loisir-jardin',
	name: 'loisir-jardin',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-jardin" }
  },
  {
	path: '/loisir-sport-collectif',
	name: 'loisir-sport-collectif',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-sport-collectif" }
  },
  {
	path: '/loisir-sport-individuel',
	name: 'loisir-sport-individuel',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-sport-individuel" }
  },
  {
	path: '/loisir-hotel',
	name: 'loisir-hotel',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-hotel" }
  },
  {
	path: '/loisir-hebergement',
	name: 'loisir-hebergement',
	meta: { title: "e-focus - Module Qualité de l'adresse - Loisir-hébergement" }
  },
  {
	path: '/services',
	name: 'services',
	meta: { title: "e-focus - Module Qualité de l'adresse - Services" }
  },
  {
	path: '/service-agees',
	name: 'service-agees',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-personnes-agées" }
  },
  {
	path: '/service-commissariat',
	name: 'service-commissariat',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-commissariat" }
  },
  {
	path: '/service-dechet',
	name: 'service-dechet',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-déchet" }
  },
  {
	path: '/service-gendarmerie',
	name: 'service-gendarmerie',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-gendarmerie" }
  },
  {
	path: '/service-hopital',
	name: 'service-hopital',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-hôpital" }
  },
  {
	path: '/service-laboratoire',
	name: 'service-laboratoire',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-laboratoire" }
  },
  {
	path: '/service-medecin',
	name: 'service-medecin',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-medecin" }
  },
  {
	path: '/service-pharmacie',
	name: 'service-pharmacie',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-pharmacie" }
  },
  {
	path: '/service-pompier',
	name: 'service-pompier',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-pompier" }
  },
  {
	path: '/service-poste',
	name: 'service-poste',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-poste" }
  },
  {
	path: '/service-recyclage',
	name: 'service-recyclage',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-recyclage" }
  },
  {
	path: '/service-specialiste',
	name: 'service-specialiste',
	meta: { title: "e-focus - Module Qualité de l'adresse - Service-spécialiste" }
  },
  {
	path: '/numerique',
	name: 'numerique',
	meta: { title: "e-focus - Module Qualité de l'adresse - Numérique" }
  },
  {
	path: '/ancien-mutations',
	name: 'ancien-mutations',
	meta: { title: "e-focus - Mutations dans l'ancien" }
  },
  {
	path: '/ancien-offres',
	name: 'ancien-offres',
	meta: { title: "e-focus - Offre dans l'ancien" }
  },
  {
	path: '/ancien-conjoncture',
	name: 'ancien-conjoncture',
	meta: { title: "e-focus - Conjoncture dans l'ancien" }
  },
  {
	path: '/locatif-conjoncture',
	name: 'locatif-conjoncture',
	meta: { title: "e-focus - Conjoncture dans le locatif" }
  },
]

const router = new VueRouter({
  routes
})

export default router
