<template>
	<transition name="home">
		<div class="pane pane-home paneglobal" style="">
			<div class="home-search">
				<div class="middle_center" style="top: 100px;transform: translate(-50%);">
					<div class="text-3xl font-medium text-900 mb-2 text-center">{{ $t('où_se_trouve_votre_projet_?') }}</div>
					<br><br>
					<div class="middle_center" style="margin-top:40px;">
						<ul class="surface-card p-2 m-0 list-none flex overflow-x-auto select-none" style="width: max-content;">
							<li class="pr-3">
								<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" 
									:class="{'bg-primary hover:bg-primary': app.efocus_type == 'A', 'text-700': app.efocus_type != 'A'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'A'})">
									<i class="pi pi-search mr-2"></i>
									<span class="font-medium">{{ $t('recherche') }}</span>
								</a>
							</li>
							<li class="flex align-items-center" v-if="user.groupe=='ADEQUATION'">
								<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
							</li>
							<li class="px-3" v-if="user.groupe=='ADEQUATION'">
								<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple" 
									:class="{'bg-primary hover:bg-primary': app.efocus_type != 'A', 'text-700': app.efocus_type != 'B'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'B'})">
									<i class="pi pi-tags mr-2"></i>
									<span class="font-medium" v-badge.warning="'beta'">{{ $t('insights') }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
								</a>
							</li>
							<li class="flex align-items-center">
								<div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
							</li>
							<li class="px-3">
								<a v-ripple class="cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 p-ripple"   v-styleclass="{ selector: '#search_history', enterClass: 'hidden', enterActiveClass: 'fadeinright', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright', hideOnOutsideClick: true }">
									<i class="pi pi-history mr-2"></i>
									<span class="font-medium">{{ $t('historique') }}</span>
								</a>
							</li>
						</ul>
					</div>
					<br><br>
					<form class="form" v-on:submit.prevent="noop">
						<p style="text-align:center">{{ $t('pour_demarrer_votre_etude_de_marche_e-focus,') }}</p><p style="text-align:center">{{ $t('localisez_votre_projet_en_saisissant_une_commune_un_quartier_ou_son_adresse_exacte') }}</p>
						<br>
						<span class="algolia-places">
							<InputText v-focus ref="search_input" id="search_input" class="w-full mb-1" type="search_input" :placeholder="$t('Adresse, commune, ...')"/>
						</span>
						<br>
						<br>
						<p style="text-align:center">{{ $t('le_service_de_calcul_de_la_valeur_de_marche') }}</p><p style="text-align:center">{{ $t('est_actif_des_lors_que_la_recherche_est_faite_sur_une_adresse_exacte.') }}</p>
					</form>
					<br>
					<br>
					<div v-if="app.efocus_type != 'A'" style="display: flex;width: 1000px;">
						<div class="w-5">
							<div class="text-900 text-xl mb-3 text-left font-medium">{{ $t('vos_differents_parcours') }}</div>
							<div class="surface-border border-1 p-3 border-round-top flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'B', 'bg-primary': app.efocus_type === 'B'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'B'});tier1='B'">
								<RadioButton name="tier1" :value="'B'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'B', 'bg-primary': app.efocus_type === 'B'}">{{ $t('analyse_des_projets_-__brs_&_psla') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'B', 'bg-primary': app.efocus_type === 'B'}">{{ $t('positionnement') }}.</span>
								</div>
							</div>
							<div v-if="app.user.parcours_perso.includes('efocus-parcours-personnalises-tous')" class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'efocus-parcours-personnalises-tous', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tous'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'efocus-parcours-personnalises-tous'});tier1='efocus-parcours-personnalises-tous'">
								<RadioButton name="tier1" :value="'efocus-parcours-personnalises-tous'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'efocus-parcours-personnalises-tous', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tous'}">{{ $t('residences_service') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'efocus-parcours-personnalises-tous', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tous'}">{{ $t('Toutes') }}</span>
								</div>
							</div>
							<div v-if="app.user.parcours_perso.includes('efocus-parcours-personnalises-senior')" class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'efocus-parcours-personnalises-senior', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-senior'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'efocus-parcours-personnalises-senior'});tier1='efocus-parcours-personnalises-senior'">
								<RadioButton name="tier1" :value="'efocus-parcours-personnalises-senior'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'efocus-parcours-personnalises-senior', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-senior'}">{{ $t('residences_service') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'efocus-parcours-personnalises-senior', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-senior'}">{{ $t('Senior') }}</span>
								</div>
							</div>
							<div v-if="app.user.parcours_perso.includes('efocus-parcours-personnalises-etudiant')" class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'efocus-parcours-personnalises-etudiant', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-etudiant'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'efocus-parcours-personnalises-etudiant'});tier1='efocus-parcours-personnalises-etudiant'">
								<RadioButton name="tier1" :value="'efocus-parcours-personnalises-etudiant'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'efocus-parcours-personnalises-etudiant', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-etudiant'}">{{ $t('residences_service') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'efocus-parcours-personnalises-etudiant', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-etudiant'}">{{ $t('Etudiant') }}</span>
								</div>
							</div>
							<div v-if="app.user.parcours_perso.includes('efocus-parcours-personnalises-tourisme')" class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'efocus-parcours-personnalises-tourisme', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tourisme'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'efocus-parcours-personnalises-tourisme'});tier1='efocus-parcours-personnalises-tourisme'">
								<RadioButton name="tier1" :value="'efocus-parcours-personnalises-tourisme'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'efocus-parcours-personnalises-tourisme', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tourisme'}">{{ $t('residences_service') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'efocus-parcours-personnalises-tourisme', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tourisme'}">{{ $t('Tourisme') }}</span>
								</div>
							</div>
							<div v-if="app.user.parcours_perso.includes('efocus-parcours-personnalises-tout-age')" class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'efocus-parcours-personnalises-tout-age', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tout-age'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'efocus-parcours-personnalises-tout-age'});tier1='efocus-parcours-personnalises-tout-age'">
								<RadioButton name="tier1" :value="'efocus-parcours-personnalises-tout-age'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'efocus-parcours-personnalises-tout-age', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tout-age'}">{{ $t('residences_service') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'efocus-parcours-personnalises-tout-age', 'bg-primary': app.efocus_type === 'efocus-parcours-personnalises-tout-age'}">{{ $t('Tout âge') }}</span>
								</div>
							</div>
							<div class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'C', 'bg-primary': app.efocus_type === 'C'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'C'});tier1='C'">
								<RadioButton name="tier1" :value="'C'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'C', 'bg-primary': app.efocus_type === 'C'}">{{ $t('Comparaison programme et adresse') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'C', 'bg-primary': app.efocus_type === 'C'}">{{ $t('benchmark') }}</span>
								</div>
							</div>
							<div class="surface-border border-1 border-top-none p-3 border-round-bottom flex align-items-center cursor-pointer" :class="{'surface-card': app.efocus_type !== 'D', 'bg-primary': app.efocus_type === 'D'}" @click="$store.dispatch('set_efocus_type', {efocus_type: 'D'});tier1='D'">
								<RadioButton name="tier1" :value="'D'" v-model="tier1" class="mr-3"></RadioButton>
								<div class="mr-4 md:mr-8">
									<div class="font-medium mb-1" :class="{'text-900': app.efocus_type !== 'D', 'bg-primary': app.efocus_type === 'D'}">{{ $t('validation_opportunite_fonciere') }}</div>
									<span class="text-sm" :class="{'text-600': app.efocus_type !== 'D', 'bg-primary': app.efocus_type === 'D'}">&nbsp;</span>
								</div>
							</div>
						</div>
						<div class="w-7">
							<div class="text-900 text-xl mb-3 text-left font-medium">{{ $t('ce_parcours_personnalise_vous_permet_de') }}</div>
							<div class="card" style="margin-top: 30px;">
								<Timeline :value="getEvents" class="customized-timeline">
									<template #marker="slotProps">
										<span class="custom-marker shadow-2" :style="{backgroundColor: slotProps.item.color}">
											<i :class="slotProps.item.icon"></i>
										</span>
									</template>
									<template #opposite="slotProps">
										{{slotProps.item.status}}
									</template>
									<template #content="slotProps">
										<small class="p-text-secondary">{{slotProps.item.date}}</small>
									</template>
								</Timeline>
							</div>
						</div>
					</div>
					<div v-else style="display: flex;width:800px;">
						<div class="w-full">
							<div class="text-900 text-xl mb-3 text-left font-medium">{{ $t('vos_recherches_recentes') }}</div>
							<div class="wrapper-scroll" style="height: calc(100vh - 650px); max-height:285px">
								<div class="surface-border border-1 p-3 border-round-top flex align-items-center cursor-pointer surface-card bb"  v-for="search in searchs.slice(0,1)" v-bind:key="search.name" @click="fire_last_search(search)">
									<div class="mr-3 p-radiobutton p-component">
										<div class="p-hidden-accessible">
										</div>
										<div>
											<icon file="search" size="24"/>
										</div>
									</div>
									<div class="mr-4 md:mr-8">
										<div class="font-medium mb-1 text-900">{{ search.name }}</div>
										<span class="text-sm text-600">{{ DonneTypeEtude(search.efocus_type) }}</span>
									</div>
								</div>
								<div class="surface-border border-1 border-top-none p-3 flex align-items-center cursor-pointer surface-card bb"  v-for="search in searchs.slice(1,9)" v-bind:key="search.name" @click="fire_last_search(search)">
									<div class="mr-3 p-radiobutton p-component">
										<div class="p-hidden-accessible">
										</div>
										<div>
											<icon file="search" size="24"/>
										</div>
									</div>
									<div class="mr-4 md:mr-8">
										<div class="font-medium mb-1 text-900">{{ search.name }}</div>
										<span class="text-sm text-600">{{ DonneTypeEtude(search.efocus_type) }}</span>
									</div>
								</div>
								<div class="surface-border border-1 border-top-none p-3 border-round-bottom flex align-items-center cursor-pointer surface-card bb" v-for="search in searchs.slice(9,10)" v-bind:key="search.name" @click="fire_last_search(search)">
									<div class="mr-3 p-radiobutton p-component">
										<div class="p-hidden-accessible">
										</div>
										<div>
											<icon file="search" size="24"/>
										</div>
									</div>
									<div class="mr-4 md:mr-8">
										<div class="font-medium mb-1 text-900">{{ search.name }}</div>
										<span class="text-sm text-600">{{ DonneTypeEtude(search.efocus_type) }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="search_history" class="hidden surface-overlay absolute top-0 right-0 shadow-2 w-full lg:w-3 h-full">
					<div class="flex flex-column p-4 h-full">
						<div class="flex align-items-center justify-content-between mb-4">
							<span class="text-900 text-xl font-medium" style="width:100%">{{ $t('historique_de_recherche') }}</span>
							<Button icon="pi pi-trash" class="p-button-text p-button-plain p-button-rounded" @click="PurgeRecherche()"></Button>
							<Button icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain"
							v-styleclass="{ selector: '#search_history', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutright' }"></Button>
						</div>
						<div class="flex-auto overflow-y-auto">
							<ScrollPanel style="width: 100%;height:calc(100% - 80px);">
								<p>
									<ul class="list-none p-0 m-0">
										<li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4 hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple surface-border cursor-pointer" v-for="search in searchs.slice(0,10)" v-bind:key="search.name" @click="fire_last_search(search)">
											<div class="flex">
												<div class="mr-0 md:mr-1">
													<span class="block text-900 font-medium mb-1">{{ DonneTypeEtude(search.efocus_type) }}</span>
													<div class="text-600">{{ search.name }}</div>
												</div>
											</div>
											<div class="mt-2 md:mt-0 flex flex-nowrap">
												<Button class="p-button-text p-button-plain p-button-rounded mr-1" icon="pi pi-search"></Button>
											</div>
										</li>
									</ul>
								</p>
							</ScrollPanel>
						</div>
					</div>
				</div>
			</div>

			<div class="px-4 py-8 md:px-6 lg:px-8 flex align-items-center justify-content-center h-fullp" v-if="this.app.nouveaute.length && v" id="news">
				<div class="surface-card p-4 shadow-2 border-round w-full lg:w-6" >
					<div class="text-center">
						<Carousel :numVisible="1" :value="this.app.nouveaute" :circular="true">
							<template #item="slotProps">
								<div class="text-center mb-8">
									<div class="mx-auto font-medium text-xl mb-4 text-black-900">{{slotProps.data.eco_titre_haut}}</div>
									<ImagePreview v-if="slotProps.data.eco_image" :src="slotProps.data.eco_image" alt="Image" height="285" />
									<div class="mx-auto font-medium text-xl mb-4 text-black-900">{{slotProps.data.eco_titre}}</div>
									<div class="mkdw" style="text-align: left">
										<div class="m-5" v-html="markdownContent(slotProps.data.eco_text)"></div>
									</div>
									<div style="text-align: center;margin-top:40px;" v-if="slotProps.data.eco_button">
										<a :href="TraiteUrl(slotProps.data.eco_button_url)" target="_blank">

											<button type="button" class="p-button-s p-component">
												<span class="p-button-label">{{ slotProps.data.eco_button_libelle }}</span>
												<span class="p-ink"></span>
											</button>
										</a>


									</div>
								</div>
							</template>
						</Carousel>
					</div>
					<div class=" border-top-1 surface-border pt-3" style="text-align: right;">
						<Button style="color: #64748B" class="p-button-outlined" @click="v = false" :label="$t('Fermer')"></Button>
					</div>
				</div>
			</div>
		</div>

	</transition>
</template>
	
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import ScrollPanel from 'primevue/scrollpanel'
import Timeline from 'primevue/timeline';
import RadioButton from 'primevue/radiobutton';
import { Bus } from '../Bus';
import Carousel from 'primevue/carousel';
import { marked } from 'marked';
import ImagePreview from 'primevue/imagepreview';

export default {
	name: 'PaneHome',

	data: function() {
		return {
			tier1:'A',
			events0: [],
			eventsB: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les programmes en TVA réduite.', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'à la synthèse.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsC: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les programmes.', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'à la synthèse.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsD: [
				{status: 'Rechercher', date: 'une adresse / une parcelle.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Accéder', date: 'à une synthèse d’indicateurs du neuf (stock, taux d’écoulement, dernières ventes…).', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Personnaliser', date: 'votre rapport et ajouter vos commentaires.', icon: 'pi pi-cog', color: '#A29C00'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsE: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les résidences séniors souhaitées', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'une synthèse d’indicateurs personnalisés.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Personnaliser', date: 'votre rapport et ajouter vos commentaires.', icon: 'pi pi-cog', color: '#A29C00'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsF: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les résidences souhaitées : étudiants, séniors…', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'une synthèse d’indicateurs personnalisés.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Personnaliser', date: 'votre rapport et ajouter vos commentaires.', icon: 'pi pi-cog', color: '#A29C00'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsG: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les résidences étudiantes souhaitées', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'une synthèse d’indicateurs personnalisés.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Personnaliser', date: 'votre rapport et ajouter vos commentaires.', icon: 'pi pi-cog', color: '#A29C00'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsH: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les résidences de tourisme souhaitées', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'une synthèse d’indicateurs personnalisés.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Personnaliser', date: 'votre rapport et ajouter vos commentaires.', icon: 'pi pi-cog', color: '#A29C00'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			eventsI: [
				{status: 'Rechercher', date: 'une adresse ou une commune.', icon: 'pi pi-search', color: '#9C27B0', image: 'game-controller.jpg'},
				{status: 'Sélectionner', date: 'les résidences tout âge souhaitées', icon: 'pi pi-check', color: '#673AB7'},
				{status: 'Accéder', date: 'une synthèse d’indicateurs personnalisés.', icon: 'pi pi-chart-bar', color: '#FF9800'},
				{status: 'Personnaliser', date: 'votre rapport et ajouter vos commentaires.', icon: 'pi pi-cog', color: '#A29C00'},
				{status: 'Imprimer', date: 'le rapport de synthèse.', icon: 'pi pi-print', color: '#607D8B'}
			],
			filter: 'A',
			searchs: [],
			v: true,
			options: {
				appId: 'pl7ASBEOP6XG',
				apiKey: 'a0d69ec053b3f74b46af6ff253901edf',
				countries: ['FR'],
			},
			form: {
				country: {
					label: '',
					data: {},
				},
			},
			location: "",
			searches: [ 'Lyon', '31 rue Mazenod 69003 Lyon', 'Lyon Part-Dieu' ]
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'user']), {
		getEvents() {
			const eventMapping = {
				'B': this.eventsB,
				'C': this.eventsC,
				'D': this.eventsD,
				'efocus-parcours-personnalises-senior': this.eventsE,
				'efocus-parcours-personnalises-tous': this.eventsF,
				'efocus-parcours-personnalises-etudiant': this.eventsG,
				'efocus-parcours-personnalises-tourisme': this.eventsH,
				'efocus-parcours-personnalises-tout-age': this.eventsI,
			};
			return eventMapping[this.app.efocus_type] || this.events0;
		}

	}),

	methods: {
		TraiteUrl: function(s) {
			return s.replace('%email%', this.app.user.email);
		},

		markdownContent: function(s) {
			return marked(s);
		},

		DonneTypeEtude: function(v) {
			const typeMapping = {
				'A': 'Recherche',
				'B': 'Analyse des projets -  BRS & PSLA',
				'C': 'Ratio Typologie/surface',
				'D': 'Développement foncier',
				'efocus-parcours-personnalises-senior': 'Résidences Service Seniors',
				'efocus-parcours-personnalises-tous': 'Résidences Service Toutes',
				'efocus-parcours-personnalises-etudiant': 'Résidences Service Etudiants',
				'efocus-parcours-personnalises-tourisme': 'Résidences Service Tourisme',
				'efocus-parcours-personnalises-tout-age': 'Résidences Service Tout âge',
			};
			return typeMapping[v] || 'Recherche';
		},

		PurgeRecherche: function() {
			localStorage.setItem("searchs", "[]")
			this.searchs = []
		},

		majStorage: function(val, name) {
			let searchs = JSON.parse(localStorage.getItem("searchs") || "[]");
			searchs = name ? searchs.filter(item => item.name !== name) : searchs;
			if (val && name) searchs.unshift(val);
			localStorage.setItem("searchs", JSON.stringify(searchs));
			this.searchs = searchs;
		},

		fire_last_search: function(search) {
			if (!search.efocus_type) search.efocus_type = 'A'
			this.$store.dispatch('set_efocus_type', {efocus_type: search.efocus_type})
			let val = {init: true, type: search.type, name:search.name, postcode: search.postcode, city: search.city, administrative: search.administrative, latlng: {lat : search.latlng.lat, lng: search.latlng.lng}, efocus_type: search.efocus_type}
			this.$store.dispatch('set_search', val)
			this.majStorage(val, search.name)
		},
		
		handleKeyDown: function(e) {
			if (e.code === 'Enter') {
				//e.preventDefault()
				e.stopPropagation()
				return false
			}
		},

		noop () {
			this.$store.dispatch('log_console', {message : "nothing"})
		},

		async getNews() {
			if (this.app.nouveaute.length > 0) {
				const url = `${this.app.nouveaute[0]}?${_.now()}`;
				try {
					const response = await axios.get(url);
					if (response && response.data) {
					document.getElementById("TexteNews").innerHTML = response.data;
					}
				} catch (error) {
					console.error("Erreur lors de la récupération des actualités: ", error);
				}
			}
		},
		mettreFocus: function() {
			this.$nextTick(() => {
				if (this.$refs.search_input) {
					document.getElementById('search_input').focus();
				}
			});
		}
			
	},
	
	mounted: function() {
		Bus.$on('focussearch', this.mettreFocus);

		this.$nextTick(function() {
			document.getElementById('search_input').focus()
		})
		let that = this
		
		let autocomplete = new google.maps.places.Autocomplete((document.getElementById('search_input')), {
			types: ['geocode'],
			componentRestrictions: {country: ["fr", "gp", "mq", "gf", "re", "pm", "yt", "nc", "pf", "mf", "tf"]}
		});
		
			google.maps.event.addListener(autocomplete, 'place_changed', function () {
			let near_place = autocomplete.getPlace();
			let region = ''
			let type = 'street'
			let city = ''
			let postcode = ''
			let name = near_place.formatted_address
			for (let index = 0; index < near_place.address_components.length; index++) {
				const element = near_place.address_components[index];
				if (index==0) {
					if (element.types[0] == "locality" || element.types[0] == "sublocality_level_1") {
						type = 'city'
						name = element.long_name
					}
				}
				if (element.types[0] == "administrative_area_level_1") {
					region = element.long_name
				}
				if (element.types[0] == "locality" || element.types[0] == "sublocality_level_1") {
					city = element.long_name
				}
				if (element.types[0] == "postal_code") {
					postcode = element.long_name
				}
			}
			let val = {init: true, type: type, name:name, postcode: postcode, city: city, administrative: region, latlng: {lat : near_place.geometry.location.lat(), lng: near_place.geometry.location.lng()}, efocus_type:that.app.efocus_type}
			that.$store.dispatch('set_search', val)
			that.majStorage(val, name)
		});
		this.majStorage(false, false)
	},

	beforeDestroy() {
		Bus.$off('focussearch', this.mettreFocus);
	},
	created: function() {
		window.addEventListener('keydown', this.handleKeyDown, { passive: true }); 
	},

	components: { RadioButton, InputText, Button, ScrollPanel, Timeline, Carousel, ImagePreview }
}
</script>


<style lang="stylus">

.help
	position absolute
	bottom 60px
	left calc(50% - 200px)

.pac-container
	background-color #fff
	border-radius 4px
	left 0
	list-style none
	margin 0
	padding 0
	position absolute
	width 100%
	top 110%
	top calc(100% + 6px)
	z-index 1000
	overflow hidden
	font-size 15px

.pac-item
	cursor default
	display block
	padding 6px 12px
	color #404040
	line-height 20px
	font-family "Roboto", arial, sans-serif;
	.pac-icon-marker
		display none
	.pac-item-query
		&:after
			content ''
			display block
			clear both
	> span
		&:last-child
			text-transform uppercase
			font-size 0.75rem
		&:after
			content br

.p-button-s {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.p-button-s {
    color: #fff;
    background: #6b23f1;
    border: 1px solid #6b23f1;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 6px;
}

code {
    background-color: rgba(0, 0, 0, .05);
    border-radius: 3px;
    padding: 2px 4px;
}
code, pre, samp {
    font-family: Roboto Mono, Lucida Sans Typewriter, Lucida Console, monaco, Courrier, monospace, sans-serif;
    font-size: .85em;
}

blockquote {
    color: rgba(0, 0, 0, .5);
    padding-left: 1.5em;
    border-left: 5px solid rgba(0, 0, 0, .1);
}
blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
}
.h-fullp {
	height: 100% !important;
}

.mkdw ul {
	list-style-type: none;
}

.mkdw ul li {
	position: relative;
	padding-left: 20px;
}

.mkdw ul li::before {
	content: '•';
	position: absolute;
	left: 0;
	top: 0;
	font-size: 20px;
}

.mkdw table {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
}
.mkdw tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.mkdw td, th {
    border-right: 1px solid #dcdcdc;
    padding: 8px 12px;
}

.mkdw th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
}

.mkdw .table-wrapper {
    max-width: 100%;
    overflow: auto;
}

.mkdw td {
    border-top: 1px solid #dcdcdc;
}

.mkdw td, th {
    border-right: 1px solid #dcdcdc;
    padding: 8px 12px;
}

.mkdw td {
    display: table-cell;
    vertical-align: inherit;
}

.mkdw td:last-child, th:last-child {
    border-right: 0;
}
:deep(.p-scrollpanel) {
    p {
        padding: .5rem;
        line-height: 1.5;
        margin: 0;
    }
}
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
  }
  
.centered {
text-align: center;
}

</style>

<style lang="stylus" scoped>
@import "~selectize/dist/css/selectize.bootstrap3.css"
@import '../assets/css/app'


.home-enter, .home-leave-to
	transform translateX(-100%)

.pane-home
	background-color var(--surface-ground)
	position absolute
	left 0
	top 60px
	height calc(100% - 118px)
	bottom 0
	width 100%
	transition 0.5s easeOutQuart
	z-index 500
	.logo
		position absolute
		left 16px
		top 16px
		padding 0
		height 40px
		img
			height 100%
	.home-search-past	
		position absolute
		width 500px
		right 30px		
		top 0px
		top 15%
		height 100%
		z-index 1
	.home-search	
		display right
		width 100%
		height 100%
		color #333
		.home-user-history
			color #333
		.slogan
			margin 0 0 24px 0
			white-space nowrap
			font-family volte, sans-serif
			font-size 30px
			text-align center
			color secondary
		.form
			width 615px
			margin 0 auto
			.input
				background #fff
				border-radius 40px
				input
					display block
					width 100%
					height 48px
					margin 0 4px 0 0
					padding 0 24px
					border none
					background none
					background-size 24px 24px
					outline none
					font inherit
					color #333
					&::-webkit-input-placeholder
						font-weight 400
				button
					position absolute
					right 0
					top 0
					height 48px
					width 48px
					border none
					background none
					border-radius 40px
					outline none
					cursor pointer
					opacity 0.5
					&:hover, &:active
						opacity 0.75
			.hint
				padding 0 0 0 32px
				margin 8px 0 0 0
				opacity 0.35
				font-size 12px
				line-height 16px
				.icon
					position absolute
					left 0
					top 50%
					transform translateY(-50%)

.ap-input
	border 1px solid primaryA400

.algolia-places
	width 100%

.history
	padding-right 10px
	float center
	width 100%

.history-value
	float left
	padding 4px 8px	
	height 36px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	margin-right 10px
	margin-bottom 10px
	cursor pointer
	border-color lighten(mon_projet,95%)
	color mon_projet
	.icon
		color mon_projet
	&:hover
		border-color mon_projet
		background mon_projet
		color white
		.icon
			color white

.news
	position absolute
	top 50%
	left 50%
	transform translate(-50%,-50%)
	max-width 800px
	min-height 400px
	border-radius 24px

.q-card {
	-webkit-box-shadow: 0 3px 6px alpha(#000, 25%)
	box-shadow: 0 3px 6px alpha(#000, 25%)
	border-radius: 4px;
	border-color: #6c21f9;
	vertical-align: top;
	background: white;
}

#TexteNews {
	width: 100%
	padding-right: 20px
	padding-left: 20px
	text-align: justify
}

.actions .close-button {
	position: absolute;
	top: 6px;
	right: 0px;
	color: #555;
}
.q-pt-sm {
	padding-top: 8px;
}
.on-left {
	margin-right: 12px;
}
.actions {
	min-height: 36px;
}
.q-card>div:not(:last-child), .q-card>img:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.q-card>div:first-child, .q-card>img:first-child {
	border-top: 0;
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
}
.q-card>div {
	border-left: 0;
	border-right: 0;
	box-shadow: none;
}
.relative-position {
	position: relative;
}
.justify-start {
	justify-content: flex-start;
}
.text-center {
	text-align: center;
}
.text-h4 {
	font-size: 2.125rem;
	font-weight: 400;
	line-height: 2.5rem;
	letter-spacing: 0.00735em;
}
.ap-input, .ap-hint {
	width: 100%;
	padding-right: 35px;
	padding-left: 16px;
	line-height: 40px;
	height: 40px;
	border-radius: 3px;
	outline: none;
	font: inherit;
	appearance: none;
	-webkit-appearance: none;
	box-sizing: border-box;
}	

#news {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	background: rgba(85,85,85,0.12);
}

.oir_txt {
font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 26px;
letter-spacing: 0.01em;
padding: 5px;
color: #FFFFFF;
text-align: left;
}
.oir {
	
	position: absolute;
	bottom: 60px;
	left: 20px;
	line-height: 50px;

position: absolute;
width: 300px;
height: 250px;
left: 40px;
bottom: 60px;
background: #008080;
justify-content: center;
align-items: center;
background: teal;
border-color: teal;
padding: 20px 20px;
min-height: 320px;
border-radius: 8px;
box-shadow: 0 14px 26px -12px hsl(232deg, 14%, 68%), 0 4px 23px 0 hsl(232deg, 14%, 68%), 0 8px 10px -5px hsl(232deg, 14%, 68%) !important;
}

.oir_but {
	position: absolute;
width: 250px;
height: 50px;
bottom:25px;
left:25px;
background: #F0E63C;
border-radius: 77px;

font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 50px;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
color: #000000;

}
.help_but {
	position: absolute;
width: 350px;
height: 60px;
bottom:8px;
background: red;
border-radius: 77px;

font-family: 'Montserrat', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
align-items: center;
text-align: center;
letter-spacing: 0.01em;
color: #FFFFFF;

}

.program-detail-info
	padding 8px 0
	.program-detail-info-filters
		text-align center
		margin 0 0 8px 0

.disabled
	color #eee
	background-color #BBB

.middle_center
	top 50%
	left 50%
	display inline-block
	transform translate(-50%, -50%)

.snack_search
	display block
	width 300px
	height 48px
	display flex
	background-color #efefef
	border-radius 8px
	margin-bottom 10px
	cursor pointer
	color #666
	&:hover
		background-color palette0
		color white !important
	&.active
		background-color palette0
		color white

.snack_choice
	display block
	width 300px
	height 38px
	display flex
	background-color #eee
	border-radius 8px
	margin-bottom 20px
	cursor pointer
	&:hover
		background-color palette0
		color white !important
	&.active
		background-color palette0
		color white

.ellipsis-text
	max-width 230px
	white-space nowrap
	overflow hidden
	text-overflow ellipsis

.custom-marker
	display -webkit-box
	display -ms-flexbox
	display flex
	width 2rem
	height 2rem
	-webkit-box-align center
	-ms-flex-align center
	align-items center
	-webkit-box-pack center
	-ms-flex-pack center
	justify-content center
	color #fff
	border-radius 50%
	z-index 1
.bb
	&:hover
		background-color var(--primary-color) !important
		color white !important
		.p-button.p-button-text.p-button-plain
			color white !important
		.text-900
			color white !important
		.text-600
			color white !important

:deep(.p-timeline-event-opposite) {
	flex: 0.5 !important;
}

</style>
