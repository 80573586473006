<template>
	<transition name="slide">
		<div class="pane pane-filters-promotion" :class="{vi : app.filters_promotion, left: !app.menuvisible}">
			<div class="right-bar">
				<div class="box right-bar-content" v-bar>
					<div class="content" ref="scrollable_aide" style="padding-left:10px;padding-right:20px">
						<div v-if="app.onglet=='onglet_prog'">
							<p class="box-title2">{{ $t('forme_urbaine') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('forme', 'collectif')" class="filtre" :class="{ active: app.filters.col }">{{ $t('collectif') }}</div>
									<div @click="toggle_switch('forme', 'individuel')" class="filtre" :class="{ active: app.filters.ind }">{{ $t('individuel') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">Cycle de vie</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('commercialisation', 'actif')" class="filtre" :class="{ active: app.filters.actif }">Actifs</div>
									<div @click="toggle_switch('commercialisation', 'projet')" class="filtre" :class="{ active: app.filters.projet }">{{ $t('projets') }}</div>									
									<div @click="toggle_switch('commercialisation', 'termine')" class="filtre" :class="{ active: app.filters.termine }">{{ $t('termines') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('tva') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('tva', 'reduite')" class="filtre" :class="{ active: app.filters.tvar }">5,5 %</div>
									<div @click="toggle_switch('tva', 'pleine')" class="filtre" :class="{ active: app.filters.tvap }">20 %</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('VEB') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('veb', 'avec')" class="filtre" :class="{ active: app.filters.veb_avec }">uniquement avec VEB détectée</div>
									<!--<div @click="toggle_switch('veb', 'sans')" class="filtre" :class="{ active: app.filters.veb_sans }">sans</div>-->									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('financements') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('financement', 'psla')" class="filtre" :class="{ active: app.filters.psla }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_financement_concerne')">{{ $t('psla') }}</div>
									<div @click="toggle_switch('financement', 'brs')" class="filtre" :class="{ active: app.filters.brs }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_financement_concerne')">{{ $t('brs') }}</div>									
									<div @click="toggle_switch('financement', 'anru')" class="filtre" :class="{ active: app.filters.anru }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_financement_concerne')">ANRU</div>									
									<div @click="toggle_switch('financement', 'autres')" class="filtre" :class="{ active: app.filters.autres }" :title="$t('affiche_les_programmes_dont_au_moins_1_lot_est_propose_via_le_type_de_un_autre_type_de_financement_aide')">{{ $t('autres') }}</div>									
									<div @click="toggle_switch('financement', 'aucun')" class="filtre" :class="{ active: app.filters.aucun }" :title="$t('affiche_les_programmes_proposant_au_moins_1_lot_sans_aide_au_financement')">{{ $t('aucun') }}</div>									
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('prix_au_m²') }}</p>
							<div class="box-content" style="padding-left: 25px;padding-right: 25px;">
								<HistogramSlider
								ref="histogramSlider"
								
								:data="prixData"
								:adsorb="true"
								:drag-interval="true"
								:force-edges="false"
								:forceEdges="false"
								:primaryColor="'#651FFF'"
								:labelColor="'#651FFF'"
								:handleSize="0"
								:bar-height="100"
								:colors="['#651FFF', '#651FFF']"
								:min="prixMin"
								:max="prixMax"
								:step=100
								:width="260"
								:block="true"
								:key="componentKey"
								:hideMinMax="true"
								:hideFromTo="true"
								:lineHeight="0"
								></HistogramSlider>
								<div style="padding:8px;">
									<Slider :range="true" v-model="prix" :step="100" :min="prixMin" :max="prixMax" @finish='sliderChange($event, "prix")' />
								</div>
							</div>
							<div style="padding-left:-8px;padding-right:-8px; padding-top:8px; display: flex; justify-content: space-between;" class="w-full">
									<div>{{ prixMin }} €</div>
									<div>De {{prix[0]}} € à {{prix[1]}} €</div>
									<div>{{ prixMax }} €</div>
								</div>
							<br><br>
							<p class="box-title2">{{ $t('programmes_actifs') }}</p>
							<div class="box-content" v-if="app.gestionhistorique">
								<p class="box-title" style="text-align:center" v-if="this.nbannee>1">{{ $t('durant_les') }} {{this.nbannee}} {{ $t('dernieres_annees') }}</p>
								<p class="box-title" style="text-align:center" v-else>{{ $t('depuis_la_derniere_annee') }}</p>
								<p>&nbsp;</p>
								<vue-slider
								v-model="nbannee"
								:interval="1"
								:max="10"
								:min="1"
								:marks="true"
								:bgStyle="{ backgroundColor: '#ddd', height: '3px' }"
								:tooltipStyle="{ backgroundColor: '#651FFF', borderColor: '#651FFF', fontSize: '12px', fontWeight: '600', color: '#f0f2f4' }"
								:processStyle="{ backgroundColor: '#651FFF' }"
								@finish='sliderChange($event, "annees")'
								></vue-slider>
							</div>
							<br><br>
							<br><br>
							<div style="display: flex;justify-content: center;">
								<div class="app_filtre" style="width:200px" @click="applique_filtre()">{{ $t('appliquer_les_filtres') }}</div>
							</div>
							<br><br>
						</div>
						<div v-if="app.onglet=='onglet_pc'">
							<p class="box-title2">{{ $t('nature_depositaire') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch_pc('depositaire', 'pc_promoteur')" class="filtre" :class="{ active: app.filters_pc.pc_promoteur }">{{ $t('promoteurs_et_affilies') }}</div>
									<div @click="toggle_switch_pc('depositaire', 'pc_bailleur')" class="filtre" :class="{ active: app.filters_pc.pc_bailleur }">{{ $t('bailleurs_sociaux_/_hlm') }}</div>
									<div @click="toggle_switch_pc('depositaire', 'pc_epl')" class="filtre" :class="{ active: app.filters_pc.pc_epl }">{{ $t('epl') }}</div>									
									<div @click="toggle_switch_pc('depositaire', 'pc_administration')" class="filtre" :class="{ active: app.filters_pc.pc_administration }">{{ $t('administrations') }}</div>									
									<div @click="toggle_switch_pc('depositaire', 'pc_autre')" class="filtre" :class="{ active: app.filters_pc.pc_autre }">{{ $t('autres') }}</div>	
									<div @click="toggle_switch_pc('depositaire', 'pc_nc')" class="filtre" :class="{ active: app.filters_pc.pc_nc }">{{ $t('non_determine') }}</div>										
								</div>
							</div>
							<br/>
							<p class="box-title2">{{ $t('etat') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch_pc('etat', 'pc_autorise')" class="filtre" :class="{ active: app.filters_pc.pc_autorise }">{{ $t('autorise') }}</div>
									<div @click="toggle_switch_pc('etat', 'pc_annule')" class="filtre" :class="{ active: app.filters_pc.pc_annule }">{{ $t('annule') }}</div>
									<div @click="toggle_switch_pc('etat', 'pc_commence')" class="filtre" :class="{ active: app.filters_pc.pc_commence }">{{ $t('commence') }}</div>
									<div @click="toggle_switch_pc('etat', 'pc_termine')" class="filtre" :class="{ active: app.filters_pc.pc_termine }">{{ $t('termine') }}</div>
								</div>
							</div>
							<br/>
							<br/>
							<p class="box-title2" style="display: flex;vertical-align: middle;align-content: center;align-items: center;justify-content: center;"><span>{{ $t('dates') }}&nbsp;&nbsp;</span><InputSwitch v-model="filter_date_checked" @change="toggle_switch_pc('','')" /></p>
							<div class="p-selectbutton p-buttonset p-component" v-if="filter_date_checked">
								<div class="p-button p-component pd05" @click="date_tab = 'dep'" :class="{ 'p-highlight': date_tab == 'dep' }" style="min-width:88px; text-align: center;">
									<span style="width:100%; text-align: center;">{{ $t('depôt') }}</span>
									<span class="p-ink"></span>
								</div>
								<div class="p-button p-component pd05" @click="date_tab = 'aut'" :class="{ 'p-highlight': date_tab == 'aut' }" style="min-width:88px; text-align: center;">
									<span style="width:100%; text-align: center;">{{ $t('autorisation') }}</span>
									<span class="p-ink"></span>
								</div>
								<div class="p-button p-component pd05" @click="date_tab = 'acq'" :class="{ 'p-highlight': date_tab == 'acq' }" style="min-width:88px; text-align: center;">
									<span style="width:100%; text-align: center;">{{ $t('acquisition') }}</span>
									<span class="p-ink"></span>
								</div>
								<br><br>
								<br><br>
								<Calendar view="month" dateFormat="mm/yy" id="range" v-model="dates2" selectionMode="range" :manualInput="false" @date-select="toggle_switch_pc('','')" />
							</div>
							<br><br>
							<div style="display: flex;justify-content: center;">
								<div class="app_filtre" style="width:200px" @click="applique_filtre_pc()">{{ $t('appliquer_les_filtres') }}</div>
							</div>
							<br><br>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import HistogramSlider from "vue-histogram-slider";
import "vue-histogram-slider/dist/histogram-slider.css";
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import Slider from 'primevue/slider';

export default {
	name: 'PaneFiltersPromotion',

	data: function() {
		return {
			dates2: null,
			filter_date_checked: false,
			date_tab: 'aut',
			nbannee: 3,
			dotOptions: [{
				tooltip: 'always'
			},{
				tooltip: 'always'
			}],
			tvar: true,
			tvap: true,
			annee_actif: [2011, 2024],
			prix: [0,0],
			tva_visible: false,
			fu_visible: false,
			etat_visible: false,
			prix_visible: false,
			nb_visible: false,
			termine : true,
			actif : true,
			projet : true,
			psla : true,
			brs : true,
			anru: true,
			aucun: true,
			autres : true,
			col: true,
			ind: true,
			nb_one: true,
			nb_two: true,
			nb_three: true,
			nb_four: true,
			jusqua: (new Date()).getFullYear(),
			data: [],
			prix_min: 0,
			prix_max: 30000,
			pc_promoteur: true,
			pc_bailleur: true,
			pc_epl: true,
			pc_administration: true,
			pc_autre: true,
			pc_nc: true,
			pc_autorise: true,
			pc_annule: false,
			pc_commence: true,
			pc_termine: false,
			componentKey:0,
			veb_avec: true,
			veb_sans: true
		}
	},

	watch: {
		'app.theme': function(newValue) {
			if (newValue) {
				this.scroll(newValue)
			}
		},

		'app.filters_promotion': function(newValue) {
			if (newValue) {
				this.$nextTick( () => {
					try {
						const elements = document.getElementsByClassName('overlay');
						while (elements.length > 0) {
							elements[0].parentNode.removeChild(elements[0]);
						}
						const elementsSlider = document.getElementsByClassName('slider-wrapper');
						while (elementsSlider.length > 0) {
							elementsSlider[0].parentNode.removeChild(elementsSlider[0]);
						}
					} catch (error) {
						console.log(error)
					}
				})
			}
		},

		'app.api_calls.load_programs': function() {
			this.componentKey += 1
			const elementsSlider = document.getElementsByClassName('slider-wrapper');
			while (elementsSlider.length > 0) {
				elementsSlider[0].parentNode.removeChild(elementsSlider[0]);
			}
//			if (!this.prix[0]) {
//				this.prix[0] = Math.round((this.app.program_prix.min-1000)/1000)*1000
//				this.prix[1] = Math.round((this.app.program_prix.max+1000)/1000)*1000
//			}
//			if (this.prix[1] < Math.round((this.app.program_prix.min-1000)/1000)*1000) {
//				this.prix[0] = Math.round((this.app.program_prix.min-1000)/1000)*1000
//				this.prix[1] = Math.round((this.app.program_prix.min-1000)/1000)*1000 + 100
//			}
//			if (this.prix[0] > Math.round((this.app.program_prix.max+1000)/1000)*1000) {
//				this.prix[0] = Math.round((this.app.program_prix.max+1000)/1000)*1000 - 100
//				this.prix[1] = Math.round((this.app.program_prix.max+1000)/1000)*1000
//			}
//			this.prix[0] = this.app.program_prix.min
//			this.prix[1] = this.app.program_prix.max
		},
	},

	mounted: function() {
		this.veb_sans = this.app.filters.veb_sans
		this.veb_avec = this.app.filters.veb_avec
		this.psla = this.app.filters.psla
		this.brs = this.app.filters.brs
		this.anru = this.app.filters.anru
		this.aucun = this.app.filters.aucun
		this.autres =  this.app.filters.autres
		this.tvar = this.app.filters.tvar
		this.tvap = this.app.filters.tvap
		this.col = this.app.filters.col
		this.ind = this.app.filters.ind
		this.termine = this.app.filters.termine
		this.actif = this.app.filters.actif
		this.projet = this.app.filters.projet
//		this.nbannee = ((new Date()).getFullYear() - this.app.filters.anciennete)

		this.prix[0] = this.app.filters.prix.min
		this.prix[1] = this.app.filters.prix.max
		const elementsSlider = document.getElementsByClassName('slider-wrapper');
		while (elementsSlider.length > 0) {
			elementsSlider[0].parentNode.removeChild(elementsSlider[0]);
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'ventilationprix' ]), {
		prixData : function() {
			return this.app.program_prix.tab;
		},
		prixMin : function() {
			return Math.round((this.app.program_prix.min-1000)/1000)*1000
		},
		prixMax : function() {
			return Math.round((this.app.program_prix.max+1000)/1000)*1000
		},
		prixRange : function() {
			let p = this.prix
			if (p[1]>Math.round((this.ventilationprix.max+1000)/1000)*1000) {
				p[1] = Math.round((this.ventilationprix.max+1000)/1000)*1000
			}
			return p;
		}
	}),

	methods: {

		updateHistogramme: function() {
			this.$refs.histogramSlider.update({ data: this.app.program_prix.tab })
		},

		applique_filtre: function() {
			this.$store.dispatch('set_filters', { veb_avec: this.veb_avec, veb_sans: this.veb_sans, termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar, annee_actif: this.jusqua-this.nbannee, psla: this.psla, brs: this.brs, autres: this.autres, anru: this.anru, aucun: this.aucun, affichage_prix_etat: this.affichage_prix_etat, affichage_prix_tva: this.affichage_prix_tva, affichage_prix_pk: this.affichage_prix_pk})
			this.$store.dispatch('app_filters')
			this.$store.dispatch('set_filters_promotion', {filters_promotion:false})
		},

		applique_filtre_pc: function() {
			this.$store.dispatch('app_filters')
			this.$store.dispatch('set_filters_promotion_pc', {filters_promotion:false})
		},

		sliderChange: function(e, name) {
			if (name=='prix') gtag('event', 'filtre_neuf_prix', {'event_category': 'filtre','event_label': 'filtre_neuf_prix'}); this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'prix'});
			if (name=='annees') gtag('event', 'filtre_neuf_annees', {'event_category': 'filtre','event_label': 'filtre_neuf_annees'}); this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'annee'});
		},

		toggle_switch_pc: function(filter, value) {
			switch (filter) {
				case 'etat':
				gtag('event', 'filtre_pc_etat', {'event_category': 'filtre','event_label': 'filtre_pc_etat'});
				this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre_pc', option: 'etat'});
				switch (value) {
					case 'pc_autorise':
					this.pc_autorise = !this.pc_autorise
					break;
					case 'pc_annule':
					this.pc_annule = !this.pc_annule
					break;
					case 'pc_commence':
					this.pc_commence = !this.pc_commence
					break;
					case 'pc_termine':
					this.pc_termine = !this.pc_termine
					break;
				}
				break;
				case 'depositaire':
				gtag('event', 'filtre_pc_depositaire', {'event_category': 'filtre','event_label': 'filtre_pc_depositaire'});
				this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre_pc', option: 'depositaire'});
				switch (value) {
					case 'pc_promoteur':
					this.pc_promoteur = !this.pc_promoteur
					break;
					case 'pc_bailleur':
					this.pc_bailleur = !this.pc_bailleur
					break;
					case 'pc_epl':
					this.pc_epl = !this.pc_epl
					break;
					case 'pc_administration':
					this.pc_administration = !this.pc_administration
					break;
					case 'pc_autre':
					this.pc_autre = !this.pc_autre
					break;
					case 'pc_nc':
					this.pc_nc = !this.pc_nc
					break;
				}
				break;				
			}
			this.$store.dispatch('set_filters_pc', { pc_autorise: this.pc_autorise, pc_annule: this.pc_annule, pc_commence: this.pc_commence, pc_termine: this.pc_termine, pc_promoteur: this.pc_promoteur, pc_bailleur: this.pc_bailleur, pc_epl: this.pc_epl, pc_administration: this.pc_administration, pc_autre: this.pc_autre, pc_nc: this.pc_nc, filter_date: this.filter_date_checked, filter_date_type: this.date_tab, filter_date_date: this.dates2})
		},
		
		toggle_switch: function(filter, value) {
			switch (filter) {
				case 'forme':
					gtag('event', 'filtre_neuf_fu', {'event_category': 'filtre','event_label': 'filtre_neuf_fu'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'forme urbaine'});
					switch (value) {
						case 'collectif':
						this.col = !this.col
						break;
						default:
						this.ind = !this.ind
						break;
					}
					break;
				case 'commercialisation':
					gtag('event', 'filtre_neuf_etat', {'event_category': 'filtre','event_label': 'filtre_neuf_etat'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'etat de commercialisation'});
					switch (value) {
						case 'actif':
						this.actif = !this.actif
						break;
						case 'projet':
						this.projet = !this.projet
						break;
						default:
						this.termine = !this.termine
						break;
					}
					break;
				case 'financement':
					gtag('event', 'filtre_neuf_financements', {'event_category': 'filtre','event_label': 'filtre_neuf_financements'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'financement'});
					switch (value) {
						case 'psla':
						this.psla = !this.psla
						break;
						case 'anru':
						this.anru = !this.anru
						break;
						case 'aucun':
						this.aucun = !this.aucun
						break;
						case 'brs':
						this.brs = !this.brs
						break;
						default:
						this.autres = !this.autres
						break;
					}
					break;
				case 'tva':
					gtag('event', 'filtre_neuf_tva', {'event_category': 'filtre','event_label': 'filtre_neuf_tva'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'tva'});
					switch (value) {
						case 'reduite':
						this.tvar = !this.tvar
						break;
						default:
						this.tvap = !this.tvap
						break;
					}
					break;
				case 'veb':
					gtag('event', 'filtre_neuf_veb', {'event_category': 'filtre','event_label': 'filtre_neuf_veb'});
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'veb'});
					switch (value) {
						case 'avec':
						this.veb_avec = !this.veb_avec
						break;
						default:
						this.veb_sans = !this.veb_sans
						break;
					}
					break;
				case 'affichage_prix_etat':
					this.affichage_prix_etat = value
					break;
				case 'affichage_prix_tva':
					this.affichage_prix_tva = value
					break;
				case 'affichage_prix_pk':
					this.affichage_prix_pk = value
					break;
				default:
				break;
			}
			this.$store.dispatch('set_filters', { veb_avec: this.veb_avec, veb_sans: this.veb_sans, termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar, annee_actif: this.jusqua-this.nbannee, psla: this.psla, brs: this.brs, autres: this.autres, anru: this.anru, aucun: this.aucun, affichage_prix_etat: this.affichage_prix_etat, affichage_prix_tva: this.affichage_prix_tva, affichage_prix_pk: this.affichage_prix_pk})
		},
		
		range_prix: function(filter, pmin, pmax) {
			let data = { filter: filter }
			data['min'] = pmin
			data['max'] = pmax
		},
		
		toggle_view: function() {
			let view = 'list'
			if (this.app.view == 'list') view = 'home'
			this.$store.dispatch('set_view', { view: view })
		},

		set_filters: function() {
			this.$store.dispatch('set_filters', { veb_avec: this.veb_avec, veb_sans: this.veb_sans, termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar, annee_actif: this.jusqua-this.nbannee, psla: this.psla, brs: this.brs, autres: this.autres, anru: this.anru, aucun: this.aucun, affichage_prix_etat: this.affichage_prix_etat, affichage_prix_tva: this.affichage_prix_tva, affichage_prix_pk: this.affichage_prix_pk})
			this.$store.dispatch('app_filters')
		},

		scroll: function(id) {
			let that = this
			setTimeout(function() {
				let $scroll = $(that.$refs.scrollable_aide)
				let $program = $scroll.find('#aide-' + id)
				if ($program) {
					try {
						$scroll.animate({ scrollTop: $program[0].offsetTop }, 250)
					} catch (error) {
						console.log(error)
					}
				}
			}, 100)
		}
	},
	
	components: {HistogramSlider, VueSlider, InputSwitch, Calendar, Slider }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.theme
	padding-right 10px
	padding-top 10px

.right-bar
	padding-left 10px
	height 100%
	background #FcFcFc
	width 100%
	color #333
	visibility hidden
	.right-bar-content
		height 100%
		.content
			height 100%

	.scroll-dots
		position absolute
		left -40px
		top 24px
		width 32px
		background alpha(green, 50%)
		border-radius radius
		z-index 2
		transition 0.25s easeOutQuart
		&:hover
			background alpha(green, 75%)
		.dot
			display block
			width 8px
			height 8px
			margin 12px
			background #fff
			opacity 0.25
			border-radius 100%
			transition 0.25s easeOutQuart
			cursor pointer
			&:hover, &.active
				opacity 1

.module_ret
	width 100%
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid #cccccc
	visibility hidden
	.middle
		top 20px

.pane-filters-promotion
	font-family 'Montserrat', sans-serif
	position fixed
	top headersize
	left PaneLeftSize !important
	bottom 0
	height calc(100% - 122px)
	width 0px
	background-color #333
	z-index 500
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fff;
	transition 0.5s easeOutQuart
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		border-right solid 1px #ccc
		width 350px
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible

.label
	display block
	margin 0 0 2px 0
	color #666
	text-transform uppercase
	font-size 11px
	font-weight 500
.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
	.box-title
		padding 0 16px
		margin 0 0 4px 0
		font-family volte, sans-serif
		font-weight 600
		color #333
		line-height 16px
		text-align left
		padding-bottom 8px
		padding-top 8px
	.box-content
		display block
		padding 0 5px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both


.infinite-loader
	position relative !important
	right 0 !important
	top 0 !important
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #536c78
		.icon
			display block
			animation rotate 1.5s infinite linear

.preference_neuf
	width 36px;
	height 36px;
	text-align center
	padding-left 10px
	line-height 36px
	cursor pointer
	&.idf
		right 0px !important

.slider-wrapper
	display none !important

		
:deep(.slider-wrapper) {
	display:none !important;
}


:deep(.overlay) {
	display:none;
}

:deep(.vue-slider-dot-tooltip-top) {
    top: 40px;
}

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
