<template>
	<transition name="slide">
		<div class="pane pane-legende" :class="app.viewlayer" v-show="false">
			<div class="header">
				<div class="back-button" v-if="app.viewlayer == 'legende'" @click="set_view('')"><icon file="x" :size="32" /></div>
				<div class="texte">{{ $t('legendes_programmes_neufs') }}</div>
			</div>
			<div class="right-bar" v-if="app.viewlayer == 'legende'">
				<div class="right-bar-content" v-bar>
					<div class="content">
						<div>
							<br/>
							<div class="choix">
								<label class="radiobox" :class="{ checked: app.pin_type=='commercialisation' }">
									<input type="checkbox" :checked="app.pin_type=='commercialisation'" @click.stop="click('commercialisation')">
									<span class="chk"><icon file="check" :size="16" /></span>
									<span class="text">{{ $t('etat_de_commercialistion') }}</span>
								</label>
								<br/><br/>
								<table style="width:100%">
									<th></th>
									<tr>
										<td style="text-align: center;width:33%;"><div class="mrk projet"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk actif"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk termine"></div></td>
									</tr>
									<tr>
										<td style="text-align: center;">{{ $t('projet') }}</td>
										<td style="text-align: center;">{{ $t('actif') }}</td>
										<td style="text-align: center;">{{ $t('termine') }}</td>
									</tr>
								</table>
							</div>
							<div class="choix">
								<label class="radiobox" :class="{ checked: app.pin_type=='prix' }">
									<input type="checkbox" :checked="app.pin_type=='prix'" @click.stop="click('prix')">
									<span class="chk"><icon file="check" :size="16" /></span>
									<span class="text">{{ $t('gamme_de_prix_au_m²') }}</span>
								</label>
								<br/><br/>
								<table style="width:100%">
									<th></th>
									<tr>
										<td style="text-align: center;width:33%;"><div class="mrk entree"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk milieu"></div></td>
										<td style="text-align: center;width:33%;"><div class="mrk haut"></div></td>
									</tr>
									<tr>
										<td style="text-align: center;">{{ $t('entree_de_gamme') }}</td>
										<td style="text-align: center;">{{ $t('milieu_de_gamme') }}</td>
										<td style="text-align: center;">{{ $t('haut_de_gamme') }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaneLagende',

	data () {
		return {
			visible: false
		}
	},

	methods: {
		click: function(pin_type) {
			this.visible=false
			this.$store.dispatch('pin_type', {pin_type: pin_type})
		},

		removeall: function() {
			this.$store.dispatch('select_programs', { programs: this.app.programs, selected: false })
		},

		set_view: function() {
			this.$store.dispatch('set_view_layer', { view: '' })
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'report', 'selected_programs' ]), {
		
	}),

	components: { }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.mrk
	left 33%
	width 24px
	height 24px
	color #f0f2f4
	font-weight 600
	border-radius 100%
	text-align center
	line-height 24px
	cursor pointer
	-webkit-user-select none
	-moz-user-select none
	-ms-user-select none
	user-select none
	&.projet
		background #ccc
	&.actif
		background #29b6f6
	&.termine
		background #0086c3
	&.entree
		background #fcc434
	&.milieu
		background #ee8d3e
	&.haut
		background #d74a22
	&.col
		background #ff4081
	&.ind
		background #f8bbd0

.pane-legende
	position fixed
	left 100%
	top headersize
	bottom 0
	height calc(100% - 155px)
	width 400px
	background-color #333
	color #ccc
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	z-index 500
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	.header
		background secondary
		height 60px
		.back-button
			position absolute
			right 10px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.texte
			top 22px
			left 10px
			height 48px
			width 200px
			color white
		.tabs
			padding 0 32px
			color #fff
			&:after
				content ''
				display block
				clear both
			.tab
				float left
				margin 0 32px 0 0
				height 96px
				width 96px
				display flex
				align-items center
				justify-content center
				flex-direction column
				opacity 0.5
				font-size 12px
				font-weight 500
				border-bottom 4px solid transparent
				text-align center
				text-transform uppercase
				cursor pointer
				&:hover
					opacity 0.75
				&.active
					opacity 1
					border-color light
				.icon
					display block
					margin 0 0 4px 0
				
	.right-bar
		padding-left 10px
		height 100%
		background white
		width 100%
		color #333
		.right-bar-content
			height 100%
			.content
				height 100%

		.scroll-dots
			position absolute
			left -40px
			top 24px
			width 32px
			background alpha(green, 50%)
			border-radius radius
			z-index 2
			transition 0.25s easeOutQuart
			&:hover
				background alpha(green, 75%)
			.dot
				display block
				width 8px
				height 8px
				margin 12px
				background #fff
				opacity 0.25
				border-radius 100%
				transition 0.25s easeOutQuart
				cursor pointer
				&:hover, &.active
					opacity 1
					
.choix
	padding-bottom 20px


</style>
