import Vue from 'vue'


// STATE
const state = {
	mode: 'consultation',
	zoom: 16,
	coords: { lat: 46.8, lng: 2.2 },
	boundingbox: [],
	change_center: false,
	pin_type: 'forme-urbaine',
	search_on_move: true,
	moved_without_search: false,
	options: {
		zoom_level_to_change_view: 12,
		zoom_max: 18,
		zoom_min: 6
	}
}



// GETTERS
const getters = {
	map: function(state) { return state }
}



// ACTIONS
const actions = {

	set_position: function({ commit }, data) {
		commit('SET_CENTER', data)		
	},

	set_pin_type: function({ commit }, data) {
		commit('EDIT_MAP', { pin_type: data.pin_type })
	},

	set_search_on_move: function({ commit }, data) {
		commit('EDIT_MAP', {
			search_on_move: data.search_on_move,
			moved_without_search: false
		})
	},

	set_moved_without_search: function({ commit }, data) {
		commit('EDIT_MAP', { moved_without_search: data.moved_without_search })
	},

	set_map_mode: function({ commit }, data) {
		commit('SET_MAP_MODE', { mode: data.mode })
	},

	select_program: function(data) {
		if (data.program.marker) {
			if (data.program.selected) data.program.marker.classList.add('selected')
			else data.program.marker.classList.remove('selected')
		}
	},

	select_programs: function(data) {
		for (let i in data.programs) {
			if (data.programs[i].marker) {
				if (data.programs[i].selected) data.programs[i].marker.classList.add('selected')
				else data.programs[i].marker.classList.remove('selected')
			}
		}
	},

	expand_program: function() {
	},

	save_map_infos: function({ commit }, data) {
		commit('EDIT_MAP', data)
	}

}



// MUTATIONS
const mutations = {

	SET_CENTER: function(state, data) {
		state.coords.lat = data.lat
		state.coords.lng = data.lng
	},

	SET_MAP_MODE: function(state, data) {
		state.mode = data.mode
	},

	EDIT_MAP: function(state, data) {
		for (let i in data) {
			Vue.set(state, i, data[i])
		}
	}
	
}

export default { state, getters, actions, mutations }