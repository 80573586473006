<template>
	<transition name="slide">
		<div class="pane pane-biens-locatifs" :class="{vi : app.bienslocatifs, left: !app.menuvisible}">
			<div class="module_ret">
				<div class="head_filter" @click.stop="cv = !cv" title="Filtrer ma recherche" :class="{ idf: !app.module_actif }">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
				<div class="btns accueil" title="Export en cours" v-if="encours">
					<div class="infinite-loader-xls">
						<div class="loader"><icon file="loader" :size="24" /></div>
					</div>
				</div>
				<div class="btns accueil" style="cursor: pointer;" v-else>
					<div @click="$store.dispatch('set_bienslocatifs', { value: false })"><icon style="cursor: pointer;" file="fermer" :size="24" /></div>
				</div>
				<div class="middle">
					{{ $t('liste_des_annonces') }}
				</div>
			</div>
			<div class="_filtre" v-if="app.bienslocatifs">
				<div class="box">
					<div v-if="cv" style="border-bottom: 4px solid #41b983; padding-bottom: 10px; padding-top: 10px; padding-top: 10px;height:380px">
						<table class="_box-title2">
							<th style="display:none"></th>
							<tr>
								<td style="width:260px" class="box-title3">
									{{ $t('loyer_compris_entre') }}
								</td>
								<td style="width:90px">
									<InputNumber inputStyle="width:90px;text-align:right" id="integeronly" v-model="price_min" suffix=" €" @change="valideInterval('price_min', $event)"/>
								</td>
								<td style="width:20px">&nbsp;{{ $t('et') }}</td>
								<td style="width:90px">
									<InputNumber inputStyle="width:90px;text-align:right" id="integeronly" v-model="price_max" suffix=" €" @change="valideInterval('price_max', $event)"/>
								</td>
								<td style="width:10px">&nbsp;</td>
							</tr>
							<tr>
								<td class="_box-title2">
									{{ $t('surface_comprise_entre') }}
								</td>
								<td>
									<InputNumber inputStyle="width:90px;text-align:right" id="integeronly" v-model="area_min" suffix=" m²" @change="valideInterval('area_min', $event)"/>
								</td>
								<td>&nbsp;{{ $t('et') }}</td>
								<td>
									<InputNumber inputStyle="width:90px;text-align:right" id="integeronly" v-model="area_max" suffix=" m²" @change="valideInterval('area_max', $event)"/>
								</td>
								<td>&nbsp;</td>
							</tr>
						</table>
						<div class="_box-title2">																	
							<table style="width:100%">
								<th style="display:none"></th>
								<tr>
									<td style="width:60%">
										<p class="box-title3">{{ $t('typologie') }}</p>
									</td>
									<td style="width:40%">
										<p class="box-title3">{{ $t('meuble') }}</p>
									</td>
								</tr>
								<tr>
									<td>
										<div class="box-content">
											<div class="switches">
												<switch-button-dpe :label="$t('t1')" divise="five" @click.native="t1 = !t1; toggle_switch('t1')" :class="{ active: t1 }" />
												<switch-button-dpe :label="$t('t2')" divise="five" @click.native="t2 = !t2; toggle_switch('t2')" :class="{ active: t2 }" />
												<switch-button-dpe :label="$t('t3')" divise="five" @click.native="t3 = !t3; toggle_switch('t3')" :class="{ active: t3 }" />
												<switch-button-dpe :label="$t('t4')" divise="five" @click.native="t4 = !t4; toggle_switch('t4')" :class="{ active: t4 }" />
												<switch-button-dpe :label="$t('t5')" divise="five" @click.native="t5 = !t5; toggle_switch('t5')" :class="{ active: t5 }" />
											</div>
										</div>
									</td>
									<td>
										<div class="box-content">
											<div class="switches">
												<switch-button-dpe :label="$t('oui')" divise="three" @click.native="MeubleOui = !MeubleOui; toggle_switchMeuble('MeubleOui')" :class="{ active: MeubleOui }" />
												<switch-button-dpe :label="$t('non')" divise="three" @click.native="MeubleNon = !MeubleNon; toggle_switchMeuble('MeubleNon')" :class="{ active: MeubleNon }" />
												<switch-button-dpe :label="$t('n.c.')" divise="three" @click.native="MeubleNC = !MeubleNC; toggle_switchMeuble('MeubleNC')" :class="{ active: MeubleNC }" />											
											</div>
										</div>
									</td>
								</tr>
							</table>
						</div>	
						<div class="_box-title2">								
							<p class="box-title3">{{ $t('dpe') }}</p>
							<div class="box-content">
								<div class="switches">
									<switch-button-dpe label="A" divise="eight_A" size=24 @click.native="DPE_A = !DPE_A; " :class="{ active: DPE_A }"  />
									<switch-button-dpe label="B" divise="eight_B" @click.native="DPE_B = !DPE_B;" :class="{ active: DPE_B }"  />
									<switch-button-dpe label="C" divise="eight_C" @click.native="DPE_C = !DPE_C;" :class="{ active: DPE_C }"  />
									<switch-button-dpe label="D" divise="eight_D" @click.native="DPE_D = !DPE_D;" :class="{ active: DPE_D }" />
									<switch-button-dpe label="E" divise="eight_E" @click.native="DPE_E = !DPE_E;" :class="{ active: DPE_E }"  />
									<switch-button-dpe label="F" divise="eight_F" @click.native="DPE_F = !DPE_F;" :class="{ active: DPE_F }"  />
									<switch-button-dpe label="G" divise="eight_G" @click.native="DPE_G = !DPE_G;" :class="{ active: DPE_G }"  />
									<switch-button-dpe label="NC" divise="eight_N" @click.native="DPE_NC = !DPE_NC;" :class="{ active: DPE_NC }"  />
								</div>
							</div>
						</div>	
						<br><br>
						<div style="display: flex;justify-content: center;">
							<div class="app_filtre" style="width:200px" @click="valide_filtre()">{{ $t('appliquer_les_filtres') }}</div>
						</div>
					</div>					
				</div>
				<div class="right-bar" :class="{filtreactif: cv}" >
					<div class="right-bar-content" v-bar>
						<div class="content" ref="scrollable_annonce">
							<annonce v-for="annonce in Annonces" :key="annonce.id" :id="'annonce-' + annonce.id" :annonce="annonce" :class="{ disabled: app.loading || !app.authorized }" />
						</div>
					</div>
				</div>
			</div>			
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Annonce from './pane-locatif/Annonce'
import SwitchButtonDpe from './global/SwitchButtonDpe'
import InputNumber from 'primevue/inputnumber';

export default {
	name: 'PaneBiensLocatifs',
	data: function() {
		return {
			encours: false,
			listeThematique: [],
			price_min:0,
			price_max:99999,
			MeubleOui: true,
			MeubleNon: true,
			MeubleNC: true,
			t1:true, 
			t2:true, 
			t3:true, 
			t4:true, 
			t5:true, 
			DPE_A: true,
			DPE_B: true,
			DPE_C: true,
			DPE_D: true,
			DPE_E: true,
			DPE_F: true,
			DPE_G: true,
			DPE_NC: true,
			area_min: 0,
			area_max: 999,
			cv: false,
			Annonces: [],
		}
	},
	watch: {
		'app.theme': function(newValue) {
			if (newValue) {
				this.scroll(newValue)
			}
		},
		'app.liste_biens_locatifs': function(newValue) {
			if (newValue) {
				this.Annonces = this.getAnnoncesFilter();
			}
		},
	},
	mounted: function() {
		this.maj()
	},
	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),
	methods: {
		
		getAnnoncesFilter: function() {
			let t = []
			for(let annonce in this.app.liste_biens_locatifs.hits){
				if (this.app.liste_biens_locatifs.hits[annonce].filter) {
					t.push(this.app.liste_biens_locatifs.hits[annonce])
				}
			}
			return t
		},

		downloadFile: function() {
			if (this.app.parambienslocatifs) {
				try {
					this.encours = true
					let url = ''
					url = this.app.BaseUrlBackend + '/proxy/yanport/properties?' + this.app.parambienslocatifs
					let that = this
					axios.get(url, { })
					.then(function(response) {
						let exportedFilename = 'annonces.xlsx'  
						let link = document.createElement("a");
						if (link.download !== undefined) {
							that.encours = false
							let url = response.data.fichier;
							link.setAttribute("href", url);
							link.setAttribute("download", exportedFilename);
							link.style.visibility = 'hidden';
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						}
					})	
				} catch (error) {
					console.log(error, 'error')
					this.encours = false
				}
			}
		},

		getJsonLocatif: function() {
			let r = []
			if (this.app.liste_biens_locatifs.hits) {
				for (let index = 0; index < this.app.liste_biens_locatifs.hits.length; index++) {
					const annonce = this.app.liste_biens_locatifs.hits[index];
					if (annonce.filter) {
						r.push({type: annonce.type, nbpieces : annonce.features.geometry.roomCount, cree: annonce.creationDate,
							maj: annonce.updateDate, surface: annonce.features.geometry.surface, prix: annonce.marketing.priceHC,
							charge: annonce.marketing.rentalExpenses, energie: annonce.features.energy.consumptionLetter,
							emission: annonce.features.energy.greenhouseGasConsumptionLetter
						})
					}	
				}
			}			
			return r
		},
		
		// eslint-disable-next-line
		toggle_switch: function(filter) {
			gtag('event', 'filtre_locatif_typologie', {'event_category': 'filtre','event_label': 'filtre_locatif_typologie'});
			this.$store.dispatch('send_event_backend', {module: 'locatif', action: 'filtre', option: 'typologie'});
		},
		
		// eslint-disable-next-line
		toggle_switchDPE: function(filter) {
			gtag('event', 'filtre_locatif_dpe', {'event_category': 'filtre','event_label': 'filtre_locatif_dpe'});
			this.$store.dispatch('send_event_backend', {module: 'locatif', action: 'filtre', option: 'dpe'});
		},
		
		// eslint-disable-next-line
		toggle_switchMeuble: function(filter) {
			gtag('event', 'filtre_locatif_meuble', {'event_category': 'filtre','event_label': 'filtre_locatif_meuble'});
			this.$store.dispatch('send_event_backend', {module: 'locatif', action: 'filtre', option: 'meuble'});
		},
		
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		
		// eslint-disable-next-line
		valideInterval: function(val, event) {
			if (val) {				
				switch (val) {
					case 'price_min':
					gtag('event', 'filtre_locatif_loyer', {'event_category': 'filtre','event_label': 'filtre_locatif_loyer'});
					break;
					case 'price_max':
					gtag('event', 'filtre_locatif_loyer', {'event_category': 'filtre','event_label': 'filtre_locatif_loyer'});
					break;
					case 'area_min':
					gtag('event', 'filtre_locatif_surface', {'event_category': 'filtre','event_label': 'filtre_locatif_surface'});
					break;
					case 'area_max':
					gtag('event', 'filtre_locatif_surface', {'event_category': 'filtre','event_label': 'filtre_locatif_surface'});
					break;
					
					default:
					break;
				}				
			}
		},

		valide_filtre: function() {
			let data = {
				price_min: this.price_min,price_max: this.price_max, t1:this.t1, t2:this.t2, t3:this.t3, t4:this.t4, t5:this.t5, area_min: this.area_min, area_max: this.area_max,
				A: this.DPE_A, B: this.DPE_B, C: this.DPE_C, D: this.DPE_D, E: this.DPE_E, F: this.DPE_F, G: this.DPE_G, NC: this.DPE_NC,
				MeubleOui: this.MeubleOui, MeubleNon: this.MeubleNon, MeubleNC: this.MeubleNC
			}
			this.$store.dispatch('set_filter_locatif', data)
			.then(() => {
				this.Annonces = this.getAnnoncesFilter();
			})
			this.cv = false
		},

		maj: function() {
			let that = this
			let url = 'https://www.myadequation.fr/EFOCUS/aide.json'
			axios.get(url, {})
			.then(response => {
				that.listeThematique = response.data.thematiques
			}).catch(console.error)
		},

		GetId: function(Id) {
			return "annonce-" + Id
		},

		scroll: function(id) {
			let that = this
			setTimeout(function() {
				let $scroll = $(that.$refs.scrollable_annonce)
				let $annonce = $scroll.find('#annonce-' + id)
				if ($annonce) {
					try {
						$scroll.animate({ scrollTop: $annonce[0].offsetTop }, 250)
					} catch (error) {
						console.log(error)
					}
				}
			}, 100)
		}
	},
	
	components: { Annonce, SwitchButtonDpe, InputNumber}
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.theme
	padding-right 10px
	padding-top 10px

._filtre
	position absolute
	height calc(100% - 60px)
	width 100%
.right-bar
	height 100%
	background white
	color #333
	visibility hidden
	.right-bar-content
		height 100%
		.content
			height 100%
.filtreactif
	height calc(100% - 264px) !important
			
.module_ret
	width 100%
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid #cccccc
	visibility hidden
.middle
	text-align center
	top 20px
	width 200px
	left 100px

.pane-biens-locatifs
	top headersize
	left PaneLeftSize !important
	bottom 0
	height calc(100% - 126px) !important
	width 0px
	background-color #333
	transition 0.5s easeOutQuart
	z-index 500
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fff;
	-webkit-box-shadow: 3px 3px 6px #555;
	box-shadow: 3px 3px 6px #555;
	border-radius: 0 0px 0px 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		width 400px
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible

.switch-button
	padding 0 0 8px 4px !important
.box
	background #f0f0f0
	
.box-content
	display block
	padding 0 16px
.box-title
	padding 0 16px
	margin 0 0 4px 0
	font-family volte, sans-serif
	font-weight 600
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
._box-title2
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
	font-family "Roboto", arial, sans-serif
.box-title3
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 2px
	font-family "Roboto", arial, sans-serif

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both
.value
	display inline-block
	text-align right
	border none
	width 100%
	padding 0 4px 0 4px
	height 28px
	background-color #fff
	border-radius 2px
	font-size 0.8rem
	line-height 28px
	outline none
.e
	margin-left -14px
.s
	margin-left -16px

.button
	width 170px
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	height 30px
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	&:hover
		background-color darken(blue, 10%)
		color white
.button.v-button:not([disabled])
	cursor pointer

.button.is-fullwidth
	display flex
	width 100%

.button.is-primary
	background-color palette0
	border-color transparent
	color #ededed
	&:hover
		opacity 0.9
		box-shadow var(--primary-box-shadow)

.button
	-webkit-appearance none
	align-items center
	border 1px solid transparent
	border-radius 4px
	box-shadow none
	display inline-flex
	font-size 1rem
	height 2.5em
	justify-content flex-start
	line-height 1.5
	padding-bottom calc(0.5em - 1px)
	padding-left calc(0.75em - 1px)
	padding-right calc(0.75em - 1px)
	padding-top calc(0.5em - 1px)
	position relative
	vertical-align top

.button.v-button
	height 38px
	line-height 1.1
	font-size 0.95rem
	font-family "Roboto", sans-serif
	transition all 0.3s
	justify-content center
	text-align center
	white-space nowrap
	border-width 1px




.retour
	width 60px
	position absolute
	left 0px
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		left 0px !important

.module_ret
	width 400px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.poweredcityscan
	position absolute
	left 185px
	top 18px
	width 180px
	height 60px
	vertical-align middle
	font-size: 18px
	font-style italic
	color primaryA400

.accueil
	width 60px;
	position absolute
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
	color #333
	&:hover
		color #fff

.neuf
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette0
	text-align center
	line-height 60px
	cursor pointer

.pan-adresse-retour
	position absolute
	top 0px
	float left
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%

.btnlayer_fu
	width 180px
	height 32px
	transition 0.5s easeOutQuart
	padding 0px 10px 10px 10px
	text-align center
	background #fafafa
	color #666
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	border solid 1px #333
	vertical-align middle
	line-height 32px
	color #666
	.divmap
		z-index 9999
		position absolute
		right calc(50% - 12px)
		top calc(50% - 12px)
		.icon
			display block

.infinite-loader-xls
	z-index 9999
	line-height 60px
	vertical-align middle
	.loader
		top 8px
		display inline-block
		border-radius 100%
		color #333
		.icon
			display block
			animation rotate 1.5s infinite linear

.head_filter
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

.filters
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	color #666
	&:hover
		color #6C21F9
					
@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
