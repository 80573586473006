<template>
	<div v-if="app.progress_bar && !app.progress_bar" class="progress"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			isLoading: false,
			progress: 0,
			intervalId: null,
			intervalDuration: 20,
		};
	},

	computed: Object.assign(mapGetters([ 'app', 'user']), {
		progressStyle() {
			return {
				width: `${this.progress}%`,
				transition: 'width 0.5s, background-color 0.5s',
			};
		},
	}
	),

	methods: {

	},

	watch: {
		'app.progress_bar': function(v) {
			this.isLoading = v;
		},

		'app.loading_yanport': function(v) {
			this.progress = (100/34) * v;
		}
	},

	components: { }
};

</script>

<style scoped>

.progress {
	height: 100%;
	background: #da491f;
	transition: width 0.5s, background-color 0.5s;
}


.progress {
   height: 4.5px;
   width: 100%;
   background: linear-gradient(#0073ea 0 0), linear-gradient(#0073ea 0 0),#dbdcef;
   background-size: 60% 100%;
   background-repeat: no-repeat;
   animation: progress-7x9cg2 5s infinite;
}

@keyframes progress-7x9cg2 {
   0% {
      background-position: -150% 0,-150% 0;
   }

   66% {
      background-position: 250% 0,-150% 0;
   }

   100% {
      background-position: 250% 0, 250% 0;
   }
}
</style>
