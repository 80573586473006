<template>
	<div>
		<div class="neuf" @click.stop="$store.dispatch('set_filters_promotion', {filters_promotion:!app.filters_promotion})" title="Filtrer ma recherche" :class="{ idf: !app.module_actif }">
			<icon file="filter_list" :size="36" class="filters" /><div class="nbf" v-if="app.filters.nb_filtre > 0">{{app.filters.nb_filtre}}</div>
		</div>
		<transition name="slide">
			<div style="display:none" :class="{vi : fu_visible, left: !app.menuvisible}" class="box maplayer_filtres_promo" v-if="app.authorized">
				<div @click.stop="fu_visible=!fu_visible" class="closeBtn"><icon file="x" /></div>
				<div class="filtre">{{ $t('filtres') }}</div>
				<br>
				<div>
					<p class="box-title">{{ $t('forme_urbaine') }}</p>
					<div class="box-content">
						<div class="switches">
							<switch-button size="80px" icon="building" :label="$t('collectif')" @click.native="toggle_switch('forme', 'collectif')" :class="{ active: app.filters.col }" />
							<switch-button size="80px" icon="house" :label="$t('individuel')" @click.native="toggle_switch('forme', 'individuel')" :class="{ active: app.filters.ind }" />
						</div>
					</div>
					<p class="box-title">{{ $t('commercialisation') }}</p>
					<div class="box-content">
						<div class="switches">
							<switch-button size="80px" icon="clipboard-empty" :label="$t('actifs')" divise="three" @click.native="toggle_switch('commercialisation', 'actif')" :class="{ active: app.filters.actif }" />
							<switch-button size="80px" icon="house-plan" :label="$t('projets')" divise="three" @click.native="toggle_switch('commercialisation', 'projet')" :class="{ active: app.filters.projet }" />
							<switch-button size="80px" icon="clipboard-ok" :label="$t('termines')" divise="three" @click.native="toggle_switch('commercialisation', 'termine')" :class="{ active: app.filters.termine }" />
						</div>
					</div>
					<p class="box-title">{{ $t('tva') }}</p>
					<div class="box-content">
						<div class="switches small">
							<switch-button size="80px" class="small" label="5,5%" @click.native="toggle_switch('tva', 'reduite')" :class="{ active: app.filters.tvar }" />
							<switch-button size="80px" class="small" label="20 %" @click.native="toggle_switch('tva', 'pleine')" :class="{ active: app.filters.tvap }" />
						</div>
					</div>
					<p class="box-title">{{ $t('prix_au_m²') }}</p>
					<div class="box-content">
						<br><br>
						<vue-slider v-model="prix" :min="0" :max="30000" :enable-cross="false"
							:interval="100" 
							:tooltipStyle="{ backgroundColor: '#0089b4', borderColor: '#0089b4', fontSize: '12px', fontWeight: '600', color: '#f0f2f4' }"
							:processStyle="{ backgroundColor: '#0089b4' }"
							:bgStyle="{ backgroundColor: '#ddd', height: '3px' }"
							:sliderStyle="{ backgroundColor: '#f0f2f4', boxShadow: 'inset 0 0 0 3px #0089b4, 0 0 0 2px #f0f2f4', top: '-6px' }"
							tooltipFormatter="{value} €"
							mergeFormatter="{value1} € - {value2} €"
							:dot-options="dotOptions"
							@change='sliderChange($event)'
							>
						</vue-slider>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import SwitchButton from './global/SwitchButton'

export default {
	name: 'PaneFiltersPromo',

	data: function() {
		return {
			dotOptions: [{
					tooltip: 'always'
					},{
					tooltip: 'always'
					}],
			tvar: true,
			tvap: true,
			annee_actif: [2018, 2021],
			prix: [0, 30000],
			tva_visible: false,
			fu_visible: false,
			etat_visible: false,
			prix_visible: false,
			nb_visible: false,
			termine : true,
			actif : true,
			projet : true,
			col: true,
			ind: true,
			nb_one: true,
			nb_two: true,
			nb_three: true,
			nb_four: true,
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {

	}),

	methods: {
		sliderChange: function() {
			gtag('event', 'filtre_neuf_prix', {'event_category': 'filtre','event_label': 'filtre_neuf_prix'});
			this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'prix'});
			this.$store.dispatch('set_filters', { termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar})
			this.$store.dispatch('app_filters')
		},

		toggle_switch: function(filter, value) {
			switch (filter) {
				case 'forme':
				this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'forme urbaine'});
					gtag('event', 'filtre_neuf_fu', {'event_category': 'filtre','event_label': 'filtre_neuf_fu'});
					switch (value) {
						case 'collectif':
							this.col = !this.col
							break;
						default:
							this.ind = !this.ind
							break;
					}
					break;
				case 'commercialisation':
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'etat de comercialisation'});
					gtag('event', 'filtre_neuf_etat', {'event_category': 'filtre','event_label': 'filtre_neuf_etat'});
					switch (value) {
						case 'actif':
							this.actif = !this.actif
							break;
						case 'projet':
							this.projet = !this.projet
							break;
						default:
							this.termine = !this.termine
							break;
					}
					break;
				case 'tva':
					this.$store.dispatch('send_event_backend', {module: 'neuf', action: 'filtre', option: 'tva'});
					gtag('event', 'filtre_neuf_tva', {'event_category': 'filtre','event_label': 'filtre_neuf_tva'});
					switch (value) {
						case 'reduite':
							this.tvar = !this.tvar
							break;
						default:
							this.tvap = !this.tvap
							break;
					}
					break;
				default:
					break;
			}
			this.$store.dispatch('set_filters', { termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar})
			this.$store.dispatch('app_filters')
		},

		range_prix: function(filter, pmin, pmax) {
			let data = { filter: filter }
			data['min'] = pmin
			data['max'] = pmax
		},

		toggle_view: function() {
			let view = 'list'
			if (this.app.view == 'list') view = 'home'
			this.$store.dispatch('set_view', { view: view })
		},

		set_filters: function() {
			this.$store.dispatch('set_filters', { termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar})
			this.$store.dispatch('app_filters')
		},
	},
	components: { VueSlider, SwitchButton }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'
@import '../assets/css/grid'

.menuvisible
	left 6px !important
.pane-filters-promo
	position absolute
	top 60px
	height 60px
	width 100%
	background white
	border-bottom 1px solid #ddd
	transition 0.5s easeOutQuart
	&.report
		transform translateX(-100%)
	.logo
		position absolute
		left 16px
		top (16px + 4px)
		padding 0
		height 32px
		cursor pointer
		img
			height 100%
	.form-filters
		height calc(100% - 72px)
		margin-top 72px
		.form-filters-content
			height 100%
			.content
				height 100%
	.btnlayer_tva
		position fixed
		width 180px
		top 74px
		height 32px
		left 620px
		transition 0.5s easeOutQuart
		padding 0px 10px 10px 10px
		text-align center
		background #fafafa
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		border solid 1px #333
		vertical-align middle
		line-height 32px
		color #666
		.divmap
			z-index 9999
			position absolute
			right calc(50% - 12px)
			top calc(50% - 12px)
			.icon
				display block
	.maplayer_tva
		position fixed
		width 180px
		top 114px
		height 120px
		left 620px
		z-index 600
		transition 0.5s easeOutQuart
		padding 7px 10px 10px 10px
		text-align center
		border-radius radius 5px
		background white
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
	.btnlayer_fu
		position fixed
		width 180px
		top 74px
		height 32px
		left 20px
		transition 0.5s easeOutQuart
		padding 0px 10px 10px 10px
		text-align center
		background #fafafa
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		border solid 1px #333
		vertical-align middle
		line-height 32px
		color #666
		.divmap
			z-index 9999
			position absolute
			right calc(50% - 12px)
			top calc(50% - 12px)
			.icon
				display block

.maplayer_filtres_promo
	position fixed
	top 60px
	left PaneLeftSize !important
	bottom 0
	height calc(100% - 95px)
	width 0px
	background-color #333
	z-index: 500;
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fff;
	transition 0.5s easeOutQuart
	-webkit-box-shadow: 3px 3px 6px #555;
	box-shadow: 3px 3px 6px #555;
	border-radius: 0 0px 0px 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		width 300px
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible
	z-index 600
	position fixed
	top 60px
	bottom 0
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	margin-top 1.33333rem
	padding 20px
	pointer-events all
	opacity 1

	.maplayer_fu
		position fixed
		width 180px
		top 114px
		height 120px
		left 20px
		z-index 600
		transition 0.5s easeOutQuart
		padding 7px 10px 10px 10px
		text-align center
		border-radius radius 5px
		background white
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
	.btnlayer_etat
		position fixed
		width 180px
		top 74px
		height 32px
		left 220px
		transition 0.5s easeOutQuart
		padding 0px 10px 10px 10px
		text-align center
		background #fafafa
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		border solid 1px #333
		vertical-align middle
		line-height 32px
		color #666
		.divmap
			z-index 9999
			position absolute
			right calc(50% - 12px)
			top calc(50% - 12px)
			.icon
				display block
	.maplayer_etat
		position fixed
		width 180px
		top 114px
		height 160px
		left 220px
		z-index 600
		transition 0.5s easeOutQuart
		padding 7px 10px 10px 10px
		text-align center
		border-radius radius 5px
		background white
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
	.btnlayer_prix
		position fixed
		width 180px
		top 74px
		height 32px
		left 420px
		transition 0.5s easeOutQuart
		padding 0px 10px 10px 10px
		text-align center
		background #fafafa
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		border solid 1px #333
		vertical-align middle
		line-height 32px
		color #666
		.divmap
			z-index 9999
			position absolute
			right calc(50% - 12px)
			top calc(50% - 12px)
			.icon
				display block
	.maplayer_prix
		position fixed
		width 330px
		top 114px
		height 80px
		left 420px
		z-index 600
		transition 0.5s easeOutQuart
		padding 7px 10px 10px 10px
		text-align center
		border-radius radius 5px
		background white
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		z-index 999
	.btnlayer_nb
		position fixed
		width 180px
		top 74px
		height 32px
		left 820px
		transition 0.5s easeOutQuart
		padding 0px 10px 10px 10px
		text-align center
		background #fafafa
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		border solid 1px #333
		vertical-align middle
		line-height 32px
		color #666
		.divmap
			z-index 9999
			position absolute
			right calc(50% - 12px)
			top calc(50% - 12px)
			.icon
				display block
	.maplayer_nb
		position fixed
		width 200px
		top 114px
		height 210px
		left 820px
		z-index 500
		transition 0.5s easeOutQuart
		padding 7px 10px 10px 10px
		text-align center
		border-radius radius 5px
		background white
		color #666
		cursor pointer
		box-shadow 0 0 4px alpha(#000, 25%)
		z-index 999
	.choix
		padding-top 20px

.loading-icon
	animation rotate 2s infinite linear
	margin 0 auto

.input
	display block
	width 100%
	height 48px
	padding 0 16px
	background #fff
	border 2px solid darken(light, 10%)
	border-radius radius
	font inherit
	color #333
	outline none
	&:focus
		border-color blue
.label
	display block
	margin 0 0 2px 0
	color #666
	text-transform uppercase
	font-size 11px
	font-weight 500
.buttonv
	right 0
	border 0
	border-radius 0
	background palette1
	border-radius 18px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight 500
	font-size 14px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 100px
	height 36px

.filtre
	font-weight bold
	text-align left


.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
	.box-title
		padding 0 16px
		margin 0 0 4px 0
		font-family volte, sans-serif
		font-weight 600
		color #333
		line-height 16px
		text-align left
		padding-bottom 8px
		padding-top 8px
	.box-content
		display block
		padding 0 16px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both

.closeBtn
	float right
	top 0px
	z-index 999

.custom-step
	width 100%
	height 100%
	border-radius 50%
	box-shadow 0 0 0 3px #ccc
	background-color #fff

.custom-step
	.active
		box-shadow 0 0 0 3px #3498db
		background-color #3498db


.neuf
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important
.preference_neuf
	width 60px;
	height 60px;
	position absolute
	left 40px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important
	
.filters
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	color #666
	&:hover
		color #6C21F9

.nbf
	position absolute
	top 12px
	right 12px
	width 16px
	height 16px
	line-height 16px
	border-radius 6px
	background-color #f0e63c
	color #333
	text-align center
	vertical-align middle
	font-size 9px
	font-weight 400
	

</style>
