<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<p class="title">{{ $t('Revenu net imposable des ménages par tranches d\'âges et par décile') }}</p>
		</div>
		<div v-if="app.livedata.insee && app.livedata.insee.length" class="stat-box-content" style="font-size: 14px;text-align: center; vertical-align: middle;">
			<div class="">
				<apexchart id="heatmap" type="heatmap" height="350" width="100%" :options="chartOptions" :series="series"></apexchart>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ArrayInseeAge',

	data: function() {
		return {
			chartOptions : {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: true,
						style: {
							colors: ['#000000'], // Définit la couleur de toutes les étiquettes en noir
						},
						formatter: function (val) {
						return val.toLocaleString('fr-FR', {
							style: 'currency',
							currency: 'EUR',
							minimumFractionDigits: 0, // Aucune décimale
							maximumFractionDigits: 0  // Aucune décimale
						});
					},
					},
					
					colors: ["#f97316"],
					xaxis: {
						type: 'category',
						categories: ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"]
					},
				},
			series: [],
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'insee_rs_chiffres', name: 'Chiffres clés'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'insee_rs_chiffres_img'});} },
			]
			,
			
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		Nbdata:function() {
			let dataLoyer= [
				{ name: '1er décile', data: this.GetD(1) },
				{ name: '2ème décile', data: this.GetD(2) },
				{ name: '3ème décile', data: this.GetD(3) },
				{ name: '4ème décile', data: this.GetD(4) },
				{ name: '5ème décile', data: this.GetD(5) },
				{ name: '6ème décile', data: this.GetD(6) },
				{ name: '7ème décile', data: this.GetD(7) },
				{ name: '8ème décile', data: this.GetD(8) },
				{ name: '9ème décile', data: this.GetD(9) },
			]
			return dataLoyer
		},

	}),

	mounted: function() {
		this.maj_graph()
	},

	methods: {
		GetD(decile) {
			let division = ["age_0_30", "age_30_39", "age_40_49", "age_50_59", "age_60_74", "age_75_more"]
			let t=[]
			for (let index = 0; index < division.length; index++) {
				if (((this.app.view == 'report_E') && (this.app.efocus_type!= 'efocus-parcours-personnalises-tous') && index > 3) || ((this.app.view == 'report_E') && (this.app.efocus_type== 'efocus-parcours-personnalises-tous')) || (this.app.view != 'report_E')) {
					t.push(this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division[division[index]].decile["decile_"+ decile].income)	
				}
			}
			return t
		},

		updateSeriesLine: function(dataLoyer, dataCategories) {
				this.series = dataLoyer
				let chartOptions= {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: true,
						formatter: function (val) {
						return val.toLocaleString('fr-FR', {
							style: 'currency',
							currency: 'EUR',
							minimumFractionDigits: 0, // Aucune décimale
							maximumFractionDigits: 0  // Aucune décimale
						});
					},
					},
					colors: ["#f97316"],
					
					xaxis: {
						type: 'category',
						categories: dataCategories
					},
				}
				this.chartOptions = chartOptions
			},

		maj_graph: function() {
			let dataLoyer= [
			{ name: '1er décile', data: this.GetD(1) },
			{ name: '2ème décile', data: this.GetD(2) },
			{ name: '3ème décile', data: this.GetD(3) },
			{ name: '4ème décile', data: this.GetD(4) },
			{ name: '5ème décile', data: this.GetD(5) },
			{ name: '6ème décile', data: this.GetD(6) },
			{ name: '7ème décile', data: this.GetD(7) },
			{ name: '8ème décile', data: this.GetD(8) },
			{ name: '9ème décile', data: this.GetD(9) },
			]
			let dataCategories = ["< 30ans", "30-39 ans", "40-49 ans", "50-59 ans", "60-74 ans", "> 75 ans"]
			if ((this.app.view == 'report_E') && (this.app.efocus_type!= 'efocus-parcours-personnalises-tous')) dataCategories = ["60-74 ans", "> 75 ans"]
			this.updateSeriesLine(dataLoyer, dataCategories);				
		},
	},

	components: {  }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'


.program-detail-info-content
	padding 8px 0
	background #f5f5f5
	border-radius radius
	overflow hidden
	table
		width 100%
		border-collapse collapse
		tr
			&:last-child
				border none
			td, th
				padding 4px 8px
			th
				font-size 12px
				font-weight 500
			td
				text-align center
				&:first-child
					text-align left
					font-size 12px
					font-weight bold
					padding-left 16px

</style>
