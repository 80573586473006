<template>
	<transition name="slide">
		<div class="pane pane-filters-promtion" :class="{vi : app.filters_rs, left: !app.menuvisible}">
			<div class="right-bar">
				<div class="box right-bar-content" v-bar>
					<div class="content" ref="scrollable_aide" style="padding-left:10px;padding-right:20px">
						<div>
							<p class="box-title2">{{ $t('cible') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div @click="toggle_switch('etudiant')" class="filtre" :class="{ active: etudiant }">{{ $t('etudiant') }}</div>
									<div @click="toggle_switch('jeune')" class="filtre" :class="{ active: jeune }">{{ $t('jeunes') }}</div>
									<div @click="toggle_switch('senior')" class="filtre" :class="{ active: senior }">{{ $t('seniors') }}</div>
									<div @click="toggle_switch('social')" class="filtre" :class="{ active: social }">{{ $t('sociales') }}</div>
									<div @click="toggle_switch('touriste')" class="filtre" :class="{ active: touriste }">{{ $t('tourisme') }}</div>
									<div @click="toggle_switch('tout')" class="filtre" :class="{ active: tout }">{{ $t('tout_age') }}</div>

								</div>
							</div>
							<br/><br/>
							<p class="box-title2">{{ $t('nature') }}</p>
							<div class="box-content">
								<div class="group_filtre">
									<div v-show="senior" @click="toggle_switchNature('BEGUINAGE')" class="filtre" :class="{ active: BEGUINAGE }">{{ $t('beguinage') }}</div>
									<div v-show="senior" @click="toggle_switchNature('COPROPRIETE')" class="filtre" :class="{ active: COPROPRIETE }">{{ $t('RSS 1ère GENERATION') }}</div>
									<div v-show="senior" @click="toggle_switchNature('EHPAD')" class="filtre" :class="{ active: EHPAD }">{{ $t('ehpad') }}</div>
									<div v-show="senior" @click="toggle_switchNature('REPOS')" class="filtre" :class="{ active: REPOS }">{{ $t('maison_de_repos') }}</div>
									<div v-show="senior" @click="toggle_switchNature('REPOS_SOINS')" class="filtre" :class="{ active: REPOS_SOINS }">{{ $t('maison_de_repos_et_de_soins') }}</div>
									<div v-show="senior" @click="toggle_switchNature('AUTONOMIE')" class="filtre" :class="{ active: AUTONOMIE }">{{ $t('residence_autonomie') }}</div>
									<div v-show="senior" @click="toggle_switchNature('RSS')" class="filtre" :class="{ active: RSS }">{{ $t('RSS 2ème GENERATION') }}</div>
									<div v-show="senior" @click="toggle_switchNature('VILLAGE_PRIVE')" class="filtre" :class="{ active: VILLAGE_PRIVE }">{{ $t('village_senior_prive') }}</div>
									<div v-show="senior" @click="toggle_switchNature('VILLAGE_SOCIAL')" class="filtre" :class="{ active: VILLAGE_SOCIAL }">{{ $t('village_senior_social') }}</div>
									<div v-show="senior" @click="toggle_switchNature('MARPA')" class="filtre" :class="{ active: MARPA }">{{ $t('marpa') }}</div>

									<div v-show="etudiant" @click="toggle_switchNature('RSE_SOCIAL')" class="filtre" :class="{ active: RSE_SOCIAL }">{{ $t('residence_services_etudiant_(rse)_sociale') }}</div>
									<div v-show="etudiant" @click="toggle_switchNature('RSE_MIXTE')" class="filtre" :class="{ active: RSE_MIXTE }">{{ $t('residence_services_etudiant_(rse)_mixte') }}</div>
									<div v-show="etudiant" @click="toggle_switchNature('RSE_LIBRE')" class="filtre" :class="{ active: RSE_LIBRE }">{{ $t('residence_services_etudiant_(rse)_libre') }}</div>

									<div v-show="touriste" @click="toggle_switchNature('HOTEL')" class="filtre" :class="{ active: HOTEL }">{{ $t('Hôtel') }}</div>
									<div v-show="touriste" @click="toggle_switchNature('RESIDENCE_DE_TOURISME')" class="filtre" :class="{ active: RESIDENCE_DE_TOURISME }">{{ $t('Résidence de tourisme') }}</div>
									<div v-show="touriste" @click="toggle_switchNature('HOTEL_ET_RÉSIDENCE')" class="filtre" :class="{ active: HOTEL_ET_RÉSIDENCE }">{{ $t('Hôtel et résidence') }}</div>

									<div v-show="jeune||social" @click="toggle_switchNature('FJT')" class="filtre" :class="{ active: FJT }">{{ $t('foyer_jeunes_travailleurs_(fjt)') }}</div>

									<div v-show="social" @click="toggle_switchNature('CHRS')" class="filtre" :class="{ active: CHRS }">{{ $t('chrs_-_centre_dhebergement_et_de_reinsertion_sociale') }}</div>

									<div v-show="tout" @click="toggle_switchNature('COLIVING')" class="filtre" :class="{ active: COLIVING }">{{ $t('Coliving') }}</div>
									<div v-show="tout" @click="toggle_switchNature('COLIVING_HABITAT_PARTAGE')" class="filtre" :class="{ active: COLIVING_HABITAT_PARTAGE }">{{ $t('Coliving - Habitat partagé') }}</div>
									<div v-show="tout" @click="toggle_switchNature('COLOCATION')" class="filtre" :class="{ active: COLOCATION }">{{ $t('Colocation') }}</div>
								</div>
							</div>
							<br/><br/>
							<div style="display: flex;justify-content: center;">
								<div class="app_filtre" style="width:200px" @click="applique_filtre()">{{ $t('appliquer_les_filtres') }}</div>
							</div>
							<br><br>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import SwitchButtonDpe from './global/SwitchButtonDpe'

export default {
	data: function() {
		return {
			f: [],
			n: [],
			enabled: true,
			HOTEL: true,
			RESIDENCE_DE_TOURISME: true,
			HOTEL_ET_RÉSIDENCE: true,

			COLIVING: true,
			COLIVING_HABITAT_PARTAGE: true,
			COLOCATION: true,


			BEGUINAGE: true,
			CHRS: false,
			COPROPRIETE: true,
			EHPAD: true,
			FJT: false,
			REPOS: true,
			REPOS_SOINS: true,
			AUTONOMIE: true,
			RSE_LIBRE: false,
			RSE_MIXTE: false,
			RSE_SOCIAL: false,
			RSS: true,
			VILLAGE_PRIVE: true,
			VILLAGE_SOCIAL: true,
			MARPA: true,
			etudiant: false,
			inter: false,
			jeune: false,
			senior: true,
			social: false,
			touriste: false,
			tout: false,
			etoile_1: true,
			etoile_2: true,
			etoile_3: true,
			etoile_4: true,
			etoile_5: true,
			etoile_NC: true,
			MeubleOui: true,
			MeubleNon: true,
			MeubleNC: true,
			ServiceOui: true,
			ServiceNon: true,
			ServiceNC: true,
			nbannee: 3,
			dotOptions: [{
				tooltip: 'always'
			},{
				tooltip: 'always'
			}],
			tvar: true,
			tvap: true,
			annee_actif: [2011, 2021],
			prix: [0, 30000],
			tva_visible: false,
			fu_visible: false,
			etat_visible: false,
			prix_visible: false,
			nb_visible: false,
			termine : true,
			actif : true,
			projet : true,
			col: true,
			ind: true,
			nb_one: true,
			nb_two: true,
			nb_three: true,
			nb_four: true,
			hb1: '60px',
			hb2: '40px',
		}
	},

	watch: {
		'app.theme': function(newValue) {
			if (newValue) {
				this.scroll(newValue)
			}
		},

		'app.efocus_type': function(newValue) {
			this.enabled = true
			if (	newValue == 'efocus-parcours-personnalises-tous' || newValue == 'efocus-parcours-personnalises-senior'
				|| 	newValue == 'efocus-parcours-personnalises-etudiant' || newValue == 'efocus-parcours-personnalises-tourisme'
				|| 	newValue == 'efocus-parcours-personnalises-tout-age') {
				this.tout = false
				this.etudiant = false
				this.jeune = false
				this.social = false
				this.touriste = false
				this.senior = false
				this.COLIVING = false;
				this.COLIVING_HABITAT_PARTAGE = false;
				this.COLOCATION = false;
				this.RSE_LIBRE = false;
				this.RSE_MIXTE = false;
				this.RSE_SOCIAL = false;
				this.FJT = false;
				this.CHRS = false;
				this.FJT = false;
				this.HOTEL = false;
				this.RESIDENCE_DE_TOURISME = false;
				this.HOTEL_ET_RÉSIDENCE = false;
				this.BEGUINAGE = false;
				this.COPROPRIETE = false;
				this.EHPAD = false;
				this.REPOS = false;
				this.REPOS_SOINS = false;
				this.AUTONOMIE = false;
				this.RSS = false;
				this.VILLAGE_PRIVE = false;
				this.VILLAGE_SOCIAL = false;
				this.MARPA = false;
				if (newValue == 'efocus-parcours-personnalises-tous') {
					this.toggle_switch('senior')
					this.toggle_switch('jeune')
					this.toggle_switch('social')
					this.toggle_switch('touriste')
					this.toggle_switch('tout')
					this.toggle_switch('etudiant')
				}
				if (newValue == 'efocus-parcours-personnalises-senior') {
					this.toggle_switch('senior')
					this.enabled = false
				}
				if (newValue == 'efocus-parcours-personnalises-etudiant') {
					this.toggle_switch('etudiant')
					this.enabled = false
				}
				if (newValue == 'efocus-parcours-personnalises-tourisme') {
					this.toggle_switch('touriste')
					this.enabled = false
				}
				if (newValue == 'efocus-parcours-personnalises-tout-age') {
					this.toggle_switch('tout')
					this.enabled = false
				}
				this.applique_filtre(true)
			}
		},
	},

	mounted: function() {
		this.f = this.TableauLibelleCible();
		this.n = this.TableauLibelleNature();
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		TableauLibelleCible() {
			const uniqueCreLibelle = [];
			for (let index = 0; index < this.app.rss_references.length; index++) {
				const element = this.app.rss_references[index];
				if (!uniqueCreLibelle.includes(element.cre_libelle)) {
					uniqueCreLibelle.push(element.cre_libelle);
				}
			}
			return uniqueCreLibelle;
		},
		
		TableauLibelleNatureByCible(cible) {
			const uniqueNarLibelle = [];
			for (let index = 0; index < this.app.rss_references.length; index++) {
				const element = this.app.rss_references[index];
				if (element.cre_libelle == cible) {
					if (!uniqueNarLibelle.includes(element.nar_libelle)) {
						uniqueNarLibelle.push(element.nar_libelle);
					}
				}
			}
			return uniqueNarLibelle;
		},

		TableauLibelleNature() {
			const uniqueNarLibelle = [];
			for (let index = 0; index < this.app.rss_references.length; index++) {
				const element = this.app.rss_references[index];
				if (this.f.includes(element.cre_libelle)) {
					if (!uniqueNarLibelle.includes(element.nar_libelle)) {
						uniqueNarLibelle.push(element.nar_libelle);
					}
				}
			}
			return uniqueNarLibelle;
		},
		
		applique_filtre: function(b) {
			this.valide_filtre()
			if (!b) this.$store.dispatch('set_filters_rs', {filters_rs:!this.app.filters_rs})
		},

		toggle_switchDPE: function(filter) {
			gtag('event', 'filtre_rss_dpe', {'event_category': 'filtre','event_label': 'filtre_rss_dpe'});
			this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'dpe'});
			switch (filter) {
				case '1': this.etoile_1 = !this.etoile_1; break;
				case '2': this.etoile_2 = !this.etoile_2; break;
				case '3': this.etoile_3 = !this.etoile_3; break;
				case '4': this.etoile_4 = !this.etoile_4; break;
				case '5': this.etoile_5 = !this.etoile_5; break;
				case 'NC': this.etoile_NC = !this.etoile_NC; break;
				default:
				break;
			}
		},

		toggle_switchMeuble: function(filter) {
			switch (filter) {
				case 'MeubleOui': this.MeubleOui = !this.MeubleOui; gtag('event', 'filtre_rss_meuble', {'event_category': 'filtre','event_label': 'filtre_rss_meuble'}); this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'meuble'}); break;
				case 'MeubleNon': this.MeubleNon = !this.MeubleNon; gtag('event', 'filtre_rss_meuble', {'event_category': 'filtre','event_label': 'filtre_rss_meuble'}); this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'meuble'}); break;
				case 'MeubleNC': this.MeubleNC = !this.MeubleNC; gtag('event', 'filtre_rss_meuble', {'event_category': 'filtre','event_label': 'filtre_rss_meuble'}); this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'meuble'}); break;
				case 'ServiceOui': this.ServiceOui = !this.ServiceOui; gtag('event', 'filtre_rss_service', {'event_category': 'filtre','event_label': 'filtre_rss_service'}); this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'service'}); break;
				case 'ServiceNon': this.ServiceNon = !this.ServiceNon; gtag('event', 'filtre_rss_service', {'event_category': 'filtre','event_label': 'filtre_rss_service'}); this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'service'}); break;
				case 'ServiceNC': this.ServiceNC = !this.ServiceNC; gtag('event', 'filtre_rss_service', {'event_category': 'filtre','event_label': 'filtre_rss_service'}); this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'service'}); break;
				default:
				break;
			}
		},
		
		toggle_switchNature: function(filter) {

			gtag('event', 'filtre_rss_nature', {'event_category': 'filtre','event_label': 'filtre_rss_nature'});
			this.$store.dispatch('send_event_backend', {module: 'rs', action: 'filtre', option: 'nature'});
			switch (filter) {
				case 'BEGUINAGE': this.BEGUINAGE = !this.BEGUINAGE; break;
				case 'CHRS': this.CHRS = !this.CHRS; break;
				case 'COPROPRIETE': this.COPROPRIETE = !this.COPROPRIETE; break;
				case 'EHPAD': this.EHPAD = !this.EHPAD; break;
				case 'FJT': this.FJT = !this.FJT; break;
				case 'REPOS': this.REPOS = !this.REPOS; break;
				case 'REPOS_SOINS': this.REPOS_SOINS = !this.REPOS_SOINS; break;
				case 'AUTONOMIE': this.AUTONOMIE = !this.AUTONOMIE; break;
				case 'RSE_LIBRE': this.RSE_LIBRE = !this.RSE_LIBRE; break;
				case 'RSE_MIXTE': this.RSE_MIXTE = !this.RSE_MIXTE; break;
				case 'RSE_SOCIAL': this.RSE_SOCIAL = !this.RSE_SOCIAL; break;
				case 'RSS': this.RSS = !this.RSS; break;
				case 'VILLAGE_PRIVE': this.VILLAGE_PRIVE = !this.VILLAGE_PRIVE; break;
				case 'VILLAGE_SOCIAL': this.VILLAGE_SOCIAL = !this.VILLAGE_SOCIAL; break;
				case 'MARPA': this.MARPA = !this.MARPA; break;

				case 'HOTEL': this.HOTEL = !this.HOTEL; break;
				case 'RESIDENCE_DE_TOURISME': this.RESIDENCE_DE_TOURISME = !this.RESIDENCE_DE_TOURISME; break;
				case 'HOTEL_ET_RÉSIDENCE': this.HOTEL_ET_RÉSIDENCE = !this.HOTEL_ET_RÉSIDENCE; break;
				case 'COLIVING': this.COLIVING = !this.COLIVING; break;
				case 'COLIVING_HABITAT_PARTAGE': this.COLIVING_HABITAT_PARTAGE = !this.COLIVING_HABITAT_PARTAGE; break;
				case 'COLOCATION': this.COLOCATION = !this.COLOCATION; break;
				default:
				break;
			}
			
		},

		valide_filtre: function() {
			let data = {etudiant: this.etudiant, inter: this.inter, jeune:this.jeune, senior:this.senior, social:this.social, touriste:this.touriste, tout:this.tout,
				E1: this.etoile_1, E2: this.etoile_2, E3: this.etoile_3, E4: this.etoile_4, E5: this.etoile_5, ENC: this.etoile_NC,
				ServiceOui: this.ServiceOui, ServiceNon: this.ServiceNon, ServiceNC: this.ServiceNC,
				MeubleOui: this.MeubleOui, MeubleNon: this.MeubleNon, MeubleNC: this.MeubleNC,
				BEGUINAGE: this.BEGUINAGE,
				CHRS: this.CHRS,
				COPROPRIETE: this.COPROPRIETE,
				EHPAD: this.EHPAD,
				FJT: this.FJT,
				REPOS: this.REPOS,
				REPOS_SOINS: this.REPOS_SOINS,
				AUTONOMIE: this.AUTONOMIE,
				RSE_LIBRE: this.RSE_LIBRE,
				RSE_MIXTE: this.RSE_MIXTE,
				RSE_SOCIAL: this.RSE_SOCIAL,
				RSS: this.RSS,
				VILLAGE_PRIVE: this.VILLAGE_PRIVE,
				VILLAGE_SOCIAL: this.VILLAGE_SOCIAL,
				MARPA: this.MARPA,
				HOTEL: this.HOTEL,
				RESIDENCE_DE_TOURISME: this.RESIDENCE_DE_TOURISME,
				HOTEL_ET_RÉSIDENCE: this.HOTEL_ET_RÉSIDENCE,
				COLIVING: this.COLIVING,
				COLIVING_HABITAT_PARTAGE: this.COLIVING_HABITAT_PARTAGE,
				COLOCATION: this.COLOCATION,
				cible: this.f,
				natures: this.n
			}
			this.$store.dispatch('set_filter_rs', data)
		},
/*			
		toggle_switch: function(filter) {
			if (this.enabled) {
				switch (filter) {
					case 'tout':
						this.tout = !this.tout;
						if (this.tout) {
							this.COLIVING = true;
							this.COLIVING_HABITAT_PARTAGE = true;
							this.COLOCATION = true;
						}
						break;
					case 'etudiant':
						this.etudiant = !this.etudiant;
						if (this.etudiant) {
							this.RSE_LIBRE = true;
							this.RSE_MIXTE = true;
							this.RSE_SOCIAL = true;
						}
						break;
					case 'jeune':
						this.jeune = !this.jeune;
						if (this.jeune) {
							this.FJT = true;
						}
						break;
					case 'social':
						this.social = !this.social;
						if (this.social) {
							this.CHRS = true;
							this.FJT = true;
						}
						break;
					case 'touriste':
						this.touriste = !this.touriste;
						if (this.touriste) {
							this.HOTEL = true;
							this.RESIDENCE_DE_TOURISME = true;
							this.HOTEL_ET_RÉSIDENCE = true;
						}
						break;
					case 'senior':
						this.senior = !this.senior;
						if (this.senior) {
							this.BEGUINAGE = true;
							this.COPROPRIETE = true;
							this.EHPAD = true;
							this.REPOS = true;
							this.REPOS_SOINS = true;
							this.AUTONOMIE = true;
							this.RSS = true;
							this.VILLAGE_PRIVE = true;
							this.VILLAGE_SOCIAL = true;
							this.MARPA = true;
						}
						break;
					default: this.$store.dispatch('add_notification', { status: 'warning', message: "Fonctionnalité à venir" })
					break;
				}
			}
		},
*/
		toggle_switch: function(filter) {
			if (!this.enabled) {
				return;
			}

			const filterActions = {
				'tout': () => {
					this.tout = !this.tout;
					if (this.tout) {
						this.updateProperties(['COLIVING', 'COLIVING_HABITAT_PARTAGE', 'COLOCATION'], true);
					}
				},
				'etudiant': () => {
					this.etudiant = !this.etudiant;
					if (this.etudiant) {
						this.updateProperties(['RSE_LIBRE', 'RSE_MIXTE', 'RSE_SOCIAL'], true);
					}
				},
				'jeune': () => {
					this.jeune = !this.jeune;
					if (this.jeune) {
						this.updateProperties(['FJT'], true);
					}
				},
				'social': () => {
					this.social = !this.social;
					if (this.social) {
						this.updateProperties(['CHRS', 'FJT'], true);
					}
				},
				'touriste': () => {
					this.touriste = !this.touriste;
					if (this.touriste) {
						this.updateProperties(['HOTEL', 'RESIDENCE_DE_TOURISME', 'HOTEL_ET_RÉSIDENCE'], true);
					}
				},
				'senior': () => {
					this.senior = !this.senior;
					if (this.senior) {
						this.updateProperties([
							'BEGUINAGE', 'COPROPRIETE', 'EHPAD', 'REPOS', 'REPOS_SOINS', 'AUTONOMIE', 'RSS',
							'VILLAGE_PRIVE', 'VILLAGE_SOCIAL', 'MARPA'
						], true);
					}
				},
				'default': () => {
					this.$store.dispatch('add_notification', {
						status: 'warning',
						message: "Fonctionnalité à venir"
					});
				}
			};

			(filterActions[filter] || filterActions['default'])();
		},

		updateProperties: function(properties, value) {
			properties.forEach(prop => {
				this[prop] = value;
			});
		},
		
		range_prix: function(filter, pmin, pmax) {
			let data = { filter: filter }
			data['min'] = pmin
			data['max'] = pmax
		},
		
		toggle_view: function() {
			let view = 'list'
			if (this.app.view == 'list') view = 'home'
			this.$store.dispatch('set_view', { view: view })
		},

		set_filters: function() {
			this.$store.dispatch('set_filters', { termine: this.termine, actif: this.actif, projet: this.projet, col: this.col, ind: this.ind, nb_one: this.nb_one, nb_two: this.nb_two, nb_three: this.nb_three, nb_four: this.nb_four, prixmin: this.prix[0], prixmax: this.prix[1], tvap: this.tvap, tvar: this.tvar, annee_actif: this.annee_actif[0]})
			this.$store.dispatch('app_filters')
		},

		scroll: function(id) {
			let that = this
			setTimeout(function() {
				let $scroll = $(that.$refs.scrollable_aide)
				let $program = $scroll.find('#aide-' + id)
				if ($program) {
				try {
					$scroll.animate({ scrollTop: $program[0].offsetTop }, 250)
				} catch (error) {
					console.log(error)
				}
			}
			}, 100)
		}
	},
	
	// eslint-disable-next-line
	components: { SwitchButtonDpe }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.theme
	padding-right 10px
	padding-top 10px

.right-bar
	padding-left 10px
	height 100%
	background #FcFcFc
	width 100%
	color #333
	visibility hidden
	.right-bar-content
		height 100%
		.content
			height 100%

	.scroll-dots
		position absolute
		left -40px
		top 24px
		width 32px
		background alpha(green, 50%)
		border-radius radius
		z-index 2
		transition 0.25s easeOutQuart
		&:hover
			background alpha(green, 75%)
		.dot
			display block
			width 8px
			height 8px
			margin 12px
			background #fff
			opacity 0.25
			border-radius 100%
			transition 0.25s easeOutQuart
			cursor pointer
			&:hover, &.active
				opacity 1

.module_ret
	width 100%
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid #cccccc
	visibility hidden
	.middle
		top 20px

.pane-filters-promtion
	font-family 'Montserrat', sans-serif
	position fixed
	top headersize
	left PaneLeftSize !important
	bottom 0
	height calc(100% - 122px)
	width 0px
	background-color #333
	z-index 500
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fff;
	transition 0.5s easeOutQuart
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		border-right solid 1px #ccc
		width 350px
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible

.label
	display block
	margin 0 0 2px 0
	color #666
	text-transform uppercase
	font-size 11px
	font-weight 500
.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
	.box-title
		padding 0 16px
		margin 0 0 4px 0
		font-family volte, sans-serif
		font-weight 600
		color #333
		line-height 16px
		text-align left
		padding-bottom 8px
		padding-top 8px
	.box-content
		display block
		padding 0 16px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both


@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
