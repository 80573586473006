<template>
	<transition name="slide">
		<div class="pane pane-aide-live" :class="{vi : app.aide_live}">
			<div class="module_ret">
				<div class="middle">
					{{ $t('aide') }}
				</div>
				<div class="back-aide-button" @click="$store.dispatch('set_help_live', {aide:!app.aide_live})"><icon file="x" :size="32" /></div>
			</div>
			<div class="right-bar">
				<div class="right-bar-content" v-bar>
					<div class="content" ref="scrollable_aide">
						<div id="TexteAideLive">{{getTheme()}}</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
	name: 'PaneAideLive',

	data: function() {
		return {
			listeThematique: []			
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {
		decode: function(h) {
			return JSON.parse(h)
		},

		getTheme: function() {
			let url = 'https://www.myadequation.fr/EFOCUS/aide_livedata.html'
			url = url + "?" + _.now()
			axios.get(url)
			.then(function(response) {
				if (response && response.data) {
					document.getElementById("TexteAideLive").innerHTML = response.data
				}
			})
		},

		scroll: function(id) {
			let that = this
			setTimeout(function() {
				let $scroll = $(that.$refs.scrollable_aide)
				let $program = $scroll.find('#aide-' + id)
				if ($program) {
					try {
						$scroll.animate({ scrollTop: $program[0].offsetTop }, 250)
					} catch (error) {
						console.log(error)
					}
				}
			}, 10)
		}
	},
	
	components: {  }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.theme
	padding-right 10px
	padding-top 10px
	text-align justify

.right-bar
	padding-left 10px
	height calc(100% - 65px)
	background white
	width 100%
	color #333
	visibility hidden
	.right-bar-content
		height 100%
		.content
			height 100%

	.scroll-dots
		position absolute
		left -40px
		top 24px
		width 32px
		background alpha(green, 50%)
		border-radius radius
		z-index 2
		transition 0.25s easeOutQuart
		&:hover
			background alpha(green, 75%)
		.dot
			display block
			width 8px
			height 8px
			margin 12px
			background #fff
			opacity 0.25
			border-radius 100%
			transition 0.25s easeOutQuart
			cursor pointer
			&:hover, &.active
				opacity 1

.module_ret
	width 100%
	height 60px
	text-align center
	font-weight 600
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid #cccccc
	visibility hidden
	.middle
		top 20px

.pane-aide-live
	position fixed
	top 60px
	left 0px !important
	bottom 0
	height calc(100% - 95px)
	width 0px
	background-color #333
	transition 0.5s easeOutQuart
	z-index 500
	text-align: center;
	vertical-align: middle;
	position: absolute;
	z-index: 500;
	background: #fff;
	-webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
	box-shadow: 0 3px 6px rgb(0 0 0 / 25%);
	border-radius: 0 0px 0px 0;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	&.left
		left 0px !important
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	&.vi
		width 300px
		.module_ret
			visibility visible
		.back-aide-button
			visibility visible
		.right-bar
			visibility visible

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
