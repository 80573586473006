<template>
	<transition name="pane-ancien-offre" :id=ofr.id>
		<div class="program" :class="{ expanded: ofr == expanded_offre, selected: ofr.selected }">
			<div class="program-main" @click="expand_offre()" v-if="!app.loading">
				<div class="image">				
					<ImagePreview @error="handleImageError" v-if="ofr.photos && ofr.photos[0]" :src="ofr.photos[0].url_photo" width="112" height="90"/>
					<ImagePreview v-else src="https://www.myadequation.fr/EFOCUS./no-camera2.png" width="112" height="90"/>
				</div>
				<div class="program-content">
					<p class="name">{{ ofr.roomsQuantity }} {{$t('piéces de')}} {{ ofr.surfaceArea }} m²</p>
					<p class="city">{{ ofr.postalCode }} {{ ofr.city }}</p>
					<p class="seller sperso" v-if="ofr.surfaceArea && ofr.price">{{ getPrice(ofr.price/ofr.surfaceArea) }}/m²</p><p class="seller sperso" v-else>&nbsp;</p>
					<div class="forme-urbaine" v-if="ofr.propertyType.toUpperCase()=='FLAT'"><icon file="building" :size="24" style="color:#cccccc" /></div>
					<div class="forme-urbaine" v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
					<div style="width: 100%">
						<div style="display: flex;right:0px;justify-content: flex-end;">
							<div class="prix">&nbsp;{{ getPrice(ofr.price) }} </div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseFromWK } from 'wkt-parser-helper'
import ImagePreview from 'primevue/imagepreview';

export default {
	name: 'PaneAncienOffre',
	data: function() {
		return {
			markerSub: false,
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png'
		}
	},

	props: {
        ofr: {
			default: false
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_offre', 'selected_offre_secteurs' ]), {
		
	}),

	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},

        
		getPrice: function(p) {
			let nPrice=p
			
			const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (nPrice==0) {
				return "-"
			} else {
                return euro.format(Math.round(nPrice))
			}
		},

		expand_offre: function() {
			if (this.expanded_offre != this.ofr) {
				this.$store.dispatch('expand_offre', { offre: this.ofr })
				this.$router.push({ name: 'offre', params: { id: this.ofr.id }}).catch(()=>{});
			} else {
				this.$store.dispatch('expand_offre', { program: false })
				this.$router.push({ name: 'offre'}).catch(()=>{});
			}
		},

		hover_parcelle: function(parcelle) {
            this.$store.select_parcelle.clearLayers()
            let geojson = parseFromWK(parcelle.parcels[0].geometry.wktGeometry)
            let tab = geojson.coordinates
            for (let n=0; n < tab[0].length; n++) {
                tab[0][n].reverse()
            }
            this.markerSub = L.polygon(tab, {color: '#000000'})
            this.markerSub.addTo(this.$store.select_parcelle)
		},

		unhover_parcelle: function() {
            this.$store.select_parcelle.clearLayers()
        },

		getTransactionType: function(type) {
			switch (type) {
				case 'sale':
					return 'Vendu'
				case 'under_construction_sale':
					return 'VEFA'
				default:
					break;
			}
		},

		GetClass: function(type) {
			switch (type) {
				case 'dependency':
					return 'dependance'
				case 'house':
					return 'maison'
				case 'apartment', 'flat':
					return 'appartement'
				case 'land':
					return 'terrain'
				case 'industrial_commercial_premise':
					return 'local'
				default:
					break;
			}
		},

		GetLabel: function(type) {
			switch (type) {
				case 'dependency':
					return 'Dépendance'
				case 'house':
					return 'Maison'
				case 'apartment', 'flat':
					return 'Appartement'
				case 'land':
					return 'Terrain'
				case 'industrial_commercial_premise':
					return 'Local industriel. commercial ou assimilé'
				default:
					break;
			}
		},

	},
	
	components: { ImagePreview  }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pan-ancien-offre
	width PaneLeftSize

.cs-transaction-id
	position: absolute
	left: 50px
	top 10px

.cs-transaction-price
	text-align: right
	position: absolute
	right: 20px
	top 10px
	font-weight 600

.cs-transaction-year
	position: absolute
	left: 150px
	top 10px

.cs-transaction-type
	position: absolute
	left: 200px
	top 10px

.cs-transaction-parcel
	top 34px
	left: 50px

.cs-collection-card-header
	border-bottom 1px solid #EFEFEF
	height 60px

.cs-widget-collection-card .cs-collection-data-tag
	border-radius 20px
	font-size .813rem
	padding 7px 10px
	color #fff
	display inline-block

.cs-collection-card-content
	padding-top 10px
	padding-bottom 10px
	border-bottom 1px solid #EFEFEF

.cs-collection-data-row
	padding-top 10px

.cs-collection-detail-link
	left 220px
	width 100%
	padding 16px 0

.maison
	background-color rgb(64, 137, 0)
.appartement
	background-color rgb(6, 129, 175)
.terrain
	background-color rgb(255, 167, 38)
.dependance
	background-color rgb(255, 23, 19)
.local
	background-color rgb(102, 0, 229)

.icone_parcelle
	font-size 1.2rem
	position absolute
	top 2px

.parcelle
	background #c72a2a
	height 24px
	width 150px
	padding-left 5px
	padding-right  5px
	padding-top 5px
	padding-bottom 5px
	border-radius 3px
	border 1px
	color #fff
	margin-right 5px
	margin-top 5px
	-webkit-transition .2s ease-in-out
	transition .2s ease-in-out

.unmodule
	height auto !important
	width PaneLeftSize !important







.cz
	position absolute
	right 4px !important
	top 6px !important

.program
	border-right: 4px solid white;
	border-top 1px solid #eee
	background #FFF
	&.inactif
		.program-main
			//background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		border-right 4px solid lighten(primaryA100,92%) !important
		&:hover
			.program-main
				background lighten(primaryA100,92%)
		.program-main
			background lighten(primaryA100,92%) !important
			.program-content
				.name, .prix
					color palette2 !important
	&:hover
		border-right 4px solid hsl(153deg 48% 49%)
	//.program-main
		//background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.cz
			display none !important
		.program-main
			background #fff
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller, .prix
					color #fff !important
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 100%
						background #eee
				.forme-urbaine
					display none !important
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		//background #fff
		padding-top 8px
		padding-bottom 8px
		padding-right 4px
		padding-left 8px

		overflow hidden
		cursor pointer
		.image
			position absolute
			height 90px
			width 112px
			border-radius 12px
			overflow hidden
			background #eee url(../../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 120px
			.name
				color #333 !important
				font-weight 700
				height 24px
				font-size 0.9rem
				line-height 24px
				white-space nowrap
				width 170px
				text-overflow ellipsis
				overflow hidden
			.city, .address
				font-size 12px
			.seller
				color #333 !important
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				width 170px
				text-overflow ellipsis
				overflow hidden
				white-space nowrap
				line-height 24px
			.forme-urbaine
				position absolute
				top 40px
				right 0px
				color #999
		.program-content_pc
			margin-left 10px
			.name
				color #333 !important
				font-weight 600
				height 24px
				font-size 13px
				line-height 24px
				white-space nowrap
				width 290px
				text-overflow ellipsis
				overflow hidden
			.city, .address
				font-size 12px
			.seller
				color #333 !important
				margin 8px 0 0 0
				font-size 12px
				width 270px
				text-overflow ellipsis
				overflow hidden
				white-space nowrap
				line-height 24px
			.forme-urbaine
				position absolute
				top 40px
				right 0px
				color #999
	
		.price
			position absolute
			left 10px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

	.program-detail
		overflow hidden
		background #fff
		height auto
		&.detail-enter, &.detail-leave-to
			height 430px
			background-color red
		.program-detail-content
			padding 16px
			.resume
				margin 8px 0 0 0
				&:first-child
					margin 0
				&:after
					content ''
					display block
					clear both
				.resume-data
					float left
					width 33.3333%
					margin 0 0 8px 0
					.label
						font-size 10px
						text-transform uppercase
						color #999
					.value
						font-weight 500
			.program-detail-info
				padding 8px 0
				.program-detail-info-filters
					margin 0 0 8px 0
				.program-detail-info-content
					padding 8px 0
					background #f5f5f5
					border-radius radius
					overflow hidden
					table
						width 100%
						border-collapse collapse
						tr
							border-bottom 1px solid #eee
							&:last-child
								border none
							&:hover
								td
									background #eee
							td, th
								padding 4px 8px
							th
								font-size 12px
								font-weight 500
							td
								text-align center
								&:first-child
									text-align left
									font-size 12px
									font-weight 500
									padding-left 16px
			.actions
				margin 8px 0 0 0
				.action
					display inline-block
					margin 0 24px 0 0
					height 32px
					color #666
					font-size 12px
					font-weight 500
					line-height @height
					cursor pointer
					&:hover
						color #333
					.icon
						float left
						margin 4px 8px 0 0


.prix
	text-align right
	font-weight 700
	color #333
	font-size 0.9rem

.sp
	padding 5px 6px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 10px
	line-height 10px
	color: var(--primary-color-text);
	margin-right 2px
	height 20px
	left 0
	bottom 10px
	background #3b82f6
	box-sizing border-box
	border-radius 20px

.BRS
	width 36px
.PSLA
	width 42px
.tvaR
	width 34px
.sansp
	padding 5px 6px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 10px
	line-height 10px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
	margin-right 2px
	height 20px
	left 0
	bottom 0px
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	width 84px
.vide
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	font-size 12px
	line-height 16px
	letter-spacing 0.4px

.sperso {
	margin: 0px !important;
    padding-bottom: 4px !important;
    padding-top: 2px !important;
}

@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none




@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
