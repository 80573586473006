<template>
  <div class="loadingio-spinner-ripple-iptjapjier">
    <div class="ldio-2n2m4u8jfaq">
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Loader',
  props: {
    color: { default: 'green' }
  }
}

</script>

<style type="text/css">
@keyframes ldio-2n2m4u8jfaq {
  0% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 9px;
    left: 9px;
    width: 78px;
    height: 78px;
    opacity: 0;
  }
}

.ldio-2n2m4u8jfaq div {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-2n2m4u8jfaq 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ldio-2n2m4u8jfaq div:nth-child(1) {
  border-color: #ffe9ff
}

.ldio-2n2m4u8jfaq div:nth-child(2) {
  border-color: #ffe9ff;
  animation-delay: -0.5s;
}

.loadingio-spinner-ripple-iptjapjier {
  width: 36px;
  height: 36px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-2n2m4u8jfaq {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.36);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-2n2m4u8jfaq div {
  box-sizing: content-box;
}

</style>