<template>
	<div class="switch-button-dpe" :class="divise">
		<div class="switch-button-content">
			<icon v-if="icon" :file="icon" :size="size" /><p class="name">{{ label }}</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'SwitchButtonDpe',
	data: function() {
		return {

		}
	},

	props: {
		icon: { default: '' },
		size: { default: 32 },
		label: { default: '' },
		divise: { default: 'two' }
	},

	computed: Object.assign(mapGetters([ 'app' ]), {

	}),
	
	methods: {
		
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.switch-button-dpe
	float left
	padding 0 0 8px 8px
	height 32px
	&:hover
		.switch-button-content
			background darken(light, 5%)
			&.active
				background palette0
			&.inactive
				background #ccc
			
	&.active
		.switch-button-content
			background primaryA400
			color #fff
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
	&.small
		.switch-button-content
			height 48px
			.name
				margin 0
				font-size 14px
	.switch-button-content
		height 40px
		display flex
		flex-direction column
		align-items center
		justify-content center
		cursor pointer
		text-align center
		font-size 12px
		color #666
		border-radius radius
		background none
		.name
			margin 4px 0 0 0
			font-weight 600
			font-family volte, sans-serif
			line-height 14px
.two
	width 50%
.three
	width 33%
.four
	width 25%
.five
	width 20%
.six
	width 16.6%
.seven
	width 14.25%
.eight
	width 12.5%

.etoile_NC
	&.active
		.switch-button-content
			background black
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.etoile_1
	&.active
		.switch-button-content
			background rgb(255, 204, 0)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.etoile_2
	&.active
		.switch-button-content
			background rgb(224, 224, 0)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.etoile_3
	&.active
		.switch-button-content
			background rgb(181, 226, 47)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
.etoile_4
	&.active
		.switch-button-content
			background rgb(51, 204, 51)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
.etoile_5
	&.active
		.switch-button-content
			background rgb(50, 153, 50)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
																				

.eight_A
	width 12.5%
	&.active
		.switch-button-content
			background rgb(50, 153, 50)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
	
.eight_B
	width 12.5%
	&.active
		.switch-button-content
			background rgb(51, 204, 51)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff
	
.eight_C
	width 12.5%
	&.active
		.switch-button-content
			background rgb(181, 226, 47)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.eight_D
	width 12.5%
	&.active
		.switch-button-content
			background rgb(224, 224, 0)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.eight_E
	width 12.5%
	&.active
		.switch-button-content
			background rgb(255, 204, 0)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.eight_F
	width 12.5%
	&.active
		.switch-button-content
			background rgb(255, 154, 51)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.eight_G
	width 12.5%
	&.active
		.switch-button-content
			background rgb(255, 0, 0)
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

.eight_N
	width 12.5%
	&.active
		.switch-button-content
			background black
	&.inactive
		.switch-button-content
			background #ccc
			color #fff

</style>
