import { render, staticRenderFns } from "./PanePrograms.vue?vue&type=template&id=1ce27f80&scoped=true"
import script from "./PanePrograms.vue?vue&type=script&lang=js"
export * from "./PanePrograms.vue?vue&type=script&lang=js"
import style0 from "./PanePrograms.vue?vue&type=style&index=0&id=1ce27f80&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce27f80",
  null
  
)

export default component.exports