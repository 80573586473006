<template>
	<div class="user">
		<div class="help-button" @click="chat()" :title="$t('aide')"><icon file="help-circle" :size="40" /></div>
		<div class="user-button" @click.stop="show()" :title="$t('mon_compte')">{{user.initial}}</div>
		<div v-show="visible" class="user-menu">
			<div class="link"  @click="$store.dispatch('set_view', { view: 'compte' })">{{ $t('mon_compte') }}</div>
			<div class="link">{{ $t('contact') }}</div>
			<div class="link" @click="signout()">{{ $t('deconnexion') }}</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Bus } from '../../Bus'

export default {
	name: 'User',
	data () {
		return {
			visible: false
		}
	},
	computed: Object.assign(mapGetters([ 'user' ]), {
		
	}),
	methods: {
		toggle_compte: function() {

		},

		show: function() {
			this.visible = !this.visible
		},
		signout: function() {
			this.visible = false
			this.$store.dispatch('signout')
		},
		chat: function() {
			Tawk_API.toggle()
		}
	},
	mounted: function() {
		let that = this
		Bus.$on('global-click', function() {
			that.visible = false
		});
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.user
	position absolute
	right 16px
	top 16px
	padding-left 44px
	.help-button
		position absolute
		left 0
		top 0
		color lighten(green, 10%)
		cursor pointer
		transition 0.25s easeOutQuart
		&:hover
			color blue
	.user-button
		width 40px
		height 40px
		background lighten(green, 10%)
		color light
		font-weight 600
		border-radius 100%
		text-align center
		line-height @height
		cursor pointer
		&:hover
			background-color blue
	.user-menu
		position absolute
		top 56px
		right 20px
		transform translateX(50%)
		width 160px
		background #fff
		padding 8px 0
		border-radius radius
		box-shadow 0 0 4px alpha(#000, 50%)
		color #666
		z-index 1001
		&:before
			content ''
			position absolute
			top -8px
			left calc(50% - 8px)
			width 0
			height 0
			border-style solid
			border-width 0 8px 8px 8px
			border-color transparent transparent #fff transparent
		.link
			height 32px
			padding 0 16px
			line-height @height
			cursor pointer
			&:hover
				color blue
				background light

</style>
