<template>
	<div class="stat-box" id="rs_repa_tr">
		<div class="stat-box-header">
			<p class="title">{{ $t('analyse_des_csp') }} {{ app.adr_init.territoire }}</p>
			<span class="tabs">
				<a id="export09" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'rs_repa_tr', exportid: 'export09'});"><span><icon file="image" /></span></a>
			</span>
		</div>
		<div class="stat-box-content">
			<div class="row">
				<div class="col-one">
					<div class="graph">
						{{ $t('repartition_des_csp') }}
						<highcharts :options="chartOptions_men_tranche"></highcharts>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

export default {
	name: 'GraphEvolutionInseeAge',

	data: function() {
		return {
			chartOptions_men_tranche: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'pie',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [{data: [	{name: 'Agriculteur', y: 0},{name: 'Entrepreneur', y: 0},{name: 'Sans activité', y: 0},{name: 'Sénior', y: 0},{name: 'Employé', y: 0},{name: 'Travailleur indépendant', y: 0},{name: 'Professions libérales', y: 0},{name: 'Retiré', y: 0}],}],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_men_tranche_gain: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					}
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {

		maj_age: function() {
			if (this.app.livedata.insee && this.app.livedata.insee.length) {
				this.chartOptions_men_tranche.series[0].data = [
					{name: 'Agriculteur', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.farmer_rate},
					{name: 'Entrepreneur', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.entrepreneur_rate},
					{name: 'Sans activité', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.without_activity_rate},
					{name: 'Sénior', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.senior_executive_rate},
					{name: 'Employé', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.employee_rate},
					{name: 'Travailleur indépendant', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.worker_rate},
					{name: 'Professions libérales', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.intermediaite_profession_rate},
					{name: 'Retiré', y: this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].employment.psc.retired_rate}
				]
			}
		},

		maj_gain: function() {			
			// Nothing
		}
	},

	mounted: function() {
		this.maj_age()
		this.maj_gain()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj_age()
				this.maj_gain()
			}
		},
	},
	
	components: { highcharts: Chart }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'

.stat-box
	.stat-box-content
		padding 0 16px
		text-align center

.graph
	min-height 200px
	text-align center
	line-height @height

</style>
