<template>
	<div class="part">
		<div class="part-title">{{ $t('marche_du_locatif') }} <span>{{app.locatif.loyer_yanport_geo[geo].label}} ({{app.locatif.loyer_yanport_geo[geo].type_label}})</span>
		</div>
		<div class="part-content">
			<div class="row">
						<!--
				<div class="col">
					<div class="business-dashboard company-dashboard">
						<div class="dashboard-card is-base-chart" v-if="geo=='cityIds' && app.insee[0].data[app.insee[0].data.length-1].household.total_household" id="tension_locatif">
							<div class="content-box">
								{{ $t('Indicateur tension de l\'offre locative') }}: <span style="font-size: 18pt;font-weight:600; color:rgb(139, 142, 240)">{{Math.round((app.insee_offre_locative*100000) / app.insee[0].data[app.insee[0].data.length-1].household.total_household)/100}}</span> <span style="color:rgb(139, 142, 240);font-style: italic;">{{ $t('logs. à l’offre pour 1000 ménages') }}</span>
								<span style="position: absolute;	right: 24px; top: 24px;"><a id="export03" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'tension_locatif', exportid: 'export03'});"><span><icon file="image" /></span></a></span>
							</div>
						</div>
						<div style="width:1%" v-if="geo=='cityIds' && app.insee[0].data[app.insee[0].data.length-1].household.total_household">&nbsp;</div>
						<div class="dashboard-card is-base-chart" id="volume_logement_locatif">
							<div class="content-box">
								{{ $t('volumes_de_logements_locatifs_libres_sur_le_marche') }}<br/><br/><span style="position: absolute; right: 24px; top: 24px;"><a id="export003" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'volume_logement_locatif', exportid: 'export003'});"><span><icon file="image" /></span></a></span>
								<div class="revenue-stats is-dark-bordered-12">
									<div class="revenue-stat">
										<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].x.substring(0, 4)}}</span>
										<span class="dark-inverted">{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].y.count}}</span>
									</div>
									<div class="revenue-stat">
										<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].x.substring(0, 4)}}</span>
										<span class="dark-inverted">{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].y.count}}</span>
									</div>
									<div class="revenue-stat">
										<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].x.substring(0, 4)}}</span>
										<span class="dark-inverted">{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].y.count}}</span>
									</div>
								</div>
							</div>
							
							<div class="chart-container">
								<apexchart									
									:height="sharingOptions.chart.height"
									:type="sharingOptions.chart.type"
									:series="sharingOptions.series"
									:options="sharingOptions"
									>
								</apexchart>
							</div>
						</div>
						<div style="width:1%">&nbsp;</div>
						<div class="business-dashboard company-dashboard">
							<div class="dashboard-card is-base-chart" :id="'loyer_logement_locatif_' + geo">
								<div class="content-box">
									{{ $t('evolution_des_loyers_libres_hors_charges/_m²_hab') }}<br/><br/><span style="position: absolute;	right: 24px; top: 24px;"><a id="export04" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'loyer_logement_locatif_' + geo, exportid: 'export04'});"><span><icon file="image" /></span></a></span>
									<div class="revenue-stats is-dark-bordered-12">
										<div class="revenue-stat">
											<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].x.substring(0, 4)}}</span>
											<span class="dark-inverted2" v-if="app.loyer_yanport && app.locatif.loyer_yanport_geo[geo].values && app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3]">{{getPrice(app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].y.values["50.0"])}}</span>
										</div>
										<div class="revenue-stat">
											<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].x.substring(0, 4)}}</span>
											<span class="dark-inverted2" v-if="app.loyer_yanport && app.locatif.loyer_yanport_geo[geo].values && app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2]">{{getPrice(app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].y.values["50.0"])}}</span>
										</div>
										<div class="revenue-stat">
											<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].x.substring(0, 4)}}</span>
											<span class="dark-inverted2" v-if="app.loyer_yanport && app.locatif.loyer_yanport_geo[geo].values && app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1]">{{getPrice(app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].y.values["50.0"])}}</span>
										</div>
									</div>
								</div>
								<div class="chart-container">
									<apexchart									
										:height="usersOptions.chart.height"
										:type="usersOptions.chart.type"
										:series="usersOptions.series"
										:options="usersOptions"
										>
									</apexchart>
								</div>
							</div>
							<div style="width:1%">&nbsp;</div>
							<div class="business-dashboard company-dashboard" :id="'pub_logement_locatif_' + geo">
								<div class="dashboard-card is-base-chart">
									<div class="content-box">
										{{ $t('evolution_des_durees_de_publication_(en_jours)') }}<br/><br/><span style="position: absolute;	right: 24px; top: 24px;"><a id="export05" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'pub_logement_locatif_' + geo, exportid: 'export05'});"><span><icon file="image" /></span></a></span>
										<div class="revenue-stats is-dark-bordered-12">
											<div class="revenue-stat">
												<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].x.substring(0, 4)}}</span>
												<span class="dark-inverted2" v-if="app.loyer_yanport && app.locatif.loyer_yanport_geo[geo].duration_values && app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3] && !(app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].y.values['50.0']=='NaN')">{{app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-3].y.values["50.0"].toFixed(0)}} j</span><span v-else>-</span>
											</div>
											<div class="revenue-stat">
												<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].x.substring(0, 4)}}</span>
												<span class="dark-inverted2" v-if="app.loyer_yanport && app.locatif.loyer_yanport_geo[geo].duration_values && app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2] && !(app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].y.values['50.0']=='NaN')">{{app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-2].y.values["50.0"].toFixed(0)}} j</span><span v-else>-</span>
											</div>
											<div class="revenue-stat">
												<span>{{app.locatif.loyer_yanport_geo[geo].values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].x.substring(0, 4)}}</span>
												<span class="dark-inverted2" v-if="app.loyer_yanport && app.locatif.loyer_yanport_geo[geo].duration_values && app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1] && !(app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].y.values['50.0']=='NaN')">{{app.locatif.loyer_yanport_geo[geo].duration_values.points[app.locatif.loyer_yanport_geo[geo].values.points.length-1].y.values["50.0"].toFixed(0)}} j</span><span v-else>-</span>
											</div>
										</div>
									</div>
									<div class="chart-container">
										<apexchart									
											:height="usersOptionsJour.chart.height"
											:type="usersOptionsJour.chart.type"
											:series="usersOptionsJour.series"
											:options="usersOptionsJour"
											>
										</apexchart>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
						-->
						<div class="col full"><!--<div class="col half">-->
					<div class="conjoncture" v-if="app.locatif.loyer_yanport_geo[geo].values">
						<div class="business-dashboard company-dashboard">
							<div class="dashboard-card is-base-chart">
								<div class="content-box" style="padding-bottom:0px !important;">
									{{ $t('details_par_typologie') }} *
									<span style="position: absolute; right: 24px; top: 24px;"><a id="export06" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'locatif_typo', exportid: 'export06'});"><span><icon file="image" /></span></a></span>
								</div>
								<div class="content-box padleft padtop">
									<div class="synoptique p-datatable p-component p-datatable-responsive-scroll p-datatable-striped p-datatable-gridlines p-datatable-grouped-header">
										<div class="p-datatable-wrapper">
											<table role="table" class="p-datatable-table">
												<thead class="p-datatable-thead">
													<tr role="row">
														<th class="p-sortable-column"><div class="p-column-header-content"><span class="p-column-title">{{ $t('type') }}</span></div></th>
														<th class="p-sortable-column"><div class="p-column-header-content"><span class="p-column-title">{{ $t('surface') }}</span></div></th>
														<th class="p-sortable-column"><div class="p-column-header-content"><span class="p-column-title">{{ $t('prix/m²_hc') }}</span></div></th>
														<!--<th class="p-sortable-column"><div class="p-column-header-content"><span class="p-column-title">{{ $t('duree_de_publication') }}</span></div> </th>-->
														<th class="p-sortable-column"><div class="p-column-header-content"><span class="p-column-title">{{ $t('annonces') }}</span></div> </th>
													</tr>
												</thead>
												<tbody class="p-datatable-tbody">
													<tr v-for="i in 5" v-bind:key="i" role="row">
														<td style="text-align: left;padding: 5px;">
															<div>{{i}}P</div>													
														</td>
														<td style="text-align: right;padding: 5px;" v-if="app.locatif.yanport_typo_ref_geo[geo] && app.locatif.yanport_typo_ref_geo[geo][i] && app.locatif.yanport_typo_ref_geo[geo][i].values"><div>
															{{SansNa(Math.round(app.locatif.yanport_typo_ref_geo[geo][i].values["50.0"]), $t('m²'))}}
														</div>
														</td><td v-else><div>&nbsp;</div></td>														
														<td  style="text-align: right;padding: 5px;" v-if="app.locatif.yanport_typo_prix_geo[geo][i] && app.locatif.yanport_typo_prix_geo[geo][i].values"><div>
															{{SansNa(Math.round(app.locatif.yanport_typo_prix_geo[geo][i].values["50.0"]*100)/100, $t('€/m²'))}}
														</div></td>
														<td v-else><div>&nbsp;</div></td>
														<!--<td  style="text-align: right;padding: 5px;" v-if="app.locatif.yanport_typo_jour_geo[geo]"><div v-if="app.locatif.yanport_typo_jour_geo[geo][i] && app.locatif.yanport_typo_jour_geo[geo][i].values">{{Math.round(app.locatif.yanport_typo_jour_geo[geo][i].values["50.0"])}} {{ $t('jours') }} &nbsp;</div></td>
														<td v-else><div>&nbsp;</div></td>-->
														<td  style="text-align: right;padding: 5px;" v-if="app.locatif.yanport_typo_ref_geo[geo]"><div v-if="app.locatif.yanport_typo_ref_geo[geo][i] && app.locatif.yanport_typo_ref_geo[geo][i].count">{{SansNa(app.locatif.yanport_typo_ref_geo[geo][i].count, $t('biens'))}}</div></td>
														<td v-else><div>&nbsp;</div></td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="part-aste">* {{ $t('donnees_sur_12_mois_glissants') }}</div>
								</div>
							</div>
						</div>
						<br/>
						<div class="business-dashboard company-dashboard">
							<div class="dashboard-card is-base-chart">
								<div class="content-box" style="padding-bottom:0px !important;">{{ $t('evolution_des_prix') }}</div>
								<div class="responsive-box" :id="'GraphPrix_' + geo">
									<apexchart :ref="'GraphPrix_' + geo" class="GraphPrix" type="line" height="100%" width="100%" :options="chartOptions2" :series="Prix.series"></apexchart>
								</div>
							</div>
						</div>
						<!--
						<br/>
						<div class="business-dashboard company-dashboard">
							<div class="dashboard-card is-base-chart" v-if="app.loyer_yanport_time == 5">
								<div class="content-box" style="padding-bottom:0px !important;">{{ $t('evolution_des_moyennes_de_publication') }}</div>
								<div class="responsive-box" :id="'GraphDuree_' + geo">
									<apexchart :ref="'GraphDuree_' + geo" type="line" height="100%" width="100%" :options="chartOptions2" :series="Duree.series"></apexchart>
								</div>
							</div>
						</div>
						-->
					</div>
				</div>					
			</div>
			<div class="row">
				<div id="locatif_dash" class="col full tab-content business-dashboard company-dashboard" style="padding-top: 0px !important;">
					<div class="business-dashboard company-dashboard" style="">
						<div class="part-title">{{ $t('distribution_des_loyers') }}</div>
						<div class="company-header is-dark-card-bordered is-dark-bg-6" style="padding: 2px !important;">
							<div :id="'heatmap_' + geo" class="responsive-box">
								<apexchart :id="heatmap" type="heatmap" height="100%" width="100%" :options="chartOptions" :series="series"></apexchart>
							</div>
						</div>				
					</div>			
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { i18n } from '../../../main';

export default {
	name: 'GeoLocatif',

	data: function() {
		return {
			unan: 0,
			deuxans: 0,

			chartOptions: {
				chart: {
					type: 'heatmap',
				},
				dataLabels: {
					enabled: false
				},
				colors: ["#008FFB"],
				xaxis: {
					type: 'category',
					categories: []
				},
			},
			series: [],
			options : {
				chart: {
					pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
					height: 400,
					type: 'line',
					stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: [4, 4, 4, 4, 4],
					curve: 'smooth'
				},
				xaxis: {
					categories: [2014, 2015, 2016],
				},
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft',
						offsetY: 30,
						offsetX: 60
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				},
				series: [],
				title: {
					text: 'Ajax Example',
				},
				noData: {
					text: 'Loading...'
				}
				},		
			Prix: {
				series: [{
					name: 'T1',
					type: 'line',
					data: [2.8, 3.8, 4.6]
				}, {
					name: 'T2',
					type: 'line',
					data: [4.9, 6.5, 8.5]
				}, {
					name: 'T3',
					type: 'line',
					data: [3, 4, 6.5]
				}, {
					name: 'T4',
					type: 'line',
					data: [1.1, 3.1, 4.1]
				}, {
					name: 'T5',
					type: 'line',
					data: [4, 2, 6]
				}],
				chartOptions: {
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: [2022,2023,2024],
					},					
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft',
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				},
			},
			Duree: {
				series: [{
					name: 'T1',
					type: 'line',
					data: [2.8, 3.8, 4.6]
				}, {
					name: 'T2',
					type: 'line',
					data: [4.9, 6.5, 8.5]
				}, {
					name: 'T3',
					type: 'line',
					data: [3, 4, 6.5]
				}, {
					name: 'T4',
					type: 'line',
					data: [1.1, 3.1, 4.1]
				}, {
					name: 'T5',
					type: 'line',
					data: [4, 2, 6]
				}],				
				chartOptions: {
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: [2014, 2015, 2016],
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft',
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				},
			},
			usersOptions : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
					height: 130,
					type: 'area',
					sparkline: {
						enabled: true,
					},
					toolbar: {
						show: false,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true | '<img src="/static/icons/reset.png" width="20">',
							customIcons: []
						},
						export: {
							csv: {
								filename: undefined,
								columnDelimiter: ',',
								headerCategory: 'category',
								headerValue: 'value',						
							},
							svg: {
								filename: undefined,
							},
							png: {
								filename: undefined,
							}
						},
						autoSelected: 'zoom' 
					},			},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					grid: {
						show: false,
						padding: {
							left: 0,
							right: 0,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: [2],
						curve: 'smooth',
					},
					xaxis: {
						type: 'numeric',
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
					tooltip: {
						x: {
							show: false,
							format: 'dd/MM/yy HH:mm',
						},
					},
				},
				usersOptionsJour : {
					series: [
					{
						name: 'Loyers médians',
						data: [15.48, 15.79, 16.2],
					},
					],
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						height: 130,
						type: 'area',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					grid: {
						show: false,
						padding: {
							left: 0,
							right: 0,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: [2],
						curve: 'smooth',
					},
					xaxis: {
						type: 'numeric',
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
					tooltip: {
						x: {
							show: false,
							format: 'dd/MM/yy HH:mm',
						},
					},
				},
				options : { /* eslint-disable-line */
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						style: {
							fontFamily: 'Nunito',
						},
						toolbar: {
							show: true,
							offsetX: 0,
							offsetY: 0,
							tools: {
								download: true,
								selection: false,
								zoom: false,
								zoomin: false,
								zoomout: false,
								pan: false,
								reset: false | '<img src="/static/icons/reset.png" width="20">',
								customIcons: []
							}
						},
						height: 350,
						type: "line",
						stacked: false
					},
					dataLabels: {
						enabled: false
					},
					colors: ['#99C2A2', '#C5EDAC', '#66C7F4'],
					series: [
					
					{
						name: i18n.t('annonces'),
						type: 'column',
						data: [17,27,32]
					},
					{
						name: i18n.t('loyer'),
						type: 'line',
						data: [15.8, 14.78, 14.84]
					},
					],
					stroke: {
						width: [4, 4, 4]
					},				
					plotOptions: {
						bar: {
							columnWidth: "20%"
						}
					},
					xaxis: {
						categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
					},
					yaxis: [
					{
						seriesName: 'Column A',
						axisTicks: {
							show: true
						},
						axisBorder: {
							show: true,
						},
						title: {
							text: i18n.t('annonces')
						}
					},
					{
						opposite: true,
						seriesName: 'Line C',
						axisTicks: {
							show: true
						},
						axisBorder: {
							show: true,
						},
						title: {
							text: i18n.t('loyer')
						}
					}
					],
					tooltip: {
						shared: false,
						intersect: true,
						x: {
							show: false
						}
					},
					legend: {
						horizontalAlign: "left",
						offsetX: 40
					}
				},
				
				sharingOptions: {
					series: [
					{
						name: i18n.t('annonces'),
						data: [],
					},
					],
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						height: 130,
						type: 'bar',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					plotOptions: {
						bar: {
							columnWidth: '50px',
							distributed: true,
							endingShape: 'rounded',
						},
					},
					dataLabels: {
						enabled: false,
					},
					legend: {
						show: false,
					},
					xaxis: {
						categories: [2022,2023,2024],					
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
				},
				
				chartOptions_trim: {
					title: '',
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						height: 280,
						type: 'areaspline',
						style: {
							fontFamily: 'Nunito',
						}
					},
					series: [
					{
						color: '#FFD501',
						name: i18n.t('annonces'),
						data: [0,0,0,0],
						type: 'column',
						yAxis: 0,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
							[0, '#FFD501'],
							[1, 'transparent']
							]
						},
						marker: {
							symbol: 'square',
							enabled: false
						}
					},{
						color: '#048855',
						name: "Loyers",
						data: [0,0,0,0],
						yAxis: 1,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
							[0, 'rgba(4,136,85,0.3)'],
							[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					
					],
					xAxis: {
						type : 'category',
						categories : ['', '', ''],
						title: {
							text: ''
						},
						allowDecimals: false,
						lineWidth: 1,
						lineColor: '#ddd',
					},
					yAxis: [
					{
						title: {
							text: i18n.t('annonces')
						},
						allowDecimals: true,
						lineWidth: 0,
						gridLineColor: '#ddd',
						gridLineDashStyle: 'dash',
						labels: {
							format: '{value}'
						},
						opposite: true
					},{
						title: {
							text: 'Loyer H.C.'
						},
						allowDecimals: true,
						lineWidth: 0,
						gridLineColor: '#ddd',
						gridLineDashStyle: 'dash',
						labels: {
							format: '{value:,.0f} €'
						}
					},
					],
					plotOptions: {
						series: {
							label: {
								connectorAllowed: false
							},
						}
					},
					tooltip: {
						shared: true,
						crosshairs: true,
						backgroundColor: '#fff',
						borderWidth: 0,
						borderRadius: 4
					},
				},
				
				reference : {
					name:'Logement T3', 
					typeId:2, 
					rooms: 3, 
					buildingConstruction: 7, 
					condition: 3, 
					parkingOpen: 1, 
					area: 60
				},

				chartOptions2 : {
					chart: {
						pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					colors: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#8f070b'],

					stroke: {
						width: [4, 4, 4, 4, 4, 4],
						curve: 'smooth',
						dashArray: [0, 0, 0, 0, 0, 10]
					},
					xaxis: {
						categories: [],
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft',
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				}
			}
	},
	
	props: [ 'heatmap', 'geo' ],

	computed: Object.assign(mapGetters([ 'app' ]), {
		getId :function(){
			return this.heatmap
		},

		getAnnee:function(){
			let chartOptions2 = {
				chart: {
					height: 400,
					type: 'line',
					stacked: false,
					toolbar: {
						show: false,
					},
					pan : {
							enabled: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
					dropShadow: {
						enabled: true,
						color: '#000',
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2
					},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: [4, 4, 4, 4, 4],
					curve: 'smooth'
				},
				xaxis: {
					categories: getYears(),
				},
				yaxis: [
				{
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
					},
					tooltip: {
						enabled: true
					}
				},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', 
						offsetY: 30,
						offsetX: 60
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				}
			}
			return chartOptions2
		}
	}),
	methods: {
		SansNa: function(v, s){
			if (isNaN(v)) return "-"			
			return v + ' ' + s;
		},
		GetT(typo) {
			let t=[]
			if (this.app.locatif && this.app.locatif.loyer_tranches_typo_geo && this.app.locatif.loyer_tranches_typo_geo[this.geo] && this.app.locatif.loyer_tranches_typo_geo[this.geo][typo]) {
				if (this.app.locatif.loyer_tranches_typo_geo[this.geo][typo]) {
					if (this.app.locatif.loyer_tranches_typo_geo[this.geo][typo].points) {
						for (let index = 0; index < this.app.locatif.loyer_tranches_typo_geo[this.geo][typo].points.length-1; index++) {
							const element = this.app.locatif.loyer_tranches_typo_geo[this.geo][typo].points[index];
							t.push(element.y.count)
						}
					}
				}
			}
			return t
		},

		onVisibilityChange(isVisible) {			
			if (isVisible) {
				this.activeSection2 = 'taboptim'
			} else {
				if (this.activeSection2 == 'taboptim') {
					this.activeSection2 = ''
				}
			}
		},

		getYears: function() {
			let v = [];
			for (let index = 0; index < 5; index++) {
				v.push(parseInt(this.app.loyer_yanport_date[index]))
			}
			return v;
		},

		getTitreLocatif: function() {
				if (this.app.locatif.loyer_yanport_geo[this.geo]  && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[0]) return { titre: { title: i18n.t('type') }, annee_1: { title: this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].x.substring(0, 4) }, annee_2: { title: this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].x.substring(0, 4) }, annee_3: { title: this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].x.substring(0, 4) }}
				else return { titre: { title: i18n.t('type') }, annee_1: { title: '' }, annee_2: { title: '' }, annee_3: { title: '' }}
			},
			getPrice: function(p) {
				if (p=="NaN") {
					return "-"
				} else {
					return Math.round(p*100)/100 + " €"
				}
			},
			getJsonLocatif: function() {
				function getPrice(p) {
					if (p=="NaN") {
						return "-"
					} else {
						return Math.round(p*100)/100 + " €"
					}
				}
				
				function vall(s, index, x, n) {
					let r=''
					if (s) {
						if (s[index]) {
							if (s[index]) {
								if (s[index].points[x]) {
									if (s[index].points[x].y) {
										if (s[index].points[x].y.values["50.0"]) {
											if (typeof s[index].points[x].y.values["50.0"] == 'number') {
												r = s[index].points[x].y.values["50.0"].toFixed(n)
											}
										}
									}
								}
							}
						}
					}
					return r 
				}
				
				let r = []
				let v1
				let v2
				let v3
				v1 = ''; v2 = ''; v3 = ''
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3]) v1 = getPrice(this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"])
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2]) v2 = getPrice(this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"])
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1]) v3 = getPrice(this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"])
				r.push({titre: 'Loyers libres hors charges €/ m² hab', annee_1: v1, annee_2: v2, annee_3: v3})
				v1 = ''; v2 = ''; v3 = ''
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3]) v1 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.count
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2]) v2 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.count
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1]) v3 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count
				r.push({titre: 'Volumes de logements locatifs libres sur le marché', annee_1: v1, annee_2: v2, annee_3: v3})
				v1 = ''; v2 = ''; v3 = ''
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].duration_values && this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3] && !(this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values['50.0']=='NaN')) v1 = this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"].toFixed(0) + ' j'
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].duration_values && this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2] && !(this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values['50.0']=='NaN')) v2 = this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"].toFixed(0) + ' j'
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].duration_values && this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1] && !(this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values['50.0']=='NaN')) v3 = this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"].toFixed(0) + ' j'
				r.push({titre: i18n.t('durees_de_publication_(en_jours)'), annee_1: v1, annee_2: v2, annee_3: v3})
				
				for (let index = 0; index < 5; index++) {
					v1 = vall(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index, this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3, 2)
					v2 = vall(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index, this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2, 2)
					v3 = vall(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index, this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1, 2)
					r.push({titre: 'Loyers libres hors charges €/ m² hab - T' + (index+1), annee_1: v1, annee_2: v2, annee_3: v3})
				}
				
				for (let index = 0; index < 5; index++) {
					v1 = vall(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index, this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3, 0)
					v2 = vall(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index, this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2, 0)
					v3 = vall(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index, this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1, 0)
					r.push({titre: i18n.t('durees_de_publication_(en_jours)') + ' - T' + (index+1), annee_1: v1, annee_2: v2, annee_3: v3})
				}
				
				return r
			},

			isNumber: function(evt) {
				evt = (evt) ? evt : window.event;
				let charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			
			updateSeriesLine: function(dataPrix, dataDuree, dataLoyer, dataCategories, cat) {
				this.series = dataLoyer
				let chartOptions= {
					chart: {
						type: 'heatmap',
						toolbar: {
							show: false
						}
					},
					dataLabels: {
						enabled: false
					},
					colors: ["#008FFB"],
					xaxis: {
						type: 'category',
						categories: dataCategories
					},
				}

				let v = [];
				for (let index = 0; index < 5; index++) {
					v.push(parseInt(this.app.loyer_yanport_date[index]))
				}
				v = cat
				let chartOptions2 = {
					chart: {
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false, // Désactive le zoom
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: v,
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				}

				this.chartOptions2 = chartOptions2

				this.chartOptions = chartOptions
				
				this.Prix.series = dataPrix
				this.Duree.series = dataDuree	
				this.v = []
				for (let index = 0; index < 5; index++) {
					this.v.push(parseInt(this.app.loyer_yanport_date[index]))
				}
			},

			maj_graph: function() {
				this.v = []
				for (let index = 0; index < 5; index++) {
					this.v.push(parseInt(this.app.loyer_yanport_date[index]))
				}
				let val0 = null
				let val1 = null
				let val2 = null
				if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].values && this.app.locatif.loyer_yanport_geo[this.geo].values.points[0]) {
					if (typeof this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"] == 'number') {
						val0 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"] 
					}
					if (typeof this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"] == 'number') {
						val1 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"] 
					}
					if (typeof this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"] == 'number') {
						val2 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"] 
					}
					
					
					let min = val0
					let max = val0
					
					if (val1 < min)
					min = val1
					
					if (val2 < min)
					min = val2
					
					if (val1 > max)
					max = val1
					
					if (val2 > max)
					max = val2
					if (val0) val0 = val0.toFixed(2)
					if (val1) val1 = val1.toFixed(2)
					if (val2) val2 = val2.toFixed(2)
					
					let _min = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.count
					let _max = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.count
					
					if (this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.count < _min)
					_min = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.count
					
					if (this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count < _min)
					_min = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count
					
					if (this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count > _max)
					_max = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.count
					
					if (this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count > _max)
					_max = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count
					
					if (max-min < 2) {
						max ++
						min --
						if (min<0)
						min = 0
					}
					
					// In the same way, update the series option
					this.sharingOptions.series = [{
						name: i18n.t('annonces'),
						data: [
						this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.count, 
						this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.count, 
						this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count
						]
					}]
					this.sharingOptions.xaxis.categories = [
						this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].x.substring(0, 4),
						this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].x.substring(0, 4),
						this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].x.substring(0, 4)]
					
					this.usersOptions.series = [{
						name: 'Loyers médians',
						data: [
						val0, val1, val2
						]
					}]
					let j_val0 = null
					let j_val1 = null
					let j_val2 = null
					if (this.app.locatif.loyer_yanport_geo[this.geo] && this.app.locatif.loyer_yanport_geo[this.geo].duration_values && this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points) {
						if (typeof this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"] == 'number') {
							j_val0 = this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"].toFixed(0)
						}
						if (typeof this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"] == 'number') {
							j_val1 = this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"].toFixed(0)
						}
						if (typeof this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"] == 'number') {
							j_val2 = this.app.locatif.loyer_yanport_geo[this.geo].duration_values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"].toFixed(0) 
						}
					}

					this.usersOptionsJour.series = [{
						name: 'Durée de publication',
						data: [
						j_val0, j_val1, j_val2
						]
					}]
					
					this.chartOptions_trim.yAxis[1].min = min
					this.chartOptions_trim.yAxis[1].max = max
					
					this.chartOptions_trim.yAxis[0].min = 0
					this.chartOptions_trim.yAxis[0].max = _max
					
					this.chartOptions_trim.series[1].data = [
					val0, 
					val1, 
					val2, 
					]
					this.chartOptions_trim.series[0].data = [
					this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.count, 
					this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.count, 
					this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.count, 
					]
					this.chartOptions_trim.xAxis.categories= [this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].x.substring(0, 4), this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].x.substring(0, 4), this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].x.substring(0, 4)]

					let fn;
					fn = function vall(s, index, x, n) {
						let r=null
						if (s) {
							if (s[index]) {
								if (s[index]) {
									if (s[index].points) {
										if (s[index].points[x]) {
											if (s[index].points[x].y) {
												if (s[index].points[x].y.values["50.0"]) {
													if (typeof s[index].points[x].y.values["50.0"] == 'number') {
														r = s[index].points[x].y.values["50.0"].toFixed(n)
													}
												}
											}
										}
									}
								}
							}
						}
						return r 
					}
					
					let dataPrix = []
					let dataDuree = []
					for (let index = 0; index < 5; index++) {
						if (this.app.locatif.yanport_typo_graph_prix_geo[this.geo][index+1]) {
							dataPrix.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
//									fn(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_prix_geo[this.geo].points.length-5, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_prix_geo[this.geo][1].points.length-4, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_prix_geo[this.geo][1].points.length-3, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_prix_geo[this.geo][1].points.length-2, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_prix_geo[this.geo][1].points.length-1, 2), 
								]
							}
							)
						} else {
							dataPrix.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
									null, 
									null, 
									null, 
								]
							}
							)

						}
/*						
						if (this.app.locatif.yanport_typo_graph_jour_geo[this.geo][index+1]) {
							dataDuree.push ({
								name: 'T' + (index+1),
								type: 'line',
								data : [
									fn(this.app.locatif.yanport_typo_graph_jour_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_jour_geo[this.geo][1].points.length-4, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_jour_geo[this.geo][1].points.length-3, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_jour_geo[this.geo][1].points.length-2, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour_geo[this.geo], index+1, this.app.locatif.yanport_typo_graph_jour_geo[this.geo][1].points.length-1, 0), 
								]
							})
						} else {
							dataDuree.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
									null, 
									null, 
									null, 
								]
							}
							)
						}
							*/
					}

					let v1 = 0
					let v2 = 0
					let v3 = 0
					let v4 = 0
					
					let a1 = ""
					let a2 = ""
					let a3 = ""
					let a4 = ""
					try {
						v1 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-4].y.values["50.0"].toFixed(2);
						a1 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-4].x.substring(0,4);
					} catch (error) {
						console.log('app.locatif.loyer_yanport_geo 1')
					}
					try {
						v2 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].y.values["50.0"].toFixed(2);
						a2 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-3].x.substring(0,4);
					} catch (error) {
						console.log('app.locatif.loyer_yanport_geo 2')
					}
					try {
						v3 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].y.values["50.0"].toFixed(2);
						a3 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-2].x.substring(0,4);
					} catch (error) {
						console.log('app.locatif.loyer_yanport_geo 3')
					}
					try {
						v4 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].y.values["50.0"].toFixed(2);
						a4 = this.app.locatif.loyer_yanport_geo[this.geo].values.points[this.app.locatif.loyer_yanport_geo[this.geo].values.points.length-1].x.substring(0,4);
					} catch (error) {
						console.log('app.locatif.loyer_yanport_geo 4')
					}

					dataPrix.push(
						{
							name: 'Total',
							type: 'line',
							data : [
								v1, v2, v3, v4
							]
						}
					)

					const cat = [
//								fn(this.app.locatif.loyer_yanport_geo[this.geo], index+1, 0, 2), 
								a1, a2, a3, a4
							]

					let dataLoyer= [{
						name: 'T1',
						data: this.GetT(1)
					},
					{
						name: 'T2',
						data: this.GetT(2)
					},
					{
						name: 'T3',
						data: this.GetT(3)
					},
					{
						name: 'T4',
						data: this.GetT(4)
					},
					{
						name: 'T5',
						data: this.GetT(5)
					}
					]
					let dataCategories = []
					
					let Indicemax = this.app.locatif.loyer_tranches_geo[this.geo].length-1
					for (let index = 0; index < Indicemax; index++) {						
						const element = this.app.locatif.loyer_tranches_geo[this.geo][index];
						const elementS = this.app.locatif.loyer_tranches_geo[this.geo][index+1];
						dataCategories.push(element.toString() + ' € - ' + elementS.toString() + ' €')
						
					}
					this.updateSeriesLine(dataPrix, dataDuree, dataLoyer, dataCategories, cat);	
					this.$store.commit('SET_CHART_REF', {r: this.$refs['GraphPrix_' + this.geo], g: 'GraphPrix_' + this.geo});
				}
			},

			refresh_graph: function() {
				// Nothing
			}
	},
	
	watch: {
		'app.loyer_tranches_typo_geo_time': function() {
			this.maj_graph()
		},
//		'app.yanport_typo_graph_jour_geo_time': function() {
//			this.maj_graph()
//		},
		'app.yanport_typo_graph_prix_geo_time': function() {
			this.maj_graph()
		},
		
	},
	mounted: function() {
		this.maj_graph()
		this.$on('maj', this.refresh_graph);
		this.$store.commit('SET_CHART_REF', {r: this.$refs['GraphPrix_' + this.geo], g: 'GraphPrix_' + this.geo});
	},

}
</script>

<style lang="stylus">
@import '../../../assets/css/app'
@import '../../../assets/css/grid'

#chart
	width 100%

.part
	margin 0 0 40px 0
	.part-title
		margin 0 0 8px 0
		line-height 32px
		font-weight 600
		text-transform uppercase
		font-size 16px

.stat-box
	display block
	width 100%
	margin 0 0 16px 0
	background #fff
	.stat-box-header
		padding 24px
		min-height 24px
		.title
			font-size 16px
			font-weight 600
			line-height 24px
		.tabs
			position absolute
			right 24px
			top 24px
			.tab
				display inline-block
				margin 0 0 0 4px
				padding 0 12px
				border-radius 40px
				line-height 24px
				font-size 12px
				color #333
				cursor pointer
				&:hover
					background-color light
				&.active
					background blue
					color #fff
		.tabs_other
			position absolute
			right 24px
			top 12px
			.tab
				display inline-block
				margin 0 0 4px 4px
				padding 0 12px
				border-radius 40px
				line-height 24px
				font-size 12px
				color #333
				cursor pointer
				font-weight 500
				&:hover
					background-color light
				&.active
					background grey
					color #fff
	.stat-box-content
		display block
		padding 8px


.table
	table
		width 100%
		border-collapse collapse
		font-size 13px
		tr
			border-bottom 1px solid light
			&:first-child
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th, td
				padding 8px 
				text-align left
				&:first-child
					padding-left 24px
			th
				font-weight 600

.tabletwotitles
	table
		margin 5px
		width 99%
		border-collapse collapse
		font-size 13px
		tr
			border-bottom 1px solid light
			&:first-child
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:nth-child(2)
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th, td
				padding 8px 
				text-align center
				border-left 1px solid #666
				border-top 1px solid #666
				border-bottom 1px solid #666
				&:last-child
					border-right 1px solid #666
			th
				font-weight 600


.tableGrille
	table
		width 100%
		border-collapse collapse
		font-size 13px
		tr
			border-bottom 1px solid light
			&:first-child
				background light
				color #666
				text-transform uppercase
				&:hover
					background-color light
				th
					padding 12px 8px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th
				background light
				color #666

			th, td
				padding 8px 
				text-align center
				border-left 1px solid #666
				border-top 1px solid #666
				border-bottom 1px solid #666
				&:last-child
					border-right 1px solid #666
			th
				font-weight 600


.sanstitre
	table
		margin 0px
		width 99%
		border-collapse collapse
		font-size 13px
		background white
		tr
			border-bottom 1px solid light
			&:first-child
				background white
				color #666
				text-transform none
				&:hover
					background-color light
				th
					padding 0px
					border-left 1px solid #666
					border-top 1px solid #666
					border-bottom 1px solid #666
					&:last-child
						border-right 1px solid #666
			&:last-child
				border-bottom none
			&:hover
				background-color darken(light, 2%)
			th, td
				padding 5px
				text-align center
				border-left 1px solid #666
				border-top 1px solid #666
				border-bottom 1px solid #666
				&:first-child
					padding-left 5px
				&:last-child
					border-right 1px solid #666
			th
				font-weight 600

.info
	display block
	padding 16px 16px 16px 48px
	background lighten(blue, 90%)
	border-radius radius
	color blue
	font-weight 400
	line-height 16px
	.icon
		position absolute
		left 16px
		top calc(50% - 12px)

.dashboard-card
	&.is-base-chart
		height 320px

.part-aste
	font-size 12px
	padding-top 10px
	width 100%
	text-align end
	
.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
	width: 50%;
	border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
	border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
	text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
	font-size: 1.8rem;
	margin-bottom: 6px;
	color: var(--primary);
}

.lnil {
	display: inline-block;
	font: normal normal normal 1em/1 'LineIconsPro Light';
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
	display: block;
	font-family: var(--font), sans-serif;
	font-weight: 600;
	font-size: 1.6rem;
	color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
	font-family: var(--font-alt), sans-serif;
	font-size: 0.85rem;
}


.r {
	font-family: var(--font-alt), sans-serif !important;
	font-size: 0.95rem !important;
	font-family: var(--font), sans-serif !important;
	font-weight: 300 !important;
	color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.company-dashboard .dashboard-card {
	flex: 1;
	display: inline-block;
	width: 100%;
	padding: 20px;
	background-color: var(--white);
	border-radius: 6px;
	border: 1px solid var(--fade-grey-dark-3);
	transition: all 0.3s;
	height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
	margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
	display: flex;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
	margin-right: 30px;
	font-family: var(--font), sans-serif;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
	text-transform: uppercase;
	font-family: var(--font-alt), sans-serif;
	font-size: 0.75rem;
	color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
	display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
	color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
	color: var(--dark-text);
	font-size: 1.6rem;
	font-weight: 600;
}
.revenue-stat {
	width: 33%
}
.dark-inverted2 {
	font-size: 1.4rem !important;
}
.responsive-box {
    aspect-ratio: 16 / 5; /* Ratio de 16:9 */
    width: 100%; /* Prend toute la largeur disponible */
}
</style>
