<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="gisementencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;" id="GisementEx">
				<div class="pan-titre-programme" id="pan-titre-gisement">
					<div class="module_ret">
						<div class="btns fermer" :title="$t('Fermer')"  @click="ferme();$store.dispatch('set_help_rs', {aide:false})"><icon file="fermer" :size="16" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateImage(expanded_gisement.gisement.id);"><icon file="download" :size="24" />&nbsp;</div>
						<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div class="indice_confiance" :class="{ pc : true }">
							{{ $t('Gisement foncier') }}<span v-if="expanded_gisement"> - {{ expanded_gisement.gisement.id }}</span>
						</div>
					</div>
					<br/>
				</div>
				<div>
					<br><br><br><br><br>
					<div id="polygonContainer"></div>
					<br><br>
				</div>
				<div>
					<div class="program" :class="{ inactif: false }">
						<!--
						<div class="program-main" style="height:285px;">
							<a v-if="expanded_rpls.position && expanded_rpls.position.lat" target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rpls.position.lat + ',' + expanded_rpls.position.lon">
								<div class="image">
									<img :src="expanded_rpls.urlphoto" alt="">
								</div>
							</a>
						</div>
						-->
					</div>
					<div class="program-detail">
						<div class="program-detail-content" style="padding-top: 8px !important;">
							<div class="program-detail-info">
								<ul class="list-none p-2 m-0">
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.surfini">
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-window-maximize mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Superficie')}} : {{ Math.round(expanded_gisement.gisement.surfini) }} m²</span>
										</div>
									</li>
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.zonage_urba">
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-map mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Zonage Urbain')}} : {{ expanded_gisement.gisement.zonage_urba }}</span>
										</div>
									</li>
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.gbgnb">
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-building mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Bâti / Non bâti')}} : {{ expanded_gisement.gisement.gbgnb }}</span>
										</div>
									</li>
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_patrimoine">
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-image mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Environnement patrimoine')}} : {{ expanded_gisement.gisement.env_patrimoine }}</span>
										</div>
									</li>
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_risque_nat">
										
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-bolt mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Risques naturels')}} : {{ expanded_gisement.env_risque_nat.gbgnb }}</span>
										</div>
									</li>
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_biodiversite">
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-star mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Biodiversité')}} : {{ expanded_gisement.gisement.env_biodiversite }}</span>
										</div>
									</li>
									<li v-if="expanded_gisement && expanded_gisement.gisement && expanded_gisement.gisement.env_risque_indus">
										<div class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
											<i class="pi pi-server mr-2 text-700"></i>
											<span class="font-medium text-700">{{$t('Risques industriels')}} : {{ expanded_gisement.gisement.env_risque_indus }}</span>
										</div>
									</li>
								</ul>
								<!--
								<div class="program-maj" v-if="expanded_rpls.date_elk">
									<div v-if="expanded_rpls.date_elk" style="font-size: 14px;" class="recent">{{ $t('Données de l\'année ') }} {{parseInt(expanded_rpls.date_elk.substring(0, 4))-1}}</div>
									<div><br v-if="expanded_rpls.date_elk"><span v-if="!expanded_rpls.date_elk">{{ $t('donnees') }}</span> {{ $t('publiees_le') }} {{expanded_rpls.date_elk.substring(8,10)}}/{{expanded_rpls.date_elk.substring(5,7)}}/{{expanded_rpls.date_elk.substring(0, 4)}}</div>
								</div>
								-->
								<br>
							</div>
							
							<!--
							<Accordion style="width:100%">
								<AccordionTab v-for="parcelle in expanded_gisement.parcelles" :key="parcelle.idpar" :header="parcelle.idpar">
									<div>Parcelle N° {{ parcelle.idpar }}</div>
									<div>Contenance : {{ parcelle.contenance }} m²</div>
									<div>{{ parcelle.pbpnb }}</div>
									<div>Surface : {{ Math.round(parcelle.surface) }} m²</div>
									<div>COS : {{ Math.round(parcelle.cos*10)/10 }}</div>
									<div>CES : {{ Math.round(parcelle.ces*10)/10 }}</div>
									<div>Hauteur max : {{ parcelle.bati_htmax }} m</div>
								</AccordionTab>
							</Accordion>
							-->
							
							
							<!--
							<DataView :value="expanded_gisement.parcelles" :layout="layout" :paginator="true" :rows="9" :sortOrder="sortOrder" :sortField="sortField" style="width:100%">
								<template #header>
									<div class="grid grid-nogutter">
										<div class="col-6" style="text-align: left">
											<Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Trier par Surface" @change="onSortChange($event)"/>
										</div>
										<div class="col-6" style="text-align: right">
											<DataViewLayoutOptions v-model="layout" />
										</div>
									</div>
								</template>
								
								<template #list="slotProps">
									<div class="col-12">
										<div class="product-list-item">
											<div class="product-list-detail">
												<div class="product-name">{{slotProps.data.name}}</div>
												<div class="product-description">{{slotProps.data.description}}</div>
												<Rating :value="Math.round(slotProps.cos*10)/2" :readonly="true" :cancel="false"></Rating>
												<i class="pi pi-tag product-category-icon"></i><span class="product-category">{{slotProps.pbpnb}}</span>
											</div>
											<div class="product-list-action">
												<span class="product-price">{{Math.round(slotProps.surface)}} m²</span>
											</div>
										</div>
									</div>
								</template>
								
								<template #grid="slotProps">
									<div class="col-12 md:col-4">
										<div class="product-grid-item card">
											<div class="product-grid-item-content">
												<div class="product-name">{{slotProps.data.name}}</div>
												<div class="product-description">{{slotProps.data.description}}</div>
												<Rating :value="slotProps.data.rating" :readonly="true" :cancel="false"></Rating>
											</div>
											<div class="product-grid-item-bottom">
												<span class="product-price">${{slotProps.data.price}}</span>
												<Button icon="pi pi-shopping-cart" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button>
											</div>
										</div>
									</div>
								</template>
							</DataView>
							-->
							
							<!---->
							<div style="width:100%;display: flex;flex-direction: row;justify-content: center;">
								<SelectButton v-model="value1" :options="options" />
							</div>
							
							<div style="width:100%;padding-top:30px;" v-if="value1=='Parcelles'">
								<div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-around;">
									<div v-for="parcelle in expanded_gisement.parcelles"  v-bind:key="parcelle.idpar" class="parcelle">
										<div>{{$t('Parcelle N°')}} <b>{{ parcelle.idpar }}</b></div>
										<div>{{$t('Contenance')}} : <b>{{ parcelle.contenance }} {{$t('m²')}}</b></div>
										<div>{{ parcelle.pbpnb }}</div>
										<div>{{$t('Surface')}} : <b>{{ Math.round(parcelle.surface) }} {{$t('m²')}}</b></div>
										<div>{{$t('COS')}} : <b>{{ Math.round(parcelle.cos*10)/10 }}</b></div>
										<div>{{$t('CES')}} : <b>{{ Math.round(parcelle.ces*10)/10 }}</b></div>
										<div>{{$t('Hauteur max')}} : <b>{{ parcelle.bati_htmax }} {{$t('m')}}</b></div>
									</div>
								</div>
							</div>
							<div style="width:100%;padding-top:30px;" v-if="value1=='Propriétaires'">
								<div style="display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-around;">
									<div v-for="proprietaire in expanded_proprietaire"  v-bind:key="proprietaire.idpk" class="parcelle" style="width:100%">
										<div>{{$t('Forme')}} <b>{{ proprietaire.forme_juridique }}</b></div>
										<div>{{$t('Dénomination')}} : <b>{{ proprietaire.denomination }}</b></div>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
// eslint-disable-next-line
import domtoimage from 'dom-to-image'
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import DataView from 'primevue/dataview';
import Panel from 'primevue/panel';
import SelectButton from 'primevue/selectbutton';

export default {
	name: 'PaneGisement',

	data: function() {
		return {
            value1: 'Parcelles',
            value2: null,
            value3: null,
            options: ['Parcelles', 'Propriétaires'],
			layout: 'grid',
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'base',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		expanded_gisement: function() {
			return this.app.expanded_gisement;
		},
		expanded_proprietaire: function() {
			let t = []
			for (let index = 0; index < this.app.expanded_gisement.parcelles.length; index++) {
				for (let index2 = 0; index2 < this.app.expanded_gisement.parcelles[index].proprietaires.length; index2++) {

					let proprietaire = this.app.expanded_gisement.parcelles[index].proprietaires[index2];
					
					// Vérifiez si le propriétaire existe déjà dans le tableau t
					let existingIndex = t.findIndex(item => item.idpk === proprietaire.idpk || item.denomination === proprietaire.denomination);
					if (existingIndex === -1) {
						// Si le propriétaire n'existe pas, ajoutez-le au tableau
						proprietaire.parcelles = []
						proprietaire.parcelles.push(this.app.expanded_gisement.parcelles[index].idpar)
						t.push(proprietaire);
					} else {
						// Si le propriétaire existe, vous pouvez utiliser existingIndex pour faire quelque chose
						t[existingIndex].parcelles.push(this.app.expanded_gisement.parcelles[index].idpar)
					}
				}
			}
			return t;
		}
	}),

	watch: {
		'app.expanded_gisement': function(newValue) {
			if (newValue) {
				this.majPolygone()
			}
		}
	},
	
	methods: {
		drawFeature: function(feature) {
			if (feature.geometry.type === "Polygon") {
				return path(feature);
			} else if (feature.geometry.type === "MultiPolygon") {
				return feature.geometry.coordinates.map(polygon => {
					return path({ type: "Polygon", coordinates: polygon });
				}).join(" ");
			}
		},

        // Fonction pour vider la div #map
        clearMap: function() {
            d3.select("#polygonContainer").select("svg").remove();
        },

		majPolygone: function() {
			// Extract coordinates from GeoJSON
			for (let index = 0; index < this.app.gisements.features.length; index++) {
				const element = this.app.gisements.features[index]
				if (element.properties.gisement.id == this.app.expanded_gisement.gisement.id) {

					const geojson = {
						"type": "FeatureCollection",
						"features": [
							element
						]
					};
					
					const width = 760;
					const height = 400;
					this.clearMap();

					const svg = d3.select("#polygonContainer")
						.append("svg")
						.attr("width", width)
						.attr("height", height);

					const projection = d3.geoIdentity().reflectY(true).fitSize([width, height], geojson);
					const path = d3.geoPath().projection(projection);

					svg.selectAll("path")
						.data(geojson.features)
						.enter().append("path")
						.attr("class", "polygon")
						.attr("d", path)
						.attr("fill", "#CCCCCC")
						.attr("stroke", "black");


					svg.selectAll("path")
						.data(geojson.features)
						.enter().append("path")
						.attr("class", "polygon")
						.attr("d", this.drawFeature)
						.attr("fill", "#CCCCCC")
						.attr("stroke", "black");

				}
			}

			
		},

        selectContent(event) {
          // Sélectionner le contenu de l'input
          event.target.select();
        },

		isInteger: function(str) {
			const num = Number(str);
			return Number.isInteger(num);
		},

		generateImage(id) {
			const elementToExport = document.getElementById('GisementEx');
			let options = {
				height: elementToExport.scrollHeight,
				width: elementToExport.scrollWidth,
				style: {
					'transform': 'scale(1)',
					'transformOrigin': 'top left',
				},
			};

			domtoimage.toPng(elementToExport, options)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.href = dataUrl;
				link.download = 'RPLS_' + id + '.png';
				link.click();
			})
			.catch((error) => {
				console.error('Erreur lors de la génération de l\'image', error);
			});

		},

		ferme: function() {
			this.$router.push({ name: 'gisement'}).catch(()=>{});
			this.$store.dispatch('expand_gisement', { selectgisement: false })
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "gisementencours"})
			this.$store.dispatch('SendEvent', { action: "gisement_download", opt_label: this.expanded_gisement.gisement.id, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-gisement' })
		}
	},

	// eslint-disable-next-line
	components: { domtoimage, Button, Accordion, AccordionTab, DataView, Panel, SelectButton }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	height calc(100% - 120px)
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 200px
			top 16px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

.program-detail
	overflow hidden
	height auto
	&.detail-enter, &.detail-leave-to
		height 430px
		background-color red
	.program-detail-content
		padding 16px
		.resume
			margin 8px 0 0 0
			&:first-child
				margin 0
			&:after
				content ''
				display block
				clear both
			.resume-data
				float left
				width 33.3333%
				margin 0 0 8px 0
				.label
					font-size 10px
					text-transform uppercase
					color #999
				.value
					font-weight 500
		.actions
			margin 8px 0 0 0
			.action
				display inline-block
				margin 0 24px 0 0
				height 32px
				color #666
				font-size 12px
				font-weight 500
				line-height @height
				cursor pointer
				&:hover
					color #333
				.icon
					float left
					margin 4px 8px 0 0
		.program-detail-info
			padding 8px 0
			.program-detail-info-filters
				text-align center
				margin 0 0 8px 0
			.program-detail-info-content
				padding 8px 0
				background #f5f5f5
				border-radius radius
				overflow hidden
				table
					width 100%
					border-collapse collapse
					tr
						&:last-child
							border none
						&:hover
							td
						td, th
							padding 4px 8px
						th
							font-size 12px
							font-weight 500
						td
							text-align center
							&:first-child
								text-align left
								font-size 12px
								font-weight bold
								padding-left 16px

.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 620px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.cible
	color #000

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.Faible
	color #f2131f
	font-weight 600
.Moyen
	color #f2aa1f
	font-weight 600
.Fort
	color #1eaa1f
	font-weight 600
.recent
	color #1eaa1f
	font-weight 600

.center
	text-align center !important

.parcelle
	border-radius 8px
	background #EEEEEE
	margin-right 10px
	margin-bottom 10px
	padding 10px
	width 220px

#polygonContainer
	width 800px
	height 400px
	padding-right 20px
	padding-left 20px

@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
