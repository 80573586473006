<template>
	<transition name="pane-adresse-ecole">
		<div class="paneleft pan-adresse-ecole">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" v-show="false"/>
			<div style="height: 100%; display: block; overflow: hidden scroll; width: 100%; box-sizing: content-box; padding-right: 20px;">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">Général</span>
							<span> > </span>
							<span><b>{{ $t('Education') }}</b></span>
						</div>
						<div class="noteTitre" :class="app.cityscan.adresse.note_education">{{app.cityscan.adresse.note_education}}</div>
					</div>
					<div class="programs-list">
						<div class="programs-list-content wrapper-scroll">
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('petite_enfance') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-creche' }); select_adresse_iso(app.cityscan.adresse.creche)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1400-02 ng-star-inserted" style="color: rgb(29, 16, 117);"></span></div>
														<div class="titre_module">{{ $t('creche') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.creche.note">{{this.app.cityscan.adresse.creche.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.creche.nb}} {{this.app.cityscan.adresse.creche.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_creche" class="checkbox" :class="{ checked: adresse_ecole_creche }" >
															<input type="checkbox" :checked="adresse_ecole_creche" id="adresse_ecole_creche" @click=" select_adresse('adresse_ecole_creche', app.cityscan.adresse.creche)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
													
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('primaire') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-maternelle' }); select_adresse_iso(app.cityscan.adresse.maternelle)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1410-02 ng-star-inserted" style="color: rgb(53, 26, 143);"></span></div>
														<div class="titre_module">{{ $t('maternelle') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.maternelle.note">{{this.app.cityscan.adresse.maternelle.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.maternelle.nb}} {{this.app.cityscan.adresse.maternelle.temps}}</div>
														
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_maternelle" class="checkbox" :class="{ checked: adresse_ecole_maternelle }"  >
															<input type="checkbox" :checked="adresse_ecole_maternelle" id="adresse_ecole_maternelle" @click=" select_adresse('adresse_ecole_maternelle', app.cityscan.adresse.maternelle)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-elementaire' }); select_adresse_iso(app.cityscan.adresse.elementaire)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1420-02 ng-star-inserted" style="color: rgb(77, 36, 169);"></span></div>
														<div class="titre_module">{{ $t('elementaire') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.elementaire.note">{{this.app.cityscan.adresse.elementaire.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.elementaire.nb}} {{this.app.cityscan.adresse.elementaire.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_elementaire" class="checkbox" :class="{ checked: adresse_ecole_elementaire }" >
															<input type="checkbox" :checked="adresse_ecole_elementaire" id="adresse_ecole_elementaire" @click=" select_adresse('adresse_ecole_elementaire', app.cityscan.adresse.elementaire)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('secondaire') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-college' }); select_adresse_iso(app.cityscan.adresse.college)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1430-02 ng-star-inserted" style="color: rgb(100, 45, 195);"></span></div>
														<div class="titre_module">{{ $t('college') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.college.note">{{this.app.cityscan.adresse.college.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.college.nb}} {{this.app.cityscan.adresse.college.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_college" class="checkbox" :class="{ checked: adresse_ecole_college }" >
															<input type="checkbox" :checked="adresse_ecole_college" id="adresse_ecole_college" @click=" select_adresse('adresse_ecole_college', app.cityscan.adresse.college)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-lycee' }); select_adresse_iso(app.cityscan.adresse.lycee)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1440-02 ng-star-inserted" style="color: rgb(124, 55, 221);"></span></div>
														<div class="titre_module">{{ $t('lycee_general') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.lycee.note">{{this.app.cityscan.adresse.lycee.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.lycee.nb}} {{this.app.cityscan.adresse.lycee.temps}}</div>
														
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_lycee" class="checkbox" :class="{ checked: adresse_ecole_lycee }" >
															<input type="checkbox" :checked="adresse_ecole_lycee"  id="adresse_ecole_lycee" @click=" select_adresse('adresse_ecole_lycee', app.cityscan.adresse.lycee)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-lycee-pro' }); select_adresse_iso(app.cityscan.adresse.lycee_pro)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1441-02 ng-star-inserted" style="color: rgb(127, 68, 182);"></span></div>
														<div class="titre_module">{{ $t('lycee_professionnel') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.lycee_pro.note">{{this.app.cityscan.adresse.lycee_pro.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.lycee_pro.nb}} {{this.app.cityscan.adresse.lycee_pro.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_lycee_pro" class="checkbox" :class="{ checked: adresse_ecole_lycee_pro }" >
															<input type="checkbox" :checked="adresse_ecole_lycee_pro" id="adresse_ecole_lycee_pro" @click=" select_adresse('adresse_ecole_lycee_pro', app.cityscan.adresse.lycee_pro)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('enseignement_superieur') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-universite' }); select_adresse_iso(app.cityscan.adresse.universite)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1450-02 ng-star-inserted" style="color: rgb(151, 92, 238);"></span></div>
														<div class="titre_module">{{ $t('universite') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.universite.note">{{this.app.cityscan.adresse.universite.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.universite.nb}} {{this.app.cityscan.adresse.universite.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_universite" class="checkbox" :class="{ checked: adresse_ecole_universite }" >
															<input type="checkbox" :checked="adresse_ecole_universite" id="adresse_ecole_universite" @click=" select_adresse('adresse_ecole_universite', app.cityscan.adresse.universite)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-superieur' }); select_adresse_iso(app.cityscan.adresse.superieures)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1460-02 ng-star-inserted" style="color: rgb(177, 128, 255);"></span></div>
														<div class="titre_module">{{ $t('ecole_superieure') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.superieures.note">{{this.app.cityscan.adresse.superieures.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.superieures.nb}} {{this.app.cityscan.adresse.superieures.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_superieur" class="checkbox" :class="{ checked: adresse_ecole_superieur }" >
															<input type="checkbox" :checked="adresse_ecole_superieur" id="adresse_ecole_superieur" @click=" select_adresse('adresse_ecole_superieur', app.cityscan.adresse.superieures)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'education-formation' }); select_adresse_iso(app.cityscan.adresse.formation_pro)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1470-02 ng-star-inserted" style="color: rgb(77, 8, 109);"></span></div>
														<div class="titre_module">{{ $t('formation_professionnelle') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.formation_pro.note">{{this.app.cityscan.adresse.formation_pro.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.formation_pro.nb}} {{this.app.cityscan.adresse.formation_pro.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_ecole_formation" class="checkbox" :class="{ checked: adresse_ecole_formation }" >
															<input type="checkbox" :checked="adresse_ecole_formation" id="adresse_ecole_formation" @click=" select_adresse('adresse_ecole_formation', app.cityscan.adresse.formation_pro)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
									</div>
								</ul>
							</div>
						</div>
					</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseTransport',
	data: function() {
		return {
			adresse_ecole_creche: true,
			adresse_ecole_formation: true,
			adresse_ecole_superieur: true,
			adresse_ecole_universite: true,
			adresse_ecole_lycee_pro: true,
			adresse_ecole_lycee: true,
			adresse_ecole_college: true,
			adresse_ecole_elementaire: true,
			adresse_ecole_maternelle: true,
			detail: '',
		}
	},
	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),
	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_ecole_creche = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_creche')!==-1)
		//	this.adresse_ecole_formation = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_formation')!==-1)
		//	this.adresse_ecole_superieur = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_superieur')!==-1)
		//	this.adresse_ecole_universite = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_universite')!==-1)
		//	this.adresse_ecole_lycee_pro = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_lycee_pro')!==-1)
		//	this.adresse_ecole_lycee = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_lycee')!==-1)
		//	this.adresse_ecole_college = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_college')!==-1)
		//	this.adresse_ecole_elementaire = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_elementaire')!==-1)
		//	this.adresse_ecole_maternelle = (this.app.preferences.adresse_niv2.indexOf('adresse_ecole_maternelle')!==-1)
		//}
	},
	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},
		select_adresse_iso: function(propObj) {
			this.$store.dispatch('set_contour_iso_param', {duration: propObj.duration, transportType: propObj.transportType})
			this.$store.dispatch('set_contour_iso', { contour: true })
		},
		getscore: function(score) {
			let score_name= ''
			if (score >= 80) {
				score_name = "Nutri-score-A"
			} else {
				if (score >= 60) {
					score_name = "Nutri-score-B"
				} else {
					if (score >= 40) {
						score_name = "Nutri-score-C"
					} else {
						if (score >= 20) {
							score_name = "Nutri-score-D"
						} else {
							score_name = "Nutri-score-E"
						}
					}
				}
			}
			return score_name
		},
		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		}
	},
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-ecole-enter
	transform translateX(100%)
.pane-adresse-ecole-leave-to
	transform translateX(-100%)

.pan-adresse-ecole
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
