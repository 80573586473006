<template>
	<div class="program">
		<div class="program-main" @click="expand_annonce()">
			<div class="image">
				<img @error="handleImageError" :src="annonce.features.visual.images[0]" alt="">
			</div>
			<div class="program-content">
				<div>
					<p class="name" :class="annonce.commercialisation">{{ getLibelle(annonce.type) }} {{getMeuble(annonce)}}{{annonce.features.geometry.roomCount }} {{ $t('piece') }}<span v-if="annonce.features.geometry.roomCount>1">s</span></p>
					<p class="city">{{ $t('du') }} {{getLibelleDate(annonce.creationDate) }} {{ $t('maj le') }} {{ getLibelleDate(annonce.updateDate) }}</p>
					<table style="width:100%">
                        <th style="display: none"></th>
						<tr>
							<td>
								<p class="seller">{{ Math.round(annonce.features.geometry.surface * 100) / 100 }} m²</p>
							</td>
							<td class="prix">
								<div v-if="annonce.type=='APARTMENT'"><icon file="building" :size="24" style="color:#cccccc" /></div>
								<div v-else><icon file="home" :size="24" style="color:#cccccc" /></div>
							</td>
						</tr>
					</table>
					
					<table style="width:100%">
                        <th style="display: none"></th>
						<tr>
							<td class="prix"><div>{{ Math.round(annonce.marketing.priceHC) }}{{$t('€ HC')}}</div></td>
						</tr>
					</table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'Annonce',
	data: function PaneAnnonce() {
		return {
			defaultImageUrl: 'https://www.myadequation.fr/EFOCUS./no-camera2.png',
			expanded: false,
			filter: 'volume',
			filter2: 'typologie',
			lib: [
				{ slug: "APARTMENT", name: "Appartement" },{ slug: "HOUSE", name: "Maison" },{ slug: "PREMISES", name: "Local Commercial" },
				{ slug: "LAND", name: "Terrain" },{ slug: "BUILDING", name: "Immeuble" },{ slug: "PARKING", name: "Parking" },
				{ slug: "PARKING_INCLUDED_IN_RENT", name: "Parking inclus dans le loyer" },{ slug: "CHALET", name: "Chalet" },{ slug: "MANSION", name: "Hôtel particulier" },
				{ slug: "CASTLE", name: "Château" },{ slug: "HOMESTEAD", name: "Propriété" },{ slug: "LOFT", name: "Loft" },{ slug: "DUPLEX", name: "Duplex" },
				{ slug: "TRIPLEX", name: "Triplex" },{ slug: "SHOP", name: "Magasin" },{ slug: "OFFICE", name: "Bureau" },{ slug: "FURNITURE", name: "Meublé" },
				{ slug: "FIREPLACE", name: "Cheminée" },{ slug: "AIR_CONDITIONING", name: "Climatisation" },{ slug: "ADSL", name: "Adsl" },
				{ slug: "CABLE", name: "Câble" },{ slug: "FIBER", name: "Fibre" },{ slug: "ELEVATOR", name: "Ascenseur" },{ slug: "DIGICODE", name: "Digicode" },
				{ slug: "INTERCOM", name: "Interphone" },{ slug: "VIDEOPHONE", name: "Vidéophone" },{ slug: "BIKE_AREA", name: "Local vélo" },
				{ slug: "TWO_WHEEL_PARK", name: "Emplacement 2 roues" },{ slug: "DISABLED_ACCESS", name: "Accès handicapé" },{ slug: "PARKING_FACILITIES", name: "Possibilité de stationnement" },
				{ slug: "BEDROOM", name: "Chambre" },{ slug: "BATHROOM", name: "Salle de bain" },{ slug: "SHOWER_ROOM", name: "Salle d'eau" },
				{ slug: "LUNCHROOM", name: "Salle à manger" },{ slug: "LIVING_ROOM", name: "Salon" },{ slug: "CELLAR", name: "Cave" },{ slug: "KITCHEN", name: "Cuisine" },
				{ slug: "TOILET", name: "WC" },{ slug: "BALCONY", name: "Balcon" },{ slug: "GARDEN", name: "Jardin" },{ slug: "SWIMMING_POOL", name: "Piscine" },
				{ slug: "TERRACE", name: "Terrasse" },{ slug: "CARETAKER", name: "Gardien" },{ slug: "CLEANING", name: "Société de nettoyage" },{ slug: "FULL", name: "Complet" },
				{ slug: "PARTIAL", name: "Partiel" },{ slug: "NEW", name: "Neuf" },{ slug: "VERY_GOOD", name: "Très bon" },{ slug: "GOOD", name: "Bon" },
				{ slug: "NORMAL", name: "Normal" },{ slug: "BAD", name: "À rafraîchir" },{ slug: "VERY_BAD", name: "Travaux à prévoir" },
				{ slug: "CHIP_NEW", name: "Etat neuf" },{ slug: "CHIP_VERY_GOOD", name: "Très bon état" },{ slug: "CHIP_GOOD", name: "Bon état" },
				{ slug: "CHIP_NORMAL", name: "Normal" },{ slug: "CHIP_BAD", name: "À rafraîchir" },{ slug: "CHIP_VERY_BAD", name: "Travaux à prévoir" },
				{ slug: "ELECTRIC", name: "Électrique" },{ slug: "GAS", name: "Au gaz" },{ slug: "FUEL", name: "Au fuel" },{ slug: "WOOD", name: "Au bois" },
				{ slug: "SOLAR", name: "Solaire" },{ slug: "HEAT_PUMP", name: "Par pompes thermiques" },{ slug: "UNDERFLOOR", name: "Géothermique" },
				{ slug: "INDIVIDUAL", name: "Individuel" },{ slug: "COLLECTIVE", name: "Collectif" },{ slug: "MIXED", name: "Mixe" },
				{ slug: "OPENED", name: "Cuisine américaine" },{ slug: "CLOSED", name: "Cuisine fermée" },{ slug: "KITCHENETTE", name: "Kitchenette" },
				{ slug: "FITTED", name: "Sur mesure" },{ slug: "EQUIPPED", name: "Équipée" },{ slug: "NONE", name: "Non équipée" },{ slug: "FREE", name: "Libre" },
				{ slug: "AGREED", name: "Conventionnée" },{ slug: "TAX_FREE", name: "Défiscalisée" },{ slug: "ACTIVE", name: "Actif" },
				{ slug: "JOINT_OWNERSHIP", name: "Indivision" },{ slug: "OK", name: "Non opposé" },{ slug: "BLOCKED", name: "Opposé" },
				{ slug: "UNCHECKED", name: "Vérification planifiée" },{ slug: "SURFACE_PRIVEE", name: "Surface Privée" },{ slug: "PRIVATE", name: "Particulier" },
				{ slug: "AGENCY", name: "Professionnel" },{ slug: "MANDATORY", name: "Professionnel" },{ slug: "REFUSAL", name: "Refus" },{ slug: "UNREACHABLE", name: "Non joignable" },
				{ slug: "MARKETING_DONE", name: "Commercialisation terminé" },{ slug: "IN_MANDATE", name: "En mandat" },{ slug: "QUALIFICATION", name: "Qualification" },
				{ slug: "NEVER", name: "Jamais" },{ slug: "CREATE", name: "Nouveau" },{ slug: "PRICE_DECREASE", name: "Prix en baisse" },{ slug: "EXPIRED", name: "Expiré" },
				{ slug: "TODAY", name: "Aujourd'hui" },{ slug: "THIS_WEEK", name: "Cette semaine" },{ slug: "OLDER", name: "Avant" },{ slug: "OFFER", name: "Offre d'achat" },
				{ slug: "COMPROMISE", name: "Sous compromis" },{ slug: "SIGNED", name: "Vendu" },{ slug: "MONTHS", name: "Mois" },{ slug: "QUARTERS", name: "Trimestre" },
				{ slug: "YEARS", name: "Année" },{ slug: "PER_DAYS", name: "par jour" },{ slug: "PER_MONTHS", name: "par mois" },{ slug: "PER_QUARTERS", name: "par trimestre" },
				{ slug: "PER_YEARS", name: "par année" },{ slug: "REAL_ESTATE", name: "Agences" },{ slug: "NOTARY", name: "Notaires" },{ slug: "MANDATARY", name: "Mandataires" },
				{ slug: "REALTY_DEVELOPER", name: "Promoteurs" },{ slug: "PROPERTY_MANAGER", name: "Gestionnaires de patrimoine" },{ slug: "TRANSACTION", name: "Transaction" },
				{ slug: "CONSTRUCTION", name: "Construction" },{ slug: "LOCATION", name: "Location" },{ slug: "ADMINISTRATION", name: "Administration" },
				{ slug: "DONE", name: "Terminée" },{ slug: "TYPE", name: "Type" },{ slug: "USER_ID", name: "Affectation" },{ slug: "construction_year", name: "Année de construction" },
				{ slug: "expenses", name: "Charges locatives" },{ slug: "floor_count", name: "Nombre d'étages" },{ slug: "has_parking", name: "Parking" },{ slug: "has_pool", name: "Piscine" },
				{ slug: "land_surface", name: "Jardin" },{ slug: "level", name: "Étage" },{ slug: "location", name: "Emplacement" },{ slug: "newly_built", name: "Construction neuve" },
				{ slug: "price", name: "Prix" },{ slug: "room_count", name: "Nombre de pièces" },{ slug: "surface", name: "Surface" },{ slug: "NEW_BUILD", name: "Construction neuve" },
				{ slug: "HABITABLE_SURFACE", name: "Surface habitable" },{ slug: "MEAN_SURFACE", name: "Surface moyenne" },{ slug: "LOT_COUNT", name: "Nombre de lots" },
				{ slug: "AREA_COUNT", name: "Nombre de pièces" },{ slug: "GENERAL_STATE", name: "État" },{ slug: "LAND_SURFACE", name: "Surface terrain" },
				{ slug: "NUMBER_OF_GARDENS", name: "Nombre de jardins" },{ slug: "NUMBER_OF_BATHROOMS", name: "Salle de bains" },{ slug: "NUMBER_OF_WC", name: "Nombre de WC" },
				{ slug: "NUMBER_OF_SHOWER_ROOMS", name: "Nombre de salle d'eau" },{ slug: "NUMBER_OF_LIVING_ROOMS", name: "Salons" },{ slug: "NUMBER_OF_BALCONIES", name: "Balcons" },
				{ slug: "NUMBER_OF_TERRACES", name: "Terrasses" },{ slug: "HEATING", name: "Chauffage" },{ slug: "CONDOMINIUM_LOT_COUNT", name: "Nombre de lot de la copropriété" },
				{ slug: "CONDOMINIUM_EXPENSES", name: "Charges" },{ slug: "CHARGES_INCLUDED", name: "Charges comprises" },{ slug: "CHARGES", name: "Charges" },
				{ slug: "VIEW", name: "Vue" },{ slug: "SEA", name: "Sur la mer" },{ slug: "MOUNTAIN", name: "Sur la montagne" },{ slug: "RIVER", name: "Sur la rivière" },
				{ slug: "LAKE", name: "Sur le lac" },{ slug: "EXPOSITION", name: "Exposition" },{ slug: "NORTH", name: "Nord" },{ slug: "EAST", name: "Est" },{ slug: "SOUTH", name: "Sud" },
				{ slug: "WEST", name: "Ouest" },{ slug: "LEGAL_PERSON", name: "Personne morale" },{ slug: "SINGLE_PROPERTY", name: "Mono propriété" },{ slug: "CONDOMINIUM", name: "Copropriété" },
				{ slug: "CONSTRUCTION_PERIOD", name: "Période de construction" },{ slug: "CONSTRUCTION_YEAR", name: "Année de construction" },{ slug: "FLOOR_COUNT", name: "Nombre d'étages" },
				{ slug: "LEVEL", name: "Étage" },{ slug: "BEDROOM_COUNT", name: "Chambres" },{ slug: "SURFACE", name: "Surface" },{ slug: "A", name: "A" },
				{ slug: "A_BIS", name: "A bis" },{ slug: "B1", name: "B1" },{ slug: "B2", name: "B2" },{ slug: "LEVEL_1", name: "Niveau 1" },{ slug: "LEVEL_2", name: "Niveau 2" },
				{ slug: "BUYER", name: "À la charge de l’acquéreur" },{ slug: "SELLER", name: "À la charge du vendeur" },
				{ slug: "CREATION", name: "Entrée" },{ slug: "DELETION", name: "Sortie" },{ slug: "PARKING_SPOT", name: "Place de parking" },
				{ slug: "BOX", name: "Box" },{ slug: "GARAGE", name: "Garage" },{ slug: "AUTHORISED", name: "Autorisé" },
				{ slug: "CANCELED", name: "Annulé" },{ slug: "STARTED", name: "Démarré" },{ slug: "FINISHED", name: "Terminé" },
				{ slug: "SINGLE", name: "Individuel" },{ slug: "DEMOLISHED", name: "Démolition" },{ slug: "SOCIAL", name: "Social" },
				{ slug: "SOCIAL_LANDLORD", name: "Bailleur social" },{ slug: "PROPERTY_DEVELOPER", name: "Promoteur" },{ slug: "PUBLIC_ADMINISTRATION", name: "Administration publique" },
				{ slug: "OTHER_COMPANY", name: "Autre société" },{ slug: "PURE_LOCAL", name: "Non déterminé" },{ slug: "EXISTING_BUILD", name: "Construction existante" },
				{ slug: "PROJECT_TYPE_RESIDENTIAL", name: "Résidentiel" },{ slug: "PROJECT_TYPE_NON_RESIDENTIAL", name: "Non résidentiel" },{ slug: "PROJECT_TYPE_MIXED", name: "Mixte" },
				{ slug: "ONLINE_ADS", name: "Annonces en ligne" },{ slug: "FIELD_PROSPECTING", name: "Prospection terrain" },{ slug: "UNPAID", name: "Non payé" },
				{ slug: "TRIALING", name: "Période d'essai" },{ slug: "UNKNOWN", name: "Inconnu" },{ slug: "MANUAL", name: "Manuel" },
				{ slug: "STRIPE", name: "Stripe" },{ slug: "constructionYear", name: "Construction" },{ slug: "energyConsumptionLetter", name: "DPE" },
				{ slug: "floorCount", name: "Nombre d'étages" },{ slug: "furnished", name: "Meublé" },{ slug: "generalState", name: "Etat du bien" },
				{ slug: "greenhouseGasConsumptionLetter", name: "GES" },{ slug: "balconyCount", name: "Balcon" },{ slug: "cellarCount", name: "Cave" },
				{ slug: "gardenCount", name: "Jardin" },{ slug: "parkingCount", name: "Parking" },{ slug: "swimmingPoolCount", name: "Piscine" },
				{ slug: "terraceCount", name: "Terrasse" },{ slug: "landSurface", name: "Surface du terrain" },{ slug: "floor", name: "Etage" },
				{ slug: "roomCount", name: "Nombre de pièces" },{ slug: "energyDiagnostic", name: "DPE / GES" },{ slug: "typology", name: "Typologie" }
			]
		}
	},

	props: [ 'annonce' ],

	computed: Object.assign(mapGetters([ 'app', 'expanded_annonce' ]), {

	}),

	methods: {
		handleImageError(event) {
			event.target.src = this.defaultImageUrl; // Remplacer par une image par défaut en cas d'erreur
		},

		getMeuble: function(annonce) {
			if (annonce.features.descriptive && annonce.features.descriptive.equipments && annonce.features.descriptive.equipments.FURNITURE) return 'meublé '
			return ''
		},

		getLibelle: function(etat) {
            for (let i in this.lib) if (this.lib[i].slug==etat) return this.lib[i].name
        },

		getLibelleBalcon: function(areas) {
			for (let index = 0; index < areas.length; index++) {
				const element = areas[index];
				if (element.type == 'BALCONY') {
					if (element.surface) return ' (' + element.surface + ' m²)'
				}
			}
			return ''
        },

		getLibelleTerrasse: function(areas) {
			for (let index = 0; index < areas.length; index++) {
				const element = areas[index];
				if (element.type == 'TERRACE') {
					if (element.surface) return ' (' + element.surface + ' m²)'
				}
			}
			return ''
        },

		getLibelleCuisine: function(areas) {
			for (let index = 0; index < areas.length; index++) {
				const element = areas[index];
				if (element.type == 'KITCHEN') {
					for (let i in this.lib) if (this.lib[i].slug==element.kitchenType) return ' (' + this.lib[i].name + ')'
				}
			}
			return ''
        },

        getLibelleDate: function(d) {
            let a = new Date(d)
            let mm = a.getMonth() + 1;
            let dd = a.getDate();
            return [(dd>9 ? '' : '0') + dd,(mm>9 ? '' : '0') + mm, a.getFullYear()].join('-');
        },

		expand_annonce: function() {
			if (this.expanded_annonce != this.annnonce) {
				this.$store.dispatch('expand_annonce', { annonce: this.annonce })
				this.$router.push({ name: 'annonce', params: { id: this.annonce.id }}).catch(()=>{});
			} else {
				this.$store.dispatch('expand_annonce', { annonce: false })
				this.$router.push({ name: 'annonce'}).catch(()=>{});
			}
		},

		Ascenseur: function () {
			let b = false
			for (let index = 0; index < this.annonce.features.additionalFeatures.length; index++) {
				const element = this.annonce.features.additionalFeatures[index];
				if (element.features && element.features.descriptive && element.features.descriptive.equipments && element.features.descriptive.equipments.ELEVATOR) {
					b = true
					break;
				}
			}
			return b
		},
	},

	components: { }
}

</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'


.program
	width calc(100% + 13px)
	border-top 1px solid #eee
	border-right: 4px solid white;
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		border-right 4px solid lighten(primaryA100,92%) !important
		&:hover
			.program-main
				background lighten(primaryA100,92%)
		.program-main
			background lighten(primaryA100,92%)
			.program-content
				.name, .prix
					color palette2 !important
	&:hover
		border-right 4px solid hsl(153deg 48% 49%)
	.program-main
		background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			background #fff
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fff
		padding-top 8px
		padding-bottom 8px
		padding-right 4px
		padding-left 8px
		overflow hidden
		cursor pointer
		.image
			position absolute
			height 90px
			width 112px
			border-radius 12px
			overflow hidden
			background #eee url(../../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 120px
			.name
				color #333 !important
				font-weight 600
				height 24px
				font-size 13px
				line-height 24px
				white-space nowrap
				width 174px
				text-overflow ellipsis
				overflow hidden
			.city, .address
				font-size 12px
			.seller
				color #333 !important
				text-transform uppercase
				font-size 12px
				width 174px
				text-overflow ellipsis
				overflow hidden
				white-space nowrap
				line-height 24px
			.forme-urbaine
				position absolute
				top 40px
				right 0px
				color #999
			.gen
				font-size 12px
				text-align center
				line-height 28px
				background-color #666
				color #fff
				border-radius 16px
				height 32px
				width 32px
		.price
			position absolute
			left 10px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

	.program-detail
		overflow hidden
		background #fff
		height auto
		&.detail-enter, &.detail-leave-to
			height 430px
			background-color red
		.program-detail-content
			padding 16px
			.resume
				margin 8px 0 0 0
				&:first-child
					margin 0
				&:after
					content ''
					display block
					clear both
				.resume-data
					float left
					width 33.3333%
					margin 0 0 8px 0
					.label
						font-size 10px
						text-transform uppercase
						color #999
					.value
						font-weight 500
			.program-detail-info
				padding 8px 0
				.program-detail-info-filters
					margin 0 0 8px 0
				.program-detail-info-content
					padding 8px 0
					background #f5f5f5
					border-radius radius
					overflow hidden
					table
						width 100%
						border-collapse collapse
						tr
							border-bottom 1px solid #eee
							&:last-child
								border none
							&:hover
								td
									background #eee
							td, th
								padding 4px 8px
							th
								font-size 12px
								font-weight 500
							td
								text-align center
								&:first-child
									text-align left
									font-size 12px
									font-weight 500
									padding-left 16px
			.actions
				margin 8px 0 0 0
				.action
					display inline-block
					margin 0 24px 0 0
					height 32px
					color #666
					font-size 12px
					font-weight 500
					line-height @height
					cursor pointer
					&:hover
						color #333
					.icon
						float left
						margin 4px 8px 0 0



.prix
	text-align right
	font-weight 600
	color primaryA100

.BRS
	padding 4px 8px
	width 44px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.ass
	width fit-content
	padding 4px 8px
	height 24px
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.tvaR
	padding 4px 8px
	width 44px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.sansp
	padding 4px 12px
	width 110px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
.vide
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
.details-container
	text-align justify
	padding 10px

.section-title, h3
	font-weight 500
	font-size 18px
	margin 0 0 16px
.description
	font-weight 400
	font-size 12px
	letter-spacing normal
	color #848282

.feature
	margin-bottom 10px
	width 33%
	font-size 12px

.value
	height 60px
	p
.tile
	display inline-block
	vertical-align top
	width 43px
	height 20px
	line-height 20px
	font-weight 700
	text-align center

.tile.selected
	position relative
	width 43px
	height 43px
	margin-top -11px
	margin-left -5px
	margin-right -5px
	line-height 40px
	border 2px solid white
	border-radius 50%
	z-index 999
.spanlib
	padding 12px 0 0
	line-height 18px
	font-weight 400
	font-size x-small

.app-property-energy-indicator
	width 380px
	h3
		font-weight 400
		font-size 13px

.annonce_detail
	border 2px solid #651fff
	background #f5f5f5

.detail
	cursor pointer

.details_moins
	image '../../assets/img/minus-circle.svg'
.details_plus
	image '../../assets/img/plus-circle.svg'

.snacks 
	display flex
	flex-wrap wrap  
	.snack 
		margin 0 4px 8px
	.snack
		cursor pointer
		display inline-block
		background var(--fade-grey-light-2)
		height 30px
		width 100px
		border-radius 500px
		border 1px solid var(--fade-grey-dark-3)
		transition all 0.3s
		.stxt
			position absolute
			left 10px
			line-height 30px
			vertical-align middle
			color #b388ff	
		.icon
			position absolute
			right 2px
			top 2px
			color #b388ff	
		&:hover 
			box-shadow var(--light-box-shadow)
			background #b388ff
			.stxt
				color #fff	
			.icon
				color #fff	
		&.is-white
			background var(--white)
		&.is-small
			height 30px

@media print
	.annonce
		.annonce-detail
			.annonce-detail-content
				.actions
					display none


</style>
