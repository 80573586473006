<template>
	<div>
		<div v-show="app.mrk.lat" class="avm2" @click.stop="LanceAVM()" :title="$t('Calcul de valeur de marché')" :class="{ idf: !app.module_actif }">
			<icon file="calcul_avm" :size="24" />&nbsp;
		</div>
		<div class="avm_price maplayer_filtres" v-show="visible||app.loading_avm">
			<div style="text-align: center; vertical-align: middle;height:100%;" v-show="app.loading_avm">
				<table style="width:100%">
					<th style="display: none"></th>
					<tr style="height:200px"><td></td></tr>
					<tr style="height:150px;text-align:center;"><td>
						<div class="text">
							<p>{{ $t('calcul_de_la_valeur_de_marche') }}</p>
							<p>{{ $t('en_cours') }} ...</p>
							<p>&nbsp;</p>
							<div class="loading-icon"><icon file="loader" :size="64" /></div>
						</div>
					</td></tr>
					<tr style="height:200px"><td></td></tr>
				</table>
			</div>
			<div v-show="!app.loading_avm">				
				<div class="actionfermer" @click.stop="resetavm();details_visible=false;visible=false">
					<a class="download_table" @click="$store.dispatch('ExportToPng',{id: 'C_avm'});"><span><icon file="image" /></span></a>
					<icon file="x" />
				</div>
				<br>
				<div id="C_avm">
					<table class="tablprix">
                        <th style="display: none"></th>
						<tbody>
							<tr>
								<td class="cellcenter" colspan="2">{{ $t('valeur_de_marche_tva_pleine_parking_inclus') }}</td>
							</tr>
							<tr class="borderbottomfonce">
								<td class="colfirst">{{ $t('immediate') }}</td>
								<td class="colprix2" v-if="app.avm.prix_marche"><b>{{enprix(app.avm.prix_marche)}}</b></td>
								<td class="colprix2" v-else><b>{{$t('NC')}}</b></td>
							</tr>
							<tr class="borderbottomfonce">
								<td class="colfirst">{{ $t('depuis_18_mois') }}</td>
								<td class="colprix2" v-if="app.avm.prix_marche"><b>{{percent(app.avm.prix_evol)}}</b></td>
								<td class="colprix2" v-else><b>{{$t('NC')}}</b></td>
							</tr>
							<tr>
								<td style="font-size:8pt; vertical-align: middle;">
								</td>
								<td style="text-align: right">
									<br>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="tablprix">
                        <th style="display: none"></th>
						<tbody>
							<tr>
								<td class="cellcenter" colspan="4">{{ $t('prix_des_references_presentes_dans_le_rayon') }}</td>
							</tr>
							<tr class="borderbottomfonce">
								<td class="colfirst">&nbsp;</td>
								<td class="coltitre">{{ $t('min') }}</td>
								<td class="coltitre">{{ $t('med') }}</td>
								<td class="coltitre">{{ $t('max') }}</td>
							</tr>
							<tr class="borderbottom">
								<td class="colfirst">{{ $t('logements') }} {{ $t('t3') }}</td>
								<td class="colprix">{{enprix(app.avm.ref_t3.prix_min)}}</td>
								<td class="colprix"><b>{{enprix(app.avm.ref_t3.prix_med)}}</b></td>
								<td class="colprix">{{enprix(app.avm.ref_t3.prix_max)}}</td>
							</tr>
							<tr class="borderbottom">
								<td class="colfirst">{{ $t('tous_les_logements') }}</td>
								<td class="colprix">{{enprix(app.avm.ref_tous.prix_min)}}</td>
								<td class="colprix"><b>{{enprix(app.avm.ref_tous.prix_med)}}</b></td>
								<td class="colprix">{{enprix(app.avm.ref_tous.prix_max)}}</td>
							</tr>
							<tr>
								<td colspan=4>&nbsp;</td>
							</tr>
						</tbody>
					</table>
					<div id="btnd" @click.stop="details_visible=!details_visible; if(details_visible){majHeatMap()}"></div>
					
					<div id="heatmapAVM" style="height:280px"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'MapAvm',

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	data () {
		return {
			Lrayon: false,
			visible: false,
			details_visible: false,
		}
	},

	watch: {
		'app.view': function() {
			if (this.$store.map_osm) this.removelayer();
		},

		'app.avm.heatmap.rayon': function(newValue) {
			this.removelayer()
			if (newValue) {
				this.addlayer()
				this.visible = true
			} else {
				this.visible = false
				this.details_visible = false
			}
		},

		'app.avm.prix_marche': function() {
			this.majHeatMap();
		},
	},

	methods: {
		LanceAVM: function() {
			this.visible=!this.visible
			if (!this.visible) {
				this.details_visible = false
				this.removelayer()
			} else {
				this.avm()
			}
		},
		
		info_avm: function() {
			this.$store.dispatch('add_notification', { status: 'warning', message: "veuillez faire une recherche sur une adresse afin d'activer le service de calcul de marché" })
		},

		avm: function() {
			this.$store.dispatch('getavm', {latlng :{ lat : this.app.mrk.lat, lng : this.app.mrk.lng}})
		},
		
		removelayer: function() {
			if(this.Lrayon) {
				this.$store.map_osm.removeLayer(this.Lrayon)
			}
		},
		
		resetavm: function() {
			this.$store.dispatch('resetavm')
		},
		
		addlayer: function() {
			try {
				this.Lrayon.clearLayers();
			} catch (error) {
				// erreur
			}
			let Crayon = L.circle([this.app.mrk.lat, this.app.mrk.lng], this.app.avm.heatmap.rayon, {
				color: '#e84242',
				fillOpacity: 0
			});
			this.Lrayon = new L.layerGroup([Crayon])
			this.$store.map_osm.addLayer(this.Lrayon);
		},

		HeatMap: function(Stream, Depart, Inc, nCpt, times, rayon) {
			let days = ["T1", "T2", "T3", "T4", "T5"],
			margin = {
				top: 90,
				right: 20,
				bottom: 60,
				left: 40
			};
			
			let width = 320,
			gridSize = Math.floor(width / times.length),
			height = 25 * days.length;
			let gridHeight = 25;

			let maingroup = d3.select('#heatmapAVM')
				.append("svg")
				.attr("class", "svg")
				.attr("width", width + margin.left + margin.right)
				.attr("height", height + margin.top + margin.bottom)
				.append("g")
				.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
			
			maingroup.selectAll(".dayLabel")
				.data(days)
				.enter().append("text")
				.text(function (d) { return d; })
				.attr("x", 0)
				.attr("y", function (d, i) { return i * gridHeight; })
				.attr("transform", "translate(-6," + gridHeight / 2 + ")")
				.attr("class", function (d, i) { return ((i >= 0 && i <= 4) ? "dayLabel axis-workweek" : "dayLabel"); })
				.style("text-anchor", "end");
			
			maingroup.selectAll(".timeLabel")
				.data(times)
				.enter().append("text")
				.text(function(d) { return d; })
				.attr("x", function(d, i) { return i * gridSize; })
				.attr("y", 0)
				.attr("transform", "translate(" + gridSize / 2 + ", -6)")
				.attr("class", function(d, i) { return ((i >= 9 && i <= 19) ? "timeLabel axis-worktime" : "timeLabel"); })
				.style("text-anchor", "middle");
			
			maingroup.append("text")
				.attr("class", "title")
				.attr("x", width / 2)
				.attr("y", -70)
				.style("text-anchor", "middle")
				.text("Heatmap prix au m² *");
			
			let data = Stream
			data.forEach(function(d) {
				d.type = +d.type;
				d.quarter = +d.quarter;
				d.count = +d.count;
			});
			
			maingroup.append("text")
				.attr("class", "subtitle")
				.attr("x", width / 2)
				.attr("y", -50)
				.style("text-anchor", "middle")
				.text("Sur " + nCpt + " références dans un rayon de " + rayon.toString() + "m");
			
			maingroup.append("text")
				.attr("class", "subtitle")
				.attr("x", width / 2)
				.attr("y", -30)
				.style("text-anchor", "middle")
				.text("appartenant à la commune de '" + this.app.adr_query.data.city + "'");
			
			let colorScale = d3.scaleLinear()
				.domain([0, d3.max(data, function(d) {return d.count; }) / 2, d3.max(data, function(d) {return d.count; })])
				.range(["#f7fbff", "#6baed6", "#08306b"]);
			
			maingroup.selectAll(".hour")
				.data(data)
				.enter().append("rect")
				.attr("x", function(d) { return d.quarter * gridSize; })
				.attr("y", function(d) { return d.type * gridHeight; })
				.attr("width", gridSize)
				.attr("height", gridHeight)
				.style("stroke", "white")
				.style("stroke-opacity", 0.6)
				.style("fill", function(d) { return colorScale(d.count); });
			
			let countScale = d3.scaleLinear()
				.domain([0, d3.max(data, function(d) {return d.count; })])
				.range([0, width])
			
			let numStops = 3;
			let countPoint = [0, d3.max(data, function(d) {return d.count; }) / 2, d3.max(data, function(d) {return d.count; })];
			
			maingroup.append("defs")
				.append("linearGradient")
				.attr("id", "legend-traffic")
				.attr("x1", "0%").attr("y1", "0%")
				.attr("x2", "100%").attr("y2", "0%")
				.selectAll("stop") 
				.data(d3.range(numStops))				
				.enter().append("stop") 
				.attr("offset", function(d,i) { 
					return countScale(countPoint[i]) / width;
				})   
				.attr("stop-color", function(d,i) { 
					return colorScale(countPoint[i]); 
				});
			
			let legendWidth = Math.min(width * 0.8, 400);
			
			let legendsvg = maingroup.append("g") // groupe principal
				.attr("class", "legendWrapper")
				.attr("transform", "translate(" + (width/2) + "," + (gridHeight * days.length + 30) + ")");
			
			legendsvg.append("rect") // rectangle avec gradient
				.attr("class", "legendRect")
				.attr("x", -legendWidth/2)
				.attr("y", 0)
				.attr("width", legendWidth)
				.attr("height", 10)
				.style("fill", "url(#legend-traffic)");
			
			legendsvg.append("text") // légende
				.attr("class", "legendTitle")
				.attr("x", 0)
				.attr("y", -10)
				.style("text-anchor", "middle")
				.text("Nombre de référence");
			
			let xScale = d3.scaleLinear() // scale pour x-axis
				.range([-legendWidth / 2, legendWidth / 2])
				.domain([ 0, d3.max(data, function(d) { return d.count; })] );
			
			legendsvg.append("g") // x axis
				.attr("class", "axis")
				.attr("transform", "translate(0," + (10) + ")")
				.call(d3.axisBottom(xScale).ticks(5));
		},
		
		majHeatMap: function() {
			document.getElementById('heatmapAVM').innerHTML = ""
			this.HeatMap(this.app.avm.heatmap.data, this.app.avm.heatmap.m, this.app.avm.heatmap.diff, this.app.avm.heatmap.cpt, this.app.avm.heatmap.price, this.app.avm.heatmap.rayon)			
		},
		
		enprix: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n) n = Math.round(n/10)*10		 
				return n.toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
			return ""
		},

		percent: function(str) {
			if (str) {
				let n = parseFloat(str)
				if (n)
				if (n > 0) {
					return '+' + Math.round(n) + ' %'
				} else {
					return Math.round(n) + ' %'
				}
			}
			return "stable"
		},

		click: function(pin_type) {
			this.visible=false
			this.$store.dispatch('pin_type', {pin_type: pin_type})
		},
		
		removeall: function() {
			this.$store.dispatch('select_programs', { programs: this.app.programs, selected: false })
		}
	}
}
</script>

<style lang="stylus">
@import '../../assets/css/app'

.avm_detail
	position absolute
	top 50px
	left 300px
	width 400px
	height 540px
	z-index 500
	transition 0.5s easeOutQuart
	padding 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	z-index 500
	background #F3F3F3
.avm_price
	position absolute
	top 50px
	left 10px
	width 280px
	height 150px
	z-index 500
	transition 0.5s easeOutQuart
	padding 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	z-index 500
	background #F3F3F3
.mapavm
	position absolute
	width 32px
	top 10px
	height 32px
	left 10px
	width 280px
	z-index 500
	transition 0.5s easeOutQuart
	padding 4px 4px 4px 4px
	text-align center
	border-radius radius 5px
	background white
	color #666
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)

.tablprix
	width 100%
	padding 10px
	border-collapse collapse
	border-spacing 0
	border: 0;
	tr
		height 25px
		vertical-align top
	td
		padding 5px 5px 5px 5px

.cellcenter
	text-align center

.borderbottomfonce
	border-bottom 1px solid #a6a6a6

.colfirst
	border-right 1px solid #a6a6a6
	text-align left
.colprix
	width 20%
	text-align right
	border-right 1px solid #e6e6e6
	background-color #FFF
.colprix2
	width 40%
	text-align right
	border-right 1px solid #e6e6e6
	background-color #FFF

.timeLabel, .dayLabel
	fill #404040
	font-weight 300
	font-size 11px

.loading-icon
	animation rotate 2s infinite linear
	margin 0 auto

.inactif
	background-color #eee
	color #ccc

.subtitle
	font-size 11px

.actionfermer
	z-index 999
	position absolute
	right 0px
	top 0px
.avm
	width 60px;
	height 60px;
	position absolute
	right 120px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 60px !important

.maplayer_filtres
	position fixed
	width 400px
	height 600px !important
	top calc(50% - 300px)
	left calc(50% - 200px)
	z-index 600
	transition 0.5s easeOutQuart
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	cursor pointer
	height 580px
	max-height 900px
	margin-top 1.33333rem
	padding 20px
	pointer-events all
	transform translateY(0)
	opacity 1
	border-radius 0
	background-color #fff
	box-shadow 0 6px 24px 0 alpha(#000, 25%)
	transition .3s transform
	transition-property transform
	transition-duration 0.3s
	transition-timing-function ease
	transition-delay 0s
	cursor normal
	&:hover
		background-color #fff !important
	
.infinite-loader
	.loader
		display inline-block
		border none
		border-radius 100%
		.icon
			display block
			animation rotate 1.5s infinite linear
.avm2
	width 60px
	height 60px
	position absolute
	left 60px
	text-align center
	line-height 60px
	cursor pointer
	&:hover
		color #6C21F9

</style>
