<template>
	<transition name="pane-ancien-mutation">
		<div class="pan-ancien-mutation unmodule" :id=mutation.propertyId style="border-bottom: 1px solid #ccc;">
			<div class="cs-widget-collection-card" style="width:98%">
				<div class="cs-widget-transaction-card ng-star-inserted" style="color:#333333">
					<div style="display:flex;justify-content: space-between;line-height:24px; vertical-align: middle;padding-bottom:10px">
						<div v-if="getDetails(mutation).length==1">
							<icon :file="GetIcone(getDetails(mutation)[0].propertyType)" :size="24" style="color:#666666;width:24px;height:24px"></icon>							
						</div>
						<div v-else  style="font-weight: 550; vertical-align: middle;">Vente multiple</div>
						<div style="font-weight: 550; vertical-align: middle;" v-if="getDetails(mutation).length==1">
							{{getDetails(mutation)[0].rooms}} {{ $t('pieces') }} - {{getDetails(mutation)[0].area}} m²
						</div>
						<div style="font-weight: 550; vertical-align: middle;">
							{{getPrice(mutation.price)}}
						</div>
						<div style="font-style: italic; vertical-align: middle;">
							{{getPricePerSquareMeter(mutation)}}
						</div>
					</div>
					<div style="display:flex;line-height:24px; vertical-align: middle;padding-bottom:10px;">
						<div>
							<icon file="calendar" :size="24" style="color:#666666;width:24px;height:24px"></icon>							
						</div>
						&nbsp;
						<div style="font-weight: 550; vertical-align: middle;top:2px">
							{{mutation.baseDate.substring(0,10)}}
						</div>
					</div>
					<div style="display:flex;line-height:24px; vertical-align: middle;padding-bottom:10px;justify-content: space-between;">
						<div>
							{{TraiteAdresse(mutation.fullAddress)}}
						</div>
						<div style="width:24px;text-align: right;">
							<a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + mutation.location.coordinates[0] + ',' + mutation.location.coordinates[1]"><img alt="streetview" :title="$t('voir_sur_google_street_view')" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>
								&nbsp;
							</a>
						</div>
					</div>
					<div style="display:flex;line-height:24px; vertical-align: middle;padding-bottom:10px;">
						<div v-if="mutation.id_parcelle.length">
							{{ $t('N° de parcelle :') }} {{mutation.id_parcelle[0]}}
						</div>
					</div>
					<div v-if="getDetails(mutation).length>1">
						{{ getDetails(mutation).length }} lots :
						<div v-for="(det, index) in getDetails(mutation, true)" v-bind:key="mutation.propertyId + '_' + index" class="cs-widget-dvf-card ng-star-inserted" style="display: flex;width: 100%;justify-content: space-between;padding-top:10px">
							<div style="font-weight: 550;padding-left:30px"><div class="dot"></div>{{GetLabel(det.propertyType)}} - {{det.rooms}} {{ $t('pieces') }} - {{det.area}} m²</div>
						</div>
						<div v-if="getDetails(mutation).length>4 " @click="ouvert=!ouvert" style="width:100%;text-align: center;padding-top:10px;padding-bottom:10px">
							<div class="dot2"></div><div class="dot2"></div><div class="dot2"></div>
						</div>
						<div v-else style="width:100%;text-align: center;">&nbsp;</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { parseFromWK } from 'wkt-parser-helper'

export default {
	name: 'PaneAncienMutation',
	data: function() {
		return {
			ouvert: false,
			markerSub: false,
		}
	},

	props: {
        mutation: {
			default: false
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
        
		hover_parcelle: function(parcelle) {
            this.$store.select_parcelle.clearLayers()
            let geojson = parseFromWK(parcelle.parcels[0].geometry.wktGeometry)
            let tab = geojson.coordinates
            for (let n=0; n < tab[0].length; n++) {
                tab[0][n].reverse()
            }
            this.markerSub = L.polygon(tab, {color: '#000000'})
            this.markerSub.addTo(this.$store.select_parcelle)
		},

		unhover_parcelle: function() {
            this.$store.select_parcelle.clearLayers()
        },

		TraiteAdresse: function(ad) {
			let a = ad.replace("Arrondissement", "arr.");
			a = a.toLowerCase() // Convertit tout en minuscules
				.split(' ')    // Divise la chaîne en un tableau de mots
				.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Met en majuscule la première lettre de chaque mot
				.join(' ');
			return a;
		},

		getDetails: function(mutation, b) {
			let t = []
			for (let index = 0; index < mutation.details.length; index++) {
				const element = mutation.details[index];
				if (element.propertyType == 'Appartement' || element.propertyType == 'Maison') {
					t.push(element)
					if (b && !this.ouvert && t.length==4) return t
				}
			}
			return t
		},

		getTransactionType: function(type) {
			switch (type) {
				case 'sale', 'Vente':
					return 'Vendu'
				case 'under_construction_sale', 'Vente en l\'état futur d\'achèvement':
					return 'VEFA'
				default:
					return type;
			}
		},

		getPricePerSquareMeter: function(mutation) {
			let surf = 0
			for (let index = 0; index < mutation.details.length; index++) {
				const element = mutation.details[index];
				if (element.propertyType == 'Appartement' || element.propertyType == 'Maison') {
					surf += element.area
				}
			}
			if (surf > 0) {
				const euro = new Intl.NumberFormat('fr-FR', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0
				});
				return euro.format(Math.round(mutation.price / surf)) + '/m²'

			}
			return ''
		},

		GetClass: function(type) {
			switch (type) {
				case 'dependency', 'Dépendance':
					return 'dependance'
				case 'house', 'Maison':
					return 'maison'
				case 'apartment', 'Appartement':
					return 'appartement'
				case 'land', 'Terrain':
					return 'terrain'
				case 'industrial_commercial_premise', 'Local industriel. commercial ou assimilé':
					return 'local'
				default:
					break;
			}
		},

		GetIcone: function(type) {
			switch (type) {
				case 'house', 'Maison':
					return 'home'
				default:
					return 'building'
			}
		},

		GetLabel: function(type) {
			switch (type) {
				case 'dependency', 'Dépendance':
					return 'Dépendance'
				case 'house', 'Maison':
					return 'Maison'
				case 'apartment', 'Appartement':
					return 'Appartement'
				case 'land', 'Terrain':
					return 'Terrain'
				case 'industrial_commercial_premise', 'Local industriel. commercial ou assimilé':
					return 'Local industriel. commercial ou assimilé'
				default:
					break;
			}
		},

		getPrice: function(price) {
            const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (price==0) {
				return "-"
			} else {
                return euro.format(price)
			}
		},
	},
	
	components: {  }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pan-ancien-mutation
	width PaneLeftSize

.cs-transaction-id
	position: absolute
	left: 50px
	top 10px

.cs-transaction-price
	text-align: right
	position: absolute
	right: 20px
	top 10px
	font-weight 600

.cs-transaction-year
	position: absolute
	left: 150px
	top 10px

.cs-transaction-type
	position: absolute
	left: 200px
	top 10px

.cs-transaction-parcel
	top 34px
	left: 50px

.cs-collection-card-header
	border-bottom 1px solid #EFEFEF
	height 60px

.cs-widget-collection-card .cs-collection-data-tag
	border-radius 20px
	font-size .813rem
	padding 7px 10px
	color #fff
	display inline-block

.cs-collection-card-content
	padding-top 10px
	padding-bottom 10px
	border-bottom 1px solid #EFEFEF

.cs-collection-data-row
	padding-top 10px

.cs-collection-detail-link
	left 220px
	width 100%
	padding 16px 0

.maison
	background-color rgb(64, 137, 0)
.appartement
	background-color rgb(6, 129, 175)
.terrain
	background-color rgb(255, 167, 38)
.dependance
	background-color rgb(255, 23, 19)
.local
	background-color rgb(102, 0, 229)

.icone_parcelle
	font-size 1.2rem
	position absolute
	top 2px

.parcelle
	background #c72a2a
	height 24px
	width 150px
	padding-left 5px
	padding-right  5px
	padding-top 5px
	padding-bottom 5px
	border-radius 3px
	border 1px
	color #fff
	margin-right 5px
	margin-top 5px
	-webkit-transition .2s ease-in-out
	transition .2s ease-in-out

.unmodule
	height auto !important
	width PaneLeftSize !important

.dot
	width 4px
	height 4px
	background-color #6b23f1
	border-radius 50%
	display inline-block
	margin-right 6px
	line-height 24px
	vertical-align middle

.dot2
	width 6px
	height 6px
	background-color #CCC
	border-radius 50%
	display inline-block
	margin-right 6px
	line-height 24px
	vertical-align middle

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
