import Vue from 'vue'



// STATE
const state = {
	tab: 'optimisation',
	secteurs: [
		{ id: 1, nom: "Lyon 8ème", nb_programmes: 7, checked: true },
		{ id: 2, nom: "Lyon 7ème", nb_programmes: 6, checked: true },
		{ id: 3, nom: "Saint Priest", nb_programmes: 3, checked: true },
	],
	stat_secteurs : [],
	stat_programmes : []
}



// GETTERS
const getters = {
	report: function(state) { return state },
	nb_checked_secteurs: function(state) { return _.filter(state.secteurs, { checked: true }).length },
	stat_secteurs: function(state) { return state.stat_secteurs },
	stat_programmes: function(state) { return state.stat_programmes }
}



// ACTIONS
const actions = {

	set_tab: function({ commit }, data) {
		commit('SET_TAB', { tab: data.tab })		
	},

	check_secteur: function({ commit }, data) {
		data.checked = !data.secteur.checked
		commit('EDIT_SECTEUR', data)
	},

}



// MUTATIONS
const mutations = {

	SET_TAB: function(state, data) {
		state.tab = data.tab
	},

	EDIT_SECTEUR: function(state, data) {
		if (data.secteur) {
			for (let i in data) {
				if (i != 'secteur') {
					Vue.set(data.secteur, i, data[i])
				}
			}
		}
	}

}

export default { state, getters, actions, mutations }