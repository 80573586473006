<template>
	<div class="stat-box" id="rs_repa_tr">
		<div class="stat-box-header">
			<p class="title">{{ $t('analyse_de_la_composition_des_menages') }} {{ app.adr_init.territoire }}</p>
			<span class="tabs">
				<a id="export09" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'rs_repa_tr', exportid: 'export09'});"><span><icon file="image" /></span></a>
			</span>
		</div>
		<div class="stat-box-content">
			<div class="row">
				<div class="col half">
					<div class="graph">
						{{ $t('repartition_des_menages_par_tranches_dâges') }}
						<highcharts :options="chartOptions_men_tranche"></highcharts>
					</div>
				</div>
				<div class="col half">
					<div class="graph" v-if="app.livedata.insee && app.livedata.insee.length" :title="$t('Pour la tranche d\'âge de ménages correspondante, sur la période ') + (parseInt(app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year)-5).toString() + ' ' + $t('a') + ' ' + (app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year) + $t(' (les 2 derniers recensements INSEE), il y a eu sur le territoire ') + app.adr_init.territoire + $t(' X personnes en plus (si valeur positive) ou en moins (si résultat négatif) par an')">						
						{{ $t('évolution_annuelle_du_nombre_de_menages_:_gains_et_pertes_annuelles_entre') }} {{app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year-5}} {{ $t('et') }} {{app.livedata.insee[0].data[app.livedata.insee[0].data.length-1].year}}
						<highcharts :options="chartOptions_men_tranche_gain" id="rs_men_tr"></highcharts>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

export default {
	name: 'GraphEvolutionInseeAge',

	data: function() {
		return {
			chartOptions_men_tranche: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
					{
						color: '#2196F3',
						name: "Offre commerciale",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
			chartOptions_men_tranche_gain: {
				credits: {
					enabled: false
				},
				title: '',
				chart: {
					height: 280,
					type: 'column',
					style: {
						fontFamily: 'Nunito',
					}
				},
				series: [
				{
						color: '#2196F3',
						name: "Mises en vente",
						data: [0,0,0],
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
								[0, 'rgba(33,150,243,0.3)'],
								[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
				],
				xAxis: {
					type : 'category',
					categories : ['', '', '', '', '', '', '', ''],
					title: {
						text: ''
					},
					allowDecimals: false,
					lineWidth: 1,
					lineColor: '#ddd',
				},
				yAxis: {
					title: {
						text: ''
					},
					lineWidth: 0,
					gridLineColor: '#ddd',
					gridLineDashStyle: 'dash'
				},
				
				plotOptions: {
					series: {
						label: {
							connectorAllowed: false
						},
					}
				},
				tooltip: {
					shared: true,
					crosshairs: true,
					backgroundColor: '#fff',
					borderWidth: 0,
					borderRadius: 4
				},
			},
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
	}),

	methods: {

		maj_age: function() {
			if (this.app.livedata.insee && this.app.livedata.insee.length) {
			this.chartOptions_men_tranche.series[0].name = this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year;
			this.chartOptions_men_tranche.series[0].data = [
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.number, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.number, 
			];
			this.chartOptions_men_tranche.xAxis.categories= ['0-29 ans', '30-39 ans', '40-49 ans', '50-59 ans', '60-74 ans', '75 ans et plus']
			}
		},

		maj_gain: function() {			
			if (this.app.livedata.insee && this.app.livedata.insee.length) {
				let l = (this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year-5).toString() + ' - ' + (this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].year).toString();
			this.chartOptions_men_tranche_gain.series[0].name = l
			this.chartOptions_men_tranche_gain.series[0].data = [
			this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_0_30.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_30_39.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_40_49.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_50_59.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_60_74.annual_evolution, 
				this.app.livedata.insee[0].data[this.app.livedata.insee[0].data.length-1].household.age_division.age_75_more.annual_evolution, 
			];
			this.chartOptions_men_tranche_gain.xAxis.categories= ['0-29 ans', '30-39 ans', '40-49 ans', '50-59 ans', '60-74 ans', '75 ans et plus']
			}
		}
	},

	mounted: function() {
		this.maj_age()
		this.maj_gain()
	},

	watch: {
		'app.livedata': function() {
			if (this.app.view == 'report' || this.app.view == 'report_B') {
				this.maj_age()
				this.maj_gain()
			}
		},
	},
	
	components: { highcharts: Chart }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'

.stat-box
	.stat-box-content
		padding 0 16px
		text-align center

.graph
	min-height 200px
	text-align center
	line-height @height


</style>
