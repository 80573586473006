<template>
	<transition name="pane-ancien-offres">
		<div class="paneleft pan-ancien-offres">
			<div class="module_ret">
				<div class="btns export" title="Export Excel">
					<download-excel :data="getJsonAncien()" :name="getNameFile()" :fields="json_fields" worksheet="efocus_annonces_location">
						<div><icon file="download" :size="24" /></div>
					</download-excel>
				</div>
				<div class="btns filtreancien" @click="$store.dispatch('set_view', { view: 'locatif' })"><icon file="back" :size="24" /></div>
				<div class="head_filter" @click.stop="cv = !cv" title="Filtrer ma recherche">
					<icon file="filter_list" :size="36" class="filters" />&nbsp;
				</div>
			</div>
			<div class="unmodule_detail_titre" style="height:84px !important;position: absolute;top:60px; border-right: 1px solid #ccc">
				<div class="icone_module"><img src="../../assets/img/yanport.png" alt="cityscan" style="height:30px;width:30px;text-align:left;"></div>
				<div class="thematique">{{ $t('module') }}</div>
				<div class="titre_sommaire"><b>{{ $t('liste de l\'offre locative') }}</b></div>					
				<div style="position:absolute;color:grey;bottom:2px;right:5px;font-size:12px">{{ Annonces.length }} offre<span v-if="Annonces.length>1">s</span></div>	
				
			</div>
			<div class="_filtre">
				<div class="box">
					<transition name="fade">
						<div v-if="cv" style="border-bottom: 4px solid #41b983; padding-bottom: 10px; padding-top: 10px; padding-top: 10px;height:450px">
							<table class="_box-title2">
								<th style="display:none"></th>
								<tr>
									<td colspan="4">{{ $t('Loyer') }}</td>
								</tr>
								<tr>
									<td style="width:50%">
										<div class="p-inputgroup">
											<InputNumber inputStyle="width:100%;text-align:right" id="integeronly" v-model="price_min" @change="valideInterval('price_min', $event)" @focus="selectContent"/>
											<span class="p-inputgroup-addon">€, min</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
									<td style="width:50%">
										<div class="p-inputgroup">
											<InputNumber inputStyle="width:100%;text-align:right" id="integeronly" v-model="price_max" @change="valideInterval('price_max', $event)" @focus="selectContent"/>
											<span class="p-inputgroup-addon">€, max</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
								</tr>
								<tr>
									<td colspan="4" style="padding-top:5px;">{{ $t('Surface') }}</td>
								</tr>
								<tr>
									<td>
										<div class="p-inputgroup">
											<InputNumber inputStyle="width:100%;text-align:right" id="integeronly" v-model="area_min" @change="valideInterval('area_min', $event)" @focus="selectContent"/>
											<span class="p-inputgroup-addon">m², min</span>
										</div>
									</td>
									<td style="width:10px">&nbsp;</td>
									<td>
										<div class="p-inputgroup">
											<InputNumber inputStyle="width:90px;text-align:right" id="integeronly" v-model="area_max" @change="valideInterval('area_max', $event)" @focus="selectContent"/>
											<span class="p-inputgroup-addon">m², max</span>
										</div>
										
									</td>
									<td>&nbsp;</td>
								</tr>
							</table>
							<div class="_box-title2">																	
								<table style="width:100%">
									<th style="display:none"></th>
									<tr>
										<td style="width:60%">
											<p class="box-title3">{{ $t('typologie') }}</p>
										</td>
										<td style="width:40%">
											<p class="box-title3">{{ $t('meuble') }}</p>
										</td>
									</tr>
									<tr>
										<td>
											<div class="box-content">
												<div class="switches">
													<switch-button size2="80px" :label="$t('t1')" divise="five" @click.native="t1 = !t1; toggle_switch('t1')" :class="{ active: t1 }" />
													<switch-button size2="80px" :label="$t('t2')" divise="five" @click.native="t2 = !t2; toggle_switch('t2')" :class="{ active: t2 }" />
													<switch-button size2="80px" :label="$t('t3')" divise="five" @click.native="t3 = !t3; toggle_switch('t3')" :class="{ active: t3 }" />
													<switch-button size2="80px" :label="$t('t4')" divise="five" @click.native="t4 = !t4; toggle_switch('t4')" :class="{ active: t4 }" />
													<switch-button size2="80px" :label="$t('t5')" divise="five" @click.native="t5 = !t5; toggle_switch('t5')" :class="{ active: t5 }" />
												</div>
											</div>
										</td>
										<td>
											<div class="box-content">
												<div class="switches">
													<switch-button size2="80px" :label="$t('oui')" divise="three" @click.native="MeubleOui = !MeubleOui; toggle_switchMeuble('MeubleOui')" :class="{ active: MeubleOui }" />
													<switch-button size2="80px" :label="$t('non')" divise="three" @click.native="MeubleNon = !MeubleNon; toggle_switchMeuble('MeubleNon')" :class="{ active: MeubleNon }" />
													<switch-button size2="80px" :label="$t('n.c.')" divise="three" @click.native="MeubleNC = !MeubleNC; toggle_switchMeuble('MeubleNC')" :class="{ active: MeubleNC }" />											
												</div>
											</div>
										</td>
									</tr>
								</table>
							</div>	
							<div class="_box-title2">								
								<p class="box-title3">{{ $t('dpe') }}</p>
								<div class="box-content">
									<div class="switches">
										<switch-button-dpe label="A" divise="eight_A" size=24 @click.native="DPE_A = !DPE_A; toggle_switchDPE('A')" :class="{ active: DPE_A }"  />
										<switch-button-dpe label="B" divise="eight_B" @click.native="DPE_B = !DPE_B; toggle_switchDPE('B')" :class="{ active: DPE_B }"  />
										<switch-button-dpe label="C" divise="eight_C" @click.native="DPE_C = !DPE_C; toggle_switchDPE('C')" :class="{ active: DPE_C }"  />
										<switch-button-dpe label="D" divise="eight_D" @click.native="DPE_D = !DPE_D; toggle_switchDPE('D')" :class="{ active: DPE_D }" />
										<switch-button-dpe label="E" divise="eight_E" @click.native="DPE_E = !DPE_E; toggle_switchDPE('E')" :class="{ active: DPE_E }"  />
										<switch-button-dpe label="F" divise="eight_F" @click.native="DPE_F = !DPE_F; toggle_switchDPE('F')" :class="{ active: DPE_F }"  />
										<switch-button-dpe label="G" divise="eight_G" @click.native="DPE_G = !DPE_G; toggle_switchDPE('G')" :class="{ active: DPE_G }"  />
										<switch-button-dpe label="NC" divise="eight_N" @click.native="DPE_NC = !DPE_NC; toggle_switchDPE('NC')" :class="{ active: DPE_NC }"  />
									</div>
								</div>
							</div>	
							<br><br>
							<div style="display: flex;justify-content: center;">
								<div class="app_filtre" style="width:200px" @click="applique_filtre()">{{ $t('appliquer_les_filtres') }}</div>
							</div>
						</div>	
					</transition>				
				</div>
				
				<div class="programs-list" >
					<div class="programs-list-content" v-bar :class="{ff:cv}">
						<div class="infinite-loader" v-if="app.loading_offre" style="text-align: center;">
							<div class="text">
								<p>{{$t('Recherche de l\'offre locative')}}</p>
								<p>{{ $t('en_cours') }} ...</p>
								<p>&nbsp;</p>
							</div>
							<div class="loader"><icon file="loader" :size="64" style="color:#333" /></div>
						</div>
						<div class="content" style="width:100% !important;" ref="scrollable_programs" v-else>
							<annonce
							v-for="annonce in Annonces"
							:annonce="annonce"
							class="bien hov"
							:id="'annonce-' + annonce.id"
							:key="annonce.id"
							@mouseenter.native="hover_parcelle(annonce)"
							@mouseleave.native="unhover_parcelle(annonce)"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Annonce from '../pane-locatif/Annonce'
import SwitchButton from '../global/SwitchButton'
import SwitchButtonDpe from '../global/SwitchButtonDpe'
import VueJsonToXls from 'vue-json-excel'
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';

export default {
	name: 'PaneAncienOffres',
	data: function() {
		return {
			json_fields: {
				"Appartement/Maison": "type",
				"Typologie": "nbpieces",
				"Créé le": "cree",
				"Mis à jour le": "maj",
				"Surface": "surface",
				"Loyer mensuel hors charges": "prix",
				"Montant des charges": "charge",
				"Année de construction": "annee",
				"Etat": "etat",
				"Energie note": "energie_note",
				"Energie (kWhep/m².an)": "energie_val",
				"Emission note": "emission_note",
				"Emission (kgéqCO2/m².an)": "emission_val",
				"Parking": "parking",
				"Desciption": "description",
			},
			value4: [20,80],
			detail: '',
			price_min:0,
			price_max:9999999,
			t1:true, 
			t2:true, 
			t3:true, 
			t4:true, 
			t5:true, 
			area_min: 0,
			area_max: 999,
			cv: false,
			Annonces:[],

			MeubleOui: true,
			MeubleNon: true,
			MeubleNC: true,
			DPE_A: true,
			DPE_B: true,
			DPE_C: true,
			DPE_D: true,
			DPE_E: true,
			DPE_F: true,
			DPE_G: true,
			DPE_NC: true,
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {

	}),

	watch: {
		'app.liste_biens_locatifs': function(newValue) {
			if (newValue) {
				this.Annonces = this.getBiensFilter();
			}
		},

		'app.expanded_annonce': function(newValue) {
			if (newValue) {
				let that = this
				setTimeout(function() {
					let $scroll = $(that.$refs.scrollable_programs)
					let $program = $scroll.find('#annonce-' + newValue)
					if ($program && $program[0]) {
						if ($program) {
					try {
						$scroll.animate({ scrollTop: $program[0].offsetTop }, 250)
					} catch (error) {
						console.log(error)
					}
				}
					}
				}, 10)
			}
		}
		
	},
	methods: {
        selectContent(event) {
          // Sélectionner le contenu de l'input
          event.target.select();
        },

		sliderChange: function() {
			gtag('event', 'filtre_transactions_annees', {'event_category': 'filtre','event_label': 'filtre_transactions_annees'}); this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'annee'});
		},
		
		getBiensFilter: function() {
			let t = []
			for(let annonce in this.app.liste_biens_locatifs.hits){
				if (this.app.liste_biens_locatifs.hits[annonce].filter) {
					t.push(this.app.liste_biens_locatifs.hits[annonce])
				}
			}
			return t
		},

		getNameFile: function() {
			let f = 'efocus_annonces_location_' + this.app.adr_init.name + ' - ' + this.app.adr_init.postcode + ' - ' + this.app.adr_init.city
			f = f.replace("'", "")
			f = f + '.xls'
			return f
		},

		getJsonAncien: function() {
			let r = []
			if (this.app.liste_biens_locatifs.hits) {
				for (let index = 0; index < this.app.liste_biens_locatifs.hits.length; index++) {
					const annonce = this.app.liste_biens_locatifs.hits[index];
					if (annonce.filter) {
						let cons = ""
						if (annonce.features.construction && annonce.features.construction.year) {
							cons = annonce.features.construction.year
						}
						let etat = ""
						if (annonce.features.descriptive && annonce.features.descriptive.generalState) {
							etat = annonce.features.descriptive.generalState
						}
						let parking = "NON"
						if (annonce.features.geometry && annonce.features.geometry.areaCount && annonce.features.geometry.areaCount.PARKING) {
							parking = "OUI"
						}
						let type = "APPARTEMENT"
						if (annonce.type != 'APARTMENT') type = 'MAISON'
						r.push({type: type, nbpieces : 'T' + annonce.features.geometry.roomCount, cree: annonce.creationDate.substring(0,10),
							maj: annonce.updateDate.substring(0,10), surface: annonce.features.geometry.surface, prix: annonce.marketing.priceHC,
							charge: annonce.marketing.rentalExpenses, 
							annee: cons,
							etat: etat,
							energie_note: annonce.features.energy.consumptionLetter,
							energie_val: annonce.features.energy.consumption,
							emission_note: annonce.features.energy.greenhouseGasConsumptionLetter,
							emission_val: annonce.features.energy.greenhouseGasConsumption,
							parking : parking,
							description: annonce.features.descriptive.description
						})
					}	
				}
			}			
			return r
		},

		// eslint-disable-next-line
		toggle_switch: function(filter) {
			gtag('event', 'filtre_transactions_typologie', {'event_category': 'filtre','event_label': 'filtre_transactions_typologie'});
			this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'typologie'});
		},
		isNumber: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				evt.preventDefault();
			} else {
				return true;
			}
		},

		// eslint-disable-next-line
		valideInterval: function(val, event) {
			if (val) {				
				switch (val) {
					case 'price_min':
						gtag('event', 'filtre_transactions_prix', {'event_category': 'filtre','event_label': 'filtre_transactions_prix'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'prix'});
						break;
					case 'price_max':
						gtag('event', 'filtre_transactions_prix', {'event_category': 'filtre','event_label': 'filtre_transactions_prix'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'prix'});
						break;
					case 'area_min':
						gtag('event', 'filtre_transactions_surface', {'event_category': 'filtre','event_label': 'filtre_transactions_surface'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'surface'});
						break;
					case 'area_max':
						gtag('event', 'filtre_transactions_surface', {'event_category': 'filtre','event_label': 'filtre_transactions_surface'});
						this.$store.dispatch('send_event_backend', {module: 'ancien_transactions', action: 'filtre', option: 'surface'});
						break;
				
					default:
						break;
				}				
			}
		},

		valide_filtre: function() {
			let data = {
				price_min: this.price_min,price_max: this.price_max, t1:this.t1, t2:this.t2, t3:this.t3, t4:this.t4, t5:this.t5, area_min: this.area_min, area_max: this.area_max,
				A: this.DPE_A, B: this.DPE_B, C: this.DPE_C, D: this.DPE_D, E: this.DPE_E, F: this.DPE_F, G: this.DPE_G, NC: this.DPE_NC,
				MeubleOui: this.MeubleOui, MeubleNon: this.MeubleNon, MeubleNC: this.MeubleNC
			}
			this.$store.dispatch('set_filter_locatif', data)
			.then(() => {
				this.Annonces = this.getBiensFilter();
			})
			this.cv = false
		},
		applique_filtre: function() {
			this.valide_filtre()
			this.cv = false
		},

		hover_parcelle: function(mutation) {
			if (mutation.marker) {
				mutation.marker.classList.add('hover')
				mutation.marker.classList.add('hover-from-list')
			}
		},

		unhover_parcelle: function(mutation) {
			if (mutation.marker) {
				mutation.marker.classList.remove('hover')
				mutation.marker.classList.remove('hover-from-list')
			}
		},

		GetClass: function(type) {
			switch (type) {
				case 'Dépendance':
					return 'dependance'
				case 'Maison':
					return 'maison'
				case 'Appartement':
					return 'appartement'
				case 'Terrain':
					return 'terrain'
				case 'Local industriel. commercial ou assimilé':
					return 'local'
				default:
					break;
			}
		},

		getPrice: function(price) {
            const euro = new Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0
            });
			if (price==0) {
				return "-"
			} else {
                return euro.format(price)
			}
		},

		GetId: function(Id) {
			return "annonce-" + Id
		},

		scroll: function(id) {
			let that = this
			setTimeout(function() {
				let $scroll = $(that.$refs.scrollable_annonce)
				let $annonce = $scroll.find('#annonce-' + id)
				if ($annonce) {
					try {
						$scroll.animate({ scrollTop: $annonce[0].offsetTop }, 250)
					} catch (error) {
						console.log(error)
					}
				}
			}, 100)
		}
	},

	mounted: function() {
	},

	// eslint-disable-next-line
	components: { InputNumber, Annonce, SwitchButton, VueJsonToXls, Slider,SwitchButtonDpe}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.ff
	height calc(100% - 460px) !important

.text
    font-size 16px
	text-align center
	display block
	line-height 32px
	white-space nowrap
	p
		padding 0 0 8px 0
		&:last-child
			padding 0

.pane-ancien-offres-enter
	transform translateX(100%)
.pane-ancien-offres-leave-to
	transform translateX(-100%)

.pan-ancien-offres
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
._filtre
	top 84px
	height calc(100% - 144px) !important
	width 100%
.infinite-loader
	position absolute
	left 16px
	top calc(50% - 64px)
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.right-bar
	height 100% !important

.switch-button
	padding 0 0 8px 4px !important
.box
	background #f0f0f0	
	
.box-content
	display block
	padding 0 16px
.box-title
	padding 0 16px
	margin 0 0 4px 0
	font-family volte, sans-serif
	font-weight 600
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
._box-title2
	padding 0 8px
	margin 0 0 4px 0
	font-family volte, sans-serif
	color #333
	line-height 16px
	text-align left
	padding-bottom 8px
	padding-top 8px
	font-family "Roboto", arial, sans-serif

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both
.value
	display inline-block
	text-align right
	border none
	width 100%
	padding 0 4px 0 4px
	height 32px
	background-color #fff
	border-radius 2px
	font-size 1.1rem
	line-height 32px
	outline none

.biens
	top 60px
	height calc(100% - 60px)
	font-size .95rem

.bien
	width 350px !important

.cs-transaction-id
	position: absolute
	left: 50px
	top 10px

.cs-transaction-price
	text-align: right
	position: absolute
	right: 20px
	top 10px
	font-weight 600

.cs-transaction-year
	position: absolute
	left: 150px
	top 10px

.cs-transaction-type
	position: absolute
	left: 200px
	top 10px

.cs-transaction-parcel
	top 34px
	left: 50px

.cs-collection-card-header
	border-bottom 1px solid #EFEFEF
	height 60px

.cs-widget-collection-card .cs-collection-data-tag
	border-radius 20px
	font-size .813rem
	padding 7px 10px
	color #fff
	display inline-block

.cs-collection-card-content
	padding-top 10px
	padding-bottom 10px
	border-bottom 1px solid #EFEFEF

.cs-collection-data-row
	padding-top 10px

.cs-collection-detail-link
	left 220px
	width 100%
	padding 16px 0

.maison
	background-color rgb(64, 137, 0)
.appartement
	background-color rgb(6, 129, 175)
.terrain
	background-color rgb(255, 167, 38)
.dependance
	background-color rgb(255, 23, 19)
.local
	background-color rgb(102, 0, 229)

.titrefiltre
	width 60px;
	height 60px;
	position absolute
	right 60px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important
.filtreancien
	width 60px;
	height 60px;
	position absolute
	right 60px
	border-left 1px solid #ccc
	text-align center
	line-height 60px
	cursor pointer

.programs-list
	height 100%;
	.programs-list-content
		border-right 1px solid #ccc

.export
	width 60px;
	position absolute
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.head_filter
	width 60px;
	height 60px;
	position absolute
	left 0px
	text-align center
	line-height 60px
	cursor pointer
	&.idf
		right 0px !important

.filters
	cursor pointer
	transition 0.25s easeOutQuart
	font-weight 400
	color #666
	&:hover
		color #6C21F9

.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure



@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
