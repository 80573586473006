<template>
	<div class="buttonadresse btnadresse" v-if="app.authorized&&false" @click="replace()" :title="$t('replace_la_carte_a_ladresse_indiquee')">
		<div v-if="app.adr_init">
			<icon file="retour_adresse2" :size="18" />&nbsp;&nbsp;&nbsp;{{app.adr_init.name}}, {{app.adr_init.postcode}} {{app.adr_init.city}} <span v-if="app.geo_init.zonage">({{ $t('zone') }} {{ app.geo_init.zonage }})</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ButtonReport',

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {

	}),

	methods: {
		replace: function() {
			try {
				delete $.ajaxSettings.headers["Authorization"];
			} catch (error) {
				// erreur
			}
			this.$store.dispatch('set_nbprogselect', {nb : 0})
			if (!this.app.nolive) {
				for (let i in this.app.programs_filter) {
					if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville)) {
						document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville).className = 'checkbox'
					}					
				}
			}
			if (this.app.adr_init.type =='city') {
				this.$store.dispatch('set_search', this.app.adr_init)
			} else {
				this.$store.map_osm.setView(new L.LatLng(this.app.mrk.lat, this.app.mrk.lng))
			}
		},

		click: function() {
			this.$store.dispatch('set_nbprogselect', {nb : 0})
			if (!this.app.nolive) {
				try {
					for (let i in this.app.programs_filter) {
						if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville)) {
						document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville).className = 'checkbox'
					}					
					}
				} catch (error) {
					console.log(error, 'error')
				}
			}
			this.$store.dispatch('set_search', {type: 'boundingbox'})
		},

		removeall: function() {
			this.$store.dispatch('select_programs', { programs: this.app.programs, selected: false })
		}
	}
}
</script>

<style lang="stylus">
@import '../../assets/css/app'

.infinite-loader
	position absolute
	right 16px
	top calc(70% - 12px)
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

.buttonadresse
	position absolute
	width auto
	padding 15px
	left calc(50% - 191px)
	top 0px
	height auto
	z-index 450
	background-color #fff
	border-bottom 1px solid #ccc
	border-right 1px solid #ccc
	border-left 1px solid #ccc
	display inline-flex
	font-weight 500
	.button-report-one
		width 232px
		margin-right 10px
		transition 0.5s easeOutQuart
		.button
			padding 7px 10px 10px 10px
			text-align right
			border-radius radius 5px
			background white
			color #666
			height 30px
			cursor pointer
			box-shadow 0 0 4px alpha(#000, 25%)
			&:hover
				background-color darken(blue, 10%)
				color white
			.icon
				right 200px

	.button-report-two
		width 140px
		transition 0.5s easeOutQuart
		.button-two
			padding 7px 10px 10px 10px
			text-align right
			border-radius radius 5px
			background white
			color #666
			height 30px
			cursor pointer
			box-shadow 0 0 4px alpha(#000, 25%)
			&:hover
				background-color darken(blue, 10%)
				color white
			.icon
				right 108px
.loading-icon
	animation rotate 2s infinite linear
	margin 0 auto

.btnadresse
	text-align center
	vertical-align middle
	cursor pointer
	&:hover
		background-color secondary
		color #fff		


</style>
