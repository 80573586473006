<template>
	<div class="stat-box">
		<div class="stat-box-header">
			<div class="flex align-items-center surface-card">
				<div style="width:48px;height:48px;border-radius:10px" class="bg-cyan-100 inline-flex align-items-center justify-content-center mr-3">
					<i class="pi pi-users text-cyan-500 text-3xl"></i>
				</div>
				<div>
					<span class="text-900 text-xl font-medium mb-2">{{ $t('historique') }}</span>
					<p class="mt-1 mb-0 text-600 font-medium text-sm">{{ $t('des_destinations_des_ventes') }}</p>
				</div>
				<div class="ml-auto">
					<div class="speeddial-tooltip-demo">
						<SpeedDial className="speeddial-left" :model="items" direction="down" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined"/>
					</div>
				</div>
			</div>
		</div>
		<div class="stat-box-content">
			<div class="table">
				<table id='datatable_destination'>
					<tbody>
						<tr>
							<th id="destination_11"></th>
							<th id="destination_12">{{app.livedata.volume_investisseur.intitule_colonne[0]}}</th>
							<th id="destination_13">{{app.livedata.volume_investisseur.intitule_colonne[1]}}</th>
							<th id="destination_14">{{app.livedata.volume_investisseur.intitule_colonne[2]}}</th>
							<th id="destination_15">{{app.livedata.volume_investisseur.intitule_colonne[3]}}</th>
							<th id="destination_16">{{app.livedata.volume_investisseur.intitule_colonne[4]}}</th>
							<th id="destination_17">{{app.livedata.volume_investisseur.intitule_colonne[5]}}</th>
							<th id="destination_18">{{app.livedata.volume_investisseur.intitule_colonne[6]}}</th>
							<th id="destination_19">{{app.livedata.volume_investisseur.intitule_colonne[7]}}</th>
						</tr>
						<tr>
							<th id="destination_21">{{app.livedata.volume_investisseur.ventes_inv.libelle}}</th>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[0]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[1]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[2]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[3]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[4]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[5]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[6]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_inv.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="destination_31">{{app.livedata.volume_investisseur.pervente_inv.libelle}}</th>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[0] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[1] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[2] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[3] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[4] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[5] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[6] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_inv.tableau[7] + " " + app.livedata.volume_investisseur.pervente_inv.unite}}</td>
						</tr>
						<tr>
							<th id="destination_41">{{app.livedata.volume_investisseur.ventes_occ.libelle}}</th>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[0]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[1]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[2]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[3]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[4]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[5]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[6]}}</td>
							<td>{{app.livedata.volume_investisseur.ventes_occ.tableau[7]}}</td>
						</tr>
						<tr>
							<th id="destination_51">{{app.livedata.volume_investisseur.pervente_occ.libelle}}</th>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[0] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[1] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[2] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[3] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[4] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[5] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[6] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
							<td>{{app.livedata.volume_investisseur.pervente_occ.tableau[7] + " " + app.livedata.volume_investisseur.pervente_occ.unite}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import SpeedDial from 'primevue/speeddial';

export default {
	name: 'ArrayDestinationVentes',

	data: function() {
		return {
			items: [
				{label: 'Data', icon: 'pi pi-fw pi-download', command:() => {this.$store.dispatch('ExportToXls',{id: 'datatable_destination', name: 'Destination des ventes'});} },
				{label: 'Image', icon: 'pi pi-fw pi-image', command:() => {this.$store.dispatch('ExportToPng',{id: 'datatable_destination'});} },
			]			
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		
		
	}),

	methods: {
		
	},
	
	components: { SpeedDial }
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'


.p-menuitem-link
	padding 0.75rem 1.25rem !important
	color #495057
	border-radius 0
	transition box-shadow 0.2s
	user-select none

:deep(.speeddial-tooltip-demo) {
	.p-speeddial-direction-up {
		&.speeddial-left {
			left: 0;
			bottom: 0;
		}
		&.speeddial-right {
			right: 0;
			bottom: 0;
		}
	}
	.p-speeddial-button.p-button.p-button-icon-only {
		width: 3rem !important;
		height: 3rem !important;
	}
}	
</style>
