<template>
	<div class="map" :class="{ 'report-visible': app.selected_programs_syno.length > 0 }">
		<div class="map-wrap" :class="{ 'not-authorized': !app.authorized }">
			<div class="t" @click="$store.dispatch('set_menu')"><icon :file="get_img()" :size="24" /></div>
			<div class="u" :class="{ouvert: app.viewlayer}" @click="set_view_layer('layer')">
				<icon file="layer2" :size="24" />
			</div>
			<div class="v" :class="{ouvert: app.viewlayer}" @click="set_view_layer('legende')"><icon file="menu_vertical" :size="24" /></div>
			<div v-show="app.module_actif" class="w" :class="{ouvert: app.viewlayer}" @click="set_view_layer('isochrone')"><icon file="timer2" :size="24" /></div>
			<div class="x" :class="{ouvert: app.viewlayer}" @click="set_view_layer('insee')">
				<icon :size="32" file="travel" />
			</div>
			<div v-if="app.user.adequation_po&&false" class="y" @click="layer_bi('bi-real-estate-rent')">
				<icon :size="32" file="layer2" />
			</div>
			<div v-if="app.user.adequation_po&&false" class="yy" @click="layer_bi('bi-real-estate-rent', 'code_iris')">
				<icon :size="32" file="layer2" />
			</div>
			<div v-if="app.user.adequation_po&&false" class="y2" @click="layer_bi('bi-real-estate-buy')">
				<icon :size="32" file="layer2" />
			</div>
			<div v-if="app.user.adequation_po&&false" class="yy2" @click="layer_bi('bi-real-estate-buy', 'code_iris')">
				<icon :size="32" file="layer2" />
			</div>
			<transition name="slide">
				<div v-if="isAncien" class="pane-legendeancien legende">
					<div class="titre_legende">
						{{ $t('legende_des_prix_de_lancien') }}
					</div>
					<ul>
						<li v-for="legend in legend_ancien" class="li_legende" v-bind:key="legend.range.id">
							<span class="cs-legend-scale-dot" :style="'background-color: ' + legend.color"></span>
							<span class="cs-legend-scale-text">{{legend.label}}</span>
						</li>
					</ul>
				</div>
			</transition>
			<transition name="slide">
				<div v-if="isAncienOffre" class="pane-legendeancienoffre legende">
					<div class="titre_legende">
						{{ $t('Biens à vendre') }}
					</div>
					<ul>
						<li v-for="legend in legend_ancienoffre" class="li_legende" v-bind:key="legend.id">
							<span class="cs-legend-scale-dot" :style="'background-color: ' + legend.color"></span>
							<span class="cs-legend-scale-text">{{legend.label}}</span>
						</li>
					</ul>
				</div>
			</transition>
			<transition name="slide">
				<div v-if="isLocatif" class="pane-legendelocatif legende">
					<div class="titre_legende">
						{{ $t('legende_des_prix_de_loyer') }}
					</div>
					<ul>
						<li v-for="legend in legend_locatif" class="li_legende" v-bind:key="legend.range.id">
							<span class="cs-legend-scale-dot" :style="'background-color: ' + legend.color"></span>
							<span class="cs-legend-scale-text">{{legend.label}}</span>
						</li>
					</ul>
				</div>
			</transition>
			<transition name="slide">
				<div v-if="app.view=='ancien-mutations'" class="pane-legendeancien legende mutation">
					<div class="titre_legende">
						{{ $t('legende_des_mutations') }}
					</div>
					<ul>
						<li v-for="legend in legend_mutation" class="li_legende" v-bind:key="legend.range.id">
							<span class="cs-legend-scale-dot" :style="'background-color: ' + legend.color"></span>
							<span class="cs-legend-scale-text">{{legend.label}}</span>
						</li>
					</ul>
				</div>
			</transition>
			<transition name="slide">
				<div v-if="app.view=='locatif'" class="pane-legendelocatifterritoire">
					<div class="choix" style="height:32px">
						<label class="radiobox" :class="{ checked: fond_loyer==1 }">
							<input type="checkbox" :checked="fond==1" @click.stop="fond_loyer=1;setfl()">
							<span class="chk"><icon file="check" :size="16" /></span>
							<span class="text">{{ $t('iris') }}</span>
						</label>
					</div>
					<div class="choix" style="height:32px">
						<label class="radiobox" :class="{ checked: fond_loyer==2 }">
							<input type="checkbox" :checked="fond==2" @click.stop="fond_loyer=2;setfl()">
							<span class="chk"><icon file="check" :size="16" /></span>
							<span class="text">{{ $t('ville') }}</span>
						</label>
					</div>
					<!--
					<div class="choix" style="height:32px">
						<label class="radiobox" :class="{ checked: fond_loyer==3 }">
							<input type="checkbox" :checked="fond==3" @click.stop="fond_loyer=3;setfl()">
							<span class="chk"><icon file="check" :size="16" /></span>
							<span class="text">{{ $t('epci') }}</span>
						</label>
					</div>
					-->
				</div>
			</transition>
			<div id="map">
			</div>
			<div class="content_filter" style="width: auto" v-if="app.efocus_type=='A'">
				<table style="width: auto;background-color: rgb(255, 255, 255); padding: 5px; width: auto; line-height: 30px; height: 30px; vertical-align: middle; border-radius: 8px; border: 1px solid rgb(204, 204, 204);">
					<th style="display:none"></th>
					<tr>
						<td style="width: 40px; vertical-align:middle;text-align:center;padding-left:10px">
							<label v-if="app.authorized&&!app.nolive" class="select-all-co" :class="{ checked: viewlayerPromo }">
								<input type="checkbox" :checked="viewlayerPromo" @click.stop="$store.dispatch('set_view_layer_promo', {view: !app.viewlayer_promo})">
								<span class="chk"><icon file="check" :size="16" /></span>
								<span class="text"></span>
							</label>
						</td>
						<td style="text-align:left;padding-right:20px">
							&nbsp;{{ $t('programmes_neufs') }}
						</td>

						<td style="width: 40px; vertical-align:middle;text-align:center;padding-left:10px">
							<label v-if="app.authorized&&!app.nolive" class="select-all-co" :class="{ checked: app.viewlayer_rs }" >
								<input type="checkbox" :checked="app.viewlayer_rs" @click.stop="$store.dispatch('set_view_layer_rs', {view: !app.viewlayer_rs})">
								<span class="chk"><icon file="check" :size="16" /></span>
								<span class="text"></span>
							</label>
						</td>
						<td style="text-align:left;padding-right:20px">
							&nbsp;{{ $t('residence_services') }}
						</td>

						<td>
							<div style="margin-right: 5px;width:230px;" title="Permet de ne visualiser que votre sélection (Programmes, PC, résidences...)">
								&nbsp;{{ $t('afficher_ma_selection') }}&nbsp;&nbsp;	<toggle-button :font-size="14" @change="uniquementSelection($event.value)" :value="selection_seul" :disabled="!app.nbprogselect && !app.nbresidenceselect" :color="{checked: '#6c21f9', unchecked: '#AAAAAA', disabled: '#CCCCCC'}" :labels="{checked: 'Oui', unchecked: 'Non'}" :width="80" :height="30" id="get_selection_seul"/>
							</div>
						</td>


						<td style="width: 40px; vertical-align:middle;text-align:center;padding-left:10px">
							<label v-if="app.authorized&&!app.nolive" class="select-all-co" :class="{ checked: app.viewlayer_poi }">
								<input type="checkbox" :checked="app.viewlayer_poi" @click="$store.dispatch('set_view_layer_poi', {view: !app.viewlayer_poi})">
								<span class="chk"><icon file="check" :size="16" /></span>
								<span class="text"></span>
							</label>
						</td>
						<td style="text-align:left;padding-right:20px">
							&nbsp;{{ $t('points_dinterêt') }}
						</td>

					</tr>
				</table>
			</div>
		</div>
		<div class="blur-area" v-if="!app.authorized">
			<div class="message">
				<icon file="alert-circle" :size="80" />
				<p class="text">{{ $t('vous_ne_disposez_pas_de_labonnement_necessaire_pour_visualiser_cette_carte.') }}</p>
				<div class="buttons">
					<div class="button">{{ $t('mettre_a_jour_mon_abonnement') }}</div>
					<div class="button gray" @click="dezoom()">{{ $t('retour') }}</div>
				</div>
			</div>
		</div>
		<div style="display: none;position:absolute;visibility:hidden" id="gl" @click="getInfosLoyer()" >
			<input type="text" id="loyer_id" value=""/>
			<input type="text" id="loyer_name" value=""/>
			<input type="text" id="loyer_zoneType" value=""/>		
			<input type="text" id="loyer_count" value=""/>		
			<input type="text" id="loyer_value" value=""/>		
		</div>


		<!-- Popup custom -->
		<div id="custom-popup" class="custom-popup cs-itinerary-popin" v-if="showPopup" @mousedown="dragMouseDown" :style="{ left: popupPosition.x + 'px', top: popupPosition.y + 'px' }">
			<div class="cs-itinerary-popin-header" style="background-color: #c65459;">
			<div class="cs-itinerary-popin-title-wrapper">
				<h4 class="cs-itinerary-popin-title">{{ selectedZone.nom }}</h4>
			</div>
			<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan" style="color:white" @click="closePopup">X</button>
			</div>
			<div class="cs-itinerary-popin-content">
			<div class="cs-itinerary-popin-main-data">
				<div v-html="selectedZone.content" style="height:auto"></div>
			</div>
			</div>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import L from 'leaflet'
import 'leaflet-editable';
import 'leaflet.pattern';
import axios from 'axios'
import map_helper from '../../helpers/map_helper'
import leafletLasso from 'leaflet-lasso' /* eslint-disable-line */
import Pbf from "leaflet.vectorgrid/dist/Leaflet.VectorGrid.js" /* eslint-disable-line */
import { i18n } from '../../main';
import { MarkerClusterGroup } from 'leaflet.markercluster'; /* eslint-disable-line */



export default {
	name: 'MapOsm',
	data: function() {
		return {
//			markers: [], // Stocke les marqueurs créés
			showPopup: false,
			selectedZone: {},
			popupPosition: { x: 0, y: 0 },
			offset: { x: 0, y: 0 },
			selectedLayer : null,
			group_n_pnru: false,
			clustersByIris: {}, // Contiendra les groupes de clusters par IRIS
			zoomThreshold: 16, // Seuil de zoom pour désactiver le clustering
			echelle_color: [
				'#abd194',  // rgb(171, 209, 148)
				'#bbd59d',  // rgb(187, 213, 157)
				'#d6ddb2',  // rgb(214, 221, 178)
				'#e1dcb0',  // rgb(225, 220, 176)
				'#e3d5a3',  // rgb(227, 213, 163)
				'#e6cd96',  // rgb(230, 205, 150)
				'#e6b56e',  // rgb(230, 181, 110)
				'#e9994c',  // rgb(233, 153, 76)
				'#ed7932',  // rgb(237, 121, 50)
				'#f15025'   // rgb(241, 80, 37)
			],

			group_tram: false,
			group_metro: false,
			group_train: false,
			maxRadius_rpls: 5000,
			maxRadius_mutation: 10000,
			maxRadius_offre: 5000,
			errorDisplayed_mutation: false,
			errorDisplayed_rpls: false,
			errorDisplayed_offre: false,
			currentPOIs: {},
			group_poi: [],
			circle: false,
			selection_seul: false,
			options: [
				{
					id: 'Transports',
					label: 'Transports',
					children: [ {
							id: 'Bus',
							label: 'Bus',
						}, {
							id: 'Tramway',
							label: 'Tramway',
						}, {
							id: 'Métro',
							label: 'Métro',
						}, 
						{
							id: 'Gare',
							label: 'Gare',
						}, {
							id: 'Aéroport',
							label: 'Aéroport',
						}
					],
				},
				{
					id: 'Education',
					label: 'Education',
						children: [ {
							id: 'Collége',
							label: 'Collége',
						}, {
							id: 'Ecole élémentaire',
							label: 'Ecole élémentaire',
						}, {
							id: 'Ecole maternelle',
							label: 'Ecole maternelle',
						}, {
							id: 'Lycée',
							label: 'Lycée',
						}, {
							id: 'Section d\'enseignement',
							label: 'Section d\'enseignement',
						}, {
							id: 'Enseignement spécialisé',
							label: 'Enseignement spécialisé',
						}, {
							id: 'Enseignement supérieur',
							label: 'Enseignement supérieur',
						},
					],
				},
				{
					id: 'Alimentaire',
					label: 'Alimentaire',
						children: [ {
							id: 'Boulangerie',
							label: 'Boulangerie',
						}, {
							id: 'Epicerie',
							label: 'Epicerie',
						}, {
							id: 'Supermarché',
							label: 'Supermarché',
						}, {
							id: 'Hypermarché',
							label: 'Hypermarché',
						}, {
							id: 'Boucher',
							label: 'Boucher',
						}, {
							id: 'Primeur',
							label: 'Primeur',
						}
					],
				},
				{
					id: 'Café - Hôtel - Restaurant',
					label: 'Café - Hôtel - Restaurant',
						children: [ {
							id: 'Hôtel',
							label: 'Hôtel',
						}, {
							id: 'Bar',
							label: 'Bar',
						}, {
							id: 'Café',
							label: 'Café',
						}, {
							id: 'Restaurant',
							label: 'Restaurant',
						}, {
							id: 'Club',
							label: 'Club',
						}
					],
				},
				{
					id: 'Achats de proximité',
					label: 'Achats de proximité',
						children: [ {
							id: 'Magasin de jouets',
							label: 'Magasin de jouets',
						}, {
							id: 'Fleuriste',
							label: 'Fleuriste',
						}, {
							id: 'Librairie',
							label: 'Librairie',
						}, {
							id: 'Marchand de journaux',
							label: 'Marchand de journaux',
						}, {
							id: 'Tabac - Presse',
							label: 'Tabac - Presse',
						}
					],
				},
				{
					id: 'Shopping',
					label: 'Shopping',
						children: [{
							id: 'Magasin de bricolage',
							label: 'Magasin de bricolage',
						}, {
							id: 'Jardinerie',
							label: 'Jardinerie',
						}, {
							id: 'Magasin de meubles',
							label: 'Magasin de meubles',
						}, {
							id: 'Magasin de vêtements',
							label: 'Magasin de vêtements',
						}, {
							id: 'Friperie',
							label: 'Friperie',
						}, {
							id: 'Magasin de chaussures',
							label: 'Magasin de chaussures',
						}, {
							id: 'Bijouterie',
							label: 'Bijouterie',
						}, {
							id: 'Vendeur de vélo',
							label: 'Vendeur de vélo',
						}, {
							id: 'Magasin de sport',
							label: 'Magasin de sport',
						}
					],
				},
				{
					id: 'Equipement public',
					label: 'Equipement public',
						children: [ {
							id: 'Police',
							label: 'Police',
						}, {
							id: 'Ambassade',
							label: 'Ambassade',
						}, {
							id: 'Hôtel de ville',
							label: 'Hôtel de ville',
						}, {
							id: 'Tribunal',
							label: 'Tribunal',
						}, {
							id: 'Bureau de poste',
							label: 'Bureau de poste',
						}
					],
				},
				{
					id: 'Santé',
					label: 'Santé',
						children: [ {
							id: 'Hôpital',
							label: 'Hôpital',
						}, {
							id: 'Pharmacie',
							label: 'Pharmacie',
						}, {
							id: 'Opticien',
							label: 'Opticien',
						}, {
							id: 'Dentiste',
							label: 'Dentiste',
						}, {
							id: 'Médecin',
							label: 'Médecin',
						}
					],
				},
				{
					id: 'Beauté',
					label: 'Beauté',
						children: [{
							id: 'Cosmétique',
							label: 'Cosmétique',
						}, {
							id: 'Coiffeur',
							label: 'Coiffeur',
						}, {
							id: 'Salon de beauté',
							label: 'Salon de beauté',
						}
					],
				},
				{
					id: 'Services',
					label: 'Services',
						children: [ {
							id: 'Distributeurs',
							label: 'Distributeurs',
						}, {
							id: 'Location de voiture',
							label: 'Location de voiture',
						}, {
							id: 'Vétérinaire',
							label: 'Vétérinaire',
						}, {
							id: 'Vendeur auto',
							label: 'Vendeur auto',
						}, {
							id: 'Agence de voyage',
							label: 'Agence de voyage',
						}, {
							id: 'Garage',
							label: 'Garage',
						}, {
							id: 'Blanchisserie',
							label: 'Blanchisserie',
						}, {
							id: 'Banque',
							label: 'Banque',
						}
					],
				}
			],
			poiList: [],
			poiEducation: [],
			poiTransport: [],
			fond: 2,
			fond_loyer:1,
			legend_ancien: false,
			legend_ancienoffre:[{id: 1, label: 'Appartement', color: '#0086c3'}, {id: 2, label: 'Maison', color: '#83c414'}],
			legend_locatif: false,
			legend_mutation: false,
			t_color_ancien : ["#fff", "#063f04", "#007f1b", "#82d318", "#e7ff00", "#ffce00", "#eb562c", "#ff2a00", "#ef0000", "#680000"],
			u : [],
			i: [],
			mk: [],
			isochrone: false,
			isochroneMapbox: false,
			cityscan: false,
			poi: false,
			IdToLabel: [],
			IdToColor: [],
			T_contour_transports: ['1200', '1202', '1210', '1220', '1221', '1222', '1230', '2000', '2001', '2002'],
			T_contour_loisirs: ['1340', '1341', '1342', '1343', '1350', '1351', '1352', '1353', '1360', '1361', '1371', '1373'],
			T_contour_commerces : ['1231', '1330', '1331', '1332', '1333', '1334', '1370'],
			T_contour_services: ['1300', '1301', '1310', '1311', '1320', '1321', '1322', '1323', '1372', '1380', '2010', '2011'],
			T_contour_education: ['1400', '1410', '1420', '1430', '1440', '1441', '1450', '1460', '1470'],
			openMapTilesLayer: false,
			openMapTilesLayer_Loyer: false,
			openMapTilesLayer_Prix: false,
			openMapTilesLayer2: false,
			
			// transports
			openMapTilesLayer_1200: false,
			openMapTilesLayer_1202: false,
			openMapTilesLayer_1210: false,
			openMapTilesLayer_1220: false,
			openMapTilesLayer_1221: false,
			openMapTilesLayer_1222: false,
			openMapTilesLayer_1230: false,
			openMapTilesLayer_2000: false,
			openMapTilesLayer_2001: false,
			openMapTilesLayer_2002: false,
			
			// education
			openMapTilesLayer_1400: false,
			openMapTilesLayer_1410: false,
			openMapTilesLayer_1420: false,
			openMapTilesLayer_1430: false,
			openMapTilesLayer_1440: false,
			openMapTilesLayer_1441: false,
			openMapTilesLayer_1450: false,
			openMapTilesLayer_1460: false,
			openMapTilesLayer_1470: false,
			
			// education
			openMapTilesLayer_1300: false,
			openMapTilesLayer_1301: false,
			openMapTilesLayer_1310: false,
			openMapTilesLayer_1311: false,
			openMapTilesLayer_1320: false,
			openMapTilesLayer_1321: false,
			openMapTilesLayer_1322: false,
			openMapTilesLayer_1323: false,
			openMapTilesLayer_1372: false,
			openMapTilesLayer_1380: false,
			openMapTilesLayer_2010: false,
			openMapTilesLayer_2011: false,
			
			// commerces
			openMapTilesLayer_1231: false,
			openMapTilesLayer_1330: false,
			openMapTilesLayer_1331: false,
			openMapTilesLayer_1332: false,
			openMapTilesLayer_1333: false,
			openMapTilesLayer_1334: false,
			openMapTilesLayer_1370: false,
			
			// loisirs
			openMapTilesLayer_1340: false,
			openMapTilesLayer_1341: false,
			openMapTilesLayer_1342: false,
			openMapTilesLayer_1343: false,
			openMapTilesLayer_1350: false,
			openMapTilesLayer_1351: false,
			openMapTilesLayer_1352: false,
			openMapTilesLayer_1353: false,
			openMapTilesLayer_1360: false,
			openMapTilesLayer_1361: false,
			openMapTilesLayer_1371: false,
			openMapTilesLayer_1373: false,
			
			communes_layer: true,
			markers_layer: false,
			markers_rpls_layer: false,
			markers_mutations_layer: false,
			markers_offre_layer: false,
			markers_locatif_layer: false,
			markers_rs_layer: false,
			markers_gisement_layer: false,
			cluster_layer: false,
			search_layer: false,
			markers: [],
			center: false,
			GrayLight: false,
			LayerMapnik: false,
			LayerImagery: false,
			LayerDark: false,
			LayerBright: false,
			commune_zoom_layer: false,
			
			style_loyer: [
			{
				fillColor: '#048855',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#13BE00',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#75E102',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#AEE500',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#DFFC04',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#FFF831',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#FFE82E',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#FFD501',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#FFA520',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#FF7B17',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#FF1D02',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			},
			{
				fillColor: '#C81400',
				fillOpacity: 0.6,
				weight: 1, 
				color: "#888888", 
			}
			],
			
			style_NAN : {
				weight: 1, 
				color: "#888888", 
				fillColor: "#888888",
				fillOpacity: 0.6,
			},
			
			vil_style : {
				weight: 1, 
				color: "#888888", 
				fillColor: "#F4EDFF",
				fillOpacity: 0,
			},
			quartier_style : {
				weight: 2, 
				color: "#006e90", 
				opacity: 1,
				fillColor: "#006e90",
				fillOpacity: 0.03
			},
			pnru_style : {
				weight: 2, 
				color: "#008ffb", 
				fillColor: "#008ffb",
				fillOpacity: 0.3
			},
			npnru_style : {
				weight: 2, 
				color: "#00e396", 
				fillColor: "#00e396",
				fillOpacity: 0.3
			},
			zac_style : {
				weight: 2, 
				color: "#c65459", 
				fillColor: "#c65459",
				fillOpacity: 0.3
			},
			qpv_style : {
				weight: 2, 
				color: "#ff9900", 
				fillColor: "#ff9900",
				fillOpacity: 0.3
			},
			qpv_style3 : {
				weight: 2, 
				color: "#ff9900", 
				fillColor: "#ff9900",
				fillOpacity: 0.1,
				dashArray : "10, 10"
			},
			tram_style : {
				weight: 5, 
				color: "#9b3bb1", 
				opacity: 0.5,
				fillColor: "#9b3bb1",
				fillOpacity: 0.5
			},
			train_style : {
				weight: 3, 
				color: "#333", 
				opacity: 0.5,
				fillColor: "#333",
				fillOpacity: 0.5
			},
			metro_style : {
				weight: 5, 
				color: "#BA2028", 
				opacity: 0.5,
				fillColor: "#BA2028",
				fillOpacity: 0.5
			},
			vil_zoom_style : {
				weight: 5, 
				dashArray: '5, 10',
				dashOffset: '5',
				color: "#000", 
				opacity: 1, 
				fillColor: "#F4EDFF",
				fillOpacity: 0,
				zIndex: 2000
			},
			iconMrk : {
				
			},
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		isLocatif: function() {
			return (this.app.view == 'locatif')
		},
		isAncien: function() {
			return (this.app.view == 'ancien')
		},
		isAncienOffre: function() {
			return (this.app.view == 'ancien-offre')
		},
		viewlayerPromo: function() {
			return this.app.viewlayer_promo
		}
	}),

	watch: {
		'app.filtre_gisement_time': function(newValue) {
			if (newValue) {
				this.load_markers_gisement()
			}
		},

		'app.nbprogselect': function(newValue) {
			if (newValue==0) {
				this.selection_seul = false;
				this.uniquementSelection(false);
				if (document.getElementById('get_selection_seul').className=="vue-js-switch toggled") document.getElementById('get_selection_seul').click()
			}
		},

		'app.changementlayout': function() {
			this.updateLayout();
		},

		'app.menuvisible': function() {
			if (this.$store.map_osm) {
				this.updateLayout();
			}
		},

		'app.mrk.lat': function(newValue) {
			if (!newValue) this.search_layer.clearLayers()
			else {
				this.search_layer.clearLayers()
				let marker = L.marker([this.app.mrk.lat, this.app.mrk.lng],{ icon: this.iconMrk, title: 'Mon projet' });
				if (marker) marker.addTo(this.search_layer)
			}
			this.$store.dispatch('set_authorization', { authorized: true })
		},

		'app.filter_ancien_time': function() {
			if (this.app.view=='ancien-mutations') {
				this.load_markers_mutations(true)
			}
		},

		'app.filter_ancien_offre_time': function() {
			if (this.app.view=='ancien-offre') this.load_markers_offre(true)
		},

		'app.efocus_type': function(newValue) {
			if (newValue=='B') {
				if (this.$store.map_osm) {
					this.$store.map_osm.dragging.disable();
					this.$store.map_osm.scrollWheelZoom.disable();
					this.$store.map_osm.zoomControl.disable();
				}
			} else {
				if (this.$store.map_osm) {
					this.$store.map_osm.dragging.enable(); 
					this.$store.map_osm.scrollWheelZoom.enable();
					this.$store.map_osm.zoomControl.enable();
				}
			}
		},
		'app.api_calls.load_locatif': function() {
			if (this.app.view=='ancien-locatif') this.load_markers_locatif(true)
			this.$store.dispatch('set_authorization', { authorized: true })
		},

		'app.api_calls.load_programs': function() {
			if (this.app.view!='rss' && this.app.view!='rpls') this.load_markers(true)
			this.$store.dispatch('set_authorization', { authorized: true })
		},

		'app.api_calls.load_rplss': function() {
			if (this.app.view=='rpls') this.load_markers_rpls(true)
			this.$store.dispatch('set_authorization', { authorized: true })
		},

		'app.api_calls.load_offre': function() {
			if (this.app.view=='ancien-offre') this.load_markers_offre(true)
			this.$store.dispatch('set_authorization', { authorized: true })
		},

		'app.viewlayer_promo': function(newValue) {
			if (this.map) this.$store.map_osm.removeLayer(this.markers_layer)
			if (newValue) this.load_markers(true)
		},

		'app.viewlayer_rpls': function(newValue) {
			if (this.map) this.$store.map_osm.removeLayer(this.markers_rpls_layer)
			if (newValue) this.load_markers_rpls(true)
		},

		'app.viewlayer_rs': function(newValue) {
			if (this.map) this.$store.map_osm.removeLayer(this.markers_rs_layer)
			if (newValue) this.load_markers_rs(true)
		},

		'app.viewlayer_poi': function(newValue) {
			
			if (newValue) {
				if (this.app.contour_poi && this.app.contour_poi.length > 0) {
					this.gere_layer();
				} else this.$store.dispatch('set_view_layer', {view: 'layer'})				
			}
			else if (this.map) {
				for (let i in this.group_poi) {
					this.$store.map_osm.removeLayer(this.group_poi[i])
				}
				try { this.group_metro.clearLayers() } catch (error)  { /* erreur*/ }
				try { this.$store.map_osm.removeLayer(this.group_metro) } catch (error)  { /* erreur*/ }

				try { this.group_tram.clearLayers() } catch (error)  { /* erreur*/ }
				try { this.$store.map_osm.removeLayer(this.group_tram) } catch (error)  { /* erreur*/ }

				try { this.group_train.clearLayers() } catch (error)  { /* erreur*/ }
				try { this.$store.map_osm.removeLayer(this.group_train) } catch (error)  { /* erreur*/ }

				this.group_poi = []
				this.currentPOIs = {};
			}
		},

		'app.api_calls.load_rss': function() {
			if (this.app.view =='rss') this.load_markers_rs(true)
		},

		'app.cityscan.isochrone.duration': function(newValue) {
			this.DisplayIsochone(newValue)
		},

		'app.isochrone.duration': function(newValue) {
			this.DisplayIsochone(newValue)
		},

		'app.contour_loisirs': function(newValue) {
			this.GestionLayer(this.T_contour_loisirs, newValue)
		},

		'app.contour_commerces': function(newValue) {
			this.GestionLayer(this.T_contour_commerces, newValue)
		},

		'app.contour_services': function(newValue) {
			this.GestionLayer(this.T_contour_services, newValue)
		},

		'app.contour_transports': function(newValue) {
			this.GestionLayer(this.T_contour_transports, newValue)
		},

		'app.contour_education': function(newValue) {
			this.GestionLayer(this.T_contour_education, newValue)
		},

		'app.contour_iso': function(newValue) {
			this.DisplayContourIso(newValue)
		},

		'app.contour_iso_map': function(newValue) {
			this.DisplayIsochone(newValue)
		},

		'app.fond_carte_loyer': function(newValue) {
			try {
				this.$store.map_osm.removeLayer(this.openMapTilesLayer_Loyer)
			} catch (error) {
				// erreur
			}
			if (newValue) this.setFondYanport()
		},

		'app.contour_loyer_time': function(newValue) {
			if (this.$store.map_osm) {
				try {
					this.$store.map_osm.removeLayer(this.openMapTilesLayer_Loyer)
				} catch (error) {
					// erreur
				}
				if (newValue) this.setFondYanport()
			}
		},

		'app.contour_prix': function(newValue) {
			this.DisplayContourPrix(newValue)
		},

		'app.cityscan_scalesData': function(newValue) {
			if (newValue && this.app.contour_prix) {
				this.DisplayContourPrix(newValue)
			}
		},

		'app.view': function(newValue, oldValue) {
			try { 
				Object.values(this.clustersByIris).forEach(clusterGroup => {
					clusterGroup.clearLayers()
					this.$store.map_osm.removeLayer(clusterGroup);
				});
			} catch (error) { /* erreur */}

			try { this.$store.map_osm.removeLayer(this.markers_gisement_layer)} catch (error) { /* erreur */}
			const iso = this.app.contour_iso
			this.$store.dispatch('set_contour_iso', { contour: false })
			this.$store.dispatch('set_contour_iso', { contour: iso })
			this.isochrone.clearLayers();

			this.legend_ancien = false
			this.legend_locatif = false
			
			if (newValue.includes('adresse') || newValue.includes('transport') || newValue.includes('education') || 
				newValue.includes('commerce') || newValue.includes('vie') || newValue.includes('loisir') || 
				newValue.includes('numerique') || newValue.includes('service')) {
				this.isochroneMapbox.clearLayers();
				this.$store.map_osm.removeLayer(this.isochroneMapbox)
				try {
					for (let i in this.group_poi) {
						this.$store.map_osm.removeLayer(this.group_poi[i])
					}
					this.group_poi = []
				} catch (error)  { /* erreur*/ }
			} else {
				if (this.app.contour_poi && this.app.contour_poi.length > 0) {
					if (oldValue.includes('adresse') || oldValue.includes('transport') || oldValue.includes('education') || 
						oldValue.includes('commerce') || oldValue.includes('vie') || oldValue.includes('loisir') || 
						oldValue.includes('numerique') || oldValue.includes('service')) {
				
						this.fetchPOIList();
					}					
				}
			}
			
			try { this.$store.dispatch('set_contour_prix', { contour: false })} catch (error)  {/* erreur*/}
			try { this.openMapTilesLayer.clearLayers() } catch (error)  { /* erreur*/ }
			try { this.$store.map_osm.removeLayer(this.openMapTilesLayer) } catch (error)  { /* erreur*/ }
			try { this.$store.map_osm.removeLayer(this.openMapTilesLayer_Prix) } catch (error)  { /* erreur*/ }
			try { this.$store.map_osm.removeLayer(this.openMapTilesLayer_Loyer) } catch (error)  { /* erreur*/ }

			// eslint-disable-next-line
			let vectorTileLayerStyles = {
				layer1620: function(properties) {
					let m = JSON.parse(properties.extra)
					let type=''
					for (let i=0; i<m.transactions.length; i++) {
						if (m.transactions[i].year > 2016) {
							if (m.transactions[i].indicator_types.length>1) {
								return {
									weight: 1,
									color: "#fff",
									fillOpacity: 0.75,
									fillColor: "#e6ff42",
									fill: true
								}
								
							} else {
								if (type == '') {
									type = m.transactions[i].indicator_types[0]
								} else {
									if (type != m.transactions[i].indicator_types[0]) {
										return {
											weight: 1,
											color: "#fff",
											fillOpacity: 0.75,
											fillColor: "#e6ff42",
											fill: true
										}
									}
								}
							}
						}
					}
					if (type == 'dependency') {
						return {
							weight: 1,
							color: "#fff",
							fillOpacity: 0.65,
							fillColor: "#ff1b27",
							fill: true
						}
					}
					if (type == 'apartment') {
						return {
							weight: 1,
							color: "#fff",
							fillOpacity: 0.65,
							fillColor: "#0080ac",
							fill: true
						}
					}
					if (type == 'house') {
						return {
							weight: 1,
							color: "#fff",
							fillOpacity: 0.65,
							fillColor: "#31891c",
							fill: true
						}
					}
					if (type == 'industrial_commercial_premise') {
						return {
							opacity: 0,
							fillOpacity: 0,
							fill: false
						}
					}
					if (type == 'land') {
						return {
							weight: 1,
							color: "#fff",
							fillOpacity: 0.65,
							fillColor: "#ffa83e",
							fill: true
						}
					}
					return {
						weight: 0,
						fillOpacity: 0,
						fill: false
					}
				},

				layer16210: function(properties) {
					let m = JSON.parse(properties.MEDIAN_PRICE_GLOBAL)
					let level = m.mean.value
					let color = t_color[t_prix.length-1]						
					for (let j=0; j<t_prix.length; j++) {
						if (level < t_prix[j]) {
							color = t_color[j]
							break;
						}
					}
					return {
						weight: 1,
						color: "#fff",
						fillOpacity: 0.75,
						fillColor: color,
						fill: true
					}
				},

				layer16231: function(properties) {
					let m = JSON.parse(properties.RENTAL_PRICE_APARTMENT)
					let level = m.mean.value
					let color = t_color[t_prix.length-1]						
					for (let j=0; j<t_prix.length; j++) {
						if (level < t_prix[j]) {
							color = t_color[j]
							break;
						}
					}
					return {
						weight: 1,
						color: "#fff",
						fillOpacity: 0.75,
						fillColor: color,
						fill: true
					}
				},

				layer2111: function(properties) {
					if (properties.cadastre_type == "BUILDING") {
						return false
					}
				},
			}
			
			if (this.cityscan) {
				this.cityscan.clearLayers()
				this.$store.map_osm.removeLayer(this.cityscan)
				this.cityscan = L.featureGroup().addTo(this.$store.map_osm)
				try {
					this.openMapTilesLayer_Loyer.clearLayers()
					this.$store.map_osm.removeLayer(this.openMapTilesLayer_Loyer)				
				} catch (error)  {
					// erreur
				}

				if (newValue=="locatif") {
					this.$store.dispatch('set_contour_loyer', { contour: false })
				}
			} else {
				this.cityscan = L.featureGroup().addTo(this.$store.map_osm)
			}
			if (this.openMapTilesLayer) {				
				this.$store.map_osm.removeLayer(this.openMapTilesLayer)
			}
			if (this.openMapTilesLayer2) {				
				this.$store.map_osm.removeLayer(this.openMapTilesLayer2)
			}

			try { 
				Object.values(this.clustersByIris).forEach(clusterGroup => {
					clusterGroup.clearLayers()
					this.$store.map_osm.removeLayer(clusterGroup);
				});
			} catch (error) { /* erreur */}


			if (this.markers_mutations_layer) {				
				this.$store.map_osm.removeLayer(this.markers_mutations_layer)
			}
			if (this.markers_offre_layer) {				
				this.$store.map_osm.removeLayer(this.markers_offre_layer)
			}
			if (this.markers_locatif_layer) {				
				this.$store.map_osm.removeLayer(this.markers_locatif_layer)
			}

			let that = this
			
			function TraiteIcone(response, T, u) {
				if (response && response.data && response.data.content && response.data.content.indicators) {
					for (let i in response.data.content.indicators) {
						let indicator = response.data.content.indicators[i]
						if (indicator && indicator.factors) {
							for (let j in indicator.factors) {
								let factor = indicator.factors[j]
								let latlng = factor.geometry.wktGeometry.substring(factor.geometry.wktGeometry.indexOf('(') + 1)
								latlng = latlng.substring(0,latlng.length-1)
								let es = latlng.indexOf(' ')
								let lat = latlng.substring(es + 1)
								let lon = latlng.substring(0,es)
								let v = u[parseInt(indicator.id)]
								let marker = L.marker([lat, lon],{ icon: v, title: factor.name });
								let nom = 'nc'
								if (factor && factor.name) nom = factor.name
								marker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + T.IdToColor[indicator.id] + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<i class="cs-itinerary-popin-icon cs-icon-thematic icon-' + indicator.id + '-02 ng-star-inserted"></i>' +
												'<h4 class="cs-itinerary-popin-title">' + T.IdToLabel[indicator.id] + ' </h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + nom + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();

								if (marker) {
									marker.addTo(T.cityscan)
								}
							}
						}
					}
				}
			}
									
			let t=[]
			t['transport'] = '1200,1201,1202,1210,1220,1221,1222,1230,2000,2001,2002'
			t['transport-bus'] = '1200'
			t['transport-tram'] = '1202'
			t['transport-metro'] = '1201'
			t['transport-route-rapide'] = '1210'
			t['transport-parking'] = '1230'
			t['transport-gare'] = '1220'
			t['transport-aeroport'] = '1221'
			t['transport-port'] = '1222'
			t['transport-velo'] = '2000'
			t['transport-auto'] = '2001'
			t['transport-borne'] = '2002'
			
			t['education'] = '1400,1410,1420,1430,1440,1441,1450,1460,1470'
			t['education-creche'] = '1400'
			t['education-maternelle'] = '1410'
			t['education-elementaire'] = '1420'
			t['education-college'] = '1430'
			t['education-lycee'] = '1440'
			t['education-lycee-pro'] = '1441'
			t['education-universite'] = '1450'
			t['education-superieur'] = '1460'
			t['education-formation'] = '1470'
			
			t['Commerces'] = '1231,1330,1331,1332,1333,1334,1370'
			t['commerce-alimentation'] = '1330'
			t['commerce-bouche'] = '1332'
			t['commerce-utilitaire'] = '1333'
			t['commerce-shopping'] = '1331'
			t['commerce-soin'] = '1334'
			t['commerce-logement'] = '1370'
			t['commerce-vehicule'] = '1231'
			
			t['Services publics & Santé'] = '1300,1301,1310,1311,1320,1321,1322,1323,1372,1380,2010,2011'
			t['service-agees'] = '1372'
			t['service-commissariat'] = '1300'
			t['service-dechet'] = '2011'
			t['service-gendarmerie'] = '1301'
			t['service-hopital'] = '1311'
			t['service-laboratoire'] = '1322'
			t['service-medecin'] = '1320'
			t['service-pharmacie'] = '1321'
			t['service-pompier'] = '1310'
			t['service-poste'] = '1380'
			t['service-recyclage'] = '2010'
			t['service-specialiste'] = '1323'

			t['loisirs'] = '1340,1341,1342,1343,1350,1351,1352,1353,1360,1361,1371,1373'
			t['loisir-bibliotheque'] = '1350'
			t['loisir-theatre'] = '1351'
			t['loisir-musee'] = '1352'
			t['loisir-cinema'] = '1353'
			t['loisir-bar'] = '1341'
			t['loisir-restaurant'] = '1340'
			t['loisir-discotheque'] = '1343'
			t['loisir-jardin'] = '1342'
			t['loisir-sport-collectif'] = '1360'
			t['loisir-sport-individuel'] = '1361'
			t['loisir-hotel'] = '1371'
			t['loisir-hebergement'] = '1373'

			if ((t[newValue]!='') && (t[newValue])) {
				let url3 = this.app.BaseUrlBackend + '/proxy/cityscan/api/indicators?addressId=' + this.app.cityscan.addressId + '&ids=' + t[newValue]
				axios.get(url3, { headers: this.app.HeaderEfocusBack})
				.then(function(response) {
					TraiteIcone(response, that, that.u)
				})
			}
			
			if (newValue=='ancien') {
				//
			}
			
			if (newValue=='locatif') {
				//
			}

			if (newValue=='ancien-mutations') {
				this.legend_ancien = false
				this.legend_locatif = false
				this.load_markers_mutations()
			}

			if (newValue=='ancien-offre') {
				this.legend_ancien = false
				this.legend_locatif = false
				this.load_markers_offre()
			}

			if (newValue=='ancien-locatif') {
				this.legend_ancien = false
				this.legend_locatif = false
				this.load_markers_locatif()
			}

			if (newValue=='rss') {
				this.legend_ancien = false
				this.legend_locatif = false
				this.load_markers_rs()
			} else {
				if (newValue=='neuf') {
					this.load_markers()										
				} else {
					if (newValue=='rpls') {
						this.load_markers_rpls()										
					} else {
						if (newValue=='gisements') {
							this.load_markers_gisement()
						}
					}
				}
			}
		},

		'app.commune_zoom': function(newValue) {
			try {
				this.commune_zoom_layer.clearLayers()
				this.$store.map_osm.removeLayer(this.commune_zoom_layer)
			} catch (error) {
				// erreur
			}
			if (newValue) {
				let that = this
				L.geoJson(newValue,
				{
					style: function(){
						return that.vil_zoom_style; 
					},
					onEachFeature: function()
					{
					}
				}).addTo(that.commune_zoom_layer);
				this.$store.map_osm.addLayer(this.commune_zoom_layer);
			}
		},

		'app.pin_type': function() {
			this.updateMarkers();
		},

		'app.contour_commune': function() {
			this.gere_layer()
		},

		'app.contour_zac': function() {
			this.gere_layer_zac()
		},

		'app.contour_pnru': function() {
			this.gere_layer_pnru()
		},

		'app.contour_npnru': function() {
			this.gere_layer_npnru()
		},

		'app.contour_qpv': function() {
			this.gere_layer_qpv()
		},

		'app.contour_qpv_trois': function() {
			this.gere_layer_qpv_trois()
		},
/*
		'app.contour_ligne_gpe': function() {
			console.log('app.contour_ligne_gpe')
			this.gere_layer()
		},

		'app.contour_metro': function() {
			console.log('app.contour_metro')
			this.gere_layer()
		},

		'app.contour_tramway': function() {
			console.log('app.contour_tramway')
			this.gere_layer()
		},

		'app.contour_train': function() {
			console.log('app.contour_train')
			this.gere_layer()
		},
*/
		'app.contour_poi': function() {
			this.gere_layer()
		},

		'app.contour_ecole': function() {
			this.gere_layer()
		},

		'app.fond_carte': function() {
			try {
				this.$store.map_osm.removeLayer(this.LayerCartodb);
			} catch (error) {
				// erreur
			}
			try {
				this.$store.map_osm.removeLayer(this.LayerMapnik);
			} catch (error) {
				// erreur
			}
			try {
				this.$store.map_osm.removeLayer(this.LayerImagery);
			} catch (error) {
				// erreur
			}
			try {
				this.$store.map_osm.removeLayer(this.LayerDark);
			} catch (error) {
				// erreur
			}
			try {
				this.$store.map_osm.removeLayer(this.LayerBright);
			} catch (error) {
				// erreur
			}
			
			if (this.app.fond_carte == 1) {
				this.$store.map_osm.addLayer(this.LayerCartodb);		
			}
			if (this.app.fond_carte == 2) {
				this.$store.map_osm.addLayer(this.LayerMapnik);
			}
			if (this.app.fond_carte == 3) {
				this.$store.map_osm.addLayer(this.LayerImagery);
			}
			if (this.app.fond_carte == 4) {
				this.$store.map_osm.addLayer(this.LayerDark);
			}
			if (this.app.fond_carte == 5) {
				this.$store.map_osm.addLayer(this.LayerBright);
			}
		},
	},

	methods: {
		closePopup() {
			this.showPopup = false;
		},
		dragMouseDown(e) {
			e.preventDefault();
			document.onmouseup = this.closeDragElement;
			document.onmousemove = this.elementDrag;

			// Capture la position de départ de la souris
			this.offset.x = e.clientX - this.popupPosition.x;
			this.offset.y = e.clientY - this.popupPosition.y;
		},
		elementDrag(e) {
			e.preventDefault();
			// Calcule la nouvelle position du popup en fonction du déplacement de la souris
			this.popupPosition.x = e.clientX - this.offset.x;
			this.popupPosition.y = e.clientY - this.offset.y;
		},
		closeDragElement() {
			// Arrête de bouger quand le bouton de la souris est relâché
			document.onmouseup = null;
			document.onmousemove = null;
		},
		layer_bi: function(l, t) {
			let tt = 'commune'
			let tgeo = 'insee'
			if (!t) t = 'insee_code'
			if (t=='code_iris') {
				tt = 'iris'
				tgeo = 'code_iris'
			} 
			if (this.group_com) this.group_com.clearLayers()

			var UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:' + tt + '&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'

			var TabInsee = []
			var vi = []
			var that = this
			let echelle = {};
			axios.get(UneUrl)
			.then(function(response_wfs) {
				if (response_wfs) {
					if (response_wfs.data) {
						if (response_wfs.data.features && response_wfs.data.features.length) {
							for (let j = 0; j < response_wfs.data.features.length; j++) {
								TabInsee.push(response_wfs.data.features[j].properties[tgeo])
							}
							var url = that.app.BaseUrlBackend + '/biens/kpi?theme=' + l + '&type=' + t + '&insee=' + TabInsee.join(',')
							axios.get(url, { headers: that.app.HeaderEfocusBack})
							.then(function(response) {
								if (response) {
									if (response.data) {
										for (let k = 0; k < response.data.length; k++) {
											vi[response.data[k].insee] = response.data[k].prix_m2_median
										}
										let repartitionPrix = vi
										// 1. Trouver le prix minimum et maximum
										let prixValues = Object.values(repartitionPrix);
										let minPrix = Math.min(...prixValues);
										let maxPrix = Math.max(...prixValues);

										// 2. Créer une échelle de 10 intervalles
										let intervalles = 10;
										let intervalleTaille = (maxPrix - minPrix) / intervalles;

										// 3. Attribuer chaque code INSEE à un intervalle en fonction de son prix
										for (let insee in repartitionPrix) {
											let prix = repartitionPrix[insee];
											// Calculer à quel intervalle appartient ce prix
											let intervalleIndex = Math.floor((prix - minPrix) / intervalleTaille);
											// S'assurer que l'index ne dépasse pas la limite supérieure (en cas de prix maximum)
											if (intervalleIndex === intervalles) {
												intervalleIndex = intervalles - 1;
											}
											echelle[insee] = intervalleIndex + 1;  // +1 pour que les intervalles soient de 1 à 10
										}
									}

									L.geoJson(response_wfs.data,
									{
										style: function(feature) {
											// Obtenir la valeur de properties.insee
											var insee = feature.properties[tgeo];
											var color;
											color = that.echelle_color[echelle[insee]];

											// Retourner l'objet de style
											return {
												fillColor: color,
												weight: 2,
												opacity: 1,
												color: 'white',  // Couleur de la bordure
												fillOpacity: 0.7
											};
										},
										onEachFeature: function()
										{
										}
									}).addTo(that.group_com);
									that.$store.map_osm.addLayer(that.group_com);


								}
							})


						}
					}
				}
			})
		},

		updateMarkers: function() {
			this.app.programs.forEach(program => {
				const pxm2 = program.tva_pleine_libre_prix_m2_parking_avec ||
							program.tva_pleine_libre_prix_m2_parking_sans ||
							program.tva_pleine_aide_prix_m2_parking_avec ||
							program.tva_pleine_aide_prix_m2_parking_sans ||
							program.tva_reduite_prix_m2_parking_avec ||
							program.tva_reduite_prix_m2_parking_sans || 0;

				if (program.marker) {
					this.removeAllClasses(program.marker);
					const markerClass = this.getMarkerClass(program, pxm2);
					if (!program.pc && markerClass) {
						program.marker.classList.add(markerClass);
					}
				}
			});
		},

		removeAllClasses: function(marker) {
			const classesToRemove = [
				'collectif_encours', 'collectif_termine', 'collectif_entree', 
				'collectif_milieu', 'collectif_haut', 'individuel_encours', 
				'individuel_termine', 'individuel_entree', 'individuel_milieu', 
				'individuel_haut', 'prix', 'forme', 'tva', 'actif', 'termine',
				'col', 'ind', 'milieu', 'bas', 'haut'
			];
			classesToRemove.forEach(cls => marker.classList.remove(cls));
		},

		getMarkerClass: function(program, pxm2) {
			const f = program.forme_urbaine.toLowerCase() !== 'individuel' ? 'collectif' : 'individuel';
			let h = '';

			if (this.app.pin_type === 'commercialisation') {
				h = program.COMMERCIALISATION === 'termine' ? `${f}_termine` : `${f}_encours`;
			} else {
				if (pxm2 < this.app.echelleprix_1) {
					h = `${f}_entree`;
				} else if (pxm2 < this.app.echelleprix_2) {
					h = `${f}_milieu`;
				} else {
					h = `${f}_haut`;
				}
			}
			return h;
		},

		async DisplayContourPrix(newValue) {

			try {
				if (this.openMapTilesLayer_Loyer) this.$store.map_osm.removeLayer(this.openMapTilesLayer_Loyer);
				if (this.openMapTilesLayer_Prix) this.$store.map_osm.removeLayer(this.openMapTilesLayer_Prix);
				if (!newValue) return;

					const scalesData = this.app.cityscan_scalesData;
					if (scalesData) {
						var t_prix = [], t_color = [];
						scalesData.forEach(scale => {
							t_prix.push(scale.range.max);
							t_color.push(scale.color);
						});
						this.legend_ancien = scalesData;
						const openmaptilesUrl = `${this.app.BaseUrlBackend}/proxy/procityscan/api/map/protobuf/16210/{z}/{x}/{y}?clientKey=${this.app.cityscan.clientKey}`;
						const vectorTileLayerStyles = this.createVectorTileLayerStyles(t_prix, t_color);
						this.openMapTilesLayer_Prix = L.vectorGrid.protobuf(openmaptilesUrl, {
							vectorTileLayerStyles,
							key: this.app.cityscan.clientKey,
							xhrHeaders: { 'Authorization': this.app.jwt }
						});
						this.openMapTilesLayer_Prix.addTo(this.$store.map_osm);
					}
			} catch (error) {
				console.error('Erreur:', error);
				// Gestion de l'erreur
			}
		},

		async fetchLegendData(activationId) {
			const url = `${this.app.BaseUrlBackend}/proxy/procityscan/api/legend/indicator?language=fr-FR&id=16210&evaluationId=${activationId}&byMonth=true`;
			const response = await axios.get(url, { headers: this.app.HeaderEfocusBack });
			return response.data.content.scales;
		},

		processScalesData: function(scales) {
			let t_prix = [], t_color = [];
			scales.forEach(scale => {
				t_prix.push(scale.range.max);
				t_color.push(scale.color);
			});
			return { t_prix, t_color };
		},

		createVectorTileLayerStyles: function(t_prix, t_color) {
			
			function styleForPriceLayer(properties, t_prix, t_color, propertyName) {
				const m = JSON.parse(properties[propertyName]);
				const level = m.mean.value;
				var color = t_color[t_prix.length - 1];

				for (let j = 0; j < t_prix.length; j++) {
					if (level < t_prix[j]) {
						color = t_color[j];
						break;
					}
				}

				return {
					weight: 1,
					color: "#fff",
					fillOpacity: 0.75,
					fillColor: color,
					fill: true
				};
			}

			return {
				layer1620: function(properties) {
					var m = JSON.parse(properties.extra);
					var type = '';
					for (let i = 0; i < m.transactions.length; i++) {
						if (m.transactions[i].year > 2016) {
							if (m.transactions[i].indicator_types.length > 1) {
								return this.styleForMultipleTypes();
							} else {
								if (type === '') {
									type = m.transactions[i].indicator_types[0];
								} else {
									if (type !== m.transactions[i].indicator_types[0]) {
										return this.styleForMultipleTypes();
									}
								}
							}
						}
					}
					return styleForType(type);
				},
				layer16210: function(properties) {
					return styleForPriceLayer(properties, t_prix, t_color, 'MEDIAN_PRICE_GLOBAL');
				},
				layer16231: function(properties) {
					return styleForPriceLayer(properties, t_prix, t_color, 'RENTAL_PRICE_APARTMENT');
				},
				layer2111: function(properties) {
					if (properties.cadastre_type === "BUILDING") {
						return false;
					}
					// Autres styles pour layer2111 si nécessaire
					return {
						// Style par défaut pour layer2111
					};
				}
			};
		},

		styleForMultipleTypes: function() {
			return {
				weight: 1,
				color: "#fff",
				fillOpacity: 0.75,
				fillColor: "#e6ff42",
				fill: true
			};
		},

		styleForType: function(type) {
			const typeToColor = {
				'dependency': "#ff1b27",
				'apartment': "#0080ac",
				'house': "#31891c",
				'industrial_commercial_premise': "#6800df",
				'land': "#ffa83e"
			};

			return {
				weight: 1,
				color: "#fff",
				fillOpacity: 0.65,
				fillColor: typeToColor[type] || "#ffffff",
				fill: true
			};
		},

		async DisplayIsochone(newValue) {
			try {
				this.isochrone.clearLayers();
				this.isochroneMapbox.clearLayers();
				this.$store.map_osm.removeLayer(this.isochrone);
				this.$store.map_osm.removeLayer(this.isochroneMapbox);

				if (newValue) {
					/*
					const baseUrl = this.app.BaseUrlBackend + '/proxy/procityscan/api/data/isochrone';
					const params = [
						`evaluationId=${this.app.cityscan.activationId}`,
						`clientKey=${this.app.cityscan.clientKey}`,
						`transportType=${this.app.cityscan.isochrone.transportType}`,
						`duration=${this.app.cityscan.isochrone.duration}`,
						'show=true'
					];
					const url = `${baseUrl}?${params.join('&')}`;

					this.$store.map_osm.addLayer(this.isochrone);
					const response = await axios.get(url, { headers: this.app.HeaderEfocusBack });

					if (!response.error && response.data.content?.geometry) {
						const geometry = response.data.content.geometry;
						const coordinates = geometry.coordinates;
						const processCoordinates = coords => coords.forEach(coord => coord.reverse());

						if (geometry.type === 'MultiPolygon') {
							coordinates.forEach(polygon => {
								processCoordinates(polygon[0]);
								L.polygon(polygon, { color: 'orange' }).addTo(this.isochrone);
							});
						} else {
							processCoordinates(coordinates[0]);
							L.polygon(coordinates, { color: 'orange' }).addTo(this.isochrone);
						}
					}
					*/


					let transporttype = '';
					if (this.app.isochrone.transportType == 'DRIVING') transporttype = 'driving'
					if (this.app.isochrone.transportType == 'BIKING') transporttype = 'cycling'
					if (this.app.isochrone.transportType == 'WALKING') transporttype = 'walking'

					if (transporttype!='') {
						this.$store.map_osm.addLayer(this.isochroneMapbox);
						const urlMapbox = 'https://api.mapbox.com/isochrone/v1/mapbox/' + transporttype + '/' + this.app.mrk.lng + '%2C' + this.app.mrk.lat + '?contours_minutes=' + this.app.isochrone.duration + '&generalize=0&polygons=true&denoise=1&access_token=pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';
						fetch(urlMapbox)
						.then((response) => response.json())
						.then((data) => {
						// Ajouter les données GeoJSON à la carte
						L.geoJSON(data, {
							style: (feature) => ({
							color: feature.properties.color,
							fillColor: feature.properties.fillColor,
							fillOpacity: feature.properties.fillOpacity,
							opacity: feature.properties.opacity,
							}),
						}).addTo(this.isochroneMapbox);
						})
						.catch((error) => {
							console.error("Erreur lors de la récupération des données GeoJSON:", error);
						});
					}
					
				}
			} catch (error) {
				console.error('Erreur lors de la récupération des données isochrones:', error);
				// Gestion de l'erreur
			}
		},

		async DisplayContourIso(newValue) {
			try {
				this.isochrone.clearLayers();
				this.$store.map_osm.removeLayer(this.isochrone);
				this.isochroneMapbox.clearLayers();
				this.$store.map_osm.removeLayer(this.isochroneMapbox);

				if (newValue) {
/**/
					const url = `${this.app.BaseUrlBackend}/proxy/procityscan/api/data/isochrone?evaluationId=${this.app.cityscan.activationId}&clientKey=${this.app.cityscan.clientKey}&transportType=${this.app.cityscan.isochrone.transportType}&duration=${this.app.cityscan.isochrone.duration}&show=true`;

					this.$store.map_osm.addLayer(this.isochrone);

					const response = await axios.get(url, { headers: this.app.HeaderEfocusBack });
					if (!response.error && response.data.content?.geometry) {
						const processData = (data) => {
							data.forEach(coord => coord.reverse());
							L.polygon(data, { color: 'orange' }).addTo(this.isochrone);
						};

						const geometry = response.data.content.geometry;
						if (geometry.type === 'MultiPolygon') {
							geometry.coordinates.forEach(coords => processData(coords[0]));
						} else {
							processData(geometry.coordinates[0]);
						}
					}
/**/
					let transporttype = '';
					if (this.app.cityscan.isochrone.transportType == 'DRIVING') transporttype = 'driving'
					if (this.app.cityscan.isochrone.transportType == 'BIKING') transporttype = 'cycling'
					if (this.app.cityscan.isochrone.transportType == 'WALKING') transporttype = 'walking'
					if (transporttype!='') {
/*
						this.$store.map_osm.addLayer(this.isochroneMapbox);
						const urlMapbox = 'https://api.mapbox.com/isochrone/v1/mapbox/' + transporttype + '/' + this.app.mrk.lng + '%2C' + this.app.mrk.lat + '?contours_minutes=' + this.app.cityscan.isochrone.duration + '&generalize=0&polygons=true&denoise=1&access_token=pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';
						fetch(urlMapbox)
						.then((response) => response.json())
						.then((data) => {
						// Ajouter les données GeoJSON à la carte
						L.geoJSON(data, {
							style: (feature) => ({
							color: feature.properties.color,
							fillColor: feature.properties.fillColor,
							fillOpacity: feature.properties.fillOpacity,
							opacity: feature.properties.opacity,
							}),
						}).addTo(this.isochroneMapbox);
						})
						.catch((error) => {
							console.error("Erreur lors de la récupération des données GeoJSON:", error);
						});
*/
					}

				}
			} catch (error) {
				console.error('Erreur lors de la récupération des données isochrones:', error);
				// Gestion de l'erreur
			}
		},

		set_view_layer: function(layer) {
			this.$store.dispatch('set_view_layer', {view: layer});
		},

		uniquementSelection: function(v) {
			this.selection_seul = v;
			this.$store.dispatch('set_uniquemen_selection', {value: v})			
			for (let i in this.app.programs) {
				if (this.app.programs[i].stock_initial < 6) {
					if (v) {
						try {
							if (this.app.programs[i] && this.app.programs[i].marker) this.app.programs[i].marker.classList.add('invisible');
						} catch (error) {
							//
						}
					} else {
						try {
							if (this.app.programs[i] && this.app.programs[i].marker) this.app.programs[i].marker.classList.remove('invisible');
						} catch (error) {
							//
						}
					}
				}
				else {
					let elements = document.querySelectorAll('[id^="' + this.app.programs[i].id + '-"]');
					for (let j = 0; j < elements.length; j++) {
						let elementId = elements[j].id;
						if (v) {
							if (document.getElementById(elementId).className != 'checkbox checked') {
								try {
									if (this.app.programs[i] && this.app.programs[i].marker) this.app.programs[i].marker.classList.add('invisible');
								} catch (error) {
									// erreur
									console.log(error)
								}
							}
						} else {
							try {
								if (this.app.programs[i] && this.app.programs[i].marker) this.app.programs[i].marker.classList.remove('invisible');
							} catch (error) {
								// erreur
								console.log(error)
							}
						}
					}
				}
			}
			for (let ii in this.app.rss) {
				let elements = document.querySelectorAll('[id^="' + this.app.rss[ii].id + '-"]');
				for (let jj = 0; jj < elements.length; jj++) {
					let elementId_rs = elements[jj].id;
					if (v) {
						if (document.getElementById(elementId_rs).className != 'checkbox checked') {
							try {
								if (this.app.rss[i] && this.app.rss[i].marker) this.app.rss[ii].marker.classList.add('invisible');
							} catch (error) {
								// erreur
							}
						}
					} else {
						try {
							if (this.app.rss[i] && this.app.rss[i].marker) this.app.rss[ii].marker.classList.remove('invisible');
						} catch (error) {
							// erreur
						}
					}
				}
			}
		},

		iconClass(fclass) {
			return fclass;
		},

		iconPOI(fclass, b) {

			let s = new Object();
			let bus 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_Bus.svg';
			let metro 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_Metro.svg';
			let train 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_TRAIN.svg';
			let tram 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/_Tram.svg';
			let airport 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/airport.svg';

			s['subway_entrance'] = metro;
			s['subway_exit'] = metro;
			s['tram_stop'] = tram;
			s['gare_station'] = train;
			s['bus_stop'] = bus;
			s['bus_station'] = bus;
			s['airport'] = airport;

			let ecole = 'https://www.myadequation.fr/EFOCUS/icones/2.0/ecole.svg';
			let alimentaire 	= 'https://www.myadequation.fr/EFOCUS/icones/2.0/alimentaire.svg';
			let cafe = 'https://www.myadequation.fr/EFOCUS/icones/2.0/cafe.svg';
			let club = 'https://www.myadequation.fr/EFOCUS/icones/2.0/club.svg';
			let hotel = 'https://www.myadequation.fr/EFOCUS/icones/2.0/hotel.svg';
			let restaurant = 'https://www.myadequation.fr/EFOCUS/icones/2.0/restaurant.svg';
			let theatre = 'https://www.myadequation.fr/EFOCUS/icones/2.0/theatre.svg';
			let equipement_maison = 'https://www.myadequation.fr/EFOCUS/icones/2.0/utilitaire.svg';
			let equipement_personne = 'https://www.myadequation.fr/EFOCUS/icones/2.0/equ_personne.svg';
			let equipement_public = 'https://www.myadequation.fr/EFOCUS/icones/2.0/publics.svg';
			let hopital = 'https://www.myadequation.fr/EFOCUS/icones/2.0/hopital.svg';
			let pharmacie = 'https://www.myadequation.fr/EFOCUS/icones/2.0/pharmacie.svg';
			let bank = 'https://www.myadequation.fr/EFOCUS/icones/2.0/bank.svg';
			let medecin = 'https://www.myadequation.fr/EFOCUS/icones/2.0/medecin.svg';
			let police = 'https://www.myadequation.fr/EFOCUS/icones/2.0/police.svg';
			let soin = 'https://www.myadequation.fr/EFOCUS/icones/2.0/soin.svg';
			let service = 'https://www.myadequation.fr/EFOCUS/icones/2.0/Services.svg';
			let shopping = 'https://www.myadequation.fr/EFOCUS/icones/2.0/shopping.svg';
			let Poste = 'https://www.myadequation.fr/EFOCUS/icones/2.0/Poste.svg';
			let parc = 'https://www.myadequation.fr/EFOCUS/icones/2.0/parc.svg';
			let musees = 'https://www.myadequation.fr/EFOCUS/icones/2.0/musees.svg';
			let sport = 'https://www.myadequation.fr/EFOCUS/icones/2.0/sport.svg';
			let cinema = 'https://www.myadequation.fr/EFOCUS/icones/2.0/cinema.svg';
			let none = 'https://www.myadequation.fr/EFOCUS/icones/2.0/none.svg';

			s['playground'] = parc;
			s['attraction'] = parc;
			s['bench'] = parc;
			s['camp_site'] = hotel;
			s['caravan_site'] = hotel;
			s['convenience'] = alimentaire;
			s['park'] = parc;
			s['theme_park'] = parc;
			s['picnic_site'] = parc;
			s['gift_shop'] = shopping;
			s['jeweller'] = equipement_personne;
			s['travel_agent'] = service;
			s['food_court'] = cafe ;
			s['embassy'] = equipement_public;
			s['car_sharing'] = service;
			s['bank'] = bank;
			s['bar'] = cafe;
			s['pub'] = cafe;
			s['public_building'] = equipement_public;
			s['town_hall'] = equipement_public;
			s['courthouse'] = equipement_public;
			s['laundry'] = service;
			s['post_box'] = service;
			s['butcher'] = alimentaire;
			s['bakery'] = alimentaire;
			s['post_office'] = Poste;
			s['cafe'] = cafe;
			s['fire_station'] = equipement_public;
			s['mall'] = shopping;
			s['arts_centre'] = musees;
			s['sports_centre'] = sport;
			s['bed_and_breakfast'] = hotel;
			s['guesthouse'] = hotel;
			s['hostel'] = hotel;
			s['hotel'] = hotel;
			s['motel'] = hotel;
			s['castle'] = musees;
			s['cinema'] = cinema;
			s['nightclub'] = club;
			s['hairdresser'] = soin;
			s['chemist'] = soin;
			s['dentist'] = medecin;
			s['education'] = ecole;
			s['florist'] = shopping;
			s['fort'] = musees;
			s['recycling_clothes'] = shopping;
			s['kindergarten'] = ecole;
			s['hospital'] = hopital;
			s['department_store'] = alimentaire;
			s['garden_centre'] = equipement_maison;
			s['car_wash'] = service;
			s['bookshop'] = shopping;
			s['library'] = shopping;
			s['bicycle_rental'] = service;
			s['car_rental'] = service;
			s['doityourself'] = equipement_maison;
			s['shoe_shop'] = equipement_personne;
			s['toy_shop'] = shopping;
			s['furniture_shop'] = equipement_maison;
			s['sports_shop'] = shopping;
			s['mobile_phone_shop'] = shopping;
			s['clothes'] = equipement_personne;
			s['computer_shop'] = shopping;
			s['nursing_home'] = soin;
			s['newsagent'] = shopping;
			s['doctors'] = medecin;
			s['memorial'] = musees;
			s['monument'] = musees;
			s['museum'] = musees;
			s['tourist_info'] = musees;
			s['optician'] = soin;
			s['artwork'] = musees;
			s['stationery'] = shopping;
			s['ice_rink'] = sport;
			s['pharmacy'] = pharmacie;
			s['swimming_pool'] = sport;
			s['track'] = sport;
			s['viewpoint'] = musees;
			s['police'] = police;
			s['greengrocer'] = alimentaire;
			s['recycling'] = equipement_maison;
			s['recycling_glass'] = equipement_maison;
			s['recycling_metal'] = equipement_maison;
			s['recycling_paper'] = equipement_maison;
			s['fast_food'] = restaurant ;
			s['restaurant'] = restaurant ;
			s['ruins'] = musees;
			s['beauty_shop'] = soin;
			s['archaeological'] = musees;
			s['stadium'] = sport;
			s['wastewater_plant'] = equipement_maison;
			s['supermarket'] = alimentaire;
			s['vending_cigarette'] = shopping;
			s['kiosk'] = shopping;
			s['golf_course'] = sport;
			s['pitch'] = sport;
			s['theatre'] = theatre;
			s['observation_tower'] = musees;
			s['college'] = ecole;
			s['university'] = ecole;
			s['car_dealership'] = service;
			s['bicycle_shop'] = shopping;
			s['veterinary'] = service;
			s['zoo'] = musees;
			s['clinic'] = hopital;
			s['market_place'] = shopping;
			s['outdoor_shop'] = shopping;
			s['school'] = ecole;
			s[''] = none;



			s["Boulangerie"] = 'https://cdn.adequation.fr/files/2b0eb16e4a491f84e3aceb2c38924278.svg';
            s["Epicerie"] = 'https://cdn.adequation.fr/files/2c9421fd6066c61b9567883d5cd43815.svg';
            s["Supermarché"] = 'https://cdn.adequation.fr/files/c538e203a751c0af6c2f1b4a376abd67.svg';
            s["Hypermarché"] = 'https://cdn.adequation.fr/files/7e332e8adb35a8d66a58a3bab5d5a07f.svg';
            s["Centre commercial"] = 'https://cdn.adequation.fr/files/79bc1ff3c4fb65f48b004e2c69fc9d59.svg';
            s["Boucher"] = 'https://cdn.adequation.fr/files/48e334f75186e8745a8ba2f7d0697430.svg';
            s["Primeur"] = 'https://cdn.adequation.fr/files/2c853b70624ed2fef300297e801c9590.svg';
            s["Hôtel"] = 'https://cdn.adequation.fr/files/7ff5d5d6e703e2ab89776f8517c35235.svg';
            s["Bar"] = 'https://cdn.adequation.fr/files/4aea274a4733aa134e86e23729477a12.svg';
            s["Café"] = 'https://cdn.adequation.fr/files/1eceadbbd7a22398d445ca018d839b55.svg';
            s["Restaurant"] = 'https://cdn.adequation.fr/files/bfe5b2ff644c613211345b248d5ee3c6.svg';
            s["Camping"] = 'https://cdn.adequation.fr/files/2804aeafe94452305f04f819c42efb3c.svg';
            s["Camping caravane"] = 'https://cdn.adequation.fr/files/c6bdb82c4191c05ee388790ea3855c2d.svg';
            s["Librairie"] = 'https://cdn.adequation.fr/files/1c89622adb758e331ae37f93c7cb94d6.svg';
            s["Marchand de journaux"] = 'https://cdn.adequation.fr/files/38dc72ca0d1589ea50233d55869f031e.svg';
            s["Château"] = 'https://cdn.adequation.fr/files/381fd5c9fa5970a699caa9a30f7738a4.svg';
            s["Musée"] = 'https://cdn.adequation.fr/files/828ffe9d3b82b95c639bf8fae294e758.svg';
            s["Parc"] = 'https://cdn.adequation.fr/files/d9f3b13062886e5cebd5eba50d52ca3b.svg';
            s["Théâtre"] = 'https://cdn.adequation.fr/files/6424253e4d45ed26ffc84a96a3712bdb.svg';
            s["Magasin de jouets"] = 'https://cdn.adequation.fr/files/c5d519e849c1f1eedd917288bfc26e39.svg';
            s["Fleuriste"] = 'https://cdn.adequation.fr/files/2a70a57a874d4ab5fb6ebfb027be9f65.svg';
            s["Boutique de souvenirs"] = 'https://cdn.adequation.fr/files/bdfec4c916a5e086bfee6a4e84fff77f.svg';
            s["Magasin de sport"] = 'https://cdn.adequation.fr/files/a20ded350c9621cad08801a8b90dfca6.svg';
            s["Papeterie"] = 'https://cdn.adequation.fr/files/0826cc60a43eda5be6a3e7c518eda9a6.svg';
            s["Tabac - Presse"] = 'https://cdn.adequation.fr/files/0a73d1d043b09af1451d8724479f9f8c.svg';
            s["Cinéma"] = 'https://cdn.adequation.fr/files/5f766f4d9bafa3d41e4e1a93f8a2830f.svg';
            s["Terrain de golf"] = 'https://cdn.adequation.fr/files/f875835f46138c92c1060a3105f0816c.svg';
            s["Patinoire"] = 'https://cdn.adequation.fr/files/16c13e671970b88db17ecea84d1a92b6.svg';
            s["Terrain de sport"] = 'https://cdn.adequation.fr/files/c89aead10e0110e170249c203ac66a89.svg';
            s["Aire de jeux"] = 'https://cdn.adequation.fr/files/79de389a4950e90d4b5b86a97480d2e6.svg';
            s["Centre sportif"] = 'https://cdn.adequation.fr/files/219ec286047153c27f523c06f67e3dbb.svg';
            s["Stade"] = 'https://cdn.adequation.fr/files/f8e886413d72bd471eccdd163421a7f7.svg';
            s["Piscine"] = 'https://cdn.adequation.fr/files/4823a554559b82b691283c97882ec273.svg';
            s["Zoo"] = 'https://cdn.adequation.fr/files/2b53f3126aa13e81b1a139b8093ab83b.svg';
            s["Magasin de bricolage"] = 'https://cdn.adequation.fr/files/babb08e10e6d0744569e6cbb11e35d04.svg';
            s["Jardinerie"] = 'https://cdn.adequation.fr/files/0af7b0a81c3942c3677ecc0ebb8bb896.svg';
            s["Magasin de meubles"] = 'https://cdn.adequation.fr/files/7931a93383c56f978f321cf71a51effb.svg';
            s["Magasin de chaussures"] = 'https://cdn.adequation.fr/files/3819ede246591388897007daa032fb38.svg';
            s["Bijouterie"] = 'https://cdn.adequation.fr/files/2cf88d5286de3c0ca117811dcd6c0c33.svg';
            s["Magasin de vêtements"] = 'https://cdn.adequation.fr/files/2fa953ad3c9f4f4f39443b2c855eed78.svg';
            s["Police"] = 'https://cdn.adequation.fr/files/8c5ee0ccd2f44e7e9fd15109d2abf4e6.svg';
            s["Hôtel de ville"] = 'https://cdn.adequation.fr/files/4bc4ef7342c41f790055ce794c2ff312.svg';
            s["Tribunal"] = 'https://cdn.adequation.fr/files/e971d048621e241f5887fc9bb092bacb.svg';
            s["Bureau de poste"] = 'https://cdn.adequation.fr/files/ea3a75d144b3e8af2c4160f72021a28a.svg';
            s["Enseignement"] = 'https://cdn.adequation.fr/files/24e951729c3e1dd52fd7a550d6140cb9.svg';
            s["Université"] = 'https://cdn.adequation.fr/files/c42cb943be5a45443cbdbd4305b9d83c.svg';
            s["Prison"] = 'https://cdn.adequation.fr/files/34513adbe42a6ad46d346d0391b3267e.svg';
            s["Bâtiment public"] = 'https://cdn.adequation.fr/files/e7afcd875302f7746d8c9c0b92eb6c95.svg';
            s["Hôpital"] = 'https://cdn.adequation.fr/files/3f7b839d2215e8754fee2521f3b7de13.svg';
            s["Pharmacie"] = 'https://cdn.adequation.fr/files/e4933611a1453e7ef33a61a645a1c706.svg';
            s["Cosmétique"] = 'https://cdn.adequation.fr/files/9a0abd25a9d25c62ed1b3aaa1ac1e855.svg';
            s["Opticien"] = 'https://cdn.adequation.fr/files/0882a75b69e71984c599e83d53cf4573.svg';
            s["Coiffeur"] = 'https://cdn.adequation.fr/files/c52490e0cb16aa56c31b272b6892cbb2.svg';
            s["Dentiste"] = 'https://cdn.adequation.fr/files/20f302cc84659b18ef02eb4fd512414f.svg';
            s["Salon de beauté"] = 'https://cdn.adequation.fr/files/8e92c06daf43fa39a90c8f53c26eecf0.svg';
            s["Médecins"] = 'https://cdn.adequation.fr/files/a7ef633ccd55444b1db625758bbd7d30.svg';
            s["Maison de repos"] = 'https://cdn.adequation.fr/files/bf02bf5998bab6917b1a24bfb79db3cb.svg';
            s["Vendeur auto"] = 'https://cdn.adequation.fr/files/d55dd6b92fb57434a56c42cb2be6569f.svg';
            s["Banque"] = 'https://cdn.adequation.fr/files/0dfa6d570dcc2aea5815fae20a0825d4.svg';
            s["Magasin de téléphonie"] = 'https://cdn.adequation.fr/files/2a45786637c623bbb42c613ff9d55476.svg';
            s["Cimetière"] = 'https://www.myadequation.fr/EFOCUS/icones/4.0/cimetiere.svg';
            s["Garderie"] = 'https://cdn.adequation.fr/files/b8b34d9832f8be6295649be985794090.svg';
					
			if (b) {
				try {					
					let v = s[fclass]
					return v
				} catch (error) {
					return false
				}
			}
					
			let Icon
			if (s[fclass]!='') {
				Icon = L.icon({iconUrl: s[fclass], iconSize: [30, 30], className: 'transparent-icon'});
				return Icon;
			}
			return false;
		},

		fetchPOIList_old() {
			if (this.app.view.includes('adresse') || this.app.view.includes('transport') || this.app.view.includes('education') || 
				this.app.view.includes('commerces') || this.app.view.includes('vie') || this.app.view.includes('loisirs') || 
				this.app.view.includes('numerique') || this.app.view.includes('services')) {
				// Nothing
			} else {
				let cp = this.app.contour_poi;
				let listepoi = "";
				for (let index = 0; index < cp.length; index++) {
					const element = cp[index];					
					let bItem = false;

					if (['Education', 'Collége', 'Ecole élémentaire', 'Ecole maternelle', 'Ecole primaire', 'Lycée',
						'Section d\'enseignement', 'Enseignement spécialisé', 'Enseignement supérieur', 'Transports', 'Bus', 'Tramway', 'Métro', 'Gare', 'Aéroport'].indexOf(element)<0) {
						for (let i=0; i < this.options.length; i++) {
							const op = this.options[i];
							if (element == op.label) {
								bItem = true;
								for (let j = 0; j < op.children.length; j++) {
									const souselement = op.children[j];
									if (listepoi!='') listepoi += ","
									listepoi += "'" + souselement.label + "'"
								}
								break;
							}
						}
						if (!bItem) {
							if (listepoi!='') listepoi += ","
							listepoi += "'" + element + "'"
						}
					}
				}
				let UneUrl = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_poi&maxFeatures=1000&cql_filter=BBOX(wkb_geometry," + this.$store.map_osm.getBounds().toBBoxString() + ") AND fclass_fr in (" + listepoi + ")&outputFormat=application/json"
				let UneUrlEducation = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_enseignement&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				//var that = this;
				axios.get(UneUrl)
					.then(response => {
						this.poiList = response.data.features;
						this.poiEducation = [];
						this.poiTransport = [];
						axios.get(UneUrlEducation)
							.then(response => {
								let cp = this.app.contour_poi;
								for (let index = 0; index < cp.length; index++) {
									const element = cp[index];														
									if (['Education', 'Collége', 'Ecole élémentaire', 'Ecole maternelle', 'Ecole primaire', 'Lycée',
										'Section d\'enseignement', 'Enseignement spécialisé', 'Enseignement supérieur'].indexOf(element)>=0) {
										for (let index = 0; index < response.data.features.length; index++) {
											const ecole = response.data.features[index];
											if (element=='Education') this.poiEducation.push(ecole)
											else {

												switch (ecole.properties.nature) {
													case "COLLEGE":
														if (element=='Collége') this.poiEducation.push(ecole)
														break;

													case "ECOLE MATERNELLE":
													case "ECOLE MATERNELLE D APPLICATION":
														if (element=='Ecole maternelle') this.poiEducation.push(ecole)
														break;

													case "ECOLE ELEMENTAIRE D APPLICATION":
													case "ECOLE DE NIVEAU ELEMENTAIRE":
													case "ECOLE DE NIVEAU ELEMENTAIRE SPECIALISEE":
														if (element=='Ecole élémentaire') this.poiEducation.push(ecole)
														break;

													case "LYCEE POLYVALENT":
													case "LYCEE PROFESSIONNEL":
													case "LYCEE D ENSEIGNEMENT TECHNOLOGIQUE":
													case "LYCEE D ENSEIGNEMENT GENERAL":
													case "LYCEE ENSEIGNT GENERAL ET TECHNOLOGIQUE":
														if (element=='Lycée') this.poiEducation.push(ecole)
														break;
													
													case "ETABLISSEMENT REGIONAL D'ENSEIGNT ADAPTE":
													case "SECTION ENSEIGT GENERAL ET TECHNOLOGIQUE":
													case "SECTION ENSEIGNT GEN. ET PROF. ADAPTE":
													case "SECTION D ENSEIGNEMENT PROFESSIONNEL":
														if (element=='Section d\'enseignement') this.poiEducation.push(ecole)
														break;
													
													case "ECOLE PROFESSIONNELLE SPECIALISEE":
													case "COLLEGE SPECIALISE":
														if (element=='Enseignement spécialisé') this.poiEducation.push(ecole)
														break;

													case "UNIVERSITE":
														if (element=='Enseignement supérieur') this.poiEducation.push(ecole)
														break;

													default:
														break;

												}
											}
										}
									}
								}
								
								axios.get(UneUrlTransport)
									.then(response => {
										let cp = this.app.contour_poi;
										for (let index = 0; index < cp.length; index++) {
											const element = cp[index];												
//											if (['Transports', 'Bus', 'Tramway', 'Métro', 'Gare', 'Aéroport'].indexOf(element)>=0) {
											if (['Transports', 'Bus', 'Aéroport'].indexOf(element)>=0) {
												for (let index = 0; index < response.data.features.length; index++) {
													const transport = response.data.features[index];
													switch (transport.properties.type) {
														case "bus_station":
														case "bus_stop":
															transport.libelle = 'Bus';
															if (element=='Bus'||element=='Transports') this.poiTransport.push(transport)
															break;
//														case "tram_stop":
//															transport.libelle = 'Tramway';
//															if (element=='Tramway'||element=='Transports') this.poiTransport.push(transport)
//															break;
//														case "subway_entrance":
//															transport.libelle = 'Métro';
//															if (element=='Métro'||element=='Transports') this.poiTransport.push(transport)
//															break;
//														case "gare_station":
//															transport.libelle = 'Gare';
//															if (element=='Gare'||element=='Transports') this.poiTransport.push(transport)
//															break;
														case "airport":
															transport.libelle = 'Aéroport';
																if (element=='Aéroport'||element=='Transports') this.poiTransport.push(transport)
																break;
														default:
															break;
													}
												}
											}
										}
										this.displayPOIOnMap(this.poiList, this.poiEducation, this.poiTransport);
									})
									.catch(error => {
										console.error(error);
									});
							})
							.catch(error => {
								console.error(error);
							});
					})
					.catch(error => {
						console.error(error);
					});
			}
		},

		// eslint-disable-next-line 
		displayPOIOnMap(filteredPOI, filteredEcole, filteredTransport) {
			// Effacer les anciens marqueurs de POI
			const newPOIs = filteredPOI;
			let newPOIMap = []
			for (const p in newPOIs) {
				newPOIMap.push(newPOIs[p].properties.gid)
			}

			// Remove POIs that are no longer in the bounds
			for (const id in this.currentPOIs) {
				let index = newPOIMap.indexOf(this.currentPOIs[id].properties.gid);
				if (index == -1) {
					if (this.group_poi[this.currentPOIs[id].properties.fclass_fr]) {
						this.group_poi[this.currentPOIs[id].properties.fclass_fr].removeLayer(this.currentPOIs[id].marker);
					}
					delete this.currentPOIs[id];
				}
			}
			if (newPOIs) {
				// Add or update POIs
				newPOIs.forEach(poi => {
					let longitude;
					let latitude;
					try {					
						[longitude, latitude] = poi.geometry.coordinates[0];	
					} catch (error) {
						[longitude, latitude] = [poi.geometry.coordinates[0],poi.geometry.coordinates[1]];
					}
					if (this.currentPOIs[poi.properties.gid]) {
						// Update existing POI if necessary
					} else {
						// Add new POI

						if (this.iconPOI(poi.properties.fclass_fr, true)) {
							const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(poi.properties.fclass_fr)});

							// Ajouter une info-bulle au marqueur
							poiMarker.bindPopup(
								'<div class="cs-itinerary-popin">'+
									'<div class="cs-itinerary-popin-header" style="background-color: ' + 'rgb(255, 73, 24);' + '">' + 													
										'<div class="cs-itinerary-popin-title-wrapper">' +
											'<h4 class="cs-itinerary-popin-title">' + this.iconClass(poi.properties.fclass_fr) + ' </h4>' +
										'</div>' +
										'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
									'</div>' +
									'<div class="cs-itinerary-popin-content">' +
										'<div class="cs-itinerary-popin-main-data">' +
											'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + poi.properties.name + '</span>' +
											'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
												'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
											'</cs-poi-line-numbers>' +
										'</div>' +
									'</div>' +
								'</div>'
							).openPopup();

							if (!this.group_poi[poi.properties.fclass_fr]) {
								let that = this
								this.group_poi[poi.properties.fclass_fr] = new L.markerClusterGroup({
									iconCreateFunction: function(cluster) {
										const childCount = cluster.getChildCount();
										const imgurl = that.iconPOI(poi.properties.fclass_fr, true)
										if (imgurl) {
											return L.divIcon({
												className: 'my-custom-cluster transparent-icon',
												html: `
													<div style="background-image: url('${imgurl}'); width: 30px; height: 30px; background-size: cover; position: relative;">
													<span style="position: absolute; top: -2px; right: -2px; color:white; background-color: #0fa8bd; border-radius: 50%; width: 13px; height: 13px; font-size: 8px; text-align: center; line-height: 13px;">
														${childCount}
													</span>
													</div>
												`,
												iconSize: [30, 30]
											});
										}
									}
								});
								this.$store.map_osm.addLayer(this.group_poi[poi.properties.fclass_fr])
							}


							poiMarker.addTo(this.group_poi[poi.properties.fclass_fr]);

							this.currentPOIs[poi.properties.gid] = { lat: latitude, lng: longitude, marker: poiMarker, properties: poi.properties };
						}
					}
				});
			}
			

			// Ajouter les marqueurs de POI sur la carte
			if (filteredEcole) {
				if (!this.group_poi['education']) this.group_poi['education'] = L.featureGroup().addTo(this.$store.map_osm)

				filteredEcole.forEach(poi => {
					let longitude;
					let latitude;
					try {					
						[longitude, latitude] = poi.geometry.coordinates[0];	
					} catch (error) {
						[longitude, latitude] = [poi.geometry.coordinates[0],poi.geometry.coordinates[1]];
					}
					
					const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI('education')});

					// Ajouter une info-bulle au marqueur
					poiMarker.bindPopup(
						'<div class="cs-itinerary-popin">'+
							'<div class="cs-itinerary-popin-header" style="background-color: ' + 'blue;' + '">' +
								'<div class="cs-itinerary-popin-title-wrapper">' +
									'<h4 class="cs-itinerary-popin-title">' + poi.properties.nature + '</h4>' +
								'</div>' +
								'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
							'</div>' +
							'<div class="cs-itinerary-popin-content">' +
								'<div class="cs-itinerary-popin-main-data">' +
									'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + poi.properties.nom + '</span>' +
									'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
										'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
									'</cs-poi-line-numbers>' +
								'</div>' +
							'</div>' +
						'</div>'
					).openPopup();
					poiMarker.addTo(this.group_poi['education']);
				});
			}

			// Ajouter les marqueurs de POI sur la carte
			
			if (filteredTransport && filteredTransport.length > 0) {

				if (!this.group_poi['transport']) {
					this.group_poi['transport'] = L.featureGroup().addTo(this.$store.map_osm)
				}
				filteredTransport.forEach(poi => {
					let longitude;
					let latitude;
					try {					
						[longitude, latitude] = poi.geometry.coordinates[0];	
					} catch (error) {
						[longitude, latitude] = [poi.geometry.coordinates[0],poi.geometry.coordinates[1]];
					}
					let bok = true;
					if (bok) {
						const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(poi.properties.type)});
						// Ajouter une info-bulle au marqueur
						poiMarker.bindPopup(
							'<div class="cs-itinerary-popin">'+
								'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
									'<div class="cs-itinerary-popin-title-wrapper">' +
										'<h4 class="cs-itinerary-popin-title">' + poi.libelle + '</h4>' +
									'</div>' +
									'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
								'</div>' +
								'<div class="cs-itinerary-popin-content">' +
									'<div class="cs-itinerary-popin-main-data">' +
										'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + poi.properties.name + '</span>' +
										'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
											'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
										'</cs-poi-line-numbers>' +
									'</div>' +
								'</div>' +
							'</div>'
						).openPopup();
						poiMarker.addTo(this.group_poi['transport']);
					}
					this.group_poi['transport'].addTo(this.$store.map_osm)
				});
			
			} else {
				try { this.group_poi['transport'].clearLayers() } catch (error)  { /* erreur*/ }
				try { this.$store.map_osm.removeLayer(this.group_poi['transport']) } catch (error)  { /* erreur*/ }
				try { this.group_poi['transport'] = new L.featureGroup(); } catch (error)  { /* erreur*/ }
			}
			
		},

		setf: function() {
			this.$store.dispatch('set_fond_carte', {fond: this.fond})
		},

		setfl: function() {
			this.$store.dispatch('set_fond_carte_loyer', {fond_loyer: this.fond_loyer})
		},

		async setFondYanport() {
			try {
				if (this.openMapTilesLayer_Prix) this.$store.map_osm.removeLayer(this.openMapTilesLayer_Prix);
				if (this.openMapTilesLayer_Loyer) this.$store.map_osm.removeLayer(this.openMapTilesLayer_Loyer);
			} catch (error) {
				// erreur
			}

			this.openMapTilesLayer_Loyer = L.featureGroup().addTo(this.$store.map_osm)
			this.legend_ancien = false
			this.legend_locatif = false
			let that = this
			let min=0
			let max=0
			let d = new Date()
			let datemax = d.toISOString()
			let d2 = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
			let datemin = d2.toISOString()
			let f = ""
			switch (this.app.fond_carte_loyer) {
				case 2:
					f = "&zoneType=CITY"
					break;
//				case 3:
//					f = "&zoneType=AGGLOMERATION"
//					break;
				case 4:
					f = "&zoneType=DEPARTMENT"
					break;
				default:
					break;
			}
			let UneUrl = this.app.BaseUrlBackend + '/proxy/yanport/maps?size=20&existingFields=marketing.priceM2HC&propertyTypes=HOUSE&propertyTypes=APARTMENT' + f + '&active=false&bbox=' + this.$store.map_osm.getBounds()._northEast.lat + ',' + this.$store.map_osm.getBounds()._southWest.lng + ',' + this.$store.map_osm.getBounds()._southWest.lat + ',' + this.$store.map_osm.getBounds()._northEast.lng + '&excludeLeBonCoinWith60DurationDays=true&from=0&deleteDateMax=' + datemax + '&zoomLevel=14.25&marketingTypes=RENT&deleteDateMin=' + datemin + '&field=PRICE_M2_HC&source=AD'
			$.getJSON(UneUrl, function(dataRegion)
			{
				L.geoJson(dataRegion,
				{
					onEachFeature: function(feature)
					{
						let n = feature.properties.percentiles.values["50.0"]
						if (n != 'NaN') {
							if (n<min || min==0) {
								min = n
							}
							if (n>max || max==0) {
								max = n
							}
						}
					}
				})
				max = Math.round(max)
				min = Math.round(min)
				let dist = (max-min) / 12
				let t = []
				let t_prix = []
				let t_color = []
				min += dist
				t.push(Math.round(min*100)/100)
				that.legend_locatif = []
				for (let n=0; n < 11; n++) {
					min += dist
					let o = Math.round(min*100)/100
					t.push(o)
					t_prix.push(o)
					t_color.push(that.style_loyer[n].fillColor)
					let c = {range: {id: 0}, color:"", label: ""}
					c.range.id = n
					c.color = that.style_loyer[n].fillColor
					c.range.prix = o
					c.label = "< " + o.toFixed(2).toString() + ' €'
					if (n==10) {
						c.label = ">= " + that.legend_locatif[9].range.prix.toFixed(2).toString() + ' €'
					}
					that.legend_locatif.push(c)
				}
				
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style: function(feature){
							let v = feature.properties.percentiles.values["50.0"]
							let selIndex = 0
							if (v=="NaN") {
								return that.style_NAN;
							} else {
								for (let n=0; n < t.length; n++) {
									selIndex = n
									if (v < t[n]) {
										break;
									}
								}
								return that.style_loyer[selIndex];
							}
						},
						onEachFeature: function(feature, layer)
						{
							let v = feature.properties.percentiles.values["50.0"]
							let w = feature.properties.percentiles.count
							let selIndex = 0
							if (v=="NaN") {
								//
							} else {
								v = Math.round(v*100)/100
								for (let n=0; n < t.length; n++) {
									selIndex = n
									if (v < t[n]) {
										break;
									}
								}
							}

							let s=""
							let c=""
							let scolor=""
							let b = ""
							if (v=="NaN") {
								s = '<span class="cs-text cs-text-regular-bold ng-star-inserted">-</span>'
								scolor = that.style_NAN.fillColor												
							} else {
								s = '<span class="cs-text cs-text-regular-bold ng-star-inserted">Loyer médian au m² : ' + v.toString() + ' €</span><br>'
								s += '<span class="cs-text cs-text-regular-bold ng-star-inserted">' + w.toString() + ' annonces</span>'
								scolor = that.style_loyer[selIndex].fillColor
								b = '<div style="width:100%; text-align: center;"><!--<button type="button" class="buttonv" style="width: 120px	!important; background-color:' + scolor + '"' +
									'onclick="' + 
									'document.getElementById(' + "'"  + 'loyer_id' + "'" + ').value=' + "'" + feature.properties.id + "'" + '; ' + 
									'document.getElementById(' + "'"  + 'loyer_name' + "'" + ').value=' + "'" + feature.properties.name.replace("'", "\u2019") + "'" + '; ' + 
									'document.getElementById(' + "'"  + 'loyer_zoneType' + "'" + ').value=' + "'" + feature.properties.zoneType + "'" + '; ' + 
									'document.getElementById(' + "'"  + 'loyer_count' + "'" + ').value=' + "'" + feature.properties.percentiles.count + "'" + '; ' + 
									'document.getElementById(' + "'"  + 'loyer_value' + "'" + ').value=' + "'" + feature.properties.percentiles.values["50.0"] + "'" + '; ' + 
									
									"document.getElementById('" + 'gl' + "').click();document.getElementsByClassName('" + 'leaflet-popup-close-button' + "')[0].click();" + '">' + 
									'Détails</button>--><div>' +
										'</div>'
							}
							c = '<div class="cs-itinerary-popin-header" style="background-color: ' + scolor + '">'
							layer.bindPopup(
							'<div class="cs-itinerary-popin">'+
								c + 													
								'<div class="cs-itinerary-popin-title-wrapper">' +
									'<h4 class="cs-itinerary-popin-title" style="color: #000">' + feature.properties.name.replace("'", "\u2019") + ' </h4>' +
								'</div>' +
								'<button type="button" id="closep" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
							'</div>' +
							'<div class="cs-itinerary-popin-content">' +
								'<div class="cs-itinerary-popin-main-data">' +
									s +
									'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted"></cs-poi-line-numbers>' +																
									b +																
								'</div>' +																
							'</div>')
											
							layer.on('click', function() {
								document.getElementById('loyer_id').value=feature.properties.id;
								document.getElementById('loyer_name').value=feature.properties.name.replace("'", "\u2019");
								document.getElementById('loyer_zoneType').value=feature.properties.zoneType; 
								document.getElementById('loyer_count').value=feature.properties.percentiles.count;
								document.getElementById('loyer_value').value=feature.properties.percentiles.values["50.0"];
								document.getElementById('gl').click();
							})
						}
						
					}).addTo(that.openMapTilesLayer_Loyer);
				});
				if (that.app.view == 'locatif') that.$store.map_osm.addLayer(that.openMapTilesLayer_Loyer);
			});
		},
										
		getInfosLoyer:function() {
			this.$store.dispatch('load_histo_loyer', { 	loyer_id: document.getElementById('loyer_id').value,
				loyer_name: document.getElementById('loyer_name').value,
				loyer_zoneType: document.getElementById('loyer_zoneType').value,
				loyer_count: document.getElementById('loyer_count').value,
				loyer_value: document.getElementById('loyer_value').value
			})
		},

		GestionLayer:function(t, newValue) {
			if (!newValue) this.SupprimeLayer(t)
			if (newValue) this.AjouteLayer(t)
		},

		SupprimeLayer:function(t) {
			for (let n=0; n < t.length; n++) {
				for (let o=this.mk.length-1; o >-1 ; o--) {
					if (this.mk[o].substring(0,4) == t[n]) {
						this.mk.splice(o,1)
					}
				}
				if (this['openMapTilesLayer_' + t[n]]) {
					this['openMapTilesLayer_' + t[n]].clearLayers()					
				}
			}
		},

		AjouteLayer:function(t) {
			
			function TraiteIcone(response, T, w) {
				if (response && response.data && response.data.content && response.data.content.results) {
					for (let i in response.data.content.results) {
						let indicator = response.data.content.results[i]
						if (indicator && indicator.factors) {
							for (let j in indicator.factors) {
								let factor = indicator.factors[j]
								if (T.mk.indexOf(indicator.indicatorId + '_' + factor.miscellaneous.id_origin)==-1) {
									let latlng = factor.geometry.wktGeometry.substring(factor.geometry.wktGeometry.indexOf('(') + 1)
									latlng = latlng.substring(0,latlng.length-1)
									let es = latlng.indexOf(' ')
									let lat = latlng.substring(es + 1)
									let lon = latlng.substring(0,es)
									let v = T.u[parseInt(indicator.indicatorId)]
									let marker = L.marker([lat, lon],{ icon: v, title: factor.name })
									marker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + T.IdToColor[indicator.indicatorId] + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<i class="cs-itinerary-popin-icon cs-icon-thematic icon-' + indicator.indicatorId + '-02 ng-star-inserted"></i>' +
												'<h4 class="cs-itinerary-popin-title">' + T.IdToLabel[indicator.indicatorId] + ' </h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + factor.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>').openPopup();
									if (marker) {
										marker.addTo(T[w])
									}
									T.mk.push(indicator.indicatorId + '_' + factor.miscellaneous.id_origin)
								}
							}
						}
					}
				}
			}
									
			let coords = this.$store.map_osm.getBounds();
			let lefttop = coords.getNorthWest();
			let rightbottom = coords.getSouthEast();
			let UneDistance = this.$store.map_osm.distance(lefttop, rightbottom);
			
			if (UneDistance > 10000) {
				this.$store.dispatch('add_notification', { status: 'warning', message: "L'affichage des POI est limitée à une diagonale de 10 km" })
			} else {
				let that = this
				for (let n=0; n < t.length; n++) {

					let url = this.app.BaseUrlBackend + '/proxy/kinaxia/data/tile?ids=' + t[n] + '&xMin=' + this.$store.map_osm.getBounds()._southWest.lng + '&yMin=' + this.$store.map_osm.getBounds()._southWest.lat + '&xMax=' + this.$store.map_osm.getBounds()._northEast.lng + '&yMax=' + this.$store.map_osm.getBounds()._northEast.lat
					let La = 'openMapTilesLayer_' + t[n]
					this[La].addTo(this.$store.map_osm)
					axios.get(url, { headers: this.app.HeaderEfocusBack})
					.then(function(response) {
						TraiteIcone(response, that, La)
					})
				}
			}
									
		},
														
		get_img: function() {
			if (this.app.menuvisible)  {
				return 'chevron-left'
			} else {
				return 'chevron-right'
			}
		},

		gere_layer_zac: function() {
			if (this.app.contour_zac) {
				this.$store.map_osm.addLayer(this.group_zac);
			} else {
				this.$store.map_osm.removeLayer(this.group_zac);
			}
		},

		gere_layer_pnru: function() {
			if (this.app.contour_pnru) {
				this.$store.map_osm.addLayer(this.group_pnru);
			} else {
				this.$store.map_osm.removeLayer(this.group_pnru);
			}
		},

		gere_layer_npnru: function() {
			if (this.app.contour_npnru) {				
				this.$store.map_osm.addLayer(this.group_n_pnru);
			} else {
				this.$store.map_osm.removeLayer(this.group_n_pnru);
			}
		},

		gere_layer_qpv: function() {
			if (this.app.contour_qpv) {
				this.$store.map_osm.addLayer(this.group_qpv);
			} else {
				this.$store.map_osm.removeLayer(this.group_qpv);
			}
		},

		gere_layer_qpv_trois: function() {
			if (this.app.contour_qpv_trois) {
				this.$store.map_osm.addLayer(this.group_tcm);
			} else {
				this.$store.map_osm.removeLayer(this.group_tcm);
			}
		},

		gere_layer_change_zoom_move: function() {

			try {
				delete $.ajaxSettings.headers["Authorization"];
			} catch (error) {
				// erreur
			}
			if (this.group_com)
			this.group_com.clearLayers()
			if (this.group_qua)
			this.group_qua.clearLayers()

						try { this.group_metro.clearLayers() } catch (error)  { /* erreur*/ }
						try { this.group_tram.clearLayers() } catch (error)  { /* erreur*/ }
						try { this.$store.map_osm.removeLayer(this.group_tram) } catch (error)  { /* erreur*/ }
						try { this.group_tram = new L.featureGroup(); } catch (error)  { /* erreur*/ }

						try { this.group_train.clearLayers() } catch (error)  { /* erreur*/ }
						try { this.$store.map_osm.removeLayer(this.group_train) } catch (error)  { /* erreur*/ }
						try { this.group_train = new L.featureGroup(); } catch (error)  { /* erreur*/ }

			if (this.group_ecole) this.group_ecole.clearLayers()
						if (this.group_ligne_gpe) this.group_ligne_gpe.clearLayers()

			let that = this
			let UneUrl

			if (this.app.contour_ligne_gpe) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web%3Agpe_lignes&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style:function(feature){
							let v = {"color":feature.properties.color,"weight":3, "outline": "none"}
							return v;
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Ligne ' + feature.properties.ligne).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.$store.map_osm.addLayer(this.group_train);
			}

			if (this.app.contour_commune) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:commune&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style: function(){
							return that.vil_style; 
						},
						onEachFeature: function()
						{
						}
					}).addTo(that.group_com);
				});
				this.$store.map_osm.addLayer(this.group_com);
				
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:quartier_adequation&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style: function(){
							return that.quartier_style; 
						},
						onEachFeature: function(feature, layer)
						{
							layer.bindPopup(feature.properties.nom);
						}
					}).addTo(that.group_qua);
				});
				this.$store.map_osm.addLayer(this.group_qua);
			}

			if (this.app.contour_poi && this.app.contour_poi.length > 0 && this.app.viewlayer_poi) {
				this.fetchPOIList_old();
			} else {
				for (let i in this.group_poi) {
					this.group_poi[i].clearLayers()
				}
				this.group_poi = []
			}

			let null_style = {}
			if (this.app.contour_ecole) {
				let Groupe_scolaireI = L.icon({iconUrl: 'https://www.myadequation.fr/EFOCUS/icones/Groupe scolaire.svg', iconSize: [30, 30],});
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:enseignement&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						pointToLayer: function (feature, latlng) {
							return L.marker(latlng, {icon: Groupe_scolaireI});
						},
						onEachFeature: function(feature, layer)
						{
							layer.bindPopup(feature.properties.nom);
						}
					}).addTo(that.group_ecole);
				});
				this.$store.map_osm.addLayer(this.group_ecole);
			}

			if (this.app.contour_train) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='rail';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
								return that.metro_style;
								case 'tram': 
								return that.tram_style;
								case 'rail': 
								return JSON.parse(feature.properties.style);
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Train ' + feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.$store.map_osm.addLayer(this.group_train);

				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				axios.get(UneUrlTransport)
				.then(response => {				
					for (let index = 0; index < response.data.features.length; index++) {
						const transport = response.data.features[index];
						if (transport.properties.type == 'gare_station') {
							transport.libelle = 'Gare';
							let longitude;
							let latitude;
							try {					
								[longitude, latitude] = transport.geometry.coordinates[0];	
							} catch (error) {
								[longitude, latitude] = [transport.geometry.coordinates[0],transport.geometry.coordinates[1]];
							}
							let bok = true;
							if (bok) {
								const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(transport.properties.type)});
								// Ajouter une info-bulle au marqueur
								poiMarker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<h4 class="cs-itinerary-popin-title">' + transport.libelle + '</h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + transport.properties.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();
								poiMarker.addTo(this.group_train);
							}											
						}
					}
				})
				.catch(error => {
					console.error(error);
				});
			}

			if (this.app.contour_metro) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='subway';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
									var v = JSON.parse(feature.properties.style)									
									if (feature.properties.nom == "Métro 1") {
										if (feature.properties.siren == "200054807") v.color = "#fb1d1a"
										else v.color = "#F2C931"
									}
									else if (feature.properties.nom == "Métro 2") {
										if (feature.properties.siren == "200054807") v.color = "#199bf6"
										else v.color = "#216EB4"
									}
									else if (feature.properties.nom == "Métro 3") v.color = "#9A9940"
									else if (feature.properties.nom == "Métro 3bis") v.color = "#9A9940"
									else if (feature.properties.nom == "Métro 4") v.color = "#BB4D98"
									else if (feature.properties.nom == "Métro 5") v.color = "#DE8B53"
									else if (feature.properties.nom == "Métro 6") v.color = "#79BB92"
									else if (feature.properties.nom == "Métro 6 - Viaduc de Passy") v.color = "#79BB92"									
									else if (feature.properties.nom == "Métro 7") v.color = "#DF9AB1"
									else if (feature.properties.nom == "Métro 7bis") v.color = "#DF9AB1"
									else if (feature.properties.nom == "Métro 8") v.color = "#C5A3CA"
									else if (feature.properties.nom == "Métro 9") v.color = "#CDC83F"
									else if (feature.properties.nom == "Métro 10") v.color = "#DFB039"
									else if (feature.properties.nom == "Métro 11") v.color = "#8E6538"
									else if (feature.properties.nom == "Métro 12") v.color = "#328E5B"
									else if (feature.properties.nom == "Métro 13") v.color = "#89C7D6"
									else if (feature.properties.nom == "Métro 14") v.color = "#67328E"
									else if (feature.properties.nom == "Ligne 1") v.color = "#F2C931"
									else if (feature.properties.nom == "Ligne 2") v.color = "#BA2028"
									else if (feature.properties.nom == "Métro Ligne a") v.color = "#fb1d1a"
									else if (feature.properties.nom == "Métro Ligne b") v.color = "#79BB92"
									else if (feature.properties.nom == "Ligne A") {
										if (feature.properties.siren == "243100518") v.color = "#fb1d1a"
										else v.color = "#eb2a2c"
									}
									else if (feature.properties.nom == "Ligne B") {
										if (feature.properties.siren == "243100518") v.color = "#F2C931"
										else v.color = "#1da1e0"
									}
									else if (feature.properties.nom == "Ligne C") {
										if (feature.properties.siren == "243100518") v.color = "#79BB92"
										else v.color = "#f27c3c"
									}
									else if (feature.properties.nom == "Ligne D") {
										if (feature.properties.siren == "243100518") v.color = "#DE8B53"
										else v.color = "#1da459"
									}
									else v.weight = 0;
									return v;
								case 'tram': 
								return that.tram_style;
								case 'rail': 
								return that.train_style;
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_metro);
				});
				this.$store.map_osm.addLayer(this.group_metro);
				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				axios.get(UneUrlTransport)
				.then(response => {				
					for (let index = 0; index < response.data.features.length; index++) {
						const transport = response.data.features[index];
						if (transport.properties.type == 'subway_entrance') {
							transport.libelle = 'Métro';
							let longitude;
							let latitude;
							try {					
								[longitude, latitude] = transport.geometry.coordinates[0];	
							} catch (error) {
								[longitude, latitude] = [transport.geometry.coordinates[0],transport.geometry.coordinates[1]];
							}
							let bok = true;
							if (bok) {
								const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(transport.properties.type)});
								// Ajouter une info-bulle au marqueur
								poiMarker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<h4 class="cs-itinerary-popin-title">' + transport.libelle + '</h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + transport.properties.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();
								poiMarker.addTo(this.group_metro);
							}											
						}
					}
				})
				.catch(error => {
					console.error(error);
				});
			}

			if (this.app.contour_tramway) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='tram';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
								return that.metro_style;
								case 'tram': 
								return JSON.parse(feature.properties.style);
								case 'rail': 
								return that.train_style;
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default';
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = '';
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' });
							});
						}
					}).addTo(that.group_tram);
				});
				this.$store.map_osm.addLayer(this.group_tram);
				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				axios.get(UneUrlTransport)
				.then(response => {				
					for (let index = 0; index < response.data.features.length; index++) {
						const transport = response.data.features[index];
						if (transport.properties.type == 'tram_stop') {
							transport.libelle = 'Tramway';
							let longitude;
							let latitude;
							try {					
								[longitude, latitude] = transport.geometry.coordinates[0];	
							} catch (error) {
								[longitude, latitude] = [transport.geometry.coordinates[0],transport.geometry.coordinates[1]];
							}
							let bok = true;
							if (bok) {
								const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(transport.properties.type)});
								// Ajouter une info-bulle au marqueur
								poiMarker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<h4 class="cs-itinerary-popin-title">' + transport.libelle + '</h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + transport.properties.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();
								poiMarker.addTo(this.group_tram);
							}											
						}
					}
				})
				.catch(error => {
					console.error(error);
				});
			}
		},

		gere_layer: function() {

			try {
				delete $.ajaxSettings.headers["Authorization"];
			} catch (error) {
				// erreur
			}
			if (this.group_com)
			this.group_com.clearLayers()
			if (this.group_qua)
			this.group_qua.clearLayers()

//			try { this.group_metro.clearLayers() } catch (error)  { /* erreur*/ }
//			try { this.group_tram.clearLayers() } catch (error)  { /* erreur*/ }
//			try { this.$store.map_osm.removeLayer(this.group_tram) } catch (error)  { /* erreur*/ }
//			try { this.group_tram = new L.featureGroup(); } catch (error)  { /* erreur*/ }

//			try { this.group_train.clearLayers() } catch (error)  { /* erreur*/ }
//			try { this.$store.map_osm.removeLayer(this.group_train) } catch (error)  { /* erreur*/ }
//			try { this.group_train = new L.featureGroup(); } catch (error)  { /* erreur*/ }

			if (this.group_ecole) this.group_ecole.clearLayers()
//			if (this.group_ligne_gpe) this.group_ligne_gpe.clearLayers()

			let that = this
			let UneUrl


			if (!this.app.contour_ligne_gpe && this.group_ligne_gpe.getLayers().length > 0) {
				this.group_ligne_gpe.clearLayers()
				try { this.$store.map_osm.removeLayer(this.group_ligne_gpe) } catch (error)  { /* erreur*/ }
			} else if (this.app.contour_ligne_gpe && (!this.group_ligne_gpe || this.group_ligne_gpe.getLayers().length == 0)) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web%3Agpe_lignes&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style:function(feature){
							let v = {"color":feature.properties.color,"weight":3, "outline": "none"}
							return v;
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Ligne ' + feature.properties.ligne).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_ligne_gpe);
				});
				this.$store.map_osm.addLayer(this.group_ligne_gpe);
			}

			if (!this.app.contour_train && this.group_train.getLayers().length > 0) {
				this.group_train.clearLayers()
				try { this.$store.map_osm.removeLayer(this.group_train) } catch (error)  { /* erreur*/ }
			} else if (this.app.contour_train && (!this.group_train || this.group_train.getLayers().length == 0)) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='rail';
						},
						style:function(feature){
							return JSON.parse(feature.properties.style);
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Train ' + feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.$store.map_osm.addLayer(this.group_train);

				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				axios.get(UneUrlTransport)
				.then(response => {				
					for (let index = 0; index < response.data.features.length; index++) {
						const transport = response.data.features[index];
						if (transport.properties.type == 'gare_station') {
							transport.libelle = 'Gare';
							let longitude;
							let latitude;
							try {					
								[longitude, latitude] = transport.geometry.coordinates[0];	
							} catch (error) {
								[longitude, latitude] = [transport.geometry.coordinates[0],transport.geometry.coordinates[1]];
							}
							let bok = true;
							if (bok) {
								const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(transport.properties.type)});
								// Ajouter une info-bulle au marqueur
								poiMarker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<h4 class="cs-itinerary-popin-title">' + transport.libelle + '</h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + transport.properties.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();
								poiMarker.addTo(this.group_train);
							}											
						}
					}
				})
				.catch(error => {
					console.error(error);
				});
			}

			if (!this.app.contour_tramway && this.group_tram.getLayers().length > 0) {
				this.group_tram.clearLayers()
				try { this.$store.map_osm.removeLayer(this.group_tram) } catch (error)  { /* erreur*/ }
			} else if (this.app.contour_tramway && (!this.group_tram || this.group_tram.getLayers().length == 0)) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='tram';
						},
						style:function(feature){
							return JSON.parse(feature.properties.style);
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default';
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = '';
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' });
							});
						}
					}).addTo(that.group_tram);
				});
				this.$store.map_osm.addLayer(this.group_tram);

				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				axios.get(UneUrlTransport)
				.then(response => {				
					for (let index = 0; index < response.data.features.length; index++) {
						const transport = response.data.features[index];
						if (transport.properties.type == 'tram_stop') {
							transport.libelle = 'Tramway';
							let longitude;
							let latitude;
							try {					
								[longitude, latitude] = transport.geometry.coordinates[0];	
							} catch (error) {
								[longitude, latitude] = [transport.geometry.coordinates[0],transport.geometry.coordinates[1]];
							}
							let bok = true;
							if (bok) {
								const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(transport.properties.type)});
								// Ajouter une info-bulle au marqueur
								poiMarker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<h4 class="cs-itinerary-popin-title">' + transport.libelle + '</h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + transport.properties.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();
								poiMarker.addTo(this.group_tram);
							}											
						}
					}
				})
				.catch(error => {
					console.error(error);
				});

			}

			if (!this.app.contour_metro && this.group_metro.getLayers().length > 0) {
				this.group_metro.clearLayers()
				try { this.$store.map_osm.removeLayer(this.group_metro) } catch (error)  { /* erreur*/ }
			} else if (this.app.contour_metro && (!this.group_metro || this.group_metro.getLayers().length == 0)) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='subway';
						},
						style:function(feature){
							var v = JSON.parse(feature.properties.style)									
							if (feature.properties.nom == "Métro 1") {
								if (feature.properties.siren == "200054807") v.color = "#fb1d1a"
								else v.color = "#F2C931"
							}
							else if (feature.properties.nom == "Métro 2") {
								if (feature.properties.siren == "200054807") v.color = "#199bf6"
								else v.color = "#216EB4"
							}
							else if (feature.properties.nom == "Métro 3") v.color = "#9A9940"
							else if (feature.properties.nom == "Métro 3bis") v.color = "#9A9940"
							else if (feature.properties.nom == "Métro 4") v.color = "#BB4D98"
							else if (feature.properties.nom == "Métro 5") v.color = "#DE8B53"
							else if (feature.properties.nom == "Métro 6") v.color = "#79BB92"
							else if (feature.properties.nom == "Métro 6 - Viaduc de Passy") v.color = "#79BB92"									
							else if (feature.properties.nom == "Métro 7") v.color = "#DF9AB1"
							else if (feature.properties.nom == "Métro 7bis") v.color = "#DF9AB1"
							else if (feature.properties.nom == "Métro 8") v.color = "#C5A3CA"
							else if (feature.properties.nom == "Métro 9") v.color = "#CDC83F"
							else if (feature.properties.nom == "Métro 10") v.color = "#DFB039"
							else if (feature.properties.nom == "Métro 11") v.color = "#8E6538"
							else if (feature.properties.nom == "Métro 12") v.color = "#328E5B"
							else if (feature.properties.nom == "Métro 13") v.color = "#89C7D6"
							else if (feature.properties.nom == "Métro 14") v.color = "#67328E"
							else if (feature.properties.nom == "Ligne 1") v.color = "#F2C931"
							else if (feature.properties.nom == "Ligne 2") v.color = "#BA2028"
							else if (feature.properties.nom == "Métro Ligne a") v.color = "#fb1d1a"
							else if (feature.properties.nom == "Métro Ligne b") v.color = "#79BB92"
							else if (feature.properties.nom == "Ligne A") {
								if (feature.properties.siren == "243100518") v.color = "#fb1d1a"
								else v.color = "#eb2a2c"
							}
							else if (feature.properties.nom == "Ligne B") {
								if (feature.properties.siren == "243100518") v.color = "#F2C931"
								else v.color = "#1da1e0"
							}
							else if (feature.properties.nom == "Ligne C") {
								if (feature.properties.siren == "243100518") v.color = "#79BB92"
								else v.color = "#f27c3c"
							}
							else if (feature.properties.nom == "Ligne D") {
								if (feature.properties.siren == "243100518") v.color = "#DE8B53"
								else v.color = "#1da459"
							}
							else v.weight = 0;
							return v;
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_metro);
				});
				this.$store.map_osm.addLayer(this.group_metro);

				let UneUrlTransport = "https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:vue_transport_point&maxFeatures=10000&bbox=" + this.$store.map_osm.getBounds().toBBoxString() + "&outputFormat=application%2Fjson"
				axios.get(UneUrlTransport)
				.then(response => {				
					for (let index = 0; index < response.data.features.length; index++) {
						const transport = response.data.features[index];
						if (transport.properties.type == 'subway_entrance') {
							transport.libelle = 'Métro';
							let longitude;
							let latitude;
							try {					
								[longitude, latitude] = transport.geometry.coordinates[0];	
							} catch (error) {
								[longitude, latitude] = [transport.geometry.coordinates[0],transport.geometry.coordinates[1]];
							}
							let bok = true;
							if (bok) {
								const poiMarker = L.marker([latitude, longitude], {icon: this.iconPOI(transport.properties.type)});
								// Ajouter une info-bulle au marqueur
								poiMarker.bindPopup(
									'<div class="cs-itinerary-popin">'+
										'<div class="cs-itinerary-popin-header" style="background-color: ' + 'grey;' + '">' + 													
											'<div class="cs-itinerary-popin-title-wrapper">' +
												'<h4 class="cs-itinerary-popin-title">' + transport.libelle + '</h4>' +
											'</div>' +
											'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
										'</div>' +
										'<div class="cs-itinerary-popin-content">' +
											'<div class="cs-itinerary-popin-main-data">' +
												'<span class="cs-text cs-text-regular-bold ng-star-inserted">' + transport.properties.name + '</span>' +
												'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
													'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
												'</cs-poi-line-numbers>' +
											'</div>' +
										'</div>' +
									'</div>'
								).openPopup();
								poiMarker.addTo(this.group_metro);
							}											
						}
					}
				})
				.catch(error => {
					console.error(error);
				});
			}

/*
			if (this.app.contour_ligne_gpe) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web%3Agpe_lignes&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style:function(feature){
							let v = {"color":feature.properties.color,"weight":3, "outline": "none"}
							return v;
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Ligne ' + feature.properties.ligne).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.$store.map_osm.addLayer(this.group_train);
			}
*/
			if (this.app.contour_commune) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:commune&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style: function(){
							return that.vil_style; 
						},
						onEachFeature: function()
						{
						}
					}).addTo(that.group_com);
				});
				this.$store.map_osm.addLayer(this.group_com);
				
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:quartier_adequation&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						style: function(){
							return that.quartier_style; 
						},
						onEachFeature: function(feature, layer)
						{
							layer.bindPopup(feature.properties.nom);
						}
					}).addTo(that.group_qua);
				});
				this.$store.map_osm.addLayer(this.group_qua);
			}

			if (this.app.contour_poi && this.app.contour_poi.length > 0 && this.app.viewlayer_poi) {
				this.fetchPOIList_old();
			} else {
				for (let i in this.group_poi) {
					this.group_poi[i].clearLayers()
				}
				this.group_poi = []
			}

			//let null_style = {}
			if (this.app.contour_ecole) {
				let Groupe_scolaireI = L.icon({iconUrl: 'https://www.myadequation.fr/EFOCUS/icones/Groupe scolaire.svg', iconSize: [30, 30],});
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:enseignement&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						pointToLayer: function (feature, latlng) {
							return L.marker(latlng, {icon: Groupe_scolaireI});
						},
						onEachFeature: function(feature, layer)
						{
							layer.bindPopup(feature.properties.nom);
						}
					}).addTo(that.group_ecole);
				});
				this.$store.map_osm.addLayer(this.group_ecole);
			}
/*
			if (this.app.contour_train) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='rail';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
								return that.metro_style;
								case 'tram': 
								return that.tram_style;
								case 'rail': 
								return JSON.parse(feature.properties.style);
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip('Train ' + feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_train);
				});
				this.$store.map_osm.addLayer(this.group_train);
			}
*/
/*
			if (this.app.contour_metro) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='subway';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
									var v = JSON.parse(feature.properties.style)									
									if (feature.properties.nom == "Métro 1") {
										if (feature.properties.siren == "200054807") v.color = "#fb1d1a"
										else v.color = "#F2C931"
									}
									else if (feature.properties.nom == "Métro 2") {
										if (feature.properties.siren == "200054807") v.color = "#199bf6"
										else v.color = "#216EB4"
									}
									else if (feature.properties.nom == "Métro 3") v.color = "#9A9940"
									else if (feature.properties.nom == "Métro 3bis") v.color = "#9A9940"
									else if (feature.properties.nom == "Métro 4") v.color = "#BB4D98"
									else if (feature.properties.nom == "Métro 5") v.color = "#DE8B53"
									else if (feature.properties.nom == "Métro 6") v.color = "#79BB92"
									else if (feature.properties.nom == "Métro 6 - Viaduc de Passy") v.color = "#79BB92"									
									else if (feature.properties.nom == "Métro 7") v.color = "#DF9AB1"
									else if (feature.properties.nom == "Métro 7bis") v.color = "#DF9AB1"
									else if (feature.properties.nom == "Métro 8") v.color = "#C5A3CA"
									else if (feature.properties.nom == "Métro 9") v.color = "#CDC83F"
									else if (feature.properties.nom == "Métro 10") v.color = "#DFB039"
									else if (feature.properties.nom == "Métro 11") v.color = "#8E6538"
									else if (feature.properties.nom == "Métro 12") v.color = "#328E5B"
									else if (feature.properties.nom == "Métro 13") v.color = "#89C7D6"
									else if (feature.properties.nom == "Métro 14") v.color = "#67328E"
									else if (feature.properties.nom == "Ligne 1") v.color = "#F2C931"
									else if (feature.properties.nom == "Ligne 2") v.color = "#BA2028"
									else if (feature.properties.nom == "Métro Ligne a") v.color = "#fb1d1a"
									else if (feature.properties.nom == "Métro Ligne b") v.color = "#79BB92"
									else if (feature.properties.nom == "Ligne A") {
										if (feature.properties.siren == "243100518") v.color = "#fb1d1a"
										else v.color = "#eb2a2c"
									}
									else if (feature.properties.nom == "Ligne B") {
										if (feature.properties.siren == "243100518") v.color = "#F2C931"
										else v.color = "#1da1e0"
									}
									else if (feature.properties.nom == "Ligne C") {
										if (feature.properties.siren == "243100518") v.color = "#79BB92"
										else v.color = "#f27c3c"
									}
									else if (feature.properties.nom == "Ligne D") {
										if (feature.properties.siren == "243100518") v.color = "#DE8B53"
										else v.color = "#1da459"
									}
									else v.weight = 0;
									return v;
								case 'tram': 
								return that.tram_style;
								case 'rail': 
								return that.train_style;
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default'; // Change le style du curseur
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = ''; // Rétablit le style du curseur
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' }); // Supprime ou personnalise l'outline
							});
						}
					}).addTo(that.group_metro);
				});
				this.$store.map_osm.addLayer(this.group_metro);
			}
*/
/*
			if (this.app.contour_tramway) {
				UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:rail&maxFeatures=100000&bbox=' + this.$store.map_osm.getBounds().toBBoxString() + '&outputFormat=application/json'
				$.getJSON(UneUrl, function(dataRegion)
				{
					L.geoJson(dataRegion,
					{
						filter: function(feature) {
							return feature.properties.fclass=='tram';
						},
						style:function(feature){
							switch (feature.properties.fclass) {
								case 'subway': 
								return that.metro_style;
								case 'tram': 
								return JSON.parse(feature.properties.style);
								case 'rail': 
								return that.train_style;
								default :
								return null_style;
							}
						},
						onEachFeature: function(feature, layer)
						{
							layer.on('mouseover', function () {
								this.bindTooltip(feature.properties.nom).openTooltip();
								this._path.style.cursor = 'default';
							});

							layer.on('mouseout', function () {
								this.closeTooltip();
								this._path.style.cursor = '';
							});
							
							layer.on('click', function() {
								this.setStyle({ outline: 'none' });
							});
						}
					}).addTo(that.group_tram);
				});
				this.$store.map_osm.addLayer(this.group_tram);
			}
			*/
		},

		dezoom: function() {
			this.$store.map_osm.setZoom(this.map.options.zoom_level_to_change_view - 1)
			this.$store.dispatch('set_authorization', { authorized: true })
		},

		change_map_mode: function(mode) {
			this.$store.dispatch('set_map_mode', { mode: mode })
		},

		center_map: function() {
			this.$store.map_osm.setView([this.map.coords.lat, this.map.coords.lng], this.map.zoom)
			
			if (this.map.boundingbox) {
				let bounds = new L.LatLngBounds(
				new L.LatLng(this.map.boundingbox[0], this.map.boundingbox[2]), 
				new L.LatLng(this.map.boundingbox[1], this.map.boundingbox[3])
				)
				this.$store.map_osm.fitBounds(bounds)
			}
		},
															
		zoom_change: function() {
			if (this.map) {
				let zoom = this.$store.map_osm.getZoom()
				if (this.app.view=='locatif') {
					if (this.fond_loyer==5) {
						if (zoom<7) {
							this.$store.dispatch('set_fond_carte_loyer', {fond_loyer: 4})							
						} else {
							if (zoom<9) {
								this.$store.dispatch('set_fond_carte_loyer', {fond_loyer: 3})							
							} else {
								if (zoom<14) {
									this.$store.dispatch('set_fond_carte_loyer', {fond_loyer: 2})							
								} else {
									this.$store.dispatch('set_fond_carte_loyer', {fond_loyer: 1})							
								}
							}
						}
					}
				}
				if (zoom >= this.zoomThreshold) {
					setTimeout(() => {
						this.$store.dispatch('associate_parcelles_markers', { markers: this.markers });
					}, 2000);
				}

			}
		},
		
		move_change: function() {
			this.GestionLayer(this.T_contour_transports, this.app.contour_transports)
			this.GestionLayer(this.T_contour_loisirs, this.app.contour_loisirs)
			this.GestionLayer(this.T_contour_commerces, this.app.contour_commerces)
			this.GestionLayer(this.T_contour_services, this.app.contour_services)
			this.GestionLayer(this.T_contour_education, this.app.contour_education)
			if (this.map.search_on_move) {
				//
			} else {
				this.$store.dispatch('set_position', { lat : this.map.coords.lat, lng : this.map.coords.lng })
			}
		},
// Fonction pour gérer le zoom et désactiver les clusters si besoin
		handleZoom: function () {
			const currentZoom = this.$store.map_osm.getZoom();

			if (currentZoom >= this.zoomThreshold) {
				// Désactive le clustering
				try { 
					Object.values(this.clustersByIris).forEach(clusterGroup => {
						clusterGroup.clearLayers()
						this.$store.map_osm.removeLayer(clusterGroup);
					});
				} catch (error) { /* erreur */}

				this.$store.map_osm.removeLayer(this.markers_mutations_layer);
				this.markers.forEach((marker) => {
					this.$store.map_osm.addLayer(marker); // Ajoute chaque marqueur indépendamment
				});

			} else {
				// Réactive le clustering
				this.markers.forEach((marker) => {
					this.$store.map_osm.removeLayer(marker); // Supprime chaque marqueur
				});
				this.$store.map_osm.addLayer(this.markers_mutations_layer); // Ajoute les clusters
			}
		},

		load_markers_mutations: function() {
			if (this.map) {
				//let markers = []

				if (this.markers_mutations_layer) {
					this.$store.map_osm.removeLayer(this.markers_mutations_layer)
				}

				this.markers_mutations_layer = L.markerClusterGroup({
						disableClusteringAtZoom: this.zoomThreshold, // Désactiver le clustering au-dessus de ce niveau de zoom
						maxClusterRadius: 50, // Rayon maximum pour le clustering
						spiderfyOnMaxZoom: true, // Si les marqueurs sont superposés, ils sont "étalés" (spiderfy)
					}).addTo(this.$store.map_osm)
				
				//this.circle = L.circle([this.app.cityscan.lat, this.app.cityscan.lon], {radius: this.app.filtre_ancien.distance, color:"#e84242",fillOpacity:0, draggable: false}).addTo(this.markers_mutations_layer);
				this.circle = L.circle([this.app.adr_init.latlng.lat, this.app.adr_init.latlng.lng], {radius: this.app.filtre_ancien.distance, color:"#e84242",fillOpacity:0, draggable: false}).addTo(this.markers_mutations_layer);
				// eslint-disable-next-line

				this.circle.enableEdit(); // Activer l'édition du cercle

				// Sauvegarder la position initiale du cercle
				const initialLatLng = [this.app.cityscan.lat, this.app.cityscan.lon];

				// Restaurer la position du cercle si elle est déplacée
				this.$store.map_osm.on('editable:vertex:dragend', e => {
					if (e.layer === this.circle) {
						if ((initialLatLng[0] != this.circle.getLatLng().lat) && (initialLatLng[1] != this.circle.getLatLng().lng)) {
							this.circle.setLatLng(initialLatLng);
							setTimeout(() => {
								let maintenant = new Date();
								this.$store.dispatch('set_mapdistancetime', {mapdistancetime: maintenant})
							}, 100); // attend 100 ms
						} else {
							let newRadius = Math.round(this.circle.getRadius()); // Obtenir le nouveau rayon en mètres
							if (newRadius < this.maxRadius_mutation) {
								this.$store.dispatch('set_mapdistance', {mapdistance: newRadius})
								this.errorDisplayed_mutation = false; // Réinitialiser l'état du message d'erreur
							} else {
								/*
								if (!this.errorDisplayed_mutation) { // Vérifier si le message d'erreur a déjà été affiché
									this.$toast.add({severity:'error', summary: 'Limitation', detail:'Le rayon de recherche est limité à ' + this.maxRadius_mutation + 'm', life: 5000});
									this.errorDisplayed_mutation = true; // Mettre à jour l'état du message d'erreur
								}
								*/
								this.$store.dispatch('set_mapdistance', {mapdistance: this.maxRadius_mutation})
							}
						}
					}
				});


				// Empêcher l'agrandissement du cercle au-delà de 2000m pendant le drag
				this.$store.map_osm.on('editable:vertex:drag', e => {
					if (e.layer === this.circle) {
						let newRadius = Math.round(this.circle.getRadius()); // Obtenir le nouveau rayon en mètres
						if (newRadius > this.maxRadius_mutation) {
							this.circle.setRadius(this.maxRadius_mutation); // Fixer le rayon maximal autorisé
							/*
							if (!this.errorDisplayed_mutation) { // Vérifier si le message d'erreur a déjà été affiché
								this.errorDisplayed_mutation = true
								this.$toast.add({
									severity: 'error',
									summary: 'Limitation',
									detail: 'Le rayon de recherche est limité à ' + this.maxRadius_mutation + 'm',
									life: 5000,
									onClose: () => { this.errorDisplayed_mutation = false; } // Réinitialiser l'état lorsque le toast est fermé
								});
							}
							*/
						}
					}
				});

				Object.values(this.clustersByIris).forEach(clusterGroup => {
					clusterGroup.clearLayers()
					this.$store.map_osm.removeLayer(clusterGroup);
				});
				this.clustersByIris = {}

				// Exemple de données (remplacez par vos données réelles)
				var markerData = [];

				// Groupement des marqueurs par code IRIS
				for (let i in this.app.cityscan.adresse.biens_comparables) {
					let bien = this.app.cityscan.adresse.biens_comparables[i]
					let iris_code = ''
					if (bien.id_parcelle && bien.id_parcelle.length > 0) {
						iris_code = bien.id_parcelle[0].substring(0, 8);
					}
					markerData.push({lat: bien.location.coordinates[0], lng: bien.location.coordinates[1], iris: iris_code, name: bien.propertyId, bien: bien })					
				}

				markerData.forEach((data) => {
					// Si un cluster group pour cet IRIS n'existe pas, on le crée
					if (!this.clustersByIris[data.iris]) {
						this.clustersByIris[data.iris] = L.markerClusterGroup({
							disableClusteringAtZoom: this.zoomThreshold,
							maxClusterRadius: 50000,
							spiderfyOnMaxZoom: false,
						});
					}
/*
					this.clustersByIris[data.iris].on('clusterclick', (event) => {
						const cluster = event.layer;
						// Zoomer sur les limites du cluster
						this.$store.map_osm.fitBounds(cluster.getBounds());
					});
*/

					// Création des marqueurs
					const marker = map_helper.build_polygon({ that: this, parcelle: data.bien, geojson: null})

					//const marker = L.marker([data.lat, data.lng]).bindPopup(data.name);
					this.clustersByIris[data.iris].addLayer(marker);

					// Ajouter tous les marqueurs au tableau général
					this.markers.push(marker);
				});

				// Ajouter les marqueurs dans les clusters
				// Ajouter chaque groupe de clusters (par IRIS) à la carte
				Object.values(this.clustersByIris).forEach(clusterGroup => {
					this.$store.map_osm.addLayer(clusterGroup);

					clusterGroup.on('clusterclick', () => {
						setTimeout(() => {
								this.$store.dispatch('associate_parcelles_markers', { markers: this.markers });
						}, 2000); // 2000 ms = 2 secondes
					});

				});
				
				/*
				for (let i in this.app.cityscan.adresse.biens_comparables) {
					let bien = this.app.cityscan.adresse.biens_comparables[i]					
					let pol = map_helper.({ that: this, parcelle: bien, geojson: null})
					if (pol) {build_polygon
						pol.addTo(this.markers_mutations_layer)
						markers.push(pol)
					}
				}
				*/

				//this.$store.map_osm.fitBounds(this.markers_mutations_layer.getBounds());
				if (this.markers.length) {
					this.$store.dispatch('associate_parcelles_markers', { markers: this.markers })
				}

			}
		},
															
		load_markers_offre: function() {
			if (this.map) {
				let markers = []
				if (this.markers_offre_layer) {
					this.$store.map_osm.removeLayer(this.markers_offre_layer)
					this.markers_offre_layer = L.featureGroup().addTo(this.$store.map_osm)
				} else {
					this.markers_offre_layer = L.featureGroup().addTo(this.$store.map_osm)
				}

				this.circle = L.circle([this.app.mrk.lat, this.app.mrk.lng], {radius: this.app.filtre_ancien_offre.distance, color:"#e84242",fillOpacity:0, draggable: false}).addTo(this.markers_offre_layer);
				// eslint-disable-next-line

				this.circle.enableEdit(); // Activer l'édition du cercle

				// Sauvegarder la position initiale du cercle
				const initialLatLng = [this.app.mrk.lat, this.app.mrk.lng];

				// Restaurer la position du cercle si elle est déplacée
				this.$store.map_osm.on('editable:vertex:dragend', e => {
					if (e.layer === this.circle) {
						if ((initialLatLng[0] != this.circle.getLatLng().lat) && (initialLatLng[1] != this.circle.getLatLng().lng)) {
							this.circle.setLatLng(initialLatLng);
							setTimeout(() => {
								let maintenant = new Date();
								this.$store.dispatch('set_mapdistanceoffretime', {mapdistanceoffretime: maintenant})
							}, 100); // attend 100 ms
						} else {
							let newRadius = Math.round(this.circle.getRadius()); // Obtenir le nouveau rayon en mètres
							if (newRadius < this.maxRadius_offre) {
								this.$store.dispatch('set_mapdistanceoffre', {mapdistanceoffre: newRadius})
								this.errorDisplayed_offre = false; // Réinitialiser l'état du message d'erreur
							} else {
								/*
								if (!this.errorDisplayed_offre) { // Vérifier si le message d'erreur a déjà été affiché
									this.$toast.add({severity:'error', summary: 'Limitation', detail:'Le rayon de recherche est limité à ' + this.maxRadius_offre + 'm', life: 5000});
									this.errorDisplayed_offre = true; // Mettre à jour l'état du message d'erreur
								}
								*/
								this.$store.dispatch('set_mapdistanceoffre', {mapdistanceoffre: this.maxRadius_offre})
							}
						}
					}
				});


				// Empêcher l'agrandissement du cercle au-delà de 2000m pendant le drag
				this.$store.map_osm.on('editable:vertex:drag', e => {
					if (e.layer === this.circle) {
						let newRadius = Math.round(this.circle.getRadius()); // Obtenir le nouveau rayon en mètres
						if (newRadius > this.maxRadius_offre) {
							this.circle.setRadius(this.maxRadius_offre); // Fixer le rayon maximal autorisé
							/*
							if (!this.errorDisplayed_offre) { // Vérifier si le message d'erreur a déjà été affiché
								this.errorDisplayed_offre = true
								this.$toast.add({
									severity: 'error',
									summary: 'Limitation',
									detail: 'Le rayon de recherche est limité à ' + this.maxRadius_offre + 'm',
									life: 5000,
									onClose: () => { this.errorDisplayed_offre = false; } // Réinitialiser l'état lorsque le toast est fermé
								});
							}
							*/
						}
					}
				});

				for (let i in this.app.offre) {
					let bien = this.app.offre[i]
					if (bien.filter) {
						let pol = map_helper.build_offre({ that: this, program: bien, geojson: null})
						if (pol) {
							pol.addTo(this.markers_offre_layer)
							markers.push(pol)
						}
					}
				}
				if (markers.length) {
					this.$store.dispatch('associate_offre_markers', { markers: markers })
				}
			}
		},

		load_markers_locatif: function() {
			if (this.map) {
				let markers = []

				if (this.markers_layer) {
					this.$store.map_osm.removeLayer(this.markers_layer)
				}

				if (this.markers_locatif_layer) {
					this.$store.map_osm.removeLayer(this.markers_locatif_layer)
					this.markers_locatif_layer = L.featureGroup().addTo(this.$store.map_osm)
				} else {
					this.markers_locatif_layer = L.featureGroup().addTo(this.$store.map_osm)
				}

				for (let i in this.app.liste_biens_locatifs.hits) {
					let bien = this.app.liste_biens_locatifs.hits[i]
					if (bien.filter) {
						let pol = map_helper.build_locatif({ that: this, program: bien, geojson: null})
						if (pol) {
							pol.addTo(this.markers_locatif_layer)
							markers.push(pol)
						}
					}
				}
				if (markers.length) {
					this.$store.dispatch('associate_locatif_markers', { markers: markers })
				}
			}
		},

		load_markers_rpls: function() {
			if (this.map) {
				this.$store.map_osm.removeLayer(this.markers_rpls_layer)
				let markers = []
				
				if (this.markers_rpls_layer) {
					this.$store.map_osm.removeLayer(this.markers_rpls_layer)
					this.markers_rpls_layer = L.featureGroup().addTo(this.$store.map_osm)
				} else {
					this.markers_rpls_layer = L.featureGroup().addTo(this.$store.map_osm)
				}

				this.circle = L.circle([this.app.adr_init.latlng.lat, this.app.adr_init.latlng.lng], {radius: this.app.filtre_rpls.distance, color:"#e84242",fillOpacity:0, draggable: false}).addTo(this.markers_rpls_layer);
				// eslint-disable-next-line

				this.circle.enableEdit(); // Activer l'édition du cercle

				// Sauvegarder la position initiale du cercle
				const initialLatLng = [this.app.mrk.lat, this.app.mrk.lng];

				// Restaurer la position du cercle si elle est déplacée
				this.$store.map_osm.on('editable:vertex:dragend', e => {
					if (e.layer === this.circle) {
						if ((initialLatLng[0] != this.circle.getLatLng().lat) && (initialLatLng[1] != this.circle.getLatLng().lng)) {
							this.circle.setLatLng(initialLatLng);
							setTimeout(() => {
								let maintenant = new Date();
								this.$store.dispatch('set_mapdistancerplstime', {mapdistancerplstime: maintenant})
							}, 100); // attend 100 ms
						} else {
							let newRadius = Math.round(this.circle.getRadius()); // Obtenir le nouveau rayon en mètres
							if (newRadius < this.maxRadius_rpls) {
								this.$store.dispatch('set_mapdistancerpls', {mapdistancerpls: newRadius})
								this.errorDisplayed_rpls = false; // Réinitialiser l'état du message d'erreur
							} else {
								this.circle.setRadius(this.maxRadius_rpls); // Fixer le rayon maximal autorisé
								/*
								if (!this.errorDisplayed_rpls) { // Vérifier si le message d'erreur a déjà été affiché
									this.$toast.add({severity:'error', summary: 'Limitation', detail:'Le rayon de recherche est limité à ' + this.maxRadius_rpls + 'm', life: 5000});
									this.errorDisplayed_rpls = true; // Mettre à jour l'état du message d'erreur
								}
								*/
								this.$store.dispatch('set_mapdistancerpls', {mapdistancerpls: this.maxRadius_rpls})
							}
						}
					}
				});


				// Empêcher l'agrandissement du cercle au-delà de 2000m pendant le drag
				this.$store.map_osm.on('editable:vertex:drag', e => {
					if (e.layer === this.circle) {
						let newRadius = Math.round(this.circle.getRadius()); // Obtenir le nouveau rayon en mètres
						if (newRadius > this.maxRadius_rpls) {
							this.circle.setRadius(this.maxRadius_rpls); // Fixer le rayon maximal autorisé
							/*
							if (!this.errorDisplayed_rpls) { // Vérifier si le message d'erreur a déjà été affiché
								this.errorDisplayed_rpls = true
								this.$toast.add({
									severity: 'error',
									summary: 'Limitation',
									detail: 'Le rayon de recherche est limité à ' + this.maxRadius_rpls + 'm',
									life: 5000,
									onClose: () => { this.errorDisplayed_rpls = false; } // Réinitialiser l'état lorsque le toast est fermé
								});
							}
							*/
						}
					}
				});
				
				for (let i in this.app.rplss_filter) {
					let rpls = this.app.rplss_filter[i]
					
					let nogamme = 'milieu'
					if (rpls.position.lat && rpls.position.lon && rpls.filter) {
						
						let marker = map_helper.build_marker({ that: this, program: rpls, pin_type: this.app.pin_type, gamme : nogamme, rpls: true })
						if (marker) {
							marker.addTo(this.markers_rpls_layer)
							markers.push(marker)
						}
					}
				}

				if (markers.length) {
					if (this.app.fitBounds) {
						this.$store.map_osm.fitBounds(this.markers_rpls_layer.getBounds());
					}
				}
				if (markers.length) {
					this.$store.dispatch('associate_rss_markers', { markers: markers })
				}
				this.uniquementSelection(this.app.uniquementselection);
			}
		},																
		
		load_markers_rs: function() {
			if (this.map) {
				this.$store.map_osm.removeLayer(this.markers_rs_layer)
				let markers = []
				
				if (this.markers_rs_layer) {
					this.$store.map_osm.removeLayer(this.markers_rs_layer)
					this.markers_rs_layer = L.featureGroup().addTo(this.$store.map_osm)
				} else {
					this.markers_rs_layer = L.featureGroup().addTo(this.$store.map_osm)
				}
				
				for (let i in this.app.rss_filter) {
					let rs = this.app.rss_filter[i]
					
					let nogamme = 'milieu'
					if (rs.location.lat && rs.location.lon) {
						
						let marker = map_helper.build_marker({ that: this, program: rs, pin_type: this.app.pin_type, gamme : nogamme, rs: true })
						if (marker) {
							marker.addTo(this.markers_rs_layer)
							markers.push(marker)
						}
					}
				}

				if (markers.length) {
					if (this.app.fitBounds) {
						this.$store.map_osm.fitBounds(this.markers_layer.getBounds());
					}
				}
				if (markers.length) {
					this.$store.dispatch('associate_rss_markers', { markers: markers })
				}
				
				
				if (this.app.commune_zoom) {
					this.$store.map_osm.fitBounds(this.commune_zoom_layer.getBounds())
				}
				
				this.uniquementSelection(this.app.uniquementselection);
			}
		},

		load_markers_gisement: function() {
			if (this.map) {
				const hatchPattern = new L.StripePattern({
				weight: 4,
				spaceWeight: 4,
				color: '#000000',
				opacity: 0.7,
				angle: 45
				});


				this.$store.map_osm.removeLayer(this.markers_gisement_layer)
				if (this.markers_gisement_layer) {
					this.$store.map_osm.removeLayer(this.markers_gisement_layer)
					this.markers_gisement_layer = L.featureGroup().addTo(this.$store.map_osm)
				} else {
					this.markers_gisement_layer = L.featureGroup().addTo(this.$store.map_osm)
				}


				hatchPattern.addTo(this.markers_gisement_layer._map);
				var that = this;
				let UneUrl = this.app.BaseUrlBackend + '/gisements/searchByBoundingBox'
				const data = { hd_lat : this.$store.map_osm.getBounds()._northEast.lat, hd_lon : this.$store.map_osm.getBounds()._southWest.lng, bg_lat : this.$store.map_osm.getBounds()._southWest.lat, bg_lon : this.$store.map_osm.getBounds()._northEast.lng, size : 2000, id : 1 }
				axios.post(UneUrl, data, { headers: this.app.HeaderEfocusBack})
				.then(response => {
					const dataGisement = response.data;
					that.$store.dispatch('liste_gisement', { gisements: dataGisement })

					// Add GeoJSON data to the map
					L.geoJson(dataGisement, {
						filter: function(feature) {
							if (feature && feature.properties && feature.properties.gisement) {
							return (	(feature.properties.gisement.surfini >= that.app.filtre_gisement.area_min)
							&& 	(feature.properties.gisement.surfini <= that.app.filtre_gisement.area_max)
							&& 	((feature.properties.gisement.gbgnb == "bati" && that.app.filtre_gisement.bati) || (feature.properties.gisement.gbgnb == "non bati" && that.app.filtre_gisement.nonbati))
							&& (!(feature.properties.gisement.env_biodiversite && that.app.filtre_gisement.biodiversite))
							&& (!(feature.properties.gisement.env_patrimoine && that.app.filtre_gisement.patrimoine))
							&& (!(feature.properties.gisement.env_risque_nat && that.app.filtre_gisement.risques_naturels))
							&& (!(feature.properties.gisement.env_risque_indus && that.app.filtre_gisement.risques_industriels))
							);} else return false
						},
						style: function() {
							return {
							fillPattern: hatchPattern,
							color: '#3388ff',
							weight: 1
							};
						},
						onEachFeature: (feature, layer) => {
							// Add a click event listener to the layer
							layer.on('click', function() {
								that.$store.dispatch('expand_gisement', { selectgisement: feature.properties });
							});
						}
					}).addTo(this.markers_gisement_layer);
					
				})
				.catch(error => {
					console.error('Error fetching data:', error);
				});
				
			}

		},																
		
		load_markers: function(sansfitBounds) {
			if (this.map) {
				let markers = []
				
				if (this.markers_layer) {
					this.$store.map_osm.removeLayer(this.markers_layer)
					this.markers_layer = L.featureGroup().addTo(this.$store.map_osm)
				} else {
					this.markers_layer = L.featureGroup().addTo(this.$store.map_osm)
				}
				
				for (let i in this.app.programs_filter) {
					let program = this.app.programs_filter[i]
					let pxm2 = 0
					if (program.tva_pleine_libre_prix_m2_parking_avec) {
						pxm2 = program.tva_pleine_libre_prix_m2_parking_avec
					} else {
						if (program.tva_pleine_libre_prix_m2_parking_sans) {
							pxm2 = program.tva_pleine_libre_prix_m2_parking_sans
						} else {
							if (program.tva_pleine_aide_prix_m2_parking_avec) {
								pxm2 = program.tva_pleine_aide_prix_m2_parking_avec
							} else {
								if (program.tva_pleine_aide_prix_m2_parking_sans) {
									pxm2 = program.tva_pleine_aide_prix_m2_parking_sans
								} else {
									if (program.tva_reduite_prix_m2_parking_avec) {
										pxm2 = program.tva_reduite_prix_m2_parking_avec
									} else {
										if (program.tva_reduite_prix_m2_parking_sans) {
											pxm2 = program.tva_reduite_prix_m2_parking_sans
										}
									}
								}
							}
						}
					}
					let nogamme = 'milieu'
					if (program.location.lat && program.location.lon) {
						if (pxm2 < this.app.echelleprix_1) {
							nogamme = 'entree'
						} else {
							if (pxm2 < this.app.echelleprix_2) {
								nogamme = 'milieu'
							} else {
								nogamme = 'haut'
							}
						}
						let marker = map_helper.build_marker({ that: this, program: program, pin_type: this.app.pin_type, gamme : nogamme })
						if (marker) {							
							marker.addTo(this.markers_layer)
							markers.push(marker)
						}
					}
				}
				if (!sansfitBounds) {
					if (markers.length) {
						if (this.app.fitBounds) {
							this.$store.map_osm.fitBounds(this.markers_layer.getBounds());
						}
					}
				}
				if (markers.length) {
					this.$store.dispatch('associate_programs_markers', { markers: markers })
				}
				
				if (this.app.commune_zoom) {
					try
					{
						this.$store.map_osm.fitBounds(this.commune_zoom_layer.getBounds())
					} catch (error) {
						//
					}
				}

				this.uniquementSelection(this.app.uniquementselection);
				this.$store.dispatch('set_loadingall', { loadingAll: false })
			}
		},


		updateLayout() {
			this.$nextTick(() => {
				if (this.$store.map_osm) {
					// Nothing
				}
			});
		},
	},

	mounted: function() {
		let that = this		
		
		this.IdToColor['1200'] = 'rgb(204, 0, 0);'
		this.IdToColor['1201'] = 'rgb(255, 73, 24);'
		this.IdToColor['1202'] = 'rgb(255, 116, 13);'		
		this.IdToColor['1210'] = 'rgb(255, 111, 30);'
		this.IdToColor['1220'] = 'rgb(237, 96, 0);'
		this.IdToColor['1221'] = 'rgb(230, 52, 0);'
		this.IdToColor['1222'] = 'rgb(255, 167, 38);'
		this.IdToColor['1230'] = 'rgb(255, 188, 0);'
		this.IdToColor['2000'] = 'rgb(65, 112, 52);'
		this.IdToColor['2001'] = 'rgb(68, 87, 69);'
		this.IdToColor['2002'] = 'rgb(94, 121, 92);'
		this.IdToColor['1400'] = 'rgb(29, 16, 117);'
		this.IdToColor['1410'] = 'rgb(53, 26, 143);'
		this.IdToColor['1420'] = 'rgb(77, 36, 169);'
		this.IdToColor['1430'] = 'rgb(100, 45, 195);'
		this.IdToColor['1440'] = 'rgb(124, 55, 221);'
		this.IdToColor['1441'] = 'rgb(127, 68, 182);'
		this.IdToColor['1450'] = 'rgb(151, 92, 238);'
		this.IdToColor['1460'] = 'rgb(177, 128, 255);'
		this.IdToColor['1470'] = 'rgb(77, 8, 109);'
		this.IdToColor['1330'] = 'rgb(124, 0, 41);'
		this.IdToColor['1332'] = 'rgb(110, 0, 55);'
		this.IdToColor['1333'] = 'rgb(224, 52, 36);'
		this.IdToColor['1331'] = 'rgb(208, 17, 66);'
		this.IdToColor['1334'] = 'rgb(216, 35, 51);'
		this.IdToColor['1370'] = 'rgb(231, 47, 52);'
		this.IdToColor['1231'] = 'rgb(243, 124, 46);'
		this.IdToColor['1372'] = 'rgb(64, 140, 38);'
		this.IdToColor['1300'] = 'rgb(174, 31, 19);'
		this.IdToColor['2011'] = 'rgb(42, 53, 47);'
		this.IdToColor['1301'] = 'rgb(220, 43, 29);'
		this.IdToColor['1311'] = 'rgb(246, 47, 72);'
		this.IdToColor['1322'] = 'rgb(78, 161, 50);'
		this.IdToColor['1320'] = 'rgb(36, 97, 13);'
		this.IdToColor['1321'] = 'rgb(22, 76, 0);'
		this.IdToColor['1310'] = 'rgb(246, 107, 99);'
		this.IdToColor['1380'] = 'rgb(255, 170, 34);'
		this.IdToColor['2010'] = 'rgb(120, 155, 114);'
		this.IdToColor['1323'] = 'rgb(22, 76, 0);'
		this.IdToColor['1350'] = 'rgb(0, 214, 242);'
		this.IdToColor['1351'] = 'rgb(0, 179, 211);'
		this.IdToColor['1352'] = 'rgb(0, 143, 179);'
		this.IdToColor['1353'] = 'rgb(18, 4, 208);'
		this.IdToColor['1341'] = 'rgb(14, 46, 215);'
		this.IdToColor['1340'] = 'rgb(13, 67, 218);'
		this.IdToColor['1343'] = 'rgb(11, 88, 222);'
		this.IdToColor['1342'] = 'rgb(7, 130, 228);'
		this.IdToColor['1360'] = 'rgb(0, 108, 148);'
		this.IdToColor['1361'] = 'rgb(0, 72, 117);'
		this.IdToColor['1371'] = 'rgb(67, 207, 239);'
		this.IdToColor['1373'] = 'rgb(63, 194, 221);'
		
		this.IdToLabel['1200'] = i18n.t('bus')
		this.IdToLabel['1202'] = i18n.t('tramway')
		this.IdToLabel['1201'] = i18n.t('metro')
		this.IdToLabel['1210'] = i18n.t('acces_route_rapide')
		this.IdToLabel['1230'] = i18n.t('parking')
		this.IdToLabel['1220'] = i18n.t('gare')
		this.IdToLabel['1221'] = i18n.t('aeroport')
		this.IdToLabel['1222'] = 'Port'
		this.IdToLabel['2000'] = i18n.t('velo_partage')
		this.IdToLabel['2001'] = i18n.t('auto_partage')
		this.IdToLabel['2002'] = i18n.t('borne_de_recharge')
		this.IdToLabel['1400'] = i18n.t('creche')
		this.IdToLabel['1410'] = i18n.t('maternelle')
		this.IdToLabel['1420'] = i18n.t('elementaire')
		this.IdToLabel['1430'] = 'Collége'
		this.IdToLabel['1440'] = 'Lycée'
		this.IdToLabel['1441'] = 'Lycée professionel'
		this.IdToLabel['1450'] = i18n.t('universite')
		this.IdToLabel['1460'] = 'Ecoles supérieures'
		this.IdToLabel['1470'] = 'Formations professionnelle'
		this.IdToLabel['1330'] = 'Commerce - alimentation'
		this.IdToLabel['1332'] = i18n.t('commerce_de_bouche')
		this.IdToLabel['1333'] = 'Commerce utilitaire'
		this.IdToLabel['1331'] = i18n.t('shopping')
		this.IdToLabel['1334'] = 'Commerce - soin'
		this.IdToLabel['1370'] = 'Service aux logements'
		this.IdToLabel['1231'] = 'Service aux véhicules'
		this.IdToLabel['1372'] = 'Services aux personnes agées'
		this.IdToLabel['1300'] = i18n.t('commissariat')
		this.IdToLabel['2011'] = 'Dêchet'
		this.IdToLabel['1301'] = i18n.t('gendarmerie')
		this.IdToLabel['1311'] = i18n.t('hôpital')
		this.IdToLabel['1322'] = 'Laboratoire'
		this.IdToLabel['1320'] = i18n.t('medecin_generaliste')
		this.IdToLabel['1321'] = i18n.t('pharmacie')
		this.IdToLabel['1310'] = 'Pompier'
		this.IdToLabel['1380'] = i18n.t('poste')
		this.IdToLabel['2010'] = i18n.t('recyclage')
		this.IdToLabel['1323'] = i18n.t('medecin_specialiste')
		this.IdToLabel['1350'] = 'Bibliothéque'
		this.IdToLabel['1351'] = 'Théâtre'
		this.IdToLabel['1352'] = i18n.t('musee')
		this.IdToLabel['1353'] = i18n.t('cinema')
		this.IdToLabel['1341'] = 'Bar'
		this.IdToLabel['1340'] = i18n.t('restaurant')
		this.IdToLabel['1343'] = i18n.t('discotheque')
		this.IdToLabel['1342'] = 'Jardin'
		this.IdToLabel['1360'] = i18n.t('sport_collectif')
		this.IdToLabel['1361'] = i18n.t('sport_individuel')
		this.IdToLabel['1371'] = i18n.t('hôtel')
		this.IdToLabel['1373'] = 'Autre hébergement'
		
		let classname = 'map-icon-prj';
		let decal = [20, 40];
		this.iconMrk = L.divIcon({ className: classname, html: '<div class="pin_monprojet"><div class="label"></div></div>', iconAnchor: decal });
		
		this.$nextTick(function() {
			
			this.legend_mutation = [
				{
					range:{
						id: 1
					},
					color:'#31891c',
					label:'Maison'
				},
				{
					range:{
						id: 2
					},
					color:'#0080ac',
					label:'Appartement'
				}
			]
			
			this.group_tcm = new L.featureGroup();
			this.group_qpv = new L.featureGroup();
			this.group_pnru = new L.featureGroup();
			this.group_n_pnru = new L.featureGroup();
			this.group_zac = new L.featureGroup();
			this.group_com = new L.featureGroup();
			this.group_qua = new L.featureGroup();
			this.group_train = new L.featureGroup();
			this.group_metro = new L.featureGroup();
			this.group_tram = new L.featureGroup();
			this.group_ecole = new L.featureGroup();
			this.commune_zoom_layer = new L.featureGroup();
			this.isochrone = new L.featureGroup();
			this.isochroneMapbox = new L.featureGroup();
			this.group_ligne_metro = new L.featureGroup();
			this.group_ligne_gpe = new L.featureGroup();

			$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:npnru_300&outputFormat=application%2Fjson", function(n)
			{
				L.geoJson(n,
				{
					
					style: function(){
						return that.npnru_style; 
					},
					onEachFeature: function(feature, layer)
					{
							layer.bindPopup(
							'<div class="cs-itinerary-popin">'+
								'<div class="cs-itinerary-popin-header" style="background-color: ' + '#ff9900;' + '">' + 													
									'<div class="cs-itinerary-popin-title-wrapper">' +
										'<h4 class="cs-itinerary-popin-title">NPNRU </h4>' +
									'</div>' +
									'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
								'</div>' +
								'<div class="cs-itinerary-popin-content">' +
									'<div class="cs-itinerary-popin-main-data">' +
										'<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom_qp + '</b>' + '</p>' +
										'<p class="cs-text-legend">' + 'Commune : ' + feature.properties.commune_qp + '</p>' +
										'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
											'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
										'</cs-poi-line-numbers>' +
									'</div>' +
								'</div>' +
							'</div>'
						).openPopup();
					}
				}).addTo(that.group_n_pnru);
			});

			$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:pnru_300&outputFormat=application%2Fjson", function(dataPnru)
			{
				L.geoJson(dataPnru,
				{
					
					style: function(){
						return that.pnru_style; 
					},
					onEachFeature: function(feature, layer)
					{
							layer.bindPopup(
							'<div class="cs-itinerary-popin">'+
								'<div class="cs-itinerary-popin-header" style="background-color: ' + '#ff9900;' + '">' + 													
									'<div class="cs-itinerary-popin-title-wrapper">' +
										'<h4 class="cs-itinerary-popin-title">PNRU </h4>' +
									'</div>' +
									'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
								'</div>' +
								'<div class="cs-itinerary-popin-content">' +
									'<div class="cs-itinerary-popin-main-data">' +
										'<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom_qp + '</b>' + '</p>' +
										'<p class="cs-text-legend">' + 'Commune : ' + feature.properties.commune_qp + '</p>' +
										'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
											'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
										'</cs-poi-line-numbers>' +
									'</div>' +
								'</div>' +
							'</div>'
						).openPopup();
					}
				}).addTo(that.group_pnru);
			});
			
			
			$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:qpv&outputFormat=application%2Fjson", function(dataQpv)
			{
				L.geoJson(dataQpv,
				{
					
					style: function(){
						return that.qpv_style; 
					},
					onEachFeature: function(feature, layer)
					{
							layer.bindPopup(
							'<div class="cs-itinerary-popin">'+
								'<div class="cs-itinerary-popin-header" style="background-color: ' + '#ff9900;' + '">' + 													
									'<div class="cs-itinerary-popin-title-wrapper">' +
										'<h4 class="cs-itinerary-popin-title">QPV </h4>' +
									'</div>' +
									'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
								'</div>' +
								'<div class="cs-itinerary-popin-content">' +
									'<div class="cs-itinerary-popin-main-data">' +
										'<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom_qp + '</b>' + '</p>' +
										'<p class="cs-text-legend">' + 'Commune : ' + feature.properties.commune_qp + '</p>' +
										'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
											'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
										'</cs-poi-line-numbers>' +
									'</div>' +
								'</div>' +
							'</div>'
						).openPopup();
					}
				}).addTo(that.group_qpv);
			});

			

			$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:zonage_urbain&outputFormat=application%2Fjson", function(dataZac)
			{
				L.geoJson(dataZac,
				{
					filter: function(feature) {
						return feature.properties.outil_op === "ZAC"; // Ne garder que les entités où outil_op est "ZAC"
					},
					style: function(){
						return that.zac_style; 
					},
					onEachFeature: function(feature, layer)
					{
						if (feature.properties.outil_op === "ZAC") {

							let info = '<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom + '</b>' + '</p>';
							if (feature.properties.mo && feature.properties.mo != '-') info += '<p class="cs-text-legend">Maitre d’ouvrage : ' + feature.properties.mo + '</p>';
							if (feature.properties.commune1 && feature.properties.commune1 != '-') info += '<p class="cs-text-legend">Commune : ' + feature.properties.commune1 + '</p>';
							if (feature.properties.sp_tot && feature.properties.sp_tot != '-') info += '<p class="cs-text-legend">Surface total : ' + feature.properties.sp_tot + ' m²</p>';
							if (feature.properties.nb_logt && feature.properties.nb_logt != '-') info += '<p class="cs-text-legend">Nombre de logements : ' + feature.properties.nb_logt + '</p>';
							if (feature.properties.sp_logt && feature.properties.sp_logt != '-') info += '<p class="cs-text-legend">Surface de logements : ' + feature.properties.sp_logt + ' m²</p>';
							if (feature.properties.sp_comm && feature.properties.sp_comm != '-') info += '<p class="cs-text-legend">Surface de commerces : ' + feature.properties.sp_comm + ' m²</p>';
							if (feature.properties.sp_bur && feature.properties.sp_bur != '-') info += '<p class="cs-text-legend">Surface de bureaux : ' + feature.properties.sp_bur + ' m²</p>';
							if (feature.properties.date1 && feature.properties.date1 != '-') info += '<p class="cs-text-legend">Année de lancement du projet : ' + feature.properties.date1 + '</p>';
							if (feature.properties.date2 && feature.properties.date2 != '-') info += '<p class="cs-text-legend">Année de début des travaux : ' + feature.properties.date2 + '</p>';
							if (feature.properties.date3 && feature.properties.date3 != '-') info += '<p class="cs-text-legend">Année de livraison finale : ' + feature.properties.date3 + '</p>';
							if (feature.properties.outil_op && feature.properties.outil_op != '-') info += '<p class="cs-text-legend">Type de l’opération : ' + feature.properties.outil_op + '</p>';
							
							// eslint-disable-next-line
							let popupContent = `
							<div class="cs-itinerary-popin">
								<div class="cs-itinerary-popin-header" style="background-color: #c65459;">
								<div class="cs-itinerary-popin-title-wrapper">
									<h4 class="cs-itinerary-popin-title">ZAC</h4>
								</div>
								<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>
								</div>
								<div class="cs-itinerary-popin-content">
								<div class="cs-itinerary-popin-main-data">
									${info}
								</div>
								</div>
							</div>`;



							layer.on("click", (e) => {
								that.showPopup = true;
								that.selectedZone = feature.properties;
								that.selectedZone.content = info;
								that.selectedZone.nom = feature.properties.nom;
								that.popupPosition = {
									x: e.containerPoint.x,
									y: e.containerPoint.y,
								};
							});


							// Créer la popup
							//layer.bindPopup(popupContent).openPopup();


							// Ajoute un événement au clic pour afficher une modale jQuery UI
/*
							layer.on('click', function() {
								$("#modal-info").html(popupContent); // Ajoute le contenu à la modale
								$("#modal-info").dialog({
									draggable: true, // Permet de déplacer la fenêtre modale
									title: 'Détails de ZAC', // Titre de la fenêtre
									width: 400, // Largeur de la modale
									modal: false // Permet à l'utilisateur de continuer à interagir avec la carte
								});
							});							
*/							
						}

						// Ajoute un événement au clic pour changer la couleur
						layer.on('click', function() {
							// Si une zone est déjà sélectionnée, on remet son style par défaut
							if (that.selectedLayer) {
								that.selectedLayer.setStyle(that.zac_style);
							}

							// Si la même zone est cliquée (deselection)
							if (that.selectedLayer === layer) {
								that.selectedLayer = null; // Désélectionne en mettant à null
								that.closePopup()
							} else {
								// Changer la couleur de la nouvelle zone sélectionnée
								layer.setStyle({
									color: 'red', // Couleur des bordures
									fillColor: 'red', // Couleur de remplissage
									fillOpacity: 0.5 // Opacité de remplissage
								});

								// Définir cette zone comme la zone actuellement sélectionnée
								that.selectedLayer = layer;
							}
						});


					}
					
				}).addTo(that.group_zac);
			});
			
			
		$.getJSON("https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:qpv_300&outputFormat=application%2Fjson", function(dataQpv3)
			{
				L.geoJson(dataQpv3,
				{
					style: function(){
						return that.qpv_style3; 
					},
					onEachFeature: function(feature, layer)
					{
						layer.bindPopup(
						'<div class="cs-itinerary-popin">'+
							'<div class="cs-itinerary-popin-header" style="background-color: ' + '#ff9900;' + '">' + 													
								'<div class="cs-itinerary-popin-title-wrapper">' +
									'<h4 class="cs-itinerary-popin-title">QPV </h4>' +
								'</div>' +
								'<button type="button" class="cs-itinerary-popin-close-button cs-icon-cityscan">&nbsp;</button>' +
							'</div>' +
							'<div class="cs-itinerary-popin-content">' +
								'<div class="cs-itinerary-popin-main-data">' +
									'<p class="cs-text-legend">' + 'Nom : ' + '<b>' + feature.properties.nom_qp + '</b>' + '</p>' +
									'<p class="cs-text-legend">' + 'Commune : ' + feature.properties.commune_qp + '</p>' +
									'<cs-poi-line-numbers class="cs-poi-line-numbers ng-star-inserted">' +
										'<!--<cs-poi-line-badge class="cs-poi-1200 cs-poi-line-badge ng-star-inserted" style="background-color: rgb(186, 78, 151); color: rgb(255, 255, 255);">11</cs-poi-line-badge>-->' +
									'</cs-poi-line-numbers>' +
								'</div>' +
							'</div>' +
						'</div>'
					).openPopup();
					}
					
				}).addTo(that.group_tcm);
			});
			
			let accessToken = 'pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';

			this.GrayLight = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=' + accessToken, {
				attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				tileSize: 512,
				zoomOffset: -1
			});
			
			
			this.LayerCartodb = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=' + accessToken, {
				attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				tileSize: 512,
				zoomOffset: -1
			});
			this.LayerMapnik = L.tileLayer('https://api.mapbox.com/styles/v1/sebdupont/cl8x860c1000l15qi6h68eupp/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken, {
				attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				tileSize: 512,
				zoomOffset: -1
			});
			this.LayerImagery = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + accessToken, {
				attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				tileSize: 512,
				zoomOffset: -1
			});

			let la = this.LayerMapnik
			if (this.app.fond_carte == 1 ) la = this.LayerCartodb
			if (this.app.fond_carte == 3 ) la = this.LayerImagery
			

			this.$store.map_osm = L.map('map', {
				center: [this.map.coords.lat, this.map.coords.lng],
				attribution: '<a target="_blank" href="https://leaflet.js/">Leaflet</a> ©',
				zoom: this.map.zoom,
				minZoom: 12,
				maxZoom: this.map.options.zoom_max,
				zoomControl: true,
				editable: true,
				layers: [ la ]
			})

			this.$store.map_osm.on("moveend", this.move_change)
			this.$store.map_osm.on("zoomend", this.zoom_change)
			const mapDiv = document.getElementById('map')
			const resizeObserver = new ResizeObserver(() => {
				this.$store.map_osm.invalidateSize();
			});
			
			resizeObserver.observe(mapDiv)

			// Créez une classe personnalisée qui étend L.Control
			let CustomButton = L.Control.extend({
			onAdd: function() {
				// Créez un élément de conteneur pour votre bouton
				let container = L.DomUtil.create('div', 'custom-button');
				
				container.title = "Télécharger la carte";
				
				// Ajoutez un gestionnaire d'événement pour le clic sur le bouton
				L.DomEvent.on(container, 'click', function() {
					that.$store.dispatch('ExportToPng', {id: 'map'});
				});

				// Retournez l'élément de conteneur
				return container;
			}
			});

			// Créez une instance de votre classe de bouton personnalisé
			let customButton = new CustomButton({ position: 'topleft' }); // Positionnez-le selon vos besoins (ex: topright, bottomleft, etc.)

			// Ajoutez le bouton personnalisé à la carte
			customButton.addTo(this.$store.map_osm);


			let t = [1210,1220,1221,1222,1230,1231,1300,1301,1310,1311,1320,1321,1322,1323,1330,1331,1332,1333,1334,1340,1341,1342,1343,1350,1351,1352,1353,1360,1361,1370,1371,1372,1373,1380,1200,1201,1202,1400,1410,1420,1430,1440,1441,1450,1460,1470,1000,1001,1020,1021,1010,1011,1022,1500,1510,1520,1530,2000,2001,2002,2010,2011]
			for (let index = 0; index < t.length; index++) {
				this.u[t[index]] = L.divIcon({ className: 'mk _' + t[index], html: '<div class="pin_monprojet"><div class="label"></div></div>', iconAnchor: decal});
				this['openMapTilesLayer_' + t[index]] = L.featureGroup().addTo(this.$store.map_osm)
			}
			
			this.$store.select_parcelle = new L.featureGroup().addTo(this.$store.map_osm)
			L.control.scale({position: 'bottomleft', metric: true, imperial: false}).addTo(this.$store.map_osm); 
			
			this.gere_layer()
			this.gere_layer_zac()
			this.gere_layer_qpv()
			this.gere_layer_qpv_trois()
			
			this.markers_layer = L.featureGroup().addTo(this.$store.map_osm)
			this.markers_rs_layer = L.featureGroup().addTo(this.$store.map_osm)
			this.markers_gisement_layer = L.featureGroup().addTo(this.$store.map_osm)
			this.markers_rpls_layer = L.featureGroup().addTo(this.$store.map_osm)
			this.search_layer = L.featureGroup().addTo(this.$store.map_osm)
			
			L.control.lasso({position:'topleft', title: 'dessiner votre périmètre sur mesure'}).addTo(this.$store.map_osm);
			document.getElementsByClassName('leaflet-control-lasso')[0].title = 'Dessiner votre périmètre sur mesure'
			if (document.getElementsByClassName('leaflet-control-attribution leaflet-control')) {
				if (document.getElementsByClassName('leaflet-control-attribution leaflet-control')[0]) {
					for (let index = 0; index < document.getElementsByClassName('leaflet-control-attribution leaflet-control')[0].childElementCount-1; index++) {
						if (document.getElementsByClassName('leaflet-control-attribution leaflet-control')[0].children[index].href=='http://leafletjs.com/') {
							document.getElementsByClassName('leaflet-control-attribution leaflet-control')[0].children[index].target = '_blank'
						}
					}
				}
			}
				
				
			this.$store.map_osm.on('lasso.finished', function(e) {
				gtag('event', 'map_dessin', {'event_category': 'map','event_label': 'map_dessin'});
				that.$store.dispatch('send_event_backend', {module: 'map', action: 'lasso', option: ''});
				let gvRegion2 = {
				}
				let gvFeature = {
					type: 'Feature',
					geometry: {
						type : 'MultiPolygon',
						coordinates : []
					}
				}
				gvRegion2.type = "FeatureCollection"
				gvRegion2.features = []
				gvRegion2.features.push(gvFeature)
				let a = []
				let b = []
				for (let i in e.latLngs) {				
					a.push([e.latLngs[i].lng, e.latLngs[i].lat])
				}
				b.push(a)
				gvRegion2.features[0].geometry.coordinates.push(b)

				switch (that.app.view) {
					case 'rss':
						that.$store.dispatch('load_rs', {type_search:'polygon', cc : gvRegion2})
						break;
					default:
						that.$store.dispatch('load_programs', {type_search:'polygon', cc : gvRegion2})
						break;
				}
			});	

			this.$store.map_osm.on('moveend', function() {
				that.$store.dispatch('set_position', { lat : that.$store.map_osm.getCenter().lat, lng : that.$store.map_osm.getCenter().lng })
				//that.gere_layer()
				that.gere_layer_change_zoom_move();
			})

		})
	},
	components: { }
	
}
</script>

<style lang="stylus">
@import '../../assets/css/app'

.opacity-enter, .opacity-leave-to
	opacity 0


.losange
	background-image url('../../assets/img/pin_rs.svg')
	background-repeat no-repeat
	background-size 44px
	width 44px
	height 44px

.couleur1
	fill #fcc434
.couleur2
	fill #ee8d3e
.couleur3
	fill #d74a22
.couleur4
	fill #29b6f6
.couleur5
	fill #73e8ff
.couleur6
	fill #0086c3

.map-icon-rs
	&.hover-from-list
		margin-left -6px
		margin-top -16px
		width 48px !important
		height 66px !important

.pincolentree
	height 33px
	width 24px
	background-image url('../../assets/img/IconCol_entree.svg')

.pincolmilieu
	height 33px
	width 24px
	background-image url('../../assets/img/IconCol_milieu.svg')

.pincolhaut
	height 33px
	width 24px
	background-image url('../../assets/img/IconCol_haut.svg')

.map-icon-prj
	background url('https://www.myadequation.fr/EFOCUS2_WEB/images/Mon%20projet.png') 100% no-repeat
	height 40px !important
	width 35px !important
.map
	height 100%
	width 100%
	background #ddd
	.map-wrap
		width 100%
		height 100%
		&.not-authorized
			filter blur(8px)
		#map
			width 100%
			height 100%
			.map-icon-rs
				&.hover-from-list
					z-index 5001 !important
					.pin
						transform scale(2) translate(0, -12px) !important
					.pincolentree
						transform scale(2) translate(0, -12px) !important
					.pincolmilieu
						transform scale(2) translate(0, -12px) !important
					.pincolhaut
						transform scale(2) translate(0, -12px) !important
					.pinindentree
						transform scale(2) translate(0, -12px) !important
					.pinindmilieu
						transform scale(2) translate(0, -12px) !important
					.pinindhaut
						transform scale(2) translate(0, -12px) !important
					.pinrs
						transform scale(2) !important
					.pinoffre
						transform scale(2) translate(0, -12px) !important
					.pinpc
						transform scale(2) translate(0, -12px) !important

			.map-icon
				&.center
					.pin
						width 16px
						height 16px
						border-radius 100%
						box-shadow inset 0 0 0 2px red
						&:before
							content ''
							position absolute
							left calc(50% - 2px)
							top calc(50% - 2px)
							width 4px
							height 4px
							background red
							border-radius 100%
					.pinhex
						width 16px
						height 16px
						border-radius 100%
						box-shadow inset 0 0 0 2px red
						border-color red
				&.forme
					&.individuel
						.pin
							background-color #f8bbd0
					&.collectif_entree
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-02.svg')
					&.collectif_milieu
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-03.svg')
					&.collectif_haut
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-04.svg')
					&.individuel_entree
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-10.svg')
					&.individuel_milieu
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-11.svg')
					&.individuel_haut
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-12.svg')
					&.collectif_encours
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-09.svg')
					&.collectif_termine
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-06.svg')
					&.individuel_encours
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-08.svg')
					&.individuel_termine
						.pin
							padding-right 0px
							background-size contain
							background-repeat no-repeat
							height 50px
							width 36px
							background-color transparent !important
							background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-07.svg')
					&.selected
						.pin
							border 3px solid black
					&.hover-from-list
						z-index 5001 !important
						.pin
							transform scale(2)
					&.hover
						.pin
							background-color blue 
					.pin
						background green
						width 18px
						height 18px
						border-radius 100%
						box-shadow 0 0 0 1px alpha(#fff, 50%)
					&.hover-from-list
						z-index 5001 !important
						.pin
							transform scale(2)
				&.prix
					&.projet
						.pin
							background-color #73e8ff
					&.entree
						.pin
							background-color #fcc434
					&.milieu
						.pin
							background-color #ee8d3e
					&.haut
						.pin
							background-color #d74a22
					&.selected
						.pin
							border 3px solid black
					&.hover-from-list
						z-index 5001 !important
						.pin
							transform scale(2)
					&.hover
						.pin
							background-color blue 
					.pin
						background green
						width 18px
						height 18px
						border-radius 100%
						box-shadow 0 0 0 1px alpha(#fff, 50%)				
					&.hover-from-list
						z-index 5001 !important
						.pin
							transform scale(2)
				&.commercialisation
					&.actif
						.pin
							background-color #29b6f6
					&.termine
						.pin
							background-color #0086c3
					&.projet
						.pin
							background-color #73e8ff
					&.selected
						.pin
							border 3px solid black
					&.hover-from-list
						z-index 5001 !important
						.pin
							transform scale(2)
					&.hover
						.pin
							background-color blue 
					.pin
						background green
						width 18px
						height 18px
						border-radius 100%
						box-shadow 0 0 0 1px alpha(#fff, 50%)				
					&.hover-from-list
						z-index 5001 !important
						.pin
							transform scale(2)				
				&.ancien
					&.house
						.pinhex
							background-color rgb(49, 137, 28)
					&.apartment
						.pinhex
							background-color rgb(0, 128, 172)
					&.dependency
						.pinhex
							background-color rgb(255, 27, 39)
					&.land
						.pinhex
							background-color rgb(255, 168, 62)							
					&.selected
						&.hover, &.hover-from-list
							.pinhex
								background-color yellow 
						.pinhex
							border 3px solid black
					&.hover-from-list
						z-index 5001 !important
						.pinhex
							transform scale(2)
					&.hover
						.pinhex
							background-color yellow 
					.pinhex
						background green
						width 18px
						height 18px
						border-radius 100%
						box-shadow 0 0 0 1px alpha(#fff, 50%)				
					&.hover-from-list
						z-index 5001 !important
						.pinhex
							transform scale(2)
				.pin
					transition 0.25s easeOutQuart
			.map-popup
				bottom -18px !important
				font-family montserra, sans-serif
				.leaflet-popup-content-wrapper
					border-radius radius
					background #fff
					color #333
					padding 0
					border-radius radius
					box-shadow 0 1px 14px alpha(#000, 50%)
					.leaflet-popup-content
						display block
						margin 0
						.popup
							display block
							.header
								.image
									height 80px
									background light
									overflow hidden
									border-top-left-radius radius
									border-top-right-radius radius
									img
										position absolute
										left 50%
										top 50%
										transform translate(-50%, -50%)
										width 101%
										height auto
										min-height 100%
										min-width 100%
								.price
									position absolute
									left -4px
									top 12px
									padding 0 8px 0 16px
									font-family volte, sans-serif
									font-weight 600
									font-size 14px
									line-height 24px
									background blue
									color #fff
									&:after, &:before
										content ''
										position absolute
										right -16px
										width 0
										height 0
										border-style solid
									&:before
										top 0
										border-width 16px 16px 0 0
										border-color blue transparent transparent transparent
									&:after
										bottom 0
										border-width 16px 0 0 16px
										border-color transparent transparent transparent blue
									.angle
										position absolute
										left 0
										top -4px
										width 0
										height 0
										border-style solid
										border-width 0 0 4px 6px
										border-color transparent transparent darken(blue, 50%) transparent
							.content
								padding 8px 0
								.program-info
									height 20px
									padding 2px 0
									line-height 16px
									font-family volte, sans-serif
									.label
										float left
										width 50%
										padding-left 16px
										text-transform uppercase
										font-size 10px
										color #999
									.value
										float left
										width 50%
										padding-left 8px
										font-size 13px
										font-weight 600
										color #333
				.leaflet-popup-tip-container
					.leaflet-popup-tip
						width 12px
						height 12px
						background-color #fff
				.leaflet-popup-close-button
					color #fff
					top 2px
					right 2px
					padding 0
					&:hover
						color #333
	.blur-area
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		z-index 501
		background alpha(#fff, 15%)
		.message
			position absolute
			left 50%
			top 50%
			width 430px
			transform translate(-50%, -50%)
			text-align center
			padding 24px
			background #fff
			border-radius radius
			box-shadow 0 0 4px alpha(#000, 25%)
			font-weight 500
			color #666
			.icon
				color #777
				margin 0 0 16px 0				
			.buttons
				margin 16px 0 0 0
				.button
					display inline-block
					padding 12px 20px
					margin 0 8px
					background blue
					box-shadow 0 0 4px alpha(#000, 25%)
					border-radius radius
					color #fff
					cursor pointer
					&:hover
						background-color darken(blue, 10%)
					&:active
						transform translateY(1px)
					&.gray
						background-color #999
						&:hover
							background-color #888
.leaflet-top
	z-index 500 !important
	top 0px

.leaflet-bottom
	z-index 500 !important

.leaflet-control-container
	position initial !important

.leaflet-control-scale
	z-index 500 !important

.leaflet-interactive:focus
	outline none !important

.t
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 50%
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 45px
	width 32px
	border-radius 0 3px 3px 0
	cursor pointer
	.icon
		top 10px
	&:hover
		background #efefef

.u
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 64px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	.icon
		top 8px
	> img
		top 8px
	&:hover
		background #efefef


.pane-legendeneuf
	padding-left 10px
	position fixed
	left 100%
	top 182px
	bottom 0
	height 160px
	width 300px
	background-color #fff
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	box-shadow -2px 0 4px alpha(#000, 25%)
	z-index 500
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	
.vv
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 8px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)

	
.v
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 8px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	.icon
		top 8px
	&:hover
		background #efefef

.w
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 120px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	.icon
		top 8px
	&:hover
		background #efefef
.x
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 176px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	.icon
		top 4px
	&:hover
		background #efefef

.y
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 240px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	color #abd194
	.icon
		top 4px
	&:hover
		background #efefef
			
.yy
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 289px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	color #e1dcb0
	.icon
		top 4px
	&:hover
		background #efefef

			
.y2
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 338px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	color #e6b56e
	.icon
		top 4px
	&:hover
		background #efefef
			
.yy2
	text-align center
	vertical-align middle
	position absolute
	z-index 500
	top 387px
	right 0px
	padding-top 4px
	background #fff
	-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
	box-shadow 0 3px 6px alpha(#000, 25%)
	height 48px
	width 48px
	border-radius 0 3px 3px 0
	cursor pointer
	color #f15025
	.icon
		top 4px
	&:hover
		background #efefef
			
.mark_poi
	width 27px !important;
	height 24px !important;
	background-color red

.mk
	width 49px !important
	height 62px !important

.pane-legendeancien
	padding-left 10px
	position fixed
	left 100%
	top 358px
	bottom 0
	height 270px
	width 200px
	background-color #fff
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	box-shadow -2px 0 4px alpha(#000, 25%)
	z-index 500
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)

.pane-legendeancienoffre
	padding-left 10px
	position fixed
	left 100%
	top 358px
	bottom 0
	height 96px
	width 200px
	background-color #fff
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	box-shadow -2px 0 4px alpha(#000, 25%)
	z-index 500
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	
.pane-legendelocatifterritoire
	position absolute
	padding-left 10px
	left 10px
	bottom 30px
	height 80px
	width 150px
	background-color #fff
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	box-shadow -2px 0 4px alpha(#000, 25%)
	z-index 500
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	
.pane-legendelocatif
	padding-left 10px
	position fixed
	left 100%
	top 296px
	bottom 0
	height 312px
	width 260px
	background-color #fff
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	box-shadow -2px 0 4px alpha(#000, 25%)
	z-index 500
	&.legende
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
.mutation
	height 90px !important
	width 250px !important

.cs-legend-scale-dot
	height 10px
	width 10px
	border-radius 50%
	display inline-block
	margin-right 15px
	-webkit-flex-shrink 0
	flex-shrink 0

.li_legende
	height 25px

.titre_legende
	font-weight 600
	padding-top 10px
	padding-bottom 10px

ul
	list-style none
	padding 0
	margin 0


.buttonv
	right 0
	border 0
	border-radius 0
	border-radius 2px
	width 120px
	font-style normal
	font-weight 500
	font-size 12px
	line-height 16px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #000000
	width 200px
	height 36px
	cursor pointer
	background palette1
	&:hover
		background palette0

&.pc
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-005.svg')
&.collectif_entree
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-002.svg')
&.collectif_milieu
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-003.svg')
&.collectif_haut
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-004.svg')
&.individuel_entree
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-010.svg')
&.individuel_milieu
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-011.svg')
&.individuel_haut
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-012.svg')
&.collectif_encours
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-009.svg')
&.collectif_termine
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-006.svg')
&.individuel_encours
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-008.svg')
&.individuel_termine
	.pin
		padding-right 0px
		background-size contain
		background-repeat no-repeat
		height 50px
		width 36px
		background-color transparent !important
		background-image url('https://www.myadequation.fr/EFOCUS/pine_efocus-007.svg')

.hover-from-list
	z-index 5001 !important
	.pin
		transform scale(2) translate(0, -12px) !important
	.pinrs
		transform scale(2) !important
	.pinoffre
		transform scale(2) translate(0, -12px) !important
	.pinpc
		transform scale(2) translate(0, -12px) !important

.custom-button
	text-align center
	vertical-align middle
	background #fff
	border 1px solid #ccc
	height 32px
	width 32px
	border-radius 0 3px 3px 0
	cursor pointer
	background-position center
	background-image url('https://www.myadequation.fr/EFOCUS/download.svg')
	background-repeat no-repeat
	background-size 24px
	&:hover
		background-color #eee
.ouvert
	transition 0.5s easeOutQuart
	transform translateX(-400px)

.content_filter
	z-index 500
	position absolute
	top 8px
	left 50px
	width auto
	display flex

.data_filter
	width 200px
	height 30px
	border-radius 15px
	background-color #fcfcfc
	border 1px solid #ccc
	text-align center
	line-height 30px
	vertical-align middle
	cursor pointer
	margin-right 5px
	&:hover
		border 1px solid #999
	&.active
		background-color blue
		color #fff
		&:hover
			border 1px solid #999
	&.disabled
		background-color #cfcfcf !important
		color #000 !important
		&:hover
			background-color #cfcfcf !important
			color #000 !important
.invisible
	display none

.v-switch-label
	font-family Arial, sans-serif !important
	font-weight normal !important

.select-all
	position absolute
	right 16px
	top 22px
	width 24px
	height 24px
	border-radius radius
	border 2px solid palette0
	display block
	cursor pointer
	display flex
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		border-color palette2
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap



.select-all-co
	//position absolute
	//right 16px
	//top 28%
	width 24px
	height 24px
	border-radius radius
	border 2px solid #CCC
	display block
	cursor pointer
	display flex
	background-color #FFF !important
	&.disabled
		cursor default
	&.checked
		background-color transparent !important
		background-color palette0 !important
		.chk .icon
			color #FFF
			display block
	&:hover
		background-color palette0 !important
	input
		position absolute
		top 0
		display none
	.chk
		float left
		width 24px
		height 24px
		border-radius (radius / 2)
		.icon
			position absolute
			left 2px
			top 2px
			display none
	.text
		display block
		line-height 32px
		white-space nowrap

.cs-text-legend
	font-size 0.8rem;
	margin 5px 0 !important


.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
	border: 1px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
	border-radius 0px !important
}
	
.pin1 {
  position: absolute;
  left: 150px;
  border-radius: 50% 50% 50% 0;
  border: 4px solid #000;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
}

.pin1::before {
  position: absolute;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: -1px;
  margin-top: -1px;
  background-color: #000;
}

.pin1::after {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: -3px;
  margin-top: 15px;
  background-color: #000;
}

._1210 {background: url('../../assets/img/poi/B1210.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._1220 {background: url('../../assets/img/poi/B1220.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._1221 {background: url('../../assets/img/poi/B1221.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1222 {background: url('../../assets/img/poi/B1222.png') 9px 10px no-repeat, url('../../assets/img/poi/yellow.png') center center no-repeat; background-size: 30px, 25px}
._1230 {background: url('../../assets/img/poi/B1230.png') 9px 10px no-repeat, url('../../assets/img/poi/yellow.png') center center no-repeat; background-size: 30px, 25px}
._1231 {background: url('../../assets/img/poi/B1231.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._1300 {background: url('../../assets/img/poi/B1300.png') 9px 10px no-repeat, url('../../assets/img/poi/red_two.png') center center no-repeat; background-size: 30px, 25px}
._1301 {background: url('../../assets/img/poi/B1301.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1310 {background: url('../../assets/img/poi/B1310.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._1311 {background: url('../../assets/img/poi/B1311.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1320 {background: url('../../assets/img/poi/B1320.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._1321 {background: url('../../assets/img/poi/B1321.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._1322 {background: url('../../assets/img/poi/B1322.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._1323 {background: url('../../assets/img/poi/B1323.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._1330 {background: url('../../assets/img/poi/B1330.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1331 {background: url('../../assets/img/poi/B1331.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1332 {background: url('../../assets/img/poi/B1332.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1333 {background: url('../../assets/img/poi/B1333.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1334 {background: url('../../assets/img/poi/B1334.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1340 {background: url('../../assets/img/poi/B1340.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._1341 {background: url('../../assets/img/poi/B1341.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._1342 {background: url('../../assets/img/poi/B1342.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._1343 {background: url('../../assets/img/poi/B1343.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._1350 {background: url('../../assets/img/poi/B1350.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._1351 {background: url('../../assets/img/poi/B1351.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._1352 {background: url('../../assets/img/poi/B1352.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._1353 {background: url('../../assets/img/poi/B1353.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._1360 {background: url('../../assets/img/poi/B1360.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1361 {background: url('../../assets/img/poi/B1361.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1371 {background: url('../../assets/img/poi/B1371.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._1372 {background: url('../../assets/img/poi/B1372.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._1373 {background: url('../../assets/img/poi/B1373.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._1380 {background: url('../../assets/img/poi/B1380.png') 9px 10px no-repeat, url('../../assets/img/poi/yellow.png') center center no-repeat; background-size: 30px, 25px}
._1200 {background: url('../../assets/img/poi/B1200.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._1201 {background: url('../../assets/img/poi/B1201.png') 9px 10px no-repeat, url('../../assets/img/poi/red_two.png') center center no-repeat; background-size: 30px, 25px}
._1202 {background: url('../../assets/img/poi/B1202.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._1400 {background: url('../../assets/img/poi/B1400.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1410 {background: url('../../assets/img/poi/B1410.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1420 {background: url('../../assets/img/poi/B1420.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1430 {background: url('../../assets/img/poi/B1430.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1440 {background: url('../../assets/img/poi/B1440.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1441 {background: url('../../assets/img/poi/B1441.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1450 {background: url('../../assets/img/poi/B1450.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1460 {background: url('../../assets/img/poi/B1460.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._1470 {background: url('../../assets/img/poi/B1470.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._2000 {background: url('../../assets/img/poi/B2000.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._2001 {background: url('../../assets/img/poi/B2001.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._2002 {background: url('../../assets/img/poi/B2002.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._2010 {background: url('../../assets/img/poi/B2010.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._2011 {background: url('../../assets/img/poi/B2011.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}



._P1210 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1210.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._P1220 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1220.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._P1221 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1221.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1222 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1222.png') 9px 10px no-repeat, url('../../assets/img/poi/yellow.png') center center no-repeat; background-size: 30px, 25px}
._P1230 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1230.png') 9px 10px no-repeat, url('../../assets/img/poi/yellow.png') center center no-repeat; background-size: 30px, 25px}
._P1231 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1231.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._P1300 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1300.png') 9px 10px no-repeat, url('../../assets/img/poi/red_two.png') center center no-repeat; background-size: 30px, 25px}
._P1301 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1301.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1310 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1310.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._P1311 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1311.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1320 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1320.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P1321 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1321.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P1322 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1322.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P1323 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1323.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P1330 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1330.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1331 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1331.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1332 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1332.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1333 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1333.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1334 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1334.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1340 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1340.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._P1341 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1341.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._P1342 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1342.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._P1343 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1343.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._P1350 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1350.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._P1351 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1351.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._P1352 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1352.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._P1353 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1353.png') 9px 10px no-repeat, url('../../assets/img/poi/blue.png') center center no-repeat; background-size: 30px, 25px}
._P1360 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1360.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1361 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1361.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1371 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1371.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._P1372 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1372.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P1373 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1373.png') 9px 10px no-repeat, url('../../assets/img/poi/blue_two.png') center center no-repeat; background-size: 30px, 25px}
._P1380 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1380.png') 9px 10px no-repeat, url('../../assets/img/poi/yellow.png') center center no-repeat; background-size: 30px, 25px}
._P1200 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1200.png') 9px 10px no-repeat, url('../../assets/img/poi/red.png') center center no-repeat; background-size: 30px, 25px}
._P1201 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1201.png') 9px 10px no-repeat, url('../../assets/img/poi/red_two.png') center center no-repeat; background-size: 30px, 25px}
._P1202 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1202.png') 9px 10px no-repeat, url('../../assets/img/poi/orange.png') center center no-repeat; background-size: 30px, 25px}
._P1400 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1400.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1410 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1410.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1420 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1420.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1430 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1430.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1440 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1440.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1441 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1441.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1450 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1450.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1460 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1460.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P1470 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B1470.png') 9px 10px no-repeat, url('../../assets/img/poi/violet.png') center center no-repeat; background-size: 30px, 25px}
._P2000 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B2000.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P2001 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B2001.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P2002 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B2002.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P2010 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B2010.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}
._P2011 {background: url('https://www.myadequation.fr/EFOCUS/icones/poi/B2011.png') 9px 10px no-repeat, url('../../assets/img/poi/green_two.png') center center no-repeat; background-size: 30px, 25px}


.cs-itinerary-popin{width:100%;border-radius:3px;background:#fff}
.cs-itinerary-popin-header{border-radius:3px 3px 0 0;color:#fff;display:-webkit-box;display:-webkit-flex;display:flex;cursor:move;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between}
.cs-itinerary-popin-header   .cs-itinerary-popin-title-wrapper{padding:13px 10px;display:-webkit-inline-box;display:-webkit-inline-flex;display:inline-flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center}
.cs-itinerary-popin-header   .cs-itinerary-popin-title-wrapper   .cs-itinerary-popin-icon{font-size:1.25rem;width:20px;height:20px;line-height:1;display:inline-block;-webkit-flex-shrink:0;flex-shrink:0;margin-right:10px}
.cs-itinerary-popin-header   .cs-itinerary-popin-title-wrapper   .cs-itinerary-popin-title{font-size:.813rem;font-weight:400}
.cs-itinerary-popin-header   .cs-itinerary-popin-close-button{position:relative;font-size:.625rem;text-align:center;min-height:100%;cursor:pointer}
.cs-itinerary-popin-header   .cs-itinerary-popin-close-button, .cs-itinerary-popin-header   .cs-itinerary-popin-close-button:after, .cs-itinerary-popin-header   .cs-itinerary-popin-close-button:before{min-width:30px}
.cs-itinerary-popin-header   .cs-itinerary-popin-close-button:before{position:absolute;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);left:0;z-index:1;color:#fff}
.cs-itinerary-popin-header   .cs-itinerary-popin-close-button:after{content:"";position:absolute;min-height:100%;top:0;left:0;background-color:rgba(0,0,0,.1)}
.cs-itinerary-popin-content{font-size:1rem;padding-top:15px}
.cs-itinerary-popin-content   .cs-itinerary-popin-main-data{padding:0 10px 10px}
.cs-itinerary-popin-content   .cs-itinerary-popin-main-data   .cs-itinerary-popin-main-data-category{font-size:.813rem;display:block}
.cs-itinerary-popin-content   .cs-itinerary-popin-extra-data{padding-bottom:10px}
.cs-itinerary-popin-content   .cs-itinerary-popin-extra-data   .cs-itinerary-iso-label{color:#bdbdbd}

.leaflet-popup {
	margin-bottom: 30px !important;
}
.leaflet-popup-content {
	background: #fff;
	border-radius: 3px;
	font-size: 1rem;
	color: #180000;
	line-height: 1.5;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
	background: transparent
	box-shadow: 0;
}

.leaflet-popup-content-wrapper {
	padding: 0px
	text-align: left;
	border-radius: 0px;
}
button, input, select, textarea {
	font-size: 100%;
	border: none;
	background: none;
	padding: 0;
	margin: 0;
}
.cs-itinerary-popin-header .cs-itinerary-popin-close-button, .cs-itinerary-popin-header .cs-itinerary-popin-close-button:after, .cs-itinerary-popin-header .cs-itinerary-popin-close-button:before {
	min-width: 30px;
}

.cs-itinerary-popin-header .cs-itinerary-popin-close-button {
	position: relative;
	font-size: .625rem;
	text-align: center;
	min-height: 100%;
	cursor: pointer;
}

.leaflet-popup-content {
	margin: 0;
	line-height: 1.4;
}

.leaflet-container a.leaflet-popup-close-button {
	padding: 16px 22px 0 0;
	color: #fff !important;
}


.marker-cluster-custom {
	background-color: rgba(241, 211, 87, 0.6);
	border: 1px solid #d9a441;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	font-size: 14px;
	color: #333;
}

.total-childs {
    position: absolute;
    top: -5px;
    right: -5px;
    color: #fff;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    font-size: 11px;
    text-align: center;
	background: #0fa8bd;
}

.tc {
    top: -12px;
}

.leaflet-marker-icon.transparent-icon {
	opacity: 0.8;
}

	/* Popup custom */
.custom-popup {
	position: absolute;
	z-index: 1000;
	width: 300px;
	background-color: white;
	border: 1px solid #ccc;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.popup-header {
	background-color: #333;
	color: white;
	padding: 5px;
	cursor: move;
}

.popup-content {
	padding: 10px;
}
</style>
