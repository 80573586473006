 <template>
	<div class="stat-box">
		<div class="p-3 border-round shadow-2 align-items-center surface-card">
			<div class="">
                <div v-if="app.view != 'report_B'" style="width: 100%;display: flex;align-items: center;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
                    <Chip style="margin-bottom:5px;margin-right:5px;" v-for="(secteur, index) in ListeSecteursCod" v-bind:key="secteur" :label="ListeSecteursLib[index]" removable @remove="del_secteur(secteur, ListeSecteursLib[index] );" />
                    <Button icon="pi pi-plus" class="p-button-rounded" style="width: 2rem;height: 2rem;position: absolute; right: 0;" @click="plus=!plus;if(plus) {focusInput()}"/>
                </div>
                <div v-else style="width: 100%;display: flex;align-items: center;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
                    <Chip style="margin-bottom:5px;margin-right:5px;" v-for="(secteur, index) in ListeSecteursCod" v-bind:key="secteur" :label="ListeSecteursLib[index]" />
                </div>
			</div>
            <div class="p-dropdown-filter-container mt-2" v-show="plus">

                <div class="card">
                    <InputText ref="ajs" id="ajs" type="text" v-model="search" @input="filterData" class="w-full mb-2"/>
                    <DataTable :value="paginatedData" :rows="rows" :totalRecords="totalRecords" :lazy="true" @lazy-load="loadData" selectionMode="multiple" :selection.sync="selectedRows" :metaKeySelection="false">
                        <Column selectionMode="multiple"></Column>
                        <Column field="qua_libelle" :header="$t('Secteur')"></Column>
                        <Column field="com_libelle" :header="$t('Commune')"></Column>
                        <template #footer>
                            <div style="display: flex;justify-content: flex-end">
                                <Button label="Ajouter" icon="pi pi-check" class="p-button-sm" @click="AjouterSecteur()" />
                            </div>
                        </template>
                </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputText from 'primevue/inputtext';
// eslint-disable-next-line
import {FilterMatchMode,FilterOperator} from 'primevue/api/';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Chip from 'primevue/chip';

export default {
	name: 'ArraySecteurs',

	data: function() {
		return {
            search: '',
            paginatedData: [],
            totalRecords: 0,
            rows: 5,
            lazyParams: { first: 0, rows: 10 },
            
            loading: false,
            filters: {
                'qua_libelle': {value: '', matchMode: 'contains'},
            },
            columns: [
                {field: 'qua_libelle', header: 'Name'},
            ],
            plus: false,
			tab: 'mois',
            searchQuery: '',
            selectedRows: [],
            selectedCities: [],
            selectedCountries: null,
            filteredData: [],
            cities: [
            ],
            countries: [
            ],
            shouldFocus: false
        }
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		ListeSecteursLib: function() {
            return this.app.secteurs_lib
        },

		ListeSecteursCod: function() {
            return this.app.secteurs
        }
	}),

    watch: {
        shouldFocus(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$el.querySelector('input').focus();
                    this.shouldFocus = false; // Réinitialiser si nécessaire
                });
            }
        }
    },

    methods: {
        focusInput() {
            this.shouldFocus = true;
        },

        AjouterSecteur() {
            for (let index = 0; index < this.selectedRows.length; index++) {
                const element = this.selectedRows[index];
                this.$store.dispatch('add_secteurs', { secteurs: element.cle_externe, secteurs_lib: element.qua_libelle });
            }
            this.search='';
            this.paginatedData = [];
            this.maj_selection();
            this.plus=false
        },

        onRowSelect(event) {
            this.selectedRows = event.value;
        },

        loadData(event) {
            this.lazyParams = event;
            let data = this.filteredData.slice(event.first, event.first + event.rows);
            if (this.search!='') this.paginatedData = data
            else this.paginatedData = []
        },

        filterData() {
            if (this.search) {
                this.filteredData = this.cities.filter(city => {
                    const matchesSearch = city.qua_libelle.toLowerCase().includes(this.search.toLowerCase()) || city.com_libelle.toLowerCase().includes(this.search.toLowerCase());
                    const notInExistingItems = !this.app.secteurs.includes(city.cle_externe);
                    return matchesSearch && notInExistingItems && ((this.app.territoires_neuf==['ALL']) || this.app.territoires_neuf.includes(city.cle_externe) || this.app.territoires_neuf.includes('ALL'));
                    }
                );
            } else {
                this.filteredData = this.cities;
            }
            this.totalRecords = this.filteredData.length;
            this.loadData(this.lazyParams);
        },

        onKeydown(event) {
            if (event.key === 'Escape') {
                this.searchQuery='';
                this.filteredData = [];
            }
        },

        del_secteur: function(cle_externe, qua_libelle) {
            this.$store.dispatch('del_secteurs', { secteurs: cle_externe, secteurs_lib: qua_libelle });
            this.searchQuery='';
            this.filteredData = [];
            this.maj_selection()
        },

        add_secteur: function(cle_externe, qua_libelle) {
            this.$store.dispatch('add_secteurs', { secteurs: cle_externe, secteurs_lib: qua_libelle });
            this.searchQuery='';
            this.filteredData = [];
            this.maj_selection()
        },

        maj_selection: function() {
			let t = []
			let p = []
			for(let i = 0; i < this.app.livedata.synoptique.length; i++){
				for(let j = 0; j < this.app.livedata.synoptique[i].tableau.length; j++){
					t.push({code : this.app.livedata.synoptique[i].tableau[j].program_id})
				}
			}
			for(let ii = 0; ii < this.app.livedata.synoptique_pc.length; ii++){
                for(let jj = 0; jj < this.app.livedata.synoptique_pc[ii].tableau.length; jj++){
					p.push({code : this.app.livedata.synoptique_pc[ii].tableau[jj].program_id})
				}
            }
			if (t.length > 2) {
				for (let iii in this.app.programs) {
					let ell = document.getElementById(this.app.programs[iii].id)
					if (ell) {
						if (ell.className!="checkbox2") {
							ell.className="checkbox2"
						}
					}
				}
				this.$store.dispatch('report_by_id', { programs: t, pc: p, view: this.app.source_view})
			} else {
				this.$store.dispatch('add_notification', { status: 'error', message: "Votre sélection de programme doit contenir un minimum de 3 programmes" })
			}
		},

		enprix: function(str) {
			if (str) {
				let n = parseFloat(str)
                if (n)
                    n = Math.round(n/10)*10         
					return n.toLocaleString('fr-FR', {style: "decimal", currency: "EUR", maximumFractionDigits : "0"}) + " €"
			}
            return ""
        },

        NotSelectedYet(t) {
            let u = [];
            for (let index = 0; index < t.length; index++) {
                if (!(t[index].qua_libelle in this.selectedCities)) u.push(t[index])
            }
            return u;
        },
    },

    created() {
        this.cities = require('../../../../../assets/json/secteurs.json');
        this.cities.sort((a, b) => {
            const nameA = a.qua_libelle.toLowerCase();
            const nameB = b.qua_libelle.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    },

    mounted() {
        this.filteredData = this.cities;
        window.addEventListener('keydown', this.onKeydown, { passive: true });
        this.totalRecords = this.cities.length;
        this.loadData(this.lazyParams);
    },

    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeydown);
    },

    components : {
        InputText,
        DataTable, 
        Column, 
        Button,
        Chip,
    },

}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'

.disabled
    background #ccc

.p-dropdown-item
    &:hover
        cursor pointer !important

</style>
