<template>
	<span class="icon" :class="classes" :style="style" v-html="src"></span>
</template>

<script>

export default {
	name: 'Icon',
	data() {
		return {
			
		}
	},
	props: {
		file: {
			default: true
		},
		size: {
			default: 24
		}
	},
	computed: {
		classes: function () {
			let classes = [];
			if (this.invert) classes.push('invert');
			return classes;
		},
		style: function () {
			let style = { width: this.size + 'px', height: this.size + 'px' };
			return style;
		},
		src: function () {
			return require('../../assets/img/' + this.file + '.svg');
		}
	}
}
</script>

<style lang="stylus">

.icon
	display inline-block
	vertical-align middle
	svg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		height 100% !important
		width 100% !important

</style>
