<template>
	<transition name="pane-adresse-education-lycee-pro">
		<div class="paneleft pan-adresse-education-lycee-pro">
			<pane-adresse-retour accueil="" retour="education" cityscan="true" v-show="false"/>
			<pane-adresse-element 
				:propObj="this.app.cityscan.adresse.lycee_pro" 
				:sstyle="'color: rgb(127, 68, 182)'" 
				:sclass="'cs-tile-icon cs-icon-thematic icon-1441-02 ng-star-inserted'" 
				:titre="$t('lycee_professionnel')"
				:detail="'education'"
				:retour="'education'"
			/>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'
import PaneAdresseElement from './PaneAdresseElement.vue'

export default {
	name: 'PaneAdresseEducationLyceePro',
	data: function() {
		return {
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
	},
	
	components: { PaneAdresseRetour, PaneAdresseElement }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'


.pane-adresse-education-lycee-pro-enter
	transform translateX(100%)
.pane-adresse-education-lycee-pro-leave-to
	transform translateX(-100%)

.pan-adresse-education-lycee-pro
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.checkbox {
	width: 20px !important;
	height: 20px !important;
}

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
