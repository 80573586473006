<template>
	<transition name="pane-ancien-conjoncture">
		<div class="paneleft pan-ancien-conjoncture">
			<pane-adresse-retour accueil="" retour="ancien" cityscan="true"/>
			<div class="conjoncture">
				<table style="width:100%">
					<th style="display:none"></th>
					<tr style="height: 24px">
						<td style="width:30%">
							<div class="round" style="width:110px">{{ $t('logement_t3') }}</div>
						</td>
						<td style="width:70%;text-align: right">
							<div class="round" style="width:160px;float: right;">{{ $t('nom_de_la_commune') }}</div>
						</td>
					</tr>
				</table>
				<br>
				<table style="width:100%">
					<th style="display:none"></th>
					<tr style="height: 30px">
						<td style="border-bottom: 1px solid #CCC; width:40%;font-style: italic">
							{{ $t('depuis') }}
						</td>
						<td style="border-bottom: 1px solid #CCC; width:30%; border-left: 1px solid #CCC; text-align: center">
							12 {{ $t('mois') }}
						</td>
						<td style="border-bottom: 1px solid #CCC; width:30%; border-left: 1px solid #CCC; text-align: center">
							24 {{ $t('mois') }}
						</td>
					</tr>
					<tr style="height: 30px">
						<td style="border-bottom: 1px solid #CCC; width:40%">
							{{ $t('evolution_des_prix') }}
						</td>
						<td style="border-bottom: 1px solid #CCC; width:30%; border-left: 1px solid #CCC; text-align: center">
							+ XX.XX %
						</td>
						<td style="border-bottom: 1px solid #CCC; width:30%; border-left: 1px solid #CCC; text-align: center">
							+ XX.XX %
						</td>
					</tr>
					<tr style="border-bottom: 1px solid #CCC; height: 30px">
						<td style="width:40%">
							{{ $t('delais_de_vente') }}
						</td>
						<td style="width:30%; border-left: 1px solid #CCC; text-align: center">
							XX jours
						</td>
						<td style="width:30%; border-left: 1px solid #CCC; text-align: center">
							XX jours
						</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAncienConjoncture',
	data: function() {
		return {
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	methods: {
	},

	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-ancien-conjoncture-enter
	transform translateX(100%)
.pane-ancien-conjoncture-leave-to
	transform translateX(-100%)

.pan-ancien-conjoncture
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.conjoncture
	position: absolute
	width: 100%
	top: 70px
	font-size 0.8rem
	padding-left: 10px
	padding-right: 10px

.round
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
	text-align center

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
