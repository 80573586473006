<template>
	<transition name="pane-locatif">
		<div class="paneleft pan-locatif">
			<div class="module_ret">
				<div class="unmodule_sous2" style="text-align:center;" v-if="app.bi_locatif[getGeoLocatif()].nom">
					<b><p v-if="app.bi_locatif[getGeoLocatif()].nom">{{app.bi_locatif[getGeoLocatif()].nom}} ({{app.bi_locatif[getGeoLocatif()].code}})</p></b>
				</div>
				<div class="unmodule_sous2" style="text-align:center;" v-else>
					<b><p>{{$t('Chargement en cours')}}</p></b>
				</div>
			</div>
			<div class="panecontent wrapper-scroll" v-bar style="height:calc(100% - 64px);" v-if="app.bi_locatif[getGeoLocatif()].nom">
				<div style="height:auto !important;padding-bottom:10px;">
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<br/>
								<div class="px-3 py-1" style="padding-right:0px !important">
									<div class="grid">
										<div class="col-12">
											<div class="surface-card shadow-2 p-3 border-round">
												<div class="flex justify-content-between mb-3">
													<div>
														<span class="block text-500 font-medium mb-3">{{ $t('loyer_h.c_median') }} *</span>
														<div class="text-900 font-medium text-xl">{{getPrice(app.bi_locatif[getGeoLocatif()].kpi.total.prix_m2_median) }}</div>
													</div>
													<div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
														<i class="pi pi-euro text-orange-500 text-xl"></i>
													</div>
												</div>
												<p v-if="app.bi_locatif[getGeoLocatif()].kpi.total.nombre_annonces" class="text-500 font-medium"><span><b>{{app.bi_locatif[getGeoLocatif()].kpi.total.nombre_annonces}}</b></span>
													<span class="text-500"> {{ $t('annonce') }}</span><span class="text-500" v-if="app.bi_locatif[getGeoLocatif()].kpi.total.nombre_annonces>1">s</span>*<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* {{ $t('sur_12_mois_glissants') }}</span>
												</p>
												<span v-else class="text-500">{{ $t('aucune_annonce_na_ete_communiquee') }}</span>
											</div>
										</div>
									</div>
								</div>
								<br/>
								<div class="dropdown-item" style="text-align: center;">
									<Button style="min-width:270px" :label="$t('Consulter les biens à louer')" class="p-button-rounded" @click="setBiensComparables(true); $store.dispatch('set_view', { view: 'ancien-locatif' })"  />
								</div>
								<div class="dropdown-item" style="text-align: center;">
									<Button style="min-width:270px" :label="$t('Afficher les résidences sociales')" class="p-button-rounded" @click="$store.dispatch('set_view', { view: 'rpls' })"  />
								</div>
								<div class="conjoncture" v-if="app.bi_locatif[getGeoLocatif()].kpi.total.nombre_annonces" style="padding-right:10px">
									<br/>
									<div class="business-dashboard company-dashboard">
										<div class="company-header is-dark-card-bordered is-dark-bg-6" style="text-align: center;width: 100%;color:#000;">
											<div style="left: 60px;">{{ $t('logements_locatifs_libre') }}</div>
										</div>
									</div>
									<div class="business-dashboard company-dashboard" id="volume_logement_locatif">
										<div class="dashboard-card is-base-chart">
											<div class="content-box">
											<!-- -->
												Volumes sur le marché<br/><br/><span style="position: absolute;	right: 5px; top: 5px;"><a id="export03" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'volume_logement_locatif', exportid: 'export03'});"><span><icon file="image" /></span></a></span>
												<div class="revenue-stats is-dark-bordered-12">
													<div class="revenue-stat">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-4].periode.label }}</span>
														<span class="dark-inverted">{{app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-4].total.nombre_annonces }}</span>
													</div>
													<div class="revenue-stat">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].periode.label }}</span>
														<span class="dark-inverted">{{app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].total.nombre_annonces }}</span>
													</div>
													<div class="revenue-stat">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].periode.label }}</span>
														<span class="dark-inverted">{{app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].total.nombre_annonces }}</span>
													</div>
													<div class="revenue-stat" v-if="app.bi_locatif[getGeoLocatif()].kpi.historique.length>3">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].periode.label }}</span>
														<span class="dark-inverted">{{app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].total.nombre_annonces }}</span>
													</div>
												</div>
											</div>
											<div class="chart-container">
												<apexchart :height="sharingOptions.chart.height" :type="sharingOptions.chart.type" :series="sharingOptions.series" :options="sharingOptions"></apexchart>
											</div>
										</div>
									</div>
									<br/>
									<div class="business-dashboard company-dashboard" id="loyer_logement_locatif">
										<div class="dashboard-card is-base-chart">
											<div class="content-box">
												{{ $t('loyers_hors_charges/_m²_hab') }}<br/><br/><span style="position: absolute;	right: 5px; top: 5px;"><a id="export04" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'loyer_logement_locatif', exportid: 'export04'});"><span><icon file="image" /></span></a></span>
												<div class="revenue-stats is-dark-bordered-12">
													<div class="revenue-stat">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-4].periode.label }}</span>
														<span class="dark-inverted">{{getPrice(app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-4].total.prix_m2_median) }}</span>
													</div>
													<div class="revenue-stat">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].periode.label }}</span>
														<span class="dark-inverted">{{getPrice(app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].total.prix_m2_median) }}</span>
													</div>
													<div class="revenue-stat">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].periode.label }}</span>
														<span class="dark-inverted">{{getPrice(app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].total.prix_m2_median) }}</span>
													</div>
													<div class="revenue-stat" v-if="app.bi_locatif[getGeoLocatif()].kpi.historique.length>3">
														<span>{{ app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].periode.label }}</span>
														<span class="dark-inverted">{{getPrice(app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].total.prix_m2_median) }}</span>
													</div>
												</div>
											</div>
											<div class="chart-container">
											<!---->
												<apexchart :height="usersOptions.chart.height" :type="usersOptions.chart.type" :series="usersOptions.series" :options="usersOptions"></apexchart>
												<!---->
											</div>
										</div>
									</div>
									<br/>
												<!--
									<div class="business-dashboard company-dashboard" id="pub_logement_locatif">
										<div class="dashboard-card is-base-chart">
											<div class="content-box">
												{{ $t('durees_de_publication_(en_jours)') }}<br/><br/><span style="position: absolute;	right: 5px; top: 5px;"><a id="export05" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'pub_logement_locatif', exportid: 'export05'});"><span><icon file="image" /></span></a></span>
												<div class="revenue-stats is-dark-bordered-12">
													<div class="revenue-stat">
														<span>{{app.loyer_yanport.values.points[0].x.substring(0, 4)}}</span>
														<span class="dark-inverted2" v-if="getDurationValues(0)">{{ getDurationValues(0)}} j</span><span v-else>-</span>
													</div>
													<div class="revenue-stat">
														<span>{{app.loyer_yanport.values.points[1].x.substring(0, 4)}}</span>
														<span class="dark-inverted2" v-if="getDurationValues(1)">{{ getDurationValues(1)}} j</span><span v-else>-</span>
													</div>
													<div class="revenue-stat">
														<span>{{app.loyer_yanport.values.points[2].x.substring(0, 4)}}</span>
														<span class="dark-inverted2" v-if="getDurationValues(2)">{{ getDurationValues(2)}} j</span><span v-else>-</span>
													</div>
													<div class="revenue-stat" v-if="app.loyer_yanport.values.points.length>3">
														<span>{{app.loyer_yanport.values.points[3].x.substring(0, 4)}}</span>
														<span class="dark-inverted2" v-if="getDurationValues(3)">{{ getDurationValues(3)}} j</span><span v-else>-</span>
													</div>
												</div>
											</div>
											<div class="chart-container">
												<apexchart :height="usersOptionsJour.chart.height" :type="usersOptionsJour.chart.type" :series="usersOptionsJour.series" :options="usersOptionsJour"></apexchart>
											</div>
										</div>
									</div>
												-->
												<br/>
									<div id="locatif_dash" class="business-dashboard company-dashboard">
										<div class="dashboard-card is-base-chart">
											<div class="content-box" style="padding-bottom:0px !important;padding-left:15px !important;">
												{{ $t('details_par_typologie') }} *
												<span style="position: absolute; right: 5px; top: 5px;"><a id="export06" class="download_table" @click="$store.dispatch('ExportToPng',{id: 'locatif_typo', exportid: 'export06'});"><span><icon file="image" /></span></a></span>
											</div>
											<div class="content-box padleft padtop">
												<table style="width: 300px !important;border-collapse: collapse;border: 1px solid #ccc;" id="locatif_typo">
													<thead style="height:28px;border-bottom: 1px solid #ccc">
														<tr>
															<th class="columnheader">{{ $t('type') }}</th>
															<th class="columnheader">{{ $t('surface') }}</th>
															<th class="columnheader" :title="$t('Loyer/m² hors charge')">{{ $t('loyer') }}</th>
															<th class="columnheader" :title="$t('duree_de_publication')">{{ $t('duree') }}</th>
															<th class="columnheader">{{ $t('annonces') }}</th>
														</tr>
													</thead>
													<tbody >
														
														<tr v-for="i in 5" v-bind:key="i" style="height:24px;border-bottom: 1px solid #ccc;">
															<td style="text-align: left;padding: 5px;">
																{{i}}P
															</td>
															<td style="text-align: right;padding: 5px;" v-if="app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].nombre_annonces">
																{{SansNaRound(app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].surface_mediane, 0)}} m²
															</td><td v-else>&nbsp;</td>
															<td  style="text-align: right;padding: 5px; white-space: nowrap;" v-if="app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].nombre_annonces">
																{{SansNaRound(app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].prix_m2_median, 10)}} €&nbsp;
															</td>
															<td v-else>&nbsp;</td>
															<td  style="text-align: right;padding: 5px;" v-if="app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].nombre_annonces">
																<!--
																<div>
																	{{SansNaRound(app.locatif.yanport_typo_jour[i].values["50.0"], 0)}} {{ $t('jours') }} &nbsp;
																</div>
																-->
															</td>
															<td v-else>&nbsp;</td>
															<td  style="text-align: right;padding: 5px;" v-if="app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].nombre_annonces">
																<div style="white-space: nowrap;">
																	{{SansNa(app.bi_locatif[getGeoLocatif()].kpi.typo['t' + i].nombre_annonces)}} {{ $t('biens') }}
																</div>
															</td>
															<td v-else>&nbsp;</td>
														</tr>
													</tbody>
												</table>
											<!-- -->
												<div class="content-box" style="padding-bottom:0px !important;padding-left:0px !important;">
													{{ $t('evolution_des_prix') }}
												</div>
												
												<apexchart  ref="GraphPrix" class="GraphPrix" id="GraphPrix" type="line" height="250" width="300" :options="Prix.chartOptions" :series="Prix.series"></apexchart>
											<!-- -->
												<div class="content-box" style="padding-bottom:0px !important;padding-left:0px !important;">
													{{ $t('evolution_des_moyennes_de_publication') }}
												</div>
												<!--
												<apexchart id="Duree" type="line" height="250" width="300" :options="Duree.chartOptions" :series="Duree.series"></apexchart>
												-->
											</div>
										</div>
										<br/>
										<div class="business-dashboard company-dashboard" style="">
											<div class="company-header is-dark-card-bordered is-dark-bg-6">
												<p>* {{ $t('donnees_sur_12_mois_glissants') }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>							
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import { i18n } from '../main';
import Button from 'primevue/button';

export default {
	name: 'PaneLocatif',
	data: function() {
		return {
			chartPrix: false,
			chartDuree: false,
			options : {
				chart: {
					height: 400,
					type: 'line',
					stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					width: [4, 4, 4, 4, 4],
					curve: 'smooth'
				},
				xaxis: {
					categories: [2014, 2015, 2016],
				},
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40
				},
				series: [],
				title: {
					text: 'Ajax Example',
				},
				noData: {
					text: 'Loading...'
				}
				},		
			Prix: {
				
				series: [{
					name: 'T1',
					type: 'line',
					data: [2.8, 3.8, 4.6]
				}, {
					name: 'T2',
					type: 'line',
					data: [4.9, 6.5, 8.5]
				}, {
					name: 'T3',
					type: 'line',
					data: [3, 4, 6.5]
				}, {
					name: 'T4',
					type: 'line',
					data: [1.1, 3.1, 4.1]
				}, {
					name: 'T5',
					type: 'line',
					data: [4, 2, 6]
				}],
				chartOptions: {
					chart: {
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: [2014, 2015, 2016],
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				},
			},
			
			Duree: {
				
				series: [{
					name: 'T1',
					type: 'line',
					data: [2.8, 3.8, 4.6]
				}, {
					name: 'T2',
					type: 'line',
					data: [4.9, 6.5, 8.5]
				}, {
					name: 'T3',
					type: 'line',
					data: [3, 4, 6.5]
				}, {
					name: 'T4',
					type: 'line',
					data: [1.1, 3.1, 4.1]
				}, {
					name: 'T5',
					type: 'line',
					data: [4, 2, 6]
				}],				
				chartOptions: {
					chart: {
						height: 400,
						type: 'line',
						stacked: false,
						toolbar: {
							show: false,
						},
						dropShadow: {
							enabled: true,
							color: '#000',
							top: 18,
							left: 7,
							blur: 10,
							opacity: 0.2
						},
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						width: [4, 4, 4, 4, 4],
						curve: 'smooth'
					},
					xaxis: {
						categories: [2014, 2015, 2016],
					},
					yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
						},
						tooltip: {
							enabled: true
						}
					},
					],
					tooltip: {
						fixed: {
							enabled: true,
							position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
							offsetY: 30,
							offsetX: 60
						},
					},
					legend: {
						horizontalAlign: 'left',
						offsetX: 40
					}
				},
			},
			
			
			
			
			
			
			
			chartOptions_prix_t1 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				}
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_prix_t2 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_prix_t3 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_prix_t4 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_prix_t5 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_duree_t1 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_duree_t2 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_duree_t3 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_duree_t4 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			chartOptions_duree_t5 : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					},
				},
				colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
				grid: {
					show: false,
					padding: {
						left: 0,
						right: 0,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					width: [2],
					curve: 'smooth',
				},
				xaxis: {
					categories: ['2020', '2021', '2022'],type: 'string',
					lines: {
						show: false,
					},
					axisBorder: {
						show: false,
					},
					labels: {
						show: false,
					},
				},
				yaxis: [
				{
					y: 0,
					offsetX: 0,
					offsetY: 0,
					labels: {
						show: false,
					},
					padding: {
						left: 0,
						right: 0,
					},
				},
				],
				tooltip: {
					x: {
						show: true,
						format: 'dd/MM/yy HH:mm',
					},
				},
			},
			usersOptions : {
				series: [
				{
					name: 'Loyers médians',
					data: [15.48, 15.79, 16.2],
				},
				],
				chart: {
					height: 100,
					type: 'area',
					sparkline: {
						enabled: true,
					},
					toolbar: {
						show: false,
						offsetX: 0,
						offsetY: 0,
						tools: {
							download: true,
							selection: true,
							zoom: true,
							zoomin: true,
							zoomout: true,
							pan: true,
							reset: true | '<img src="/static/icons/reset.png" width="20">',
							customIcons: []
						},
						export: {
							csv: {
								filename: undefined,
								columnDelimiter: ',',
								headerCategory: 'category',
								headerValue: 'value',						
							},
							svg: {
								filename: undefined,
							},
							png: {
								filename: undefined,
							}
						},
						autoSelected: 'zoom' 
					},			},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					grid: {
						show: false,
						padding: {
							left: 0,
							right: 0,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: [2],
						curve: 'smooth',
					},
					xaxis: {
						type: 'numeric',
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
					tooltip: {
						x: {
							show: false,
							format: 'dd/MM/yy HH:mm',
						},
					},
				},
				
				usersOptionsJour : {
					series: [
					{
						name: 'Loyers médians',
						data: [15.48, 15.79, 16.2],
					},
					],
					chart: {
						height: 100,
						type: 'area',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					grid: {
						show: false,
						padding: {
							left: 0,
							right: 0,
						},
					},
					dataLabels: {
						enabled: false,
					},
					stroke: {
						width: [2],
						curve: 'smooth',
					},
					xaxis: {
						type: 'numeric',
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
					tooltip: {
						x: {
							show: false,
							format: 'dd/MM/yy HH:mm',
						},
					},
				},
				
				options : { /* eslint-disable-line */
					
					chart: {
						style: {
							fontFamily: 'Nunito',
						},
						toolbar: {
							show: true,
							offsetX: 0,
							offsetY: 0,
							tools: {
								download: true,
								selection: false,
								zoom: false,
								zoomin: false,
								zoomout: false,
								pan: false,
								reset: false | '<img src="/static/icons/reset.png" width="20">',
								customIcons: []
							}
						},
						height: 350,
						type: "line",
						stacked: false
					},
					dataLabels: {
						enabled: false
					},
					colors: ['#99C2A2', '#C5EDAC', '#66C7F4'],
					series: [
					
					{
						name: "Annonces",
						type: 'column',
						data: [17,27,32]
					},
					{
						name: "Loyer",
						type: 'line',
						data: [15.8, 14.78, 14.84]
					},
					],
					stroke: {
						width: [4, 4, 4]
					},				
					plotOptions: {
						bar: {
							columnWidth: "20%"
						}
					},
					xaxis: {
						categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
					},
					yaxis: [
					{
						seriesName: 'Column A',
						axisTicks: {
							show: true
						},
						axisBorder: {
							show: true,
						},
						title: {
							text: "Annonces"
						}
					},
					{
						opposite: true,
						seriesName: 'Line C',
						axisTicks: {
							show: true
						},
						axisBorder: {
							show: true,
						},
						title: {
							text: "Loyer"
						}
					}
					],
					tooltip: {
						shared: false,
						intersect: true,
						x: {
							show: false
						}
					},
					legend: {
						horizontalAlign: "left",
						offsetX: 40
					}
				},
				
				sharingOptions: {
					series: [
					{
						name: "Annonces",
						data: [],
					},
					],
					chart: {
						height: 100,
						type: 'bar',
						toolbar: {
							show: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#8b8ef0', '#8b8ef0', '#8b8ef0'],
					plotOptions: {
						bar: {
							columnWidth: '50px',
							distributed: true,
							endingShape: 'rounded',
						},
					},
					dataLabels: {
						enabled: false,
					},
					legend: {
						show: false,
					},
					xaxis: {
						categories: [2020,2021,2022],					
						lines: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
						},
					},
					yaxis: [
					{
						y: 0,
						offsetX: 0,
						offsetY: 0,
						labels: {
							show: false,
						},
						padding: {
							left: 0,
							right: 0,
						},
					},
					],
				},
				
				chartOptions_trim: {
					title: '',
					chart: {
						height: 280,
						type: 'areaspline',
						style: {
							fontFamily: 'Nunito',
						}
					},
					series: [
					{
						color: '#FFD501',
						name: "Annonces",
						data: [0,0,0,0],
						type: 'column',
						yAxis: 0,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
							[0, '#FFD501'],
							[1, 'transparent']
							]
						},
						marker: {
							symbol: 'square',
							enabled: false
						}
					},{
						color: '#048855',
						name: "Loyers",
						data: [0,0,0,0],
						yAxis: 1,
						fillColor: {
							linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
							stops: [
							[0, 'rgba(4,136,85,0.3)'],
							[1, 'transparent']
							]
						},
						marker: {
							symbol: 'circle',
							enabled: false
						}
					},
					
					],
					xAxis: {
						type : 'category',
						categories : ['', '', ''],
						title: {
							text: ''
						},
						allowDecimals: false,
						lineWidth: 1,
						lineColor: '#ddd',
					},
					yAxis: [
					{
						title: {
							text: "Annonces"
						},
						allowDecimals: true,
						lineWidth: 0,
						gridLineColor: '#ddd',
						gridLineDashStyle: 'dash',
						labels: {
							format: '{value}'
						},
						opposite: true
					},{
						title: {
							text: 'Loyer H.C.'
						},
						allowDecimals: true,
						lineWidth: 0,
						gridLineColor: '#ddd',
						gridLineDashStyle: 'dash',
						labels: {
							format: '{value:,.0f} €'
						}
					},
					],
					plotOptions: {
						series: {
							label: {
								connectorAllowed: false
							},
						}
					},
					tooltip: {
						shared: true,
						crosshairs: true,
						backgroundColor: '#fff',
						borderWidth: 0,
						borderRadius: 4
					},
				},
				
				reference : {
					name:'Logement T3', 
					typeId:2, 
					rooms: 3, 
					buildingConstruction: 7, 
					condition: 3, 
					parkingOpen: 1, 
					area: 60
				},
				
				fu_visible: false,
				detail: '',
			}
		},
		computed: Object.assign(mapGetters([ 'app', 'map' ]), {
			
			PrixChartOptions() {
				let cc = this.Prix.chartOptions
				cc.xaxis.categories = this.app.loyer_yanport_date
				return cc
			}, 

			PrixSeries() {
				let dataPrix = []
				for (let index = 0; index < 5; index++) {
					if (this.app.locatif.yanport_typo_graph_prix && this.app.locatif.yanport_typo_graph_prix[index+1]) {

						let i = index + 1
						let t = []
						let s = this.app.locatif.yanport_typo_graph_prix
						let r=null

						for (let x = 0; x < 5; x++) {
							if (s) {
								if (s[i]) {
									if (s[i].points) {
										if (s[i].points[x]) {
											if (s[i].points[x].y) {
												if (s[i].points[x].y.values["50.0"]) {
													if (typeof s[i].points[x].y.values["50.0"] == 'number') {
														r = s[i].points[x].y.values["50.0"].toFixed(2)
													}
												}
											}
										}
									}
								}
							}
							t.push(r)
						}
						



						dataPrix.push(
						{
							name: 'T' + (index+1),
							type: 'line',
							data : t								
						}
						)
					} else {
						dataPrix.push(
						{
							name: 'T' + (index+1),
							type: 'line',
							data : [
								null, 
								null, 
								null, 
							]
						}
						)

					}

				}
				return dataPrix
			},

			PublicationSeries() {
				let dataDuree = []
				for (let index = 0; index < 5; index++) {
					if (this.app.locatif.yanport_typo_graph_jour && this.app.locatif.yanport_typo_graph_jour[index+1]) {

						let i = index + 1
						let t = []
						let s = this.app.locatif.yanport_typo_graph_jour
						let r=null

						for (let x = 0; x < 5; x++) {
							if (s) {
								if (s[i]) {
									if (s[i].points) {
										if (s[i].points[x]) {
											if (s[i].points[x].y) {
												if (s[i].points[x].y.values["50.0"]) {
													if (typeof s[i].points[x].y.values["50.0"] == 'number') {
														r = s[i].points[x].y.values["50.0"].toFixed(2)
													}
												}
											}
										}
									}
								}
							}
							t.push(r)
						}
						



						dataDuree.push(
						{
							name: 'T' + (index+1),
							type: 'line',
							data : t								
						}
						)
					} else {
						dataDuree.push(
						{
							name: 'T' + (index+1),
							type: 'line',
							data : [
								null, 
								null, 
								null, 
							]
						}
						)

					}
					
				}
				return dataDuree
			}
		}),
		methods: {
			getGeoLocatif: function() {
				if (this.app.fond_carte_loyer == 2) return 'insee_code'
				return 'code_iris'
			},

			getDurationValues: function(n) {
				const points = this.app.loyer_yanport.duration_values.points;
				if (points.length >= n-1) {
					const firstValues = points[n].y.values;
					for (const key in firstValues) {
						if (Object.prototype.hasOwnProperty.call(firstValues, key)) {
							const value = firstValues[key];
							if (this.isNumber(value)) { // Vérifiez si la valeur est un nombre
								return Number(value).toFixed(0); // Convertissez la valeur en nombre avant d'appeler toFixed
							} else {
								return 0;
							}
						}
					}
				}
				return 0; // ou une autre valeur par défaut si nécessaire
			},
			fn: function(s, index, x, n) {
				let r=null
				if (s) {
					if (s[index]) {
						if (s[index]) {
							if (s[index].points) {
								if (s[index].points[x]) {
									if (s[index].points[x].y) {
										if (s[index].points[x].y.values["50.0"]) {
											if (typeof s[index].points[x].y.values["50.0"] == 'number') {
												r = s[index].points[x].y.values["50.0"].toFixed(n)
											}
										}
									}
								}
							}
						}
					}
				}
				return r 
			},

			SansNa: function(v){
				if (v=='NaN') v = "-";
				return v;
			},
			SansNaRound: function(v, n){
				if (v=='NaN') v = "-"
				else {
					if (n==0) {
						return Math.round(v);
					} else {
						return Math.round(v*n)/n;
					}
				}
			},
			getTitreLocatif: function() {
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[0]) return { titre: { title: i18n.t('type') }, annee_1: { title: this.app.loyer_yanport.values.points[0].x.substring(0, 4) }, annee_2: { title: this.app.loyer_yanport.values.points[1].x.substring(0, 4) }, annee_3: { title: this.app.loyer_yanport.values.points[2].x.substring(0, 4) }}
				else return { titre: { title: i18n.t('type') }, annee_1: { title: '' }, annee_2: { title: '' }, annee_3: { title: '' }}
			},
			getPrice: function(p) {
				if (p=="NaN") {
					return "-"
				} else {
					return Math.round(p*10)/10 + " €"
				}
			},
			getJsonLocatif: function() {
				function getPrice(p) {
					if (p=="NaN") {
						return "-"
					} else {
						return Math.round(p*100)/100 + " €"
					}
				}
				
				function vall(s, index, x, n) {
					let r=''
					if (s) {
						if (s[index]) {
							if (s[index]) {
								if (s[index].points[x]) {
									if (s[index].points[x].y) {
										if (s[index].points[x].y.values["50.0"]) {
											if (typeof s[index].points[x].y.values["50.0"] == 'number') {
												r = s[index].points[x].y.values["50.0"].toFixed(n)
											}
										}
									}
								}
							}
						}
					}
					return r 
				}
				
				let r = []
				let v1
				let v2
				let v3
				v1 = ''; v2 = ''; v3 = ''
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[0]) v1 = getPrice(this.app.loyer_yanport.values.points[0].y.values["50.0"])
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[1]) v2 = getPrice(this.app.loyer_yanport.values.points[1].y.values["50.0"])
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[2]) v3 = getPrice(this.app.loyer_yanport.values.points[2].y.values["50.0"])
				r.push({titre: 'Loyers libres hors charges €/ m² hab', annee_1: v1, annee_2: v2, annee_3: v3})
				v1 = ''; v2 = ''; v3 = ''
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[0]) v1 = this.app.loyer_yanport.values.points[0].y.count
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[1]) v2 = this.app.loyer_yanport.values.points[1].y.count
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[2]) v3 = this.app.loyer_yanport.values.points[2].y.count
				r.push({titre: 'Volumes de logements locatifs libres sur le marché', annee_1: v1, annee_2: v2, annee_3: v3})
				v1 = ''; v2 = ''; v3 = ''
				if (this.app.loyer_yanport && this.app.loyer_yanport.duration_values && this.app.loyer_yanport.duration_values.points[0] && !(this.app.loyer_yanport.duration_values.points[0].y.values['50.0']=='NaN')) v1 = this.app.loyer_yanport.duration_values.points[0].y.values["50.0"].toFixed(0) + ' j'
				if (this.app.loyer_yanport && this.app.loyer_yanport.duration_values && this.app.loyer_yanport.duration_values.points[1] && !(this.app.loyer_yanport.duration_values.points[1].y.values['50.0']=='NaN')) v2 = this.app.loyer_yanport.duration_values.points[1].y.values["50.0"].toFixed(0) + ' j'
				if (this.app.loyer_yanport && this.app.loyer_yanport.duration_values && this.app.loyer_yanport.duration_values.points[2] && !(this.app.loyer_yanport.duration_values.points[2].y.values['50.0']=='NaN')) v3 = this.app.loyer_yanport.duration_values.points[2].y.values["50.0"].toFixed(0) + ' j'
				r.push({titre: i18n.t('durees_de_publication_(en_jours)'), annee_1: v1, annee_2: v2, annee_3: v3})
				
				for (let index = 0; index < 5; index++) {
					v1 = vall(this.app.locatif.yanport_typo_graph_prix, index, 0, 2)
					v2 = vall(this.app.locatif.yanport_typo_graph_prix, index, 1, 2)
					v3 = vall(this.app.locatif.yanport_typo_graph_prix, index, 2, 2)
					r.push({titre: 'Loyers libres hors charges €/ m² hab - T' + (index+1), annee_1: v1, annee_2: v2, annee_3: v3})
				}
				
				for (let index = 0; index < 5; index++) {
					v1 = vall(this.app.locatif.yanport_typo_graph_prix, index, 0, 0)
					v2 = vall(this.app.locatif.yanport_typo_graph_prix, index, 1, 0)
					v3 = vall(this.app.locatif.yanport_typo_graph_prix, index, 2, 0)
					r.push({titre: i18n.t('durees_de_publication_(en_jours)') + ' - T' + (index+1), annee_1: v1, annee_2: v2, annee_3: v3})
				}
				
				return r
			},
			setBiensComparables: function(p) {
				this.$store.dispatch('set_bienslocatifs', { value: p })
			},
			isNumber: function(evt) {
				evt = (evt) ? evt : window.event;
				let charCode = (evt.which) ? evt.which : evt.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
					evt.preventDefault();
				} else {
					return true;
				}
			},
			setContour: function(el) {
				this.$store.dispatch('set_contour_loyer', { contour: el })
			},
			DonneLibelleReference: function(filter) {
				switch (filter) {
					case 'typologie':
						return 'Logement T' + this.app.cityscan.realty.rooms
					case 'forme':
						if (this.app.cityscan.realty.typeId==1) {
							return 'Maison'
						} else {
							return 'Appartement'
						}
					case 'etat':
						switch (this.app.cityscan.realty.condition) {
							case 1:
								return 'Refait à neuf'
							case 2:
								return 'Rafraîchi'
							case 3:
								return 'Standard'
							case 4:
								return 'A rafraîchir'
							default:
								return 'A refaire'
						}
					case 'parking':
						if (this.app.cityscan.realty.parkingOpen==1) {
							return 'Parking inclus'
						} else {
							return 'Sans parking'
						}
					default:
						break;
				}
			},
			DonneValeurPrix: function(val) {
				const euro = new Intl.NumberFormat('fr-FR', {
					style: 'currency',
					currency: 'EUR',
					minimumFractionDigits: 0
				});
				if (val==0) {
					return "-"
				} else {
					return euro.format(val)
				}
			},
			
			details: function(d) {
				if (this.detail==d) {
					this.detail=''
				}
				else {
					this.detail=d
				}
			},
			updateSeriesLine: function(dataPrix, dataDuree) {
				function getGeoLocatif(t) {
					if (t.app.fond_carte_loyer == 2) return 'insee_code'
					return 'code_iris'
				}

				const th = getGeoLocatif(this)
				this.Prix.series = dataPrix
				this.Duree.series = dataDuree
				let d = [
					this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-4].periode.label,	
					this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-3].periode.label,	
					this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-2].periode.label,	
					this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-1].periode.label,	
				]

				const p = {
					series: dataPrix,
					chartOptions: {
						xaxis: {
							categories : d
						}
					}
				}
//				this.Prix.chartOptions.xaxis.categories = d
				this.Prix = p
			},
			maj_graph: function() {
				function getGeoLocatif(t) {
					if (t.app.fond_carte_loyer == 2) return 'insee_code'
					return 'code_iris'
				}

				const th = getGeoLocatif(this)
				if (this.app.bi_locatif[th] && this.app.bi_locatif[th].kpi && this.app.bi_locatif[th].kpi.historique && this.app.bi_locatif[th].kpi.historique.length>3) {
					this.sharingOptions.series = [{
						name: i18n.t('annonces'),
						data: [
						this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-4].total.nombre_annonces, 
						this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-3].total.nombre_annonces, 
						this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-2].total.nombre_annonces,
						this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-1].total.nombre_annonces,
						]
					}]
					this.usersOptions.series = [{
						name: 'Loyers médians',
						data: [
							this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-4].total.prix_m2_median), 
							this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-3].total.prix_m2_median), 
							this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-2].total.prix_m2_median),
							this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-1].total.prix_m2_median),
						]
					}]

					let dataPrix = []
					let dataDuree = []
					for (let index = 0; index < 5; index++) {
						dataPrix.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
								this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-4].typo['t' + (index+1)].prix_m2_median),
								this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-3].typo['t' + (index+1)].prix_m2_median),
								this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-2].typo['t' + (index+1)].prix_m2_median),
								this.getPrice(this.app.bi_locatif[th].kpi.historique[this.app.bi_locatif[th].kpi.historique.length-1].typo['t' + (index+1)].prix_m2_median),
								]
							}
						)
					}
					this.updateSeriesLine(dataPrix, dataDuree);
				}

				/*
				let val0 = null
				let val1 = null
				let val2 = null
				let val3 = null
				if (this.app.loyer_yanport && this.app.loyer_yanport.values && this.app.loyer_yanport.values.points[0]) {
					if (typeof this.app.loyer_yanport.values.points[0].y.values["50.0"] == 'number') {
						val0 = this.app.loyer_yanport.values.points[0].y.values["50.0"] 
					}
					if (typeof this.app.loyer_yanport.values.points[1].y.values["50.0"] == 'number') {
						val1 = this.app.loyer_yanport.values.points[1].y.values["50.0"] 
					}
					if (typeof this.app.loyer_yanport.values.points[2].y.values["50.0"] == 'number') {
						val2 = this.app.loyer_yanport.values.points[2].y.values["50.0"] 
					}
					if (this.app.loyer_yanport.values.points.length>3) {
						if (typeof this.app.loyer_yanport.values.points[3].y.values["50.0"] == 'number') {
							val3 = this.app.loyer_yanport.values.points[3].y.values["50.0"] 
						}
					}

					let min = val0
					let max = val0
					
					if (val1 < min) min = val1
					if (val2 < min) min = val2
					if (val1 > max) max = val1
					if (val2 > max) max = val2
					if (this.app.loyer_yanport.values.points.length>3) {
						if (val3 < min) min = val3
						if (val3 > max) max = val3
						if (val3) val3 = val3.toFixed(2)
					}
					

					if (val0) val0 = val0.toFixed(2)
					if (val1) val1 = val1.toFixed(2)
					if (val2) val2 = val2.toFixed(2)
					
					let _min = this.app.loyer_yanport.values.points[0].y.count
					let _max = this.app.loyer_yanport.values.points[0].y.count
					
					if (this.app.loyer_yanport.values.points[1].y.count < _min) _min = this.app.loyer_yanport.values.points[1].y.count
					if (this.app.loyer_yanport.values.points[2].y.count < _min) _min = this.app.loyer_yanport.values.points[2].y.count
					if (this.app.loyer_yanport.values.points[1].y.count > _max) _max = this.app.loyer_yanport.values.points[1].y.count
					if (this.app.loyer_yanport.values.points[2].y.count > _max) _max = this.app.loyer_yanport.values.points[2].y.count
					
					if (this.app.loyer_yanport.values.points.length>3) {
						if (this.app.loyer_yanport.values.points[3].y.count < _min) _min = this.app.loyer_yanport.values.points[3].y.count
						if (this.app.loyer_yanport.values.points[3].y.count > _max) _max = this.app.loyer_yanport.values.points[3].y.count
					}
					if (max-min < 2) {
						max ++
						min --
						if (min<0)
						min = 0
					}

					if (this.app.bi_locatif[getGeoLocatif()].kpi.historique.length>3) {
						this.sharingOptions.series = [{
							name: i18n.t('annonces'),
							data: [
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-4].total.nombre_annonces, 
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].total.nombre_annonces, 
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].total.nombre_annonces,
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].total.nombre_annonces,
							]
						}]
						
						
						this.usersOptions.series = [{
							name: 'Loyers médians',
							data: [
								this.getPrice(this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-4].total.prix_m2_median), 
								this.getPrice(this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].total.prix_m2_median), 
								this.getPrice(this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].total.prix_m2_median),
								this.getPrice(this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].total.prix_m2_median),
							]
						}]
					} else {
						this.sharingOptions.series = [{
							name: i18n.t('annonces'),
							data: [
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-3].total.nombre_annonces, 
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-2].total.nombre_annonces,
							this.app.bi_locatif[getGeoLocatif()].kpi.historique[app.bi_locatif[getGeoLocatif()].kpi.historique.length-1].total.nombre_annonces,
							]
						}]
						
						
						this.usersOptions.series = [{
							name: 'Loyers médians',
							data: [
							val0, val1, val2
							]
						}]
					}

					
					
					
					let j_val0 = null
					let j_val1 = null
					let j_val2 = null
					let j_val3 = null
					
					if (this.app.loyer_yanport && this.app.loyer_yanport.duration_values && this.app.loyer_yanport.duration_values.points) {
						if (typeof this.app.loyer_yanport.duration_values.points[0].y.values["50.0"] == 'number') {
							j_val0 = this.getDurationValues(0)
						}
						if (typeof this.app.loyer_yanport.duration_values.points[1].y.values["50.0"] == 'number') {
							j_val1 = this.getDurationValues(1)
						}
						if (typeof this.app.loyer_yanport.duration_values.points[2].y.values["50.0"] == 'number') {
							j_val2 = this.getDurationValues(2)
						}
						if (this.app.loyer_yanport.duration_values.points.length>3) {
							j_val3 = this.getDurationValues(3)
						}
					}
					if (this.app.loyer_yanport.duration_values.points.length>3) {
						this.usersOptionsJour.series = [{
							name: 'Durée de publication',
							data: [
							j_val0, j_val1, j_val2, j_val3
							]
						}]
					} else {
						this.usersOptionsJour.series = [{
							name: 'Durée de publication',
							data: [
							j_val0, j_val1, j_val2
							]
						}]

					}
					
					this.chartOptions_trim.yAxis[1].min = min
					this.chartOptions_trim.yAxis[1].max = max
					
					this.chartOptions_trim.yAxis[0].min = 0
					this.chartOptions_trim.yAxis[0].max = _max
					if (this.app.loyer_yanport.values.points.length>3) {
						this.chartOptions_trim.series[1].data = [
						val0, 
						val1, 
						val2, 
						val3, 
						]
						this.chartOptions_trim.series[0].data = [
						this.app.loyer_yanport.values.points[0].y.count, 
						this.app.loyer_yanport.values.points[1].y.count, 
						this.app.loyer_yanport.values.points[2].y.count, 
						this.app.loyer_yanport.values.points[3].y.count, 
						]
						this.chartOptions_trim.xAxis.categories= [this.app.loyer_yanport.values.points[0].x.substring(0, 4), this.app.loyer_yanport.values.points[1].x.substring(0, 4), this.app.loyer_yanport.values.points[2].x.substring(0, 4), this.app.loyer_yanport.values.points[3].x.substring(0, 4)]
					} else {
						this.chartOptions_trim.series[1].data = [
						val0, 
						val1, 
						val2, 
						]
						this.chartOptions_trim.series[0].data = [
						this.app.loyer_yanport.values.points[0].y.count, 
						this.app.loyer_yanport.values.points[1].y.count, 
						this.app.loyer_yanport.values.points[2].y.count, 
						]
						this.chartOptions_trim.xAxis.categories= [this.app.loyer_yanport.values.points[0].x.substring(0, 4), this.app.loyer_yanport.values.points[1].x.substring(0, 4), this.app.loyer_yanport.values.points[2].x.substring(0, 4)]

					}
					
					let fn;
					fn = function vall(s, index, x, n) {
						let r=null
						if (s) {
							if (s[index]) {
								if (s[index]) {
									if (s[index].points) {
										if (s[index].points[x]) {
											if (s[index].points[x].y) {
												if (s[index].points[x].y.values["50.0"]) {
													if (typeof s[index].points[x].y.values["50.0"] == 'number') {
														r = s[index].points[x].y.values["50.0"].toFixed(n)
													}
												}
											}
										}
									}
								}
							}
						}
						return r 
					}
					
					let dataPrix = []
					let dataDuree = []
					for (let index = 0; index < 5; index++) {
						if (this.app.locatif.yanport_typo_graph_prix && this.app.locatif.yanport_typo_graph_prix[index+1]) {
							dataPrix.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
									fn(this.app.locatif.yanport_typo_graph_prix, index+1, 0, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix, index+1, 1, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix, index+1, 2, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix, index+1, 3, 2), 
									fn(this.app.locatif.yanport_typo_graph_prix, index+1, 4, 2), 
								]
							}
							)
						} else {
							dataPrix.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
									null, 
									null, 
									null, 
								]
							}
							)

						}
						if (this.app.locatif.yanport_typo_graph_jour && this.app.locatif.yanport_typo_graph_jour[index+1]) {
							dataDuree.push ({
								name: 'T' + (index+1),
								type: 'line',
								data : [
									fn(this.app.locatif.yanport_typo_graph_jour, index+1, 0, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour, index+1, 1, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour, index+1, 2, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour, index+1, 3, 0), 
									fn(this.app.locatif.yanport_typo_graph_jour, index+1, 4, 0), 
								]
							})
						} else {
							dataDuree.push(
							{
								name: 'T' + (index+1),
								type: 'line',
								data : [
									null, 
									null, 
									null, 
								]
							}
							)

						}
					}
					this.updateSeriesLine(dataPrix, dataDuree);
				}
				*/
			},
		},
		watch: {
			'app.view': function(newValue) {
				if (newValue=='locatif') {
					this.maj_graph()
				}
			},
			'app.loyer_yanport.label': function() {
				this.maj_graph()
			},
			'app.yanport_typo_graph_jour_time': function(newValue) {
				if (newValue) {
					this.maj_graph()
				}
			},
			'app.yanport_typo_graph_prix_time': function(newValue) {
				if (newValue) {
					this.maj_graph()
				}
			},
			
		},
		mounted: function() {
			this.setContour(true)
			this.reference.name						= this.app.cityscan.realty.name
			this.reference.typeId					= this.app.cityscan.realty.typeId
			this.reference.rooms					= this.app.cityscan.realty.rooms
			this.reference.buildingConstruction		= this.app.cityscan.realty.buildingConstruction
			this.reference.condition				= this.app.cityscan.realty.condition
			this.reference.parkingOpen				= this.app.cityscan.realty.parkingOpen
			this.reference.area						= this.app.cityscan.realty.area		
			this.maj_graph()
		},
		components: { Button }
	}

</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.panecontent
	top 0px

.programs-list
	height 100%
.module_ret
	width PaneLeftSize
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.aide
	width 60px
	left 0px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.infinite-loader
	position relative
	vertical-align middle
	text-align center
	width 100%
	padding-left 40px
	height 280px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.pane-locatif-enter
	transform translateX(100%)
.pane-locatif-leave-to
	transform translateX(-100%)

.conjoncture
	width 340px
	padding-left 10px	
	padding-right 0 !important

.columnheader
	padding 5px
	background-color #f5f5f5	

.value
	display inline-block
	text-align right
	border none
	width 100%
	padding 0 12px
	height 32px
	background-color #f5f5f5
	border-radius 2px
	font-size 1.4rem
	line-height 32px
	outline none

.round
	padding 4px 8px
	height 24px
	left 0
	bottom 0
	background #FAFAFA
	border 1px solid rgba(0, 0, 0, 0.6)
	box-sizing border-box
	border-radius 20px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight normal
	font-size 12px
	line-height 16px
	letter-spacing 0.4px
	color rgba(0, 0, 0, 0.87)
	text-align center

.sous-titre
	font-weight 500
	color #000
	padding-top 10px
	padding-bottom 10px

.lien_module
	left 330px
	font-size 0.8rem
	color red
	padding-bottom 5px
	cursor pointer
.bar
	height 16px
	margin 0 0 16px 0
	border-radius radius
	.progress
		position absolute
		left 0
		top 0
		height 100%
		&:first-child
			border-top-left-radius radius
			border-bottom-left-radius radius
		&:last-child
			border-top-right-radius radius
			border-bottom-right-radius radius
		.percent
			position absolute
			left 50%
			transform translateX(-50%)
			font-size 0.7rem
			font-weight 500
			line-height 18px
			color #000
.module
	width 430px !important
	height auto
	padding-top 10px
	vertical-align middle
	background #f0f0f0 !important
	border 0 !important
	cursor pointer
	border-right 0
	padding-left 0 !important
	margin-right 10px
	margin-left 10px

.titre
	width 435px
	height 40px
	padding-left 0 !important
	padding-right 0 !important
	padding-top 10px
	margin-right 10px
	margin-left 10px
	vertical-align middle
	background-color #fafafa
	border-bottom 1px solid #cccccc
	border-top 1px solid #cccccc
	font-size 10pt
	font-weight bold
	text-align center

.module
	width PaneLeftSize
	padding-left 10px
	padding-top 10px
	vertical-align middle
	background-color #fff
	border-bottom 1px solid #cccccc


.pan-locatif
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.scoring
	transition max-height 0.3s ease-out
	font-size 0.8rem
	.vie_quartier
		height 800px

.button
	width 170px
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	height 30px
	cursor pointer
	box-shadow 0 0 4px alpha(#000, 25%)
	&:hover
		background-color darken(blue, 10%)
		color white

.maplayer_filtres
	position fixed
	width 500px
	top calc(50% - 290px)
	left calc(50% - 250px)
	z-index 600
	transition 0.5s easeOutQuart
	padding 7px 10px 10px 10px
	text-align center
	border-radius radius 5px
	background white
	color #666
	cursor pointer
	max-height 900px
	margin-top 1.33333rem
	padding 20px
	pointer-events all
	transform translateY(0)
	opacity 1
	border-radius 0
	box-shadow 0 6px 24px 0 alpha(#000, 25%)
	transition .3s transform
	transition-property transform
	transition-duration 0.3s
	transition-timing-function ease
	transition-delay 0s

.filtre
	font-weight bold
	text-align left


.box
	margin 0 0 24px 0
	&:first-child
		padding-top 16px
	.box-title
		padding 0 16px
		margin 0 0 4px 0
		font-family volte, sans-serif
		font-weight 600
		color #333
		line-height 16px
		text-align left
		padding-bottom 8px
		padding-top 8px
	.box-content
		display block
		padding 0 16px

.switches
	margin-left -8px
	&:after
		content ''
		display block
		clear both

.closeBtn
	float right
	top 0px
	z-index 999

.choix
	width: 430px
	height: 80px
.unchoix
	top: -6px

.tableloyer
	td
		border: solid 1px #CCCCCC		

.buttonv
	border 0
	padding 0 16px
	border-radius 0
	background primaryA400
	border-radius 18px
	font-family Montserrat, sans-serif
	font-style normal
	font-weight 500
	font-size 14px
	line-height 16px
	line-height 36px
	text-align center
	letter-spacing 0.75px
	text-transform uppercase
	color #FFFFFF
	width 250px
	height 36px
	cursor pointer
	&:hover
		background secondary
	> a
		color #FFFFFF !important

.titre2
	background-color primaryA400
	color lighten(primaryA100, 90%)

.unmodule_sous
	vertical-align middle
	padding 0px
	text-align center
	line-height 40px

.unmodule_sous2
	vertical-align middle
	padding 0px
	text-align center
	width PaneLeftSize
	width calc(100% - 120px)
	left 60px
	line-height 60px
	p
		font-size 18px	
		color #333 !important
		font-weight 600 !important

.thematique2
	position absolute
	top 18px
	right 30px
	font-size 16px
	color #888
	font-weight 500



.padleft
	padding-left 15px !important
.padtop
	padding-top 15px !important
.dropdown-menu
	left auto
	right 0
	position absolute
	top 100%
	z-index 20
	margin-top 68px
	box-shadow 0 5px 16px hsl(0deg, 0%, 93%)
	border-color hsl(0deg, 0%, 93%)
	padding-top 0
	min-width 260px
	display block
.dropdown-content
	background-color #fff
	border-radius 4px
	box-shadow none
	padding-bottom 0.5rem
	border 1px solid hsl(0deg, 0%, 93%)
    padding-top 0
    overflow hidden

.dropdown-head
	display flex
	align-items center
	padding 28px 16px
	margin-bottom 12px
	background #f1f1f1

.dropdown.is-spaced .dropdown-item.is-media
	display flex
	align-items center
	
.dropdown .dropdown-menu .dropdown-item
	color hsl(232deg, 14%, 68%)
	font-family "Roboto", sans-serif
.dropdown-item
	padding 0.5rem 1rem
	font-size 0.85rem
	color hsl(232deg, 14%, 68%)
	transition all 0.3s
a.dropdown-item, button.dropdown-item
	padding-right 3rem
	text-align inherit
	white-space nowrap
	width 100%

.dropdown-item
	color hsl(0deg, 0%, 29%)
	display block
	font-size 0.875rem
	line-height 1.5
	padding 0.375rem 1rem
	position relative

.dropdown-divider
	background-color hsl(0deg, 0%, 93%)
	border none
	display block
	height 1px
	margin 0.5rem 0

.button.v-button:not([disabled])
	cursor pointer

.button.is-fullwidth
	display flex
	width 100%

.button.is-primary
	background-color palette0
	border-color transparent
	color #ededed
	&:hover
		opacity 0.9
		box-shadow var(--primary-box-shadow)

.button
	-webkit-appearance none
	align-items center
	border 1px solid transparent
	border-radius 4px
	box-shadow none
	display inline-flex
	font-size 1rem
	height 2.5em
	justify-content flex-start
	line-height 1.5
	padding-bottom calc(0.5em - 1px)
	padding-left calc(0.75em - 1px)
	padding-right calc(0.75em - 1px)
	padding-top calc(0.5em - 1px)
	position relative
	vertical-align top

.button.v-button
	height 38px
	line-height 1.1
	font-size 0.85rem
	font-family "Roboto", sans-serif
	transition all 0.3s
	justify-content center
	text-align center
	white-space nowrap
	border-width 1px

.business-dashboard
	width 330px !important
.xls
	width 60px
	position absolute
	right 60px
	top 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.infinite-loader2
	top calc(50% - 64px)
	text-align center
	.loader
		display inline-block
		background main_color
		border none
		border-radius 100%
		color #ccc
		.icon
			display block
			animation rotate 1.5s infinite linear

.company-dashboard .company-header {
	display: flex;
	padding: 20px;
	background: var(--white);
	border: 1px solid var(--fade-grey-dark-3);
	border-radius: 4px;
	margin-bottom: 0.2rem;
}
.company-dashboard .company-header .header-item {
    width: 50%;
    border-right: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .company-header .header-item:last-child {
    border-right: none;
}
.company-dashboard .company-header .header-item .item-inner {
    text-align: center;
}
.company-dashboard .company-header .header-item .item-inner .lnil, .company-dashboard .company-header .header-item .item-inner .lnir {
    font-size: 1.8rem;
    margin-bottom: 6px;
    color: var(--primary);
}

.lnil {
    display: inline-block;
    font: normal normal normal 1em/1 'LineIconsPro Light';
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.company-dashboard .company-header .header-item .item-inner span {
    display: block;
    font-family: var(--font), sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--dark-text);
}
.company-dashboard .company-header .header-item .item-inner p {
    font-family: var(--font-alt), sans-serif;
    font-size: 0.85rem;
}


.r {
    font-family: var(--font-alt), sans-serif !important;
    font-size: 0.85rem !important;
    font-family: var(--font), sans-serif !important;
    font-weight: 300 !important;
    color: var(--light-text) !important;
	display: initial !important;
}


.company-dashboard .dashboard-card.is-base-chart {
    padding: 0;
    display: flex;
    flex-direction: column;
}

.company-dashboard .dashboard-card {
    flex: 1;
    display: inline-block;
    width: 100%;
    padding: 20px;
    background-color: var(--white);
    border-radius: 6px;
    border: 1px solid var(--fade-grey-dark-3);
    transition: all 0.3s;
    height: 100%;
}
.company-dashboard .dashboard-card.is-base-chart .content-box {
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 30px;
	padding-bottom: 30px;
}
.company-dashboard .dashboard-card.is-base-chart .chart-container {
    margin-top: auto;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--fade-grey-dark-3);
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat {
    margin-right: 5px;
    font-family: var(--font), sans-serif;
	text-align: center;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:first-child {
    text-transform: uppercase;
    font-family: var(--font-alt), sans-serif;
    font-size: 0.75rem;
    color: var(--light-text);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span {
    display: block;
}
.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span.current {
    color: var(--primary);
}

.company-dashboard .dashboard-card.is-base-chart .content-box .revenue-stats .revenue-stat span:nth-child(2) {
    color: var(--dark-text);
    font-size: 1.4rem;
    font-weight: 600;
}
.revenue-stat {
	width: 33%
}
.chart-container {
}
.dark-inverted2 {
	font-size: 1.4rem !important;
}

:deep(.p-button-label) {
	font-weight: 400 !important;
}	
</style>
