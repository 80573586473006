<template>
	<transition name="pane-etude">
		<div class="pan-etude">
			<div class="cs-widget-collection-card">
				<table style="width:100%;height:auto;">
					<th style="display: none"></th>
					<tr style="height:25px;">
						<td>Le {{ etude.created_at.slice(8,10) }}/{{ etude.created_at.slice(5,7) }}/{{ etude.created_at.slice(0,4) }}</td>
						<td rowspan="2" style="width:50px;"><div><a :href="etude.his_url" target="_blank"><span><icon file="download" /></span></a></div></td>
					</tr>
					<tr style="height:25px;">
						<td class="secteur">{{etude.his_label}}</td>
					</tr>
				</table>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'PaneEtude',

	data: function() {
		return {
			markerSub: false,
		}
	},
	
	props: {
        etude: {
			default: false
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

    mounted: function() {

	},

	methods: {

    },
	
	components: {  }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

table
	tr
		td
			padding-left 10px			
			padding-top 10px
			padding-bottom 10px

.pan-etude
	width 100%
	padding-bottom 10px

.secteur
	font-weight normal

.cs-transaction-id
	position: absolute
	left: 50px
	top 10px

.cs-transaction-price
	text-align: right
	position: absolute
	right: 20px
	top 10px
	font-weight 600

.cs-transaction-year
	position: absolute
	left: 150px
	top 10px

.cs-transaction-type
	position: absolute
	left: 200px
	top 10px

.cs-transaction-parcel
	top 34px
	left: 50px

.cs-collection-card-header
	border-bottom 1px solid #EFEFEF
	height 60px

.cs-widget-collection-card .cs-collection-data-tag
	border-radius 20px
	font-size .813rem
	padding 7px 10px
	color #fff
	display inline-block

.cs-collection-card-content
	padding-top 10px
	padding-bottom 10px
	border-bottom 1px solid #EFEFEF

.cs-collection-data-row
	padding-top 10px

.cs-collection-detail-link
	left 220px
	width 100%
	padding 16px 0

.maison
	background-color rgb(64, 137, 0)
.appartement
	background-color rgb(6, 129, 175)
.terrain
	background-color rgb(255, 167, 38)
.dependance
	background-color rgb(255, 23, 19)
.local
	background-color rgb(102, 0, 229)

.icone_parcelle
	font-size 1.2rem
	position absolute
	top 2px

.parcelle
	background #c72a2a
	height 24px
	width 150px
	padding-left 5px
	padding-right  5px
	padding-top 5px
	padding-bottom 5px
	border-radius 3px
	border 1px
	color #fff
	margin-right 5px
	margin-top 5px
	-webkit-transition .2s ease-in-out
	transition .2s ease-in-out

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

</style>
