<template>
	<transition name="slide">
		<div class="pane pane-isochrone" :class="app.viewlayer"  v-if="false">
			<div class="header">
				<div class="back-button" v-if="app.viewlayer == 'isochrone'" @click="set_view('')"><icon file="x" :size="32" /></div>
				<div class="texte">{{ $t('isochrone') }}</div>
			</div>
			<div class="right-bar" v-if="app.viewlayer == 'isochrone'">
				<div class="right-bar-content" v-bar>
					<div class="content">
						<div>
							<br/>
							<div class="">{{ $t('moyen_de_transport') }} </div>
							<br/>
							<div class="choix" style="height:100px">
								<div class="box-content">
									<div class="switches">
										<switch-button size2="80px" icon="block" divise="five" @click.native="toggle_switch(false);setContour(app.cityscan.isochrone.transportType)" :class="{ active: !app.cityscan.isochrone.transportType }" />
										<switch-button size2="80px" icon="directions_bus" divise="five" @click.native="toggle_switch('TRANSIT');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='TRANSIT' }" />
										<switch-button size2="80px" icon="directions_car" divise="five" @click.native="toggle_switch('DRIVING');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='DRIVING' }" />
										<switch-button size2="80px" icon="directions_bike" divise="five" @click.native="toggle_switch('BIKING');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='BIKING' }" />
										<switch-button size2="80px" icon="directions_walk" divise="five" @click.native="toggle_switch('WALKING');setContour(app.cityscan.isochrone.transportType)" :class="{ active: app.cityscan.isochrone.transportType=='WALKING' }" />
									</div>
								</div>
							</div>
							<div class="">{{ $t('temps_en_minutes') }}</div>
							<br/>
							<div class="choix" style="height:120px">
								<div class="box-content">
									<div class="switches">
										<switch-button label="5" divise="six" @click.native="toggle_switch_two(5);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==5 }" />
										<switch-button label="10" divise="six" @click.native="toggle_switch_two(10);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==10 }" />
										<switch-button label="15" divise="six" @click.native="toggle_switch_two(15);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==15 }" />
										<switch-button label="20" divise="six" @click.native="toggle_switch_two(20);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==20 }" />
										<switch-button label="25" divise="six" @click.native="toggle_switch_two(25);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==25 }" />
										<switch-button label="30" divise="six" @click.native="toggle_switch_two(30);setContour(app.cityscan.isochrone.transportType)" class='small' :class="{ active: app.cityscan.isochrone.duration==30, inactive:(app.cityscan.isochrone.transportType=='TRANSIT') }" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import SwitchButton from './global/SwitchButton'

export default {
	name: 'PaneIsochrone',

	data () {
		return {
			visible: false
		}
	},

	methods: {
		setContour: function(el) {
			this.$store.dispatch('set_contour_iso_map', { contour: el })
		},

		toggle_switch_two: function(duration) {
			this.$store.dispatch('set_contour_iso_map_param', {transportType: this.app.cityscan.isochrone.transportType, duration: duration})			
		},

		toggle_switch: function(transportType) {
			this.$store.dispatch('set_contour_iso_map', {contours: false})
			this.$store.dispatch('set_contour_iso_map_param', {transportType: transportType, duration: this.app.cityscan.isochrone.duration})
		},

		set_view: function() {
			this.$store.dispatch('set_view_layer', { view: '' })
		},
	},

	computed: Object.assign(mapGetters([ 'app', 'report', 'selected_programs' ]), {
		
	}),
	
	components: { SwitchButton }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'


.pane-isochrone
	position fixed
	left 100%
	top headersize
	bottom 0
	height calc(100% - 155px)
	width 400px
	background-color #333
	color #ccc
	transition 0.5s easeOutQuart
	border-left 1px solid darken(light, 10%)
	z-index 500
	&.isochrone
		transform translateX(-100%)
	&.slide-enter, &.slide-leave-to
		transform translateX(0)
	.header
		background secondary
		height 60px
		.back-button
			position absolute
			right 10px
			top 6px
			padding 8px
			color #fff
			cursor pointer
			border-radius 100%
			transition 0.25s easeOutQuart
			&:hover
				background palette2
		.texte
			top 22px
			left 10px
			height 48px
			width 100px
			color white
		.tabs
			padding 0 32px
			color #fff
			&:after
				content ''
				display block
				clear both
			.tab
				float left
				margin 0 32px 0 0
				height 96px
				width 96px
				display flex
				align-items center
				justify-content center
				flex-direction column
				opacity 0.5
				font-size 12px
				font-weight 500
				border-bottom 4px solid transparent
				text-align center
				text-transform uppercase
				cursor pointer
				&:hover
					opacity 0.75
				&.active
					opacity 1
					border-color light
				.icon
					display block
					margin 0 0 4px 0
				
	.right-bar
		padding-left 10px
		height 100%
		background white
		width 100%
		color #333
		.right-bar-content
			height 100%
			.content
				height 100%

		.scroll-dots
			position absolute
			left -40px
			top 24px
			width 32px
			background alpha(green, 50%)
			border-radius radius
			z-index 2
			transition 0.25s easeOutQuart
			&:hover
				background alpha(green, 75%)
			.dot
				display block
				width 8px
				height 8px
				margin 12px
				background #fff
				opacity 0.25
				border-radius 100%
				transition 0.25s easeOutQuart
				cursor pointer
				&:hover, &.active
					opacity 1
					
.choix
	padding-bottom 20px
	padding-right 18px

</style>
